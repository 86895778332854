import { InlineContent, Icon, context, Notification } from '@lightning/ui'

export class OverrideIconYInlineContent extends InlineContent {
  _createIcon(base, iconProps) {
    return {
      ...base,
      type: Icon,
      y: 0,
      w: this.style.iconW,
      h: this.style.iconH,
      ...iconProps,
    }
  }
}

export default class ToastNotification extends Notification {
  static _template() {
    const template = { ...super._template(), zIndex: 999 }
    // override Notification template for Title and Description from Textbox to InlineContent
    const Title = {
      ...template.Container.Content.Text.Title,
      type: OverrideIconYInlineContent,
      w: 420,
      contentWrap: true,
      justify: 'flex-start',
      style: {
        iconH: 32,
        iconW: 32,
        contentSpacing: 2,
        textStyle: {
          ...context.theme.typography.headline3,
        },
      },
    }
    const Desc = {
      ...template.Container.Content.Text.Description,
      type: OverrideIconYInlineContent,
      w: 410,
      contentWrap: true,
      justify: 'flex-start',
      style: {
        iconH: 32,
        iconW: 32,
        contentSpacing: 2,
        textStyle: {
          ...context.theme.typography.body3,
        },
      },
    }
    template.Container.Content.Text.Title = Title
    template.Container.Content.Text.Description = Desc
    return template
  }

  _updateIcon() {
    super._updateIcon()
    this._Icon.patch({ y: !this._description && 28 })
  }

  _updateTitle() {
    this._Title.patch({
      content: this._title,
      y: !this._description && 32,
    })
    this._titleLoaded(true)
  }

  _updateDescription() {
    this._Description.patch({
      content: this._description,
    })
    this._descriptionLoaded(true)
  }

  _init() {
    // delay enter call to allow height to properly get set
    this._enterDelay = setTimeout(() => {
      this.enter()
    }, 500)
    // only automatically dismiss a notification if persist is falsy
    if (!this.persist) {
      this._dismissDelay = setTimeout(async () => {
        await this.dismiss()
        this.dismissCallback && this.dismissCallback()
        // once dismissed remove from the notifications container (parent)
        this.fireAncestors('$removeNotification', this.notificationId)
      }, 7000)
    }
  }

  _disable() {
    clearTimeout(this._dismissDelay)
  }
}
