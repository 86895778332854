import { Hero } from '../../components/suite/Hero'
import { context, Column, TitleRow, TextBox } from '@lightning/ui'
import { Router, Language } from '@lightningjs/sdk'
import PageBase from '../PageBase'
import { imagePath } from '../../utils/imagePath'
import strictUriComponentEncode from '../../utils/strictUriComponentEncode'
import EntityBackground from '../../components/EntityBackground'
import { sentenceCase } from '../../utils/sentenceCase'
import { GameCategories } from '../../constants/playProviderEnums'
import EntityDetails from '../../components/EntityDetails'
import Tile from '../../components/Tile'
import { RemoteConfig } from '../../lib/remoteConfig'
import dataLayerAnalytics from '../../lib/analytics/dataLayerAnalyticsService'
import ControllerConnectionChange from '../../lib/bluetooth/controllerConnectionChange'
import { RouteHash } from '../../constants/routeEnums'
import { corner } from '../../components/XGButton'
import UserAccountProvider from '../../api/providers/userAccountProvider'
import * as ratingsData from '../../../static/rating/data.json'
import CodeGenerator from '../../lib/codeGenerator'
import { NotificationType } from '../../constants/notificationEnums'

class BottomAlignedHero extends Hero {
  static _template() {
    return {
      ...super._template(),

      // Make Hero flexbox align to bottom
      flex: {
        direction: 'column',
        justifyContent: 'flex-end',
      },
    }
  }

  _init() {
    super._init()
    this._Metadata.patch({
      style: {
        textStyle: context.theme.typography.body3,
        contentSpacing: 8,
      },
      contentProperties: {
        paddingRight: 8,
        verticalAlign: 'top',
      },
    })
  }
}

export default class EntityPage extends PageBase {
  static _template() {
    return {
      hideNav: true,
      w: 1920,
      h: 1080,
      Background: {
        type: EntityBackground,
      },
      SectionsContainer: {
        type: Column,
        x: context.theme.layout.marginX,
        y: 424,
        w: context.theme.layout.screenW - context.theme.layout.marginX,
        h: context.theme.layout.screenH - context.theme.layout.marginY * 2,
        zIndex: 2,
        alwaysScroll: true,
        alpha: 0.001,
        items: [
          {
            type: BottomAlignedHero,
            w: 1920 - context.theme.layout.marginX * 2,
            h: 294,
            extraItemSpacing: -4,
          },
          {
            type: TextBox,
            content: ' ', // default to empty string because we need the textbox's height for layout
            style: {
              textStyle: {
                ...context.theme.typography.body3,
              },
            },
            skipFocus: true,
            extraItemSpacing: 28,
          },
          { type: EntityDetails, extraItemSpacing: 65 },
          {
            type: TitleRow,
            title: 'Image gallery',
            h: 294,
            lazyScroll: true,
            // This row not announced b/c it's all images, override with ''
            announce: ' ',
          },
        ],
      },
    }
  }

  historyState() {
    return {
      breadcrumbTitle: this.data.name,
    }
  }

  _bindFunctions() {
    this._patchButton = this._patchButton.bind(this)
    this._buttonCallback = this._buttonCallback.bind(this)
  }

  _active() {
    this._bindFunctions()
    this._controllerConnectionChange = new ControllerConnectionChange(this._patchButton)
    this._controllerConnectionChange.renderWithControllerData()
  }

  _inactive() {
    this._controllerConnectionChange.removeListener()
  }

  _onDataProvided() {
    this._heroBackground = imagePath(this.data, this.data.assets?.entity)
    this._title = this.data.name
    this._description = this.data.description.long

    dataLayerAnalytics.processComplete()

    this._renderWithEntityData()
  }

  _formatPlayerText(players) {
    if (players.startsWith('1')) {
      return '1 Player'
    } else {
      const numPlayers = players.charAt(0)
      return `1-${numPlayers} Players`
    }
  }

  _getTitleMetadataContent() {
    const contentArray = []
    this.data.author && contentArray.push({ text: this.data.author })
    if (this.data.multiPlay) {
      contentArray.push({ badge: this._formatPlayerText(this.data.multiPlay) })
      RemoteConfig.getInstance().couchplayEnabled &&
        this.data.remoteMultiPlayer &&
        contentArray.push({ badge: 'Party' })
    }
    this.data.isJoystickControl && contentArray.push({ badge: 'Bluetooth game controller' })
    return contentArray
  }

  _getDetailsBadges() {
    const badges = !this.data.categories
      ? []
      : this.data.categories.map((category) => {
          category = decodeURI(category) // in case a category is encoded, decode it for better UI formatting
          return {
            badge:
              category === GameCategories.RPG ? category.toUpperCase() : sentenceCase(category),
          }
        })
    return badges
  }

  _getImageTiles() {
    // return early if game has no gallery thumbnails
    if (!this.data.assets?.galleryThumbnails || this.data.assets?.galleryThumbnails.length == 0) {
      return
    }
    const imageTiles = this.data.assets?.galleryThumbnails.map((image, index) => {
      return {
        type: Tile,
        width: 523,
        height: 294,
        imgSrc: imagePath(this.data, `galleryThumbnails/${image}`),
        navigate: () => {
          Router.navigate(
            `${RouteHash.ENTITY_PATH}${strictUriComponentEncode(this.data.gamePlayTitle)}${
              RouteHash.ENTITY_GALLERY_PATH
            }${index}`,
            {
              keepAlive: true,
            }
          )
        },
      }
    })
    return imageTiles
  }

  _renderWithEntityData() {
    this._Background.patch({
      imgSrc: this._heroBackground,
    })
    this._Hero.patch({
      metadata: this._getTitleMetadataContent(),
      title: this._title,
    })
    this._EntityDetails.patch({
      overview: this._description,
      overviewOnEnter: () =>
        Router.navigate(
          `${RouteHash.ENTITY_PATH}${strictUriComponentEncode(this.data.gamePlayTitle)}${
            RouteHash.ENTITY_OVERVIEW_PATH
          }`,
          {
            keepAlive: true,
          }
        ),
      rating: this.data.rating,
      genres: this._getDetailsBadges(),
      languages: this.data.locale?.join(', '),
    })

    const imageGalleryItems = this._getImageTiles()
    if (!imageGalleryItems) {
      // if no gallery items remove image gallery
      this._SectionsContainer.removeItemAt(2)
    } else {
      this._ImageGallery.patch({
        items: imageGalleryItems,
      })
    }

    this._SectionsContainer.setSmooth('alpha', 1, {
      duration: 0.75,
      delay: 0.5,
    })
  }

  _patchButton(controllerConnected) {
    this._Hero.patch({
      buttons:
        // current unplayable state to show no CTA
        this.data.playable && [
          {
            title: this._getButtonTitle(controllerConnected),
            onEnter: () => {
              this._buttonCallback(controllerConnected)
            },
            rounded: corner.bottomLeftAndTopRight,
          },
        ],
    })
    // after buttons have been added to Hero, add voice guidance properties
    // that rely on the existence of + number of buttons in Hero
    for (let i = 0; i < this._HeroButtons?.length; i++) {
      this._HeroButtons[i].announceContext = [
        Language.translate('ANNOUNCE_LENGTH', i + 1, this._HeroButtons?.length),
        this._UnlockHelperText.content,
        'PAUSE-2',
      ]
    }

    this._Hero.announceContext = [
      this._HeroButtons?.length > 1 && Language.translate('ENTITY_NAVIGATION_LEFT_RIGHT'),
      Language.translate('ENTITY_NAVIGATION_UP_DOWN'),
      'PAUSE-2',
    ]
  }

  _getButtonTitle(controllerConnected) {
    if (controllerConnected) {
      if (UserAccountProvider.getInstance().contentControls[this.data.rating]) {
        // if this game's rating is locked add helper text and set button title
        this._UnlockHelperText.patch({
          content: `A one-time code will be sent to the account holder's mobile number.`,
        })
        return 'Unlock to play'
      }
      return 'Play'
    } else {
      return 'Connect a controller'
    }
  }

  _buttonCallback(controllerConnected) {
    if (
      this.data.gamePlayTitle != null &&
      this.data.gamePlayTitle.length > 0 &&
      this.data.playable
    ) {
      if (controllerConnected) {
        if (UserAccountProvider.getInstance().contentControls[this.data.rating]) {
          // if this game's rating is locked
          this._navigateToNextContentControls()
          return
        }
        this._navigateToGameplay()
      } else {
        Router.navigate(RouteHash.SETTINGS)
      }
    }
  }

  _navigateToGameplay() {
    Router.navigate(
      `${RouteHash.GAMEPLAY_PATH}${strictUriComponentEncode(this.data.gamePlayTitle)}`,
      {
        keepAlive: true,
        gamePlayTitle: this.data.gamePlayTitle,
      }
    )
  }

  _navigateToNextContentControls() {
    if (!UserAccountProvider.getInstance().mobilePhone) {
      Router.navigate(RouteHash.CONTENT_CONTROLS + RouteHash.NO_PHONE_CONTENT_CONTROLS_PATH)
    } else {
      const rating = ratingsData[this.data.rating].title
      const game = this.data.name
      // generate the code and send the text to the user
      CodeGenerator.getInstance().generateCode()
      UserAccountProvider.getInstance()
        .unlockUserAccount({
          code: CodeGenerator.getInstance().code,
          rating,
          game,
        })
        .catch(() => {
          this.fireAncestors('$sendNotification', {
            type: NotificationType.SEND_CODE_FAILED,
          })
        })
      this._navigateToCodeEntry({ rating, game })
    }
  }

  _navigateToCodeEntry({ rating, game }) {
    const nextRoutePath = RouteHash.CONTENT_CONTROLS + RouteHash.ENTER_CODE_CONTENT_CONTROLS_PATH
    Router.navigate(
      CodeGenerator.getInstance().codeEntryDev
        ? `${nextRoutePath}/${CodeGenerator.getInstance().code}`
        : nextRoutePath,
      // pass these params to code entry page for resend code functionality
      {
        gameRating: rating,
        gameName: game,
        onSubmit: () => {
          // reset codeChallengePassed
          CodeGenerator.getInstance().codeChallengePassed = false
          this._navigateToGameplay()
        },
        keepAlive: true, // keepAlive so that page isn't destroyed in case we need to dispatch error toast
      }
    )
  }

  _getFocused() {
    return this._SectionsContainer
  }

  get _SectionsContainer() {
    return this.tag('SectionsContainer')
  }

  get _Background() {
    return this.tag('Background')
  }

  get _Hero() {
    return this._SectionsContainer.Items.children[0]
  }

  get _HeroButtons() {
    return this._Hero.buttons
  }

  get _UnlockHelperText() {
    return this._SectionsContainer.Items.children[1]
  }

  get _EntityDetails() {
    return this._SectionsContainer.Items.children[2]
  }

  get _ImageGallery() {
    return this._SectionsContainer.Items.children[3]
  }
}
