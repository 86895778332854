import { Row, Button } from '@lightning/ui'
import { Language } from '@lightningjs/sdk'
import { RemoteConfig } from '../lib/remoteConfig'

export default class FilterRow extends Row {
  static _template() {
    return {
      ...super._template(),
      items: [],
      wrapSelected: true,
    }
  }

  _getButtons() {
    this._buttonsArray = this.filters?.map((filter, index) => {
      return {
        type: Button,
        h: 72,
        style: {
          minWidth: 1,
          radius: 32,
        },
        title: filter,
        announceContext: [
          Language.translate('ANNOUNCE_LENGTH', index + 1, this.filters.length),
          Language.translate('ENTITY_NAVIGATION_LEFT_RIGHT'),
          'PAUSE-2',
        ],
        alpha: 0.001,
      }
    })
    return this._buttonsArray
  }

  _init() {
    super._init()
    this.filters && this.patch({ items: this._getButtons() })
    if (this.items.length > 0) {
      // set selected index state to ensure the selected category is the one focused when coming back from unfocused
      this._selectedState = 0
      this.selectedIndex = this._selectedState
      // auto select (but not focus) the first quick filter on init
      this.selected.mode = 'selected'
      // call signal to filter by selected
      this.fireAncestors('$updateGames', this.selected.title)
    }
  }

  _firstActive() {
    // delay smoothing in filter buttons to prevent visual overlap on load
    this._delayFirstRender = setTimeout(() => {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].setSmooth('alpha', 1, { duration: 0.5 })
      }
    }, 500)
  }

  _inactive() {
    clearTimeout(this._delayFirstRender)
  }

  _focus() {
    this.selectedIndex = this._selectedState
  }

  _unfocus() {
    this._selectedState = this.selectedIndex
    this.selected.mode = 'selected'
  }

  _debounceFilterSelection() {
    // ensure user has stopped scrolling filter options before firing
    // signal, minimizing API calls and improving performance
    this._id && clearTimeout(this._id)
    this._id = setTimeout(() => {
      this.fireAncestors('$updateGames', this.selected.title)
      clearTimeout(this._id)
      // debounce delay is configurable, advised to be > input hold timeout
    }, RemoteConfig.getInstance().inputFilterSelectDebounce)
  }

  selectNext() {
    super.selectNext()
    this._debounceFilterSelection()
  }

  selectPrevious() {
    if (this.selectedIndex === 0) {
      // if select previous occurs when index is 0 signal parent to focus on nav drawer
      this.fireAncestors('$focusNav')
    } else {
      // if select previous occurs when index is not 0, handle as typical select previous
      // and filter games accordingly
      super.selectPrevious()
      this._debounceFilterSelection()
    }
  }

  onScreenEffect(onScreenItems) {
    if (this.items && this.items.length) {
      for (let i = 0; i < this.items.length; i++) {
        let mode
        if (i === this.selectedIndex) {
          if (this.items[i].mode === 'focused') {
            mode = 'focused'
          } else {
            mode = this.items[i].mode === 'selected' ? this.items[i].mode : 'selected'
          }
        } else if (onScreenItems.includes(this.items[i]) || i > this.selectedIndex) {
          mode = 'unfocused'
        } else {
          mode = 'offScreen'
        }
        this.items[i].mode = mode
      }
    }
  }
}
