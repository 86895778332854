import lng from '@lightningjs/core'
import { utils, context } from '@lightning/ui'
import { OverviewPanel } from './overview/OverviewPanel'
import { KeyboardPanel } from './KeyboardPanel'
import { CouchplayPanel } from './couchplay/CouchplayPanel'
import { SidekickTabBar } from './SidekickTabBar'

export class Sidekick extends lng.Component {
  static _template() {
    return {
      FadedBackground: {
        rect: true,
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        color: utils.getHexColor('#0D0D0F', 0.64),
      },
      Sidekick: {
        w: 490,
        h: 984,
        x: 1390,
        y: 48,
        Background: {
          rect: true,
          texture: lng.Tools.getRoundRect(
            490,
            984,
            24,
            0,
            utils.getHexColor('#232328'),
            true,
            utils.getHexColor('#232328')
          ),
        },
        TabBar: {
          type: SidekickTabBar,
          x: 35,
          y: 35,
          alpha: 0.001,
        },
        KeyboardPanel: {
          type: KeyboardPanel,
          panelName: 'KeyboardPanel',
          mountX: 0.5,
          x: (w) => w / 2,
          y: 149,
          alpha: 0,
        },
        OverviewPanel: {
          type: OverviewPanel,
          panelName: 'OverviewPanel',
          mountX: 0.5,
          x: (w) => w / 2,
          y: 149,
          alpha: 0.001,
        },
        CouchplayPanel: {
          type: CouchplayPanel,
          panelName: 'CouchplayPanel',
          mountX: 0.5,
          x: (w) => w / 2,
          y: 149,
          alpha: 0,
        },
      },
    }
  }

  _init() {
    this._CouchplayPanel.gamingClient = this.gamingClient
    this._OverviewPanel.patch({
      gameDetails: this.gameDetails,
      gamingClient: this.gamingClient,
      exitOverride: this.exitOverride,
    })
    this._TabBar.patch({
      sessionCreated: this.gamingClient && this.gamingClient.partyIsOngoing,
    })
    this._CouchplayPanel.patch({
      gameHasCouchplay: this.gameDetails.remoteMultiPlayer,
    })
    // when first attached move the Notifications Container over to prevent overlap
    this.fireAncestors('$offsetXNotificationsContainer', this._Sidekick.w + 24) // 24 for margin
  }

  _firstActive() {
    this.reset()
    this._refocus()
    this._TabBar.setSmooth('alpha', 1, { delay: 0.3 })
  }

  reset() {
    this._TabBar.reset()
    this._focusComponent = this._TabBar
  }

  $onTabFocus(panelName) {
    if (this._renderTimeout) {
      clearTimeout(this._renderTimeout)
    }

    if (this._shownPanel === panelName) {
      return
    }

    // debounce
    this._renderTimeout = setTimeout(() => {
      this._renderTabContent(panelName)

      if (this._focusComponent != this._TabBar) {
        this._focusComponent = this._TabBar
      }
      this._refocus()
    }, 300)
  }

  $updateSessionIndicator() {
    this._TabBar.sessionCreated = this.gamingClient.partyIsOngoing
  }

  _renderTabContent(panelName) {
    if (this._shownPanel === panelName) {
      return
    }

    if (this._shownPanel != null) {
      this._Sidekick.tag(this._shownPanel).hidePanel()
    }

    this._Sidekick.tag(panelName).showPanel()

    this._shownPanel = panelName
  }

  _handleUp() {
    if (this._focusComponent != this._TabBar) {
      this._focusComponent = this._TabBar
    }
    return true
  }

  _handleDown() {
    if (this._focusComponent == this._TabBar) {
      this._TabBar._Tabs.selected.patch({ mode: 'selected' })
      this._focusComponent = this._Sidekick.tag(this._shownPanel)
    }
  }

  get _Sidekick() {
    return this.tag('Sidekick')
  }

  get _OverviewPanel() {
    return this._Sidekick.tag('OverviewPanel')
  }

  get _KeyboardPanel() {
    return this._Sidekick.tag('KeyboardPanel')
  }

  get _CouchplayPanel() {
    return this._Sidekick.tag('CouchplayPanel')
  }

  get _TabBar() {
    return this._Sidekick.tag('TabBar')
  }

  _getFocused() {
    return this._focusComponent
  }
}
