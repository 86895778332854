import PageBase from './PageBase'
import { formatArcadeGames } from '../formatters'
import { GameTilesContainer } from './Play'
import ArcadeProvider from '../api/providers/arcadeProvider'
import { Column, context } from '@lightningjs/ui-components'
import FilterRow from '../components/FilterRow'
import { Emulators } from '../lib/gameplay/emulatorEnums'
import { RemoteConfig } from '../lib/remoteConfig'

export default class Arcade extends PageBase {
  static _template() {
    return {
      ...super._template(),
      SectionsContainer: {
        type: Column,
        y: 150,
        x: context.theme.layout.marginX,
        extraItemSpacing: 52,
        w: context.theme.layout.screenW - context.theme.layout.marginX,
        h: context.theme.layout.screenH,
        items: [
          {
            type: FilterRow,
            filters: ['All games'],
            h: 72,
          },
          {
            type: GameTilesContainer,
          },
        ],
      },
    }
  }

  $focusNav() {
    this._handleLeft()
  }

  _expandForNav(smooth = true) {
    if (smooth) {
      this._SectionsContainer.smooth = { x: this.PAGE_EXPANDED_X }
    } else {
      this._SectionsContainer.patch({ x: this.PAGE_EXPANDED_X })
    }
  }

  _collapseForNav() {
    this._SectionsContainer.smooth = { x: this.PAGE_COLLAPSED_X }
  }

  _init() {
    super._init()
    if (RemoteConfig.getInstance().retroModeFlag) {
      this._pageReadyDelay = setTimeout(() => {
        this.fireAncestors('$hideLoading')
      }, 1000)
    }
  }

  _active() {
    super._active()
    let gameDataFormatted = []

    for (const [key, value] of Object.entries(ArcadeProvider.getInstance().emulatorData)) {
      // filter out browser emulator data
      if (key === Emulators.Cloud || key.startsWith('Test')) {
        value.games.forEach((game) => {
          let gameCopy = { ...game }
          if (gameCopy.imgSrc) {
            gameCopy.imgSrc = `https://cdn2.np.gpe.xfinity.com/images/${game.imgSrc}`
          }
          gameDataFormatted.push({
            ...gameCopy,
            author: value.publisher,
            emulator: key,
          })
        })
      }
    }

    const gameRows = formatArcadeGames(gameDataFormatted, 6)
    this._GameTilesContainer._GameTiles.patch({ items: gameRows, alpha: 1 })
  }

  _inactive() {
    clearTimeout(this._pageReadyDelay)
  }

  get _SectionsContainer() {
    return this.tag('SectionsContainer')
  }

  get _GameTilesContainer() {
    return this._SectionsContainer.items[1]
  }

  _getFocused() {
    return this._SectionsContainer
  }
}
