import { TextBox, context } from '@lightningjs/ui-components'
import { CouchplayPanelNames } from '../panelNames'
import { PanelTemplate } from '../PanelTemplate'
import { CustomButton } from '../../suite/Hero/Hero'
import { corner } from '../../XGButton'

export class DefaultHostPanel extends PanelTemplate {
  _init() {
    super._init()
    this._bindFunctions()
  }

  _bindFunctions() {
    this._createSession = this._createSession.bind(this)
    this._patchDetails = this._patchDetails.bind(this)
  }

  _active() {
    super._active()
    this._patchDetails()
  }

  _patchDetails() {
    if (this.gameHasCouchplay) {
      this._patchCouchplayGame()
    } else {
      this._patchNoCouchplay()
    }
  }

  _patchCouchplayGame() {
    this.patch({
      skipFocus: false,
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Party mode',
          extraItemSpacing: 48,
          style: {
            textStyle: context.theme.typography.headline2,
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          centerInParent: true,
          extraItemSpacing: 64,
          content: `You can play this game with up to three of your friends.\n\nGet a unique code and share it with your friends, so you can play Xfinity Games together.`,
          style: {
            textStyle: {
              ...context.theme.typography.body3,
              wordWrapWidth: 405,
              textAlign: 'center',
            },
          },
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeftAndTopRight,
          w: 386,
          title: 'Start a party',
          centerInParent: true,
          onEnter: this._createSession,
        },
      ],
    })
  }

  _patchNoCouchplay() {
    if (!this.gameHasCouchplay) {
      this.patch({
        items: [
          {
            type: TextBox,
            skipFocus: true,
            content: 'Party mode',
            extraItemSpacing: 48,
            style: {
              textStyle: context.theme.typography.headline2,
            },
          },
          {
            type: TextBox,
            skipFocus: true,
            content: 'This game can’t be played in party mode.',
            centerInParent: true,
            extraItemSpacing: 24,
            style: {
              textStyle: {
                ...context.theme.typography.headline3,
                wordWrapWidth: 405,
                textAlign: 'center',
              },
            },
          },
          {
            type: TextBox,
            skipFocus: true,
            centerInParent: true,
            extraItemSpacing: 64,
            content: `Party mode lets you play Xfinity Games with up to three of your friends. Go back to the main menu to find games you can play in party mode.`,
            style: {
              textStyle: {
                ...context.theme.typography.body3,
                wordWrapWidth: 405,
                textAlign: 'center',
              },
            },
          },
        ],
      })
    }
  }

  get _CreateSessionButton() {
    return this.items[2]
  }

  async _createSession() {
    this._CreateSessionButton.patch({
      mode: 'disabled',
      title: 'Creating party...',
    })

    try {
      await this.gamingClient.createParty()
      this.fireAncestors('$updateSessionIndicator')
      this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.SESSION_CODE)
    } catch (err) {
      console.error(err)
      this._patchDetails()
    }
  }
}
