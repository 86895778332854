/**
 * Mixin for Rows that fades left-most Row items off when navigating to the right
 */
export default function withHorizontalFader(Base) {
  return class extends Base {
    onScreenEffect(onScreenItems) {
      if (this.items && this.items.length) {
        this.items.forEach((item, idx) => {
          // TODO: when ContentRow bug is fixed, change 0.001 to 0
          const alpha = onScreenItems.includes(item) || idx >= this.selectedIndex ? 1 : 0.001

          if (this._shouldSmooth) {
            item.smooth = { alpha }
          } else {
            item.alpha = alpha
          }
        })
      }
    }
  }
}
