import gamesIcon from '../assets/icons/ic_games_outline_inverse'
import gamesIconFilled from '../assets/icons/ic_games_fill_inverse'

import settingsIcon from '../assets/icons/ic_settings_outline_inverse'
import settingsIconFilled from '../assets/icons/ic_settings_fill_inverse'

import groupIcon from '../assets/icons/ic_group_outline_inverse'
import groupIconFilled from '../assets/icons/ic_group_fill_inverse'

import helpIcon from '../assets/icons/ic_help_outline'
import helpIconFilled from '../assets/icons/ic_help_fill_inverse'

import arcadeIcon from '../assets/icons/ic_joystick_outline_inverse'
import arcadeIconFilled from '../assets/icons/ic_joystick_fill_inverse'

import { RouteHash } from '../constants/routeEnums'
import { XGNavItem } from '../components/NavDrawerItem/NavDrawerItem'
import { RemoteConfig } from '../lib/remoteConfig'

const navItemBase = { type: XGNavItem, h: 80, w: 80 }

export default [
  {
    ...navItemBase,
    title: 'Arcade',
    logo: arcadeIcon,
    logoFocused: arcadeIconFilled,
    route: RouteHash.ARCADE,
  },
  {
    ...navItemBase,
    title: 'Settings',
    logo: settingsIcon,
    logoFocused: settingsIconFilled,
    h: 80,
    w: 40,
    route: RemoteConfig.getInstance().retroModeFlag ? RouteHash.BLUETOOTH : RouteHash.SETTINGS,
  },
]

export const couchplayNavConfig = {
  ...navItemBase,
  title: 'Party',
  logo: groupIcon,
  logoFocused: groupIconFilled,
  route: RouteHash.PARTY,
}

export const helpNavConfig = {
  ...navItemBase,
  title: 'Help',
  logo: helpIcon,
  logoFocused: helpIconFilled,
  route: RouteHash.HELP_AND_SUPPORT,
}

export const playNavConfig = {
  ...navItemBase,
  title: 'Play',
  logo: gamesIcon,
  logoFocused: gamesIconFilled,
  route: RouteHash.PLAY,
}
