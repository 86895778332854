export default class MiniLogger {
    constructor() {
        this._queue = [];
        this._static = '';
    }
    log(...messages) {
        this._queue.push(messages.join(' '));
    }
    set static(message) {
        this._static = message;
    }
    get static() {
        return this._static;
    }
    get queue() {
        return this._queue;
    }
    empty() {
        return this._queue.splice(0);
    }
    // Singleton
    static getInstance() {
        if (!MiniLogger._instance) {
            MiniLogger._instance = new MiniLogger();
        }
        return MiniLogger._instance;
    }
}
