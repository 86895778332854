export var FireboltLifecycleState;
(function (FireboltLifecycleState) {
    FireboltLifecycleState["INITIALIZING"] = "initializing";
    FireboltLifecycleState["INACTIVE"] = "inactive";
    FireboltLifecycleState["SUSPENDED"] = "suspended";
    FireboltLifecycleState["BACKGROUND"] = "background";
    FireboltLifecycleState["FOREGROUND"] = "foreground";
})(FireboltLifecycleState || (FireboltLifecycleState = {}));
export var FireboltLifecycleEvent;
(function (FireboltLifecycleEvent) {
    FireboltLifecycleEvent["UNLOADING"] = "unloading";
})(FireboltLifecycleEvent || (FireboltLifecycleEvent = {}));
