export var GamepadMapping;
(function (GamepadMapping) {
    GamepadMapping[GamepadMapping["D_UP"] = 12] = "D_UP";
    GamepadMapping[GamepadMapping["D_DOWN"] = 13] = "D_DOWN";
    GamepadMapping[GamepadMapping["D_LEFT"] = 14] = "D_LEFT";
    GamepadMapping[GamepadMapping["D_RIGHT"] = 15] = "D_RIGHT";
    GamepadMapping[GamepadMapping["TOP"] = 3] = "TOP";
    GamepadMapping[GamepadMapping["RIGHT"] = 1] = "RIGHT";
    GamepadMapping[GamepadMapping["DOWN"] = 0] = "DOWN";
    GamepadMapping[GamepadMapping["LEFT"] = 2] = "LEFT";
    GamepadMapping[GamepadMapping["HOME"] = 16] = "HOME";
    GamepadMapping[GamepadMapping["SELECT"] = 8] = "SELECT";
    GamepadMapping[GamepadMapping["START"] = 9] = "START";
    GamepadMapping[GamepadMapping["RIGHT_BUMPER"] = 5] = "RIGHT_BUMPER";
    GamepadMapping[GamepadMapping["RIGHT_TRIGGER"] = 7] = "RIGHT_TRIGGER";
    GamepadMapping[GamepadMapping["LEFT_BUMPER"] = 4] = "LEFT_BUMPER";
    GamepadMapping[GamepadMapping["LEFT_TRIGGER"] = 6] = "LEFT_TRIGGER";
})(GamepadMapping || (GamepadMapping = {}));
export var GamepadAxes;
(function (GamepadAxes) {
    GamepadAxes[GamepadAxes["LEFT_HORIZONTAL"] = 0] = "LEFT_HORIZONTAL";
    GamepadAxes[GamepadAxes["LEFT_VERTICAL"] = 1] = "LEFT_VERTICAL";
    GamepadAxes[GamepadAxes["RIGHT_HORIZONTAL"] = 2] = "RIGHT_HORIZONTAL";
    GamepadAxes[GamepadAxes["RIGHT_VERTICAL"] = 3] = "RIGHT_VERTICAL";
})(GamepadAxes || (GamepadAxes = {}));
export var AxesDirectionalNames;
(function (AxesDirectionalNames) {
    AxesDirectionalNames["LEFT_LEFT"] = "LL";
    AxesDirectionalNames["LEFT_RIGHT"] = "LR";
    AxesDirectionalNames["LEFT_UP"] = "LU";
    AxesDirectionalNames["LEFT_DOWN"] = "LD";
    AxesDirectionalNames["RIGHT_LEFT"] = "RL";
    AxesDirectionalNames["RIGHT_RIGHT"] = "RR";
    AxesDirectionalNames["RIGHT_UP"] = "RU";
    AxesDirectionalNames["RIGHT_DOWN"] = "RD";
})(AxesDirectionalNames || (AxesDirectionalNames = {}));
