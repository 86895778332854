import { AnalyticsErrorBucketCodes } from '../analytics/analyticsEnums';
import CoreEventCreator from '../analytics/coreEventCreator';
import { sendAnalyticsEvent } from '../analytics/analyticsService';
import { createControllerStatusChangedEvent, createErrorEvent } from '../analytics/eventCreators';
export default class BluetoothAnalyticsService {
    constructor() {
        this._bindFunctions();
    }
    _bindFunctions() {
        this.sendControllerStatusChangedEvent = this.sendControllerStatusChangedEvent.bind(this);
    }
    sendControllerStatusChangedEvent(eventDetails) {
        sendAnalyticsEvent(createControllerStatusChangedEvent(CoreEventCreator.getInstance().createEvent(), eventDetails));
    }
    sendBluetoothError(code) {
        sendAnalyticsEvent(createErrorEvent(CoreEventCreator.getInstance().createEvent(), AnalyticsErrorBucketCodes.BLUETOOTH, code));
    }
}
