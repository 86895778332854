import { AxesDirectionalNames, GamepadAxes, GamepadMapping } from './buttonMappingEnums';
import * as KeyboardEvents from './keyboardEvents';
import LoggingService from '../logging/loggingService';
import { LogLevels } from '../logging/loggingEnums';
import { RemoteConfig } from '../remoteConfig';
class ControllerButton {
    constructor(buttonIndex, eventFired, holdTimeout) {
        this._buttonIndex = buttonIndex;
        this._eventFired = eventFired;
        this._timeoutLength = holdTimeout;
        this._ready = true;
        this._firstPress = true;
        // can set timeout Id to -1 because clearTimeout(timeoutId)
        // with invalid timeoutId does not throw error
        this._timeoutId = -1;
        this.bindFunctions();
    }
    bindFunctions() {
        this.triggerKeyDown = this.triggerKeyDown.bind(this);
        this.triggerKeyUp = this.triggerKeyUp.bind(this);
        this.checkPressed = this.checkPressed.bind(this);
        this.triggerEvents = this.triggerEvents.bind(this);
    }
    _handleRapidFire() {
        this._log(`Keydown KeyboardEvent sent: ${JSON.stringify(this._eventFired)}`);
        // set a timeout to prevent rapid firing of keydown event and prevent really quick scrolling
        this._ready = false;
        this._timeoutId = window.setTimeout(() => {
            this._ready = true;
            this._firstPress = false;
        }, this._firstPress ? this._timeoutLength * 2 : this._timeoutLength); // wait a bit before starting to scroll to ensure that user really wants to scroll
    }
    _resetKeyUp() {
        this._log(`Keyup KeyboardEvent sent: ${JSON.stringify(this._eventFired)}`);
        // cancel the timeout because once a user releases the key, the next press
        // should be registered even if the timeout is not up yet
        clearTimeout(this._timeoutId);
        this._ready = true;
        this._firstPress = true;
    }
    triggerKeyDown() {
        if (this._ready) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYDOWN, this._eventFired));
            this._handleRapidFire();
        }
    }
    triggerKeyUp() {
        if (!this._ready) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYUP, this._eventFired));
            this._resetKeyUp();
        }
    }
    checkPressed(gamepad) {
        var _a;
        if (gamepad !== null && gamepad.buttons.length > this._buttonIndex) {
            return (_a = gamepad.buttons[this._buttonIndex]) === null || _a === void 0 ? void 0 : _a.pressed;
        }
        return false;
    }
    triggerEvents(gamepad) {
        // if gamepad is null, don't bother continuing
        if (!gamepad) {
            return;
        }
        if (this.checkPressed(gamepad)) {
            this.triggerKeyDown();
        }
        else {
            this.triggerKeyUp();
        }
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.GAMEPAD, text);
    }
}
class ControllerAxis {
    constructor(axesIndices, positiveKeyEvent, negativeKeyEvent, threshold, holdTimeout) {
        this._axesIndices = axesIndices;
        this._positiveKeyEvent = positiveKeyEvent; // the keyboard event that should be fired when the positive threshold is crossed
        this._negativeKeyEvent = negativeKeyEvent; // the keyboard event that should be fired when the negative threshold is crossed
        this._threshold = threshold;
        this._timeoutLength = holdTimeout;
        this._positiveReady = true;
        this._negativeReady = true;
        this._negativeFirstPress = true;
        this._positiveFirstPress = true;
        this._positiveTimeoutId = -1;
        this._negativeTimeoutId = -1;
        this.bindFunctions();
    }
    bindFunctions() {
        this.firePositiveEvent = this.firePositiveEvent.bind(this);
        this.fireNegativeEvent = this.fireNegativeEvent.bind(this);
        this.positiveKeyUp = this.positiveKeyUp.bind(this);
        this.negativeKeyUp = this.negativeKeyUp.bind(this);
        this.clearAllKeys = this.clearAllKeys.bind(this);
        this.checkPositiveThreshold = this.checkPositiveThreshold.bind(this);
        this.checkNegativeThreshold = this.checkNegativeThreshold.bind(this);
        this.checkNeutral = this.checkNeutral.bind(this);
    }
    firePositiveEvent() {
        if (this._positiveReady) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYDOWN, this._positiveKeyEvent));
            this._log(`Keydown KeyboardEvent sent: ${JSON.stringify(this._positiveKeyEvent)}`);
            // set a timeout to prevent rapid firing of keydown event and prevent really quick scrolling
            this._positiveReady = false;
            this._positiveTimeoutId = window.setTimeout(() => {
                this._positiveReady = true;
                this._positiveFirstPress = false;
            }, this._positiveFirstPress ? this._timeoutLength * 2 : this._timeoutLength);
            // clear the timeout for the negative threshold
            this.negativeKeyUp();
        }
    }
    fireNegativeEvent() {
        if (this._negativeReady) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYDOWN, this._negativeKeyEvent));
            this._log(`Keydown KeyboardEvent sent: ${JSON.stringify(this._negativeKeyEvent)}`);
            // set a timeout to prevent rapid firing of keydown event and prevent really quick scrolling
            this._negativeReady = false;
            this._negativeTimeoutId = window.setTimeout(() => {
                this._negativeReady = true;
                this._negativeFirstPress = false;
            }, this._negativeFirstPress ? this._timeoutLength * 2 : this._timeoutLength);
            // clear the timeout for the positive threshold
            this.positiveKeyUp();
        }
    }
    positiveKeyUp() {
        if (!this._positiveReady) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYUP, this._positiveKeyEvent));
            this._log(`Keyup KeyboardEvent sent: ${JSON.stringify(this._positiveKeyEvent)}`);
            // cancel the timeout because once the axis is back in neutral or negative position, the next threshold cross
            // should be registered even if the timeout is not up yet
            if (this._positiveTimeoutId) {
                clearTimeout(this._positiveTimeoutId);
            }
            this._positiveReady = true;
            this._positiveFirstPress = true;
        }
    }
    negativeKeyUp() {
        if (!this._negativeReady) {
            window.dispatchEvent(new KeyboardEvent(KeyEvents.KEYUP, this._negativeKeyEvent));
            this._log(`Keyup KeyboardEvent sent: ${JSON.stringify(this._negativeKeyEvent)}`);
            // cancel the timeout because once the axis is back in neutral or negative position, the next threshold cross
            // should be registered even if the timeout is not up yet
            if (this._negativeTimeoutId) {
                clearTimeout(this._negativeTimeoutId);
            }
            this._negativeReady = true;
            this._negativeFirstPress = true;
        }
    }
    clearAllKeys() {
        this.positiveKeyUp();
        this.negativeKeyUp();
    }
    checkPositiveThreshold(gamepad) {
        // check ALL axes to see if threshold has been crossed
        for (let i = 0; i < this._axesIndices.length; i++) {
            const axisIndex = this._axesIndices[i];
            if (gamepad != null && gamepad.axes.length > axisIndex) {
                const axis = gamepad.axes[axisIndex];
                if (axis && axis > this._threshold) {
                    return true;
                }
            }
        }
        return false;
    }
    checkNegativeThreshold(gamepad) {
        // check ALL axes to check if negative threshold has been crossed
        for (let i = 0; i < this._axesIndices.length; i++) {
            const axisIndex = this._axesIndices[i];
            if (gamepad != null && gamepad.axes.length > axisIndex) {
                const axis = gamepad.axes[axisIndex];
                if (axis && axis < -this._threshold) {
                    return true;
                }
            }
        }
        return false;
    }
    checkNeutral(gamepad) {
        // check that ALL axes are neutral
        for (let i = 0; i < this._axesIndices.length; i++) {
            const axisIndex = this._axesIndices[i];
            if (gamepad != null && gamepad.axes.length > axisIndex) {
                const axis = gamepad.axes[axisIndex];
                if (!axis || axis > this._threshold || axis < -this._threshold) {
                    return false;
                }
            }
        }
        return true;
    }
    triggerEvents(gamepad) {
        if (this.checkPositiveThreshold(gamepad)) {
            this.firePositiveEvent();
        }
        if (this.checkNegativeThreshold(gamepad)) {
            this.fireNegativeEvent();
        }
        // axis is in neutral position. all timeouts should be cleared, and
        // keyboard event should be sent on the crossing of either positive or negative threshold
        if (this.checkNeutral(gamepad)) {
            this.clearAllKeys();
        }
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.GAMEPAD, text);
    }
}
export const KeyEvents = Object.freeze({
    KEYDOWN: 'keydown',
    KEYUP: 'keyup',
});
export class ControllerService {
    constructor() {
        this._defaultHoldTimeout = RemoteConfig.getInstance().inputHoldTimeout;
        this._joystickThreshold = RemoteConfig.getInstance().inputAxisThreshold;
        this._checkControllersInitialized = false;
        this._controllerButtons = [];
        this._controllerAxes = [];
        this._start = null;
        this._lastActiveCallbacks = new Set();
        this._onConnectionChangeCallbacks = new Set();
        this._lastActive = new Date();
        this.bindFunctions();
        this.initialize();
    }
    bindFunctions() {
        this.checkGamepads = this.checkGamepads.bind(this);
        this.mapKeypads = this.mapKeypads.bind(this);
        this.checkGamepads = this.checkGamepads.bind(this);
        this.initialize = this.initialize.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.addController = this.addController.bind(this);
        this.removeController = this.removeController.bind(this);
        this.addLastActiveCallback = this.addLastActiveCallback.bind(this);
        this.removeLastActiveCallback = this.removeLastActiveCallback.bind(this);
        this._checkActivity = this._checkActivity.bind(this);
        this._activateLastActiveCallbacks = this._activateLastActiveCallbacks.bind(this);
    }
    _gamepadConnected(e) {
        this._log(`gamepadconnected: ${JSON.stringify(e.gamepad)}`);
        this._lastActive = new Date();
        this.addController(e.gamepad.index);
        this._onConnectionChangeCallbacks.forEach((callback) => {
            callback(true);
        });
    }
    _gamepadDisconnected(e) {
        this._log(`gamepaddisconnected: ${JSON.stringify(e.gamepad)}`);
        this.removeController(e.gamepad.index);
        this._onConnectionChangeCallbacks.forEach((callback) => {
            callback(false);
        });
    }
    initialize() {
        if (this._checkControllersInitialized) {
            return;
        }
        this.mapKeypads();
        window.addEventListener('gamepadconnected', (e) => this._gamepadConnected(e));
        window.addEventListener('gamepaddisconnected', (e) => this._gamepadDisconnected(e));
    }
    _removeListeners() {
        window.removeEventListener('gamepadconnected', (e) => this._gamepadConnected(e));
        window.removeEventListener('gamepaddisconnected', (e) => this._gamepadDisconnected(e));
    }
    deactivate() {
        this._log('canceling animation frame');
        cancelAnimationFrame(this._start);
        this._start = null;
        this._removeListeners();
    }
    addLastActiveCallback(callback) {
        this._lastActiveCallbacks.add(callback);
    }
    removeLastActiveCallback(callback) {
        this._lastActiveCallbacks.delete(callback);
    }
    addOnConnectionChangeCallback(callback) {
        this._onConnectionChangeCallbacks.add(callback);
    }
    removeOnConnectionChangeCallback(callback) {
        this._onConnectionChangeCallbacks.delete(callback);
    }
    mapKeypads() {
        this._log('initialize check gamepads loop');
        this.checkGamepads();
        this._checkControllersInitialized = true;
    }
    checkGamepads() {
        this._activateLastActiveCallbacks();
        if (!window.navigator || !navigator.getGamepads) {
            return;
        }
        const gamepads = navigator.getGamepads();
        if (!gamepads) {
            return;
        }
        for (let i = 0; i < gamepads.length; i++) {
            const gp = gamepads[i];
            // skip if a slot in the gamepad is null
            if (!gp) {
                continue;
            }
            const buttons = this._controllerButtons[i];
            const axes = this._controllerAxes[i];
            if (buttons) {
                buttons.forEach((button) => {
                    button.triggerEvents(gp);
                });
            }
            if (axes) {
                axes.forEach((axis) => {
                    axis.triggerEvents(gp);
                });
            }
            this._checkActivity(gp);
        }
        this._start = requestAnimationFrame(this.checkGamepads);
    }
    removeController(index) {
        this._controllerButtons[index] = null;
        this._controllerAxes[index] = null;
    }
    addController(index) {
        while (index > this._controllerButtons.length - 1) {
            this._controllerButtons.push(null);
        }
        while (index > this._controllerAxes.length - 1) {
            this._controllerAxes.push(null);
        }
        this._controllerButtons[index] = [
            new ControllerButton(GamepadMapping.D_LEFT, KeyboardEvents.arrowLeftEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.D_RIGHT, KeyboardEvents.arrowRightEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.D_UP, KeyboardEvents.arrowUpEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.D_DOWN, KeyboardEvents.arrowDownEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.RIGHT, KeyboardEvents.controllerB, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.DOWN, KeyboardEvents.enterEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.LEFT, KeyboardEvents.leftEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.TOP, KeyboardEvents.topEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.HOME, KeyboardEvents.homeEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.LEFT_BUMPER, KeyboardEvents.leftBumperEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.RIGHT_BUMPER, KeyboardEvents.rightBumperEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.LEFT_TRIGGER, KeyboardEvents.leftTriggerEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.RIGHT_TRIGGER, KeyboardEvents.rightTriggerEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.SELECT, KeyboardEvents.selectEvent, this._defaultHoldTimeout),
            new ControllerButton(GamepadMapping.START, KeyboardEvents.startEvent, this._defaultHoldTimeout),
        ];
        this._controllerAxes[index] = [
            new ControllerAxis([GamepadAxes.LEFT_HORIZONTAL], { ...KeyboardEvents.arrowRightEvent, code: AxesDirectionalNames.LEFT_RIGHT }, { ...KeyboardEvents.arrowLeftEvent, code: AxesDirectionalNames.LEFT_LEFT }, this._joystickThreshold, this._defaultHoldTimeout),
            new ControllerAxis([GamepadAxes.RIGHT_HORIZONTAL], { ...KeyboardEvents.arrowRightEvent, code: AxesDirectionalNames.RIGHT_RIGHT }, { ...KeyboardEvents.arrowLeftEvent, code: AxesDirectionalNames.RIGHT_LEFT }, this._joystickThreshold, this._defaultHoldTimeout),
            // for the vertical axes, values are: up is negative, down is positive.
            // So when a vertical axis is pushed up, the negative event is triggered, and when a vertical axis is pushed down, the positive event is triggered.
            // Hence why the arrow down and arrow up events are flipped.
            new ControllerAxis([GamepadAxes.LEFT_VERTICAL], { ...KeyboardEvents.arrowDownEvent, code: AxesDirectionalNames.LEFT_DOWN }, { ...KeyboardEvents.arrowUpEvent, code: AxesDirectionalNames.LEFT_UP }, this._joystickThreshold, this._defaultHoldTimeout),
            new ControllerAxis([GamepadAxes.RIGHT_VERTICAL], { ...KeyboardEvents.arrowDownEvent, code: AxesDirectionalNames.RIGHT_DOWN }, { ...KeyboardEvents.arrowUpEvent, code: AxesDirectionalNames.RIGHT_UP }, this._joystickThreshold, this._defaultHoldTimeout),
        ];
    }
    get lastActive() {
        return this._lastActive;
    }
    _activateLastActiveCallbacks() {
        this._lastActiveCallbacks.forEach((callback) => {
            callback(this._lastActive);
        });
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.GAMEPAD, text);
    }
    _checkActivity(gp) {
        gp.buttons.every((button) => {
            if (button.pressed) {
                this._lastActive = new Date();
                return false;
            }
            return true;
        });
        gp.axes.every((axis) => {
            // check if user is moving joystick, because joystick values are always between -1.0 and 1.0
            // we dont necessarily care about the direction of the joystick movement, just that the joystick is
            // moved. We compare to joystickThreshold instead of 0 because joystick neutral position values can shift a bit over time
            if (Math.abs(axis) >= this._joystickThreshold) {
                this._lastActive = new Date();
                return false;
            }
            return true;
        });
    }
    // Singleton
    static getInstance() {
        if (!ControllerService.instance) {
            ControllerService.instance = new ControllerService();
        }
        return ControllerService.instance;
    }
}
