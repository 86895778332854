import { sendAnalyticsEvent } from './analyticsService';
import CoreEventCreator from './coreEventCreator';
import { createAppSessionEndedEvent, createAppSessionStartedEvent } from './eventCreators';
export default class AppSessionAnalyticsService {
    constructor() {
        this._bindFunctions();
    }
    _bindFunctions() {
        this.sendAppSessionStarted = this.sendAppSessionStarted.bind(this);
        this.sendAppSessionEnded = this.sendAppSessionEnded.bind(this);
    }
    sendAppSessionStarted() {
        sendAnalyticsEvent(createAppSessionStartedEvent(CoreEventCreator.getInstance().createEvent()));
    }
    sendAppSessionEnded() {
        sendAnalyticsEvent(createAppSessionEndedEvent(CoreEventCreator.getInstance().createEvent()));
    }
}
