import axios from 'axios';
export default class MockBluetoothAPI {
    constructor(baseURL, pollInterval = 1000) {
        this._baseURL = baseURL;
        this._activated = false;
        this._registeredEvents = new Map();
        this._pollInterval = pollInterval;
    }
    addListener(eventType, callback) {
        if (!this._registeredEvents.has(eventType)) {
            this._registeredEvents.set(eventType, new Set());
        }
        this._registeredEvents.get(eventType).add(callback);
    }
    removeListener(eventType, callback) {
        if (this._registeredEvents.has(eventType)) {
            let registeredEvents = this._registeredEvents.get(eventType);
            registeredEvents.delete(callback);
        }
    }
    activate() {
        this._pollCommand = setInterval(() => {
            axios
                .get(`${this._baseURL}/events`)
                .then((response) => {
                const event = response.data;
                if (event.eventType != null) {
                    this._activateCallbacks(event.eventType, event.event);
                }
            })
                .catch((err) => {
                console.error(`error polling for events: ${err}`);
            });
        }, this._pollInterval);
        return new Promise((resolve) => {
            this._activated = true;
            resolve({ success: true });
        });
    }
    deactivate() {
        this._registeredEvents.clear();
        this._activated = false;
        clearInterval(this._pollCommand);
    }
    startScan(timeout) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/startScan`, { timeout: timeout })
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    stopScan() {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/stopScan`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    getDiscoveredDevices() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${this._baseURL}/discoveredDevices`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    getPairedDevices() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${this._baseURL}/pairedDevices`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    getConnectedDevices() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${this._baseURL}/connectedDevices`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    pair(deviceID) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/pair/${deviceID}`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    unpair(deviceID) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/unpair/${deviceID}`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    connect(deviceID, deviceType) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/connect/${deviceID}`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    disconnect(deviceID, deviceType) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this._baseURL}/disconnect/${deviceID}`)
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    respondToEvent(deviceID, eventType, responseValue) {
        // TODO: this doesn't really do anything right now
        return new Promise((resolve) => {
            resolve({ success: true });
        });
    }
    _activateCallbacks(event, notification) {
        let callbacks = this._registeredEvents.get(event);
        if (callbacks) {
            callbacks.forEach((callback) => callback(notification));
        }
    }
}
