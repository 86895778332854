export function getGamePlayContainerId() {
    return 'gamePlayContainer';
}
function createGamePlayElement() {
    const gamePlayDiv = window.document.createElement('div');
    gamePlayDiv.setAttribute('id', getGamePlayContainerId());
    gamePlayDiv.setAttribute('style', 'width: 1280px; height: 720px; position: absolute; left: 0px; top: 0px; z-index: -500;');
    window.document.body.appendChild(gamePlayDiv);
}
export default function gameplayInit() {
    createGamePlayElement();
}
