import { Base, TextBox, ScrollWrapper, context } from '@lightning/ui'
import { Language } from '@lightningjs/sdk'
import { entityDescLabel } from '../pages/Entity/EntityOverview'

export default class FullScreenScrollingText extends Base {
  static _template() {
    return {
      ...super._template(),
      announce: ' ',
      announceContext: [],
      zIndex: 2,
      alpha: 0.001,
      Title: {
        type: TextBox,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 93,
        content: '',
        style: {
          textStyle: { ...context.theme.typography.display1 },
        },
        skipFocus: true,
        announce: ' ',
      },
      Description: {
        type: ScrollWrapper,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 210,
        h: 740,
        w: 780,
        content: '',
        showScrollBar: true,
        style: {
          fadeHeight: 400,
          sliderMarginLeft: 36,
          textStyle: {
            ...context.theme.typography.body2,
            lineHeight: 40,
          },
        },
        announce: ' ',
      },
    }
  }

  _active() {
    this._Title.patch({ content: this.title })
    this._Description.patch({ content: this.description })
    this.setSmooth('alpha', 1, { delay: 0.5, duration: 0.25 })
    this.announceContext = [
      this._Title.content === entityDescLabel && this._Title.content,
      this._Description.content,
      this._descriptionIsScrollable && Language.translate('ENTITY_OVERVIEW_NAVIGATION'),
      'PAUSE-2',
    ]
  }

  get _descriptionIsScrollable() {
    return this.description?.length && this.description.length > 800
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Description() {
    return this.tag('Description')
  }

  _getFocused() {
    if (this._descriptionIsScrollable) {
      return this._Description
    }
    return undefined
  }
}
