import { context, utils } from '@lightning/ui'

const CONTRACTED_WIDTH = 64

export const base = () => {
  return {
    minWidth: CONTRACTED_WIDTH,
    w: CONTRACTED_WIDTH,
    h: CONTRACTED_WIDTH,
    paddingX: 15, // padding within button, set icon off from left side
    justify: 'left',
    titlePadding: 16, // Space btwn icon and text
    contentColor: utils.getHexColor('#F6F6F9', 1),

    // stateful colors
    backgroundColor: utils.getHexColor('#FFFFFF', 0),
    textStyle: { textColor: utils.getHexColor('#F6F6F9', 0) },
  }
}

export const mode = () => {
  return {
    focused: {
      textStyle: { textColor: utils.getHexColor('#F6F6F9', 1) },
      contentColor: utils.getHexColor('#F6F6F9', 1),
      backgroundColor: utils.getHexColor('#FFFFFF', 0.15),
    },
    selected: {
      backgroundColor: utils.getHexColor('#FFFFFF', 0.15),
    },
    collapsed: {
      backgroundColor: utils.getHexColor('#FFFFFF', 0),
    },
    expanded: {
      textStyle: { textColor: utils.getHexColor('#F6F6F9', 1) },
    },
    softFocused: {
      backgroundColor: utils.getHexColor('#FFFFFF', 0.15),
    },
  }
}
