import { Platform } from './eventInfoFactoryInterface';
import RdkDevice from './rdkDevice';
import XBOAccount from './xboAccount';
export default class DeviceInfo {
    constructor() {
        this._physicalDevice = new RdkDevice();
        this._customerAccount = new XBOAccount();
        this._platform = null;
    }
    async setDetails(platform) {
        this._platform = platform;
        if (this._platform === Platform.RDK) {
            this._physicalDevice = new RdkDevice();
            this._customerAccount = new XBOAccount();
            await Promise.allSettled([
                this._physicalDevice.initialize(),
                this._customerAccount.initialize(),
            ]);
        }
    }
    get platform() {
        return this._platform;
    }
    get customerAccount() {
        return this._customerAccount;
    }
    get physicalDevice() {
        return this._physicalDevice;
    }
    static getInstance() {
        if (!DeviceInfo.instance) {
            DeviceInfo.instance = new DeviceInfo();
        }
        return DeviceInfo.instance;
    }
}
