import { AnalyticsGeneralErrorCodes, AnalyticsPlatformErrorCodes, } from '../../lib/analytics/analyticsEnums';
import GeneralAnalyticsService from '../../lib/analytics/generalAnalyticsService';
import { RemoteConfig } from '../../lib/remoteConfig';
import api from './api';
import FireboltAuthService from '../../lib/firebolt/fireboltAuthService';
import PlatformAnalyticsService from '../../lib/analytics/platformAnalyticsService';
export default class UserAccountProvider {
    constructor() {
        this._bindFunctions();
        this._maxCapacity = true;
        this._contentControls = {
            E: false,
            'E10+': false,
            M: true,
            T: true,
        };
    }
    _bindFunctions() {
        this._setMobilePhone = this._setMobilePhone.bind(this);
        this._getIntent = this._getIntent.bind(this);
        this._sendRequestFailedEvent = this._sendRequestFailedEvent.bind(this);
        this.getCapacityAndUserAccount = this.getCapacityAndUserAccount.bind(this);
        this.getUserAccount = this.getUserAccount.bind(this);
        this.createUserAccount = this.createUserAccount.bind(this);
        this.unlockUserAccount = this.unlockUserAccount.bind(this);
        this.updateUserAccount = this.updateUserAccount.bind(this);
    }
    // get the app capacity and existing user's account details or determine if new user
    getCapacityAndUserAccount() {
        if (RemoteConfig.getInstance().retroModeFlag || !FireboltAuthService.getInstance().isHardware) {
            return new Promise((resolve) => {
                this._gameplayStateId = process.env.GAMEPLAY_ID ? process.env.GAMEPLAY_ID : undefined;
                this._isNewUser = !this._gameplayStateId;
                this._maxCapacity = false;
                this._setMobilePhone();
                resolve(true);
            });
        }
        return api
            .get(RemoteConfig.getInstance().platformURL + RemoteConfig.getInstance().capacityCheckPath, {
            authHeader: true,
        })
            .then((result) => {
            this._isNewUser = !result.data.gameplayStateId;
            this._gameplayStateId = result.data.gameplayStateId;
            this._maxCapacity = result.data.maxCapacity;
            this._setContentControls(result.data.parentalControl);
            this._setMobilePhone(result.data.mobilePhone);
        })
            .catch((err) => {
            console.error(err);
            this._sendRequestFailedEvent(err.response);
        });
    }
    // get an existing user's account details
    getUserAccount() {
        if (!FireboltAuthService.getInstance().isHardware) {
            return new Promise((resolve) => {
                this._gameplayStateId = process.env.GAMEPLAY_ID ? process.env.GAMEPLAY_ID : undefined;
                this._setMobilePhone();
                resolve(true);
            });
        }
        return api
            .get(RemoteConfig.getInstance().platformURL + RemoteConfig.getInstance().userAccountPath, {
            authHeader: true,
        })
            .then((result) => {
            this._gameplayStateId = result.data.gameplayStateId;
            this._setContentControls(result.data.parentalControl);
            this._setMobilePhone(result.data.mobilePhone);
        })
            .catch((err) => {
            console.error(err);
            this._sendRequestFailedEvent(err.response);
        });
    }
    // create a new user's account and set account details
    createUserAccount() {
        if (!FireboltAuthService.getInstance().isHardware) {
            return new Promise((resolve) => {
                this._gameplayStateId = process.env.GAMEPLAY_ID ? process.env.GAMEPLAY_ID : undefined;
                this._isNewUser = !this._gameplayStateId;
                this._setMobilePhone();
                resolve(true);
            });
        }
        return api
            .post(RemoteConfig.getInstance().platformURL + RemoteConfig.getInstance().welcomeUserPath)
            .then((result) => {
            this._gameplayStateId = result.data.gameplayStateId;
            this._isNewUser = !this._gameplayStateId;
            this._contentControls = result.data.parentalControl;
            this._setMobilePhone(result.data.mobilePhone);
        })
            .catch((err) => {
            console.error(err);
            this._sendRequestFailedEvent(err.response);
        });
    }
    // send OTP to existing user's primary mobile in order to update account details
    unlockUserAccount(data) {
        return api
            .post(RemoteConfig.getInstance().platformURL + RemoteConfig.getInstance().contentControlPath, {
            requestBody: {
                mobilePhone: this._mobilePhone,
                intent: this._getIntent(data),
            },
        })
            .catch((err) => {
            console.error(err);
            this._sendRequestFailedEvent(err.response);
            throw new Error(err);
        });
    }
    // update existing user's account details
    updateUserAccount(data) {
        if (!FireboltAuthService.getInstance().isHardware) {
            return new Promise((resolve, reject) => {
                // do not update content controls in browser
                reject(true);
            });
        }
        return api
            .put(RemoteConfig.getInstance().platformURL + RemoteConfig.getInstance().contentControlPath, {
            requestBody: {
                parentalControl: data,
            },
        })
            .then((result) => {
            this._gameplayStateId = result.data.gameplayStateId;
            this._contentControls = result.data.parentalControl;
            this._setMobilePhone(result.data.mobilePhone);
        })
            .catch((err) => {
            console.error(err);
            this._sendRequestFailedEvent(err.response);
        });
    }
    _getIntent({ code, game, rating }) {
        const prefix = 'skill.kit.xgb.';
        const url = '&url=https://xfinity.com/xfinity-games-beta';
        if (!game && !rating) {
            return `${prefix}manage?code=${code}${url}`;
        }
        return `${prefix}singleuse?code=${code}&gameLabel=${game}&rating=${rating}${url}`;
    }
    _sendRequestFailedEvent(errorResponse) {
        const generalAnalyticsService = new GeneralAnalyticsService();
        generalAnalyticsService.sendGeneralError(AnalyticsGeneralErrorCodes.PERFORM_REQUEST_FAILED, errorResponse);
    }
    get isNewUser() {
        return this._isNewUser;
    }
    get gameplayStateId() {
        return this._gameplayStateId;
    }
    get atMaxCapacity() {
        return this._maxCapacity;
    }
    get contentControls() {
        return this._contentControls;
    }
    _setContentControls(controls) {
        if (!controls) {
            const platformAnalyticsService = new PlatformAnalyticsService();
            platformAnalyticsService.sendPlatformError(AnalyticsPlatformErrorCodes.NO_CONTENT_CONTROLS);
        }
        else {
            this._contentControls = controls;
        }
    }
    get mobilePhone() {
        return this._mobilePhone;
    }
    _setMobilePhone(phone) {
        // set _mobilePhone as the provided string in query param for testing, if doesn't exist
        // use string in .env file (local dev), otherwise use the provided string param
        this._mobilePhone = this._devMobilePhone
            ? this._devMobilePhone
            : process.env.MOBILE
                ? process.env.MOBILE
                : phone;
    }
    set devMobilePhone(phone) {
        this._devMobilePhone = phone;
    }
    // Singleton
    static getInstance() {
        if (!UserAccountProvider._instance) {
            UserAccountProvider._instance = new UserAccountProvider();
        }
        return UserAccountProvider._instance;
    }
}
