export class DeviceDetails {
}
export const MockDevices = [
    {
        deviceID: '1',
        name: 'Controller 1',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '2',
        name: 'Controller 2',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '3',
        name: 'Controller 3',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '4',
        name: 'Controller 4',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '5',
        name: 'Controller 5',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '6',
        name: 'Controller 6',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
    {
        deviceID: '7',
        name: 'Controller 7',
        deviceType: 'Joystick',
        rawDeviceType: '0',
        lastConnectedState: false,
        paired: false,
        connected: false,
    },
];
