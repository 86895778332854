import { Row } from '@lightning/ui' // TODO import should be from '@lightning/ui' but there are alpha issues
import { Router } from '@lightningjs/sdk'

export default class ScrollingRow extends Row {
  _render(next, prev) {
    super._render(next, prev)
    for (let i = 0; i < this.items.length; i++) {
      if (i < this.selectedIndex) {
        this.items[i].fullHide()
      } else if (i > this.selectedIndex) {
        this.items[i].halfHide()
      }
    }

    this.items[this.selectedIndex].show()

    this.fireAncestors('$sectionUpdated', this.items[this.selectedIndex], this.selectedIndex)

    this._refocus()
  }

  // updateNextSection updates the next section of the ScrollingRow
  // sometimes, we don't want to completely replace the next section. We may
  // only wish to call the section's updateSection function and allow the section
  // to determine how to update itself. If doReplace is set to false, we call the
  // next section's updateSection method instead of removing and initializing a new
  // version of the next section. The updateSection takes newParams to inform
  // how the next section's udpated view should look.
  $updateNextSection(newParams, doReplace = true) {
    if (doReplace) {
      while (this.items.length - 1 > this.selectedIndex) {
        this.removeItemAt(this.items.length - 1)
      }
    }

    // if the next section hasn't been initialized yet or if we need to completely
    // replace the next section, we will append the next section to the items array and thus
    // initialize a new version of the next section
    if (this.selectedIndex === this.items.length - 1 || doReplace) {
      if (this.selected.selected.nextSection != null) {
        this.appendItems([{ ...this.selected.selected.nextSection, alpha: 0.001 }])
        this.items[this.selectedIndex + 1].updateSection(newParams)
        this.items[this.selectedIndex + 1].halfHide(0.3)
      }
    } else {
      this.items[this.selectedIndex + 1].updateSection(newParams)
    }
  }

  $removeNextSection() {
    while (this.items.length - 1 > this.selectedIndex) {
      this.removeItemAt(this.items.length - 1)
    }
  }

  // this function will search for the function name among the children sections and
  // if the function exists, it will call the function upon that child.
  $callAmongSections(name, params) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i][name] != null) {
        if (params != null) {
          this.items[i][name](...params)
        } else {
          this.items[i][name](...params)
        }
      }
    }
  }

  // shift focus to next section
  $focusOnNextSection() {
    super.selectNext()
    this.selected._refocus()
  }

  $focusOnPreviousSection() {
    super.selectPrevious()
    this.selected._refocus()
  }

  $focusOnSection(index) {
    if (index != null) {
      if (this.selectedIndex !== index && index >= 0 && index < this.items.length) {
        this.selectedIndex = index
      }
    }
  }

  get Content() {
    return this.items
  }

  _handleBack(e) {
    if (this.selectedIndex > 0) {
      this.selectPrevious()
      e.preventDefault() // must prevent default on browser back event if stopping propagation
      return true
    } else {
      // otherwise allow back event to propagate
      return false
    }
  }

  _handleControllerB() {
    if (this.selectedIndex > 0) {
      this.selectPrevious()
      return
    }

    Router.back()
  }
}
