export function setImage({ url, loadCallback, errCallback }) {
    const background = new Image();
    background.src = url;
    background.onload = () => {
        loadCallback();
    };
    background.onerror = () => {
        errCallback();
        console.error(`Error retrieving image from ${url}`);
    };
    return background;
}
