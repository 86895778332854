import { GameCast } from '../../gamecast/gamecastsdk-2.4.0.js'
import { doGet, doPost } from '../utils/api'
import { ControllerService } from '../lib/controller/controllerService'
export const Targets = {
  LUNAR: 'lunar',
}

const TIMEOUT = 30
const signalEndpoint = 'https://qzbo3ibxxk.execute-api.us-east-2.amazonaws.com'

export default class LunarLaunch {
  constructor(config) {
    this._bindFunctions()
    this._targetModule = 'lunar'
    this._config = config
    this._start = null
  }

  checkGamepads() {
    //this._activateLastActiveCallbacks()

    if (!window.navigator || !navigator.getGamepads) {
      return
    }

    const gamepads = navigator.getGamepads()
    if (!gamepads) {
      return
    }

    for (let i = 0; i < gamepads.length; i++) {
      const gp = gamepads[i]

      // skip if a slot in the gamepad is null
      if (gp) {
        console.log(gp)
        this.gameCast.addGamepad(gp)
        break
      }
    }

    this._start = requestAnimationFrame(this.checkGamepads)
  }

  _bindFunctions() {
    this.checkGamepads = this.checkGamepads.bind(this)
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.pause = this.pause.bind(this)
    this.resume = this.resume.bind(this)
    this.mute = this.mute.bind(this)
    this.unmute = this.unmute.bind(this)
    this.sendNativeMessage = this.sendNativeMessage.bind(this)
  }

  async _createVideo() {
    this.gameCastInitialised = false
    this.debugLog = null
    this._videoId = 'launcher-video-lunar'
    const video = document.createElement('video')
    // video.id = "streamVideoElement";
    video.id = this._videoId
    video.width = '1280'
    video.height = '720'
    video.autoplay = true
    video.playsInline = true
    video.disablePictureInPicture = true
    const container = document.getElementById(this._config.containerId)
    // document.body.append(video);
    container?.appendChild(video)
  }

  sendNativeMessage() {
    if (this.gameCast !== null) {
      const encoder = new TextEncoder()
      const messageBody = encoder.encode(`Message sent at ${new Date()}`)
      this.gameCast.sendApplicationMessage(messageBody)
    }
  }

  async _gameCastInit() {
    // Add event listener for keydown event
    this.gameCast = new GameCast({
      videoElement: document.getElementById('launcher-video-lunar'),
      inputConfiguration: {
        autoMouse: true,
        autoKeyboard: true,
        autoGamepad: true,
        hapticFeedback: false,
        setCursor: 'visibility',
        autoPointerLock: 'fullscreen',
      },
      clientConnection: {
        connectionState: this.streamConnectionStateCallback,
        channelError: this.streamChannelErrorCallback,
        serverDisconnect: this.streamServerDisconnectCallback,
        applicationMessage: this.streamApplicationMessageCallback,
      },
    })

    // this.start()
    this.statDebug = setInterval(this.getStreamStats.bind(this), 1000)
  }

  getStreamStats() {
    const stats = this.gameCast.getRTCStats()
    // console.log(stats);
  }

  strToByteArray(str) {
    return new TextEncoder().encode(str)
  }

  streamConnectionStateCallback(state) {
    console.log('Connection state: ' + state)
    if (state === 'disconnected') {
      clearInterval(this.statDebug)
      // appDisconnect()
    }
  }

  streamChannelErrorCallback(error) {
    console.log('Connection error: ' + JSON.stringify(error))
    this.gameCastInitialised = false
  }

  streamServerDisconnectCallback() {
    console.log('Server disconnected with reason:')
    // if ( === "terminated") {
    // Stream session has ended, disable all reconnection UI
    // }
    // The connection state will transition to 'disconnected' within 5 seconds,
    // but there is no reason to wait. The client can disconnect immediately.
    // this.gameCast.close();
    this.gameCastInitialised = false
  }

  streamApplicationMessageCallback() {
    console.log('Received ' + ' bytes of message from Application')
  }

  async start() {
    try {
      this._config.target = 'lunar'
      this._createVideo()
      this._gameCastInit()

      const queryParams = new URLSearchParams(window.location.search)
      const streamGroupId = queryParams.get('stream_id', 'bnKMYZkGu')
      const fps = queryParams.get('fps', '30')

      //const streamGroupId = 'bnKMYZkGu'

      // Generate the signal request for a new WebRTC connection
      const signalRequest = this.gameCast.generateSignalRequest()

      console.log(signalEndpoint)
      console.log(signalRequest)

      // Initiate the connection attempt via our backend server API
      // gets replaced with Thor token on the STB
      const token = ''
      let signalResponse = (
        await doPost(signalEndpoint, {
          SignalRequest: signalRequest,
          streamGroupId: streamGroupId,
          fps:fps
        })
      )?.signalResponse

      // // Complete connection by forwarding signal response to GameCast object
      await this.gameCast.processSignalResponse(signalResponse)
      //this.tag("StatusContainer").visible = false
      //this.tag("Loading").visible = false
      console.log(signalResponse)

      this.gameCast.attachInput()
      console.log('received signal response, checking for gamepads')
      //this.checkGamepads()
      // await this.gameCast.getRTCStats()
      this.gameCastInitialised = true
      const intervalID = setInterval(this.sendNativeMessage, 1000)
    } catch (e) {
      this.gameCastInitialised = false
      console.log(e)
      // this.gameCast.close()
      //this.tag("Loading").visible = false
      //this.tag("Error").visible = true
    }
  }

  stop() {
    this.mediaElement?.remove()
  }

  pause() {
    document.getElementById(this._videoId).pause()
  }

  resume() {
    document.getElementById(this._videoId).pause()
  }

  mute() {
    document.getElementById(this._videoId).mute()
  }

  unmute() {
    document.getElementById(this._videoId).unmute()
  }

  get mediaElement() {
    return document.getElementById(this._videoId)
  }

  deactivate() {
    this._log('canceling animation frame')
    cancelAnimationFrame(this._start)
    this._start = null
  }
}
