import PageBase from '../PageBase'
import ThumbsSurveyPage from './ThumbsSurveyPage'
import BadExperienceReasonsPage from './BadExperienceReasonsPage'
import { Router } from '@lightningjs/sdk'
import CoreEventCreator from '../../lib/analytics/coreEventCreator'
import { NotificationType } from '../../constants/notificationEnums'

const SurveyPages = [ThumbsSurveyPage, BadExperienceReasonsPage]

export default class Survey extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
    }
  }

  set params(args) {
    this.gameSession = args.gameSession
    CoreEventCreator.getInstance()._setGameSession(this.gameSession)
    this.currentPage = parseInt(args.pageNumber)
    if (!this.currentPage || this.currentPage < 0 || this.currentPage >= SurveyPages.length) {
      this.currentPage = 0
    }
    this.postSurveyRoute = args.postSurveyRoute

    this.patchPage(this.currentPage)
  }

  $nextSurveyPage() {
    if (this.currentPage < SurveyPages.length - 1) {
      this.currentPage += 1
      this.patchPage(this.currentPage)
    }
  }

  $previousSurveyPage() {
    if (this.currentPage > 0) {
      this.currentPage -= 1
      this.patchPage(this.currentPage)
    }
  }

  patchPage(pageNumber) {
    this.patch({ Survey: undefined })
    this.patch(
      {
        Survey: {
          type: SurveyPages[pageNumber],
        },
        alpha: 0.001, // Not sure why but setting this to 0 causes empty button problem with smoothing, probably a race condition
      },
      true
    )

    this._patchPageTimeout = setTimeout(() => {
      this.setSmooth('alpha', 1, { duration: 0.5 })
    }, 300)
  }

  _inactive() {
    CoreEventCreator.getInstance()._removeGameSession()
    clearTimeout(this._patchPageTimeout)
  }

  _handleLast(e) {
    return this._goBack(e)
  }

  _handleBack(e) {
    return this._goBack(e)
  }

  _handleControllerB(e) {
    return this._goBack(e)
  }

  _handleHome() {
    // stop propagation
    return true
  }

  _goBack(e) {
    e.preventDefault()
    if (this.currentPage > 0) {
      this.$previousSurveyPage()
    }

    // don't propagate
    return true
  }

  $onExitSurvey(notify = true) {
    if (notify) {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.SURVEY_FEEDBACK,
      })
    }

    if (this.postSurveyRoute) {
      Router.navigate(this.postSurveyRoute)
    } else {
      Router.back()
    }
  }

  _getFocused() {
    return this._Survey
  }

  get _Survey() {
    return this.tag('Survey')
  }
}
