import { CardAboutStacked, utils } from '@lightning/ui'

export default class XGCardAboutStacked extends CardAboutStacked {
  // need to override the order of these funcs so we can't just call
  // super._update
  _update() {
    super._updateLayout()
    super._updateDescription()
    this._updateDescriptionPosition()
    super._updateTitleStyle()
    super._update()
  }

  _updateDescriptionPosition() {
    if (this.description) {
      this._Description.patch({
        x: this.style.paddingHorizontal,
        content: this.description,
        contentWrap: true,
        contentProperties: {
          marginBottom: 4,
        },
        y:
          this._Title.y + this._Title.style.textStyle &&
          this._Title.style.textStyle.lineHeight + this.style.paddingVertical * 1.5,
        justify: 'flex-start',
        badgeProperties: {
          style: {
            backgroundColor: utils.getHexColor('#FFFFFF', 0.15),
            strokeWidth: 0,
          },
        },
        style: {
          textStyle: {
            wordWrapWidth: super._calculateTextWidth(),
          },
        },
      })
    }
    this._bottomElementsTimer = setTimeout(() => {
      if (this._isAttached()) {
        this._updateBottomTitle()
        this._updateBottomDescription()
      }
    }, 200)
  }

  _inactive() {
    clearTimeout(this._bottomElementsTimer)
  }

  _updateBottomTitle() {
    super._updateBottomTitle()
    let y = this.style.paddingFirstLine
    if (this.description) {
      y = this._Description.h + this.style.paddingFirstLine * 4
    }
    this._TitleBottom.patch({ y: y })
  }

  _updateBottomDescription() {
    super._updateBottomDescription()
    if (this.descriptionBottom) {
      this._DescriptionBottom.patch({
        style: {
          textStyle: {
            ...this.style.descriptionTextStyle,
            maxLines: this._Description.h === 120 ? 1 : this._Description.h === 80 ? 2 : 3,
            wordWrapWidth: this._calculateTextWidth(),
          },
        },
      })
    }
  }
}
