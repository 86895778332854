import { sendAnalyticsEvent } from './analyticsService';
import CoreEventCreator from './coreEventCreator';
import { createNegativeFeedbackEvent, createPositiveFeedbackEvent } from './eventCreators';
export var SurveyReasons;
(function (SurveyReasons) {
    SurveyReasons["VIDEO"] = "video";
    SurveyReasons["CONTROL_DELAY"] = "controldelay";
    SurveyReasons["AUDIO_DELAY"] = "audiodelay";
    SurveyReasons["LOAD_TIME"] = "loadtime";
    SurveyReasons["OTHER"] = "other";
})(SurveyReasons || (SurveyReasons = {}));
export class SurveyFeedback {
}
export class SurveyAnalyticsService {
    sendPositiveFeedback() {
        if (CoreEventCreator.getInstance().gameSession == null) {
            console.warn('Survey Analytics: game session info is null. Skip sending feedback.');
            return;
        }
        sendAnalyticsEvent(createPositiveFeedbackEvent(CoreEventCreator.getInstance().createEvent()));
    }
    sendNegativeFeedback(negativeReasons) {
        if (CoreEventCreator.getInstance().gameSession == null) {
            console.warn('Survey Analytics: game session info is null. Skip sending feedback.');
            return;
        }
        const negativeReasonsArr = negativeReasons && negativeReasons.size > 0 ? Array.from(negativeReasons) : [];
        sendAnalyticsEvent(createNegativeFeedbackEvent(CoreEventCreator.getInstance().createEvent(), negativeReasonsArr));
    }
}
