import { Button, Icon, Row, utils } from '@lightning/ui'
import lng from '@lightningjs/core'
import gamesIcon from '../../assets/icons/ic_games.png'
import keyboardIcon from '../../assets/icons/ic_keyboard.png'
import groupIcon from '../../assets/icons/ic_group_fill_inverse.png'
import { RemoteConfig } from '../../lib/remoteConfig'

export class SidekickTab extends Button {
  static _template() {
    return {
      ...super._template(),
      subTheme: 'Sidekick',
      Indicator: {
        texture: lng.Tools.getRoundRect(
          16,
          16,
          8,
          4,
          utils.getHexColor('#232328'),
          true,
          utils.getHexColor('#0AC284')
        ),
        x: 60,
        y: -5,
        alpha: 0,
      },
    }
  }

  set indicatorOn(newIndicatorOn) {
    if (newIndicatorOn) {
      this._Indicator.alpha = 1
    } else {
      this._Indicator.alpha = 0
    }
  }

  _focus() {
    super._focus()
    this.fireAncestors('$onTabFocus', this.panelName)
  }

  get _Indicator() {
    return this.tag('Indicator')
  }
}

export class SidekickTabBar extends lng.Component {
  static _template() {
    return {
      Tabs: {
        type: Row,
        subTheme: 'Sidekick',
        w: 420,
        autoResizeHeight: true,
        neverScroll: true,
        style: {
          itemSpacing: 80,
        },
        items: [
          {
            type: SidekickTab,
            h: 64,
            style: {
              radius: 32,
            },
            prefix: [
              {
                type: Icon,
                icon: gamesIcon,
                w: 40,
              },
            ],
            panelName: 'OverviewPanel',
          },
          {
            type: SidekickTab,
            h: 64,
            style: {
              radius: 32,
            },
            prefix: [
              {
                type: Icon,
                icon: groupIcon,
                w: 40,
              },
            ],
            panelName: 'CouchplayPanel',
          },
          {
            type: SidekickTab,
            h: 64,
            style: {
              radius: 32,
            },
            prefix: [
              {
                type: Icon,
                icon: keyboardIcon,
                w: 40,
              },
            ],
            panelName: 'KeyboardPanel',
          },
        ],
      },
      TabLine: {
        rect: true,
        h: 1,
        w: 420,
        y: 64 + 32,
        color: utils.getHexColor('#FFFFFF', 0.1),
      },
    }
  }

  _active() {
    if (!RemoteConfig.getInstance().couchplayEnabled) {
      this._Tabs.removeItemAt(1)
    }
  }

  set isHost(isHost) {
    this._isHost = isHost
    if (!this._isHost && this._Tabs.items.length === 3) {
      this._Tabs.removeItemAt(1)
    }
  }

  get isHost() {
    return this._isHost
  }

  _getFocused() {
    return this._Tabs
  }

  _updateTabLine() {
    if (this._tabHeight != null) {
      this._TabLine.patch({ y: this._tabHeight + 32 })
    }
  }

  reset() {
    this._Tabs.selectedIndex = 0
  }

  addTab(tab) {
    this._Tabs.appendItems([tab])
    this._updateTabLine()
  }

  get sessionCreated() {
    return this._sessionCreated
  }

  set sessionCreated(newSessionCreated) {
    this._sessionCreated = newSessionCreated
    if (RemoteConfig.getInstance().couchplayEnabled) {
      this._Tabs.items[1].patch({ indicatorOn: this._sessionCreated })
    }
  }

  set tabs(tabs) {
    if (tabs) {
      this._Tabs.appendItems(this.tabs)
      if (this.tabs.length > 0) {
        this._tabHeight = this.tabs[0].h
      }
      this._updateTabLine()
    }
  }

  get tabs() {
    return this._tabs
  }

  get _Tabs() {
    return this.tag('Tabs')
  }

  get _TabLine() {
    return this.tag('TabLine')
  }
}
