import { OverviewPanelNames } from '../panelNames'
import { GameOverviewPanel } from './GameOverviewPanel'
import { ExitConfirmationPanel } from './ExitConfirmationPanel'
import { PanelTemplate } from '../PanelTemplate'

export class OverviewPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      w: 420,
      items: [],
    }
  }

  _active() {
    super._active()
    this.$showOverviewPanel(OverviewPanelNames.GAME_OVERVIEW)
  }

  $showOverviewPanel(panelName) {
    switch (panelName) {
      case OverviewPanelNames.GAME_OVERVIEW:
        this.patch({
          items: [
            {
              type: GameOverviewPanel,
              gamingClient: this.gamingClient,
              gameDetails: this.gameDetails,
            },
          ],
        })
        break
      case OverviewPanelNames.EXIT_CONFIRMATION:
        this.patch({
          items: [
            {
              type: ExitConfirmationPanel,
              gamingClient: this.gamingClient,
              gameDetails: this.gameDetails,
              exitOverride: this.exitOverride,
            },
          ],
        })
    }
  }

  showPanel() {
    this.setSmooth('alpha', 1, { duration: 0.1 })
  }

  hidePanel() {
    this.alpha = 0
  }
}
