import { Column, context, Key, Keyboard, TextBox, utils } from '@lightning/ui'
import { Router, Language } from '@lightningjs/sdk'
import { SpinningIcon } from '../components/SpinningIcon'
import ControllerConnectionChange from '../lib/bluetooth/controllerConnectionChange'
import lng from '@lightningjs/core'
import { RouteHash } from '../constants/routeEnums'
import XGButton, { corner } from './XGButton'

export default class DialpadColumn extends lng.Component {
  _construct() {
    super._construct()
    this._codeError = false
    this._CODE_NUM_DIGITS = 6
  }

  static _template() {
    return {
      ...super._template(),
      Title: {
        type: TextBox,
        mountX: 0.5,
        x: context.theme.layout.screenW / 2,
        y: 136,
        style: {
          textStyle: {
            ...context.theme.typography.display2,
            textAlign: 'center',
          },
        },
      },
      Subtitle: {
        type: TextBox,
        mountX: 0.5,
        x: context.theme.layout.screenW / 2,
        y: 216,
        style: {
          textStyle: {
            ...context.theme.typography.body1,
            textAlign: 'center',
          },
        },
      },
      Code: {
        type: TextBox,
        x: context.theme.layout.screenW / 2 + 18, // additional to force centering with keyboard keys
        y: 293,
        mountX: 0.5,
        w: 336,
        h: 72,
        style: {
          textStyle: {
            ...context.theme.typography.display2,
            textAlign: 'center',
            letterSpacing: 45,
          },
        },
      },
      ErrorMessage: {
        type: TextBox,
        mountX: 0.5,
        x: context.theme.layout.screenW / 2,
        y: 358,
        visible: this._codeError,
        style: {
          textStyle: {
            ...context.theme.typography.caption1,
            textAlign: 'center',
            textColor: utils.getHexColor('#FF99BA'),
          },
        },
      },
      InteractiveColumn: {
        type: Column,
        w: 416,
        autoResizeHeight: true,
        x: context.theme.layout.screenW / 2,
        y: 421,
        mountX: 0.5,
        style: {
          itemSpacing: 48,
          itemTransition: { duration: 0 },
        },
        signals: {
          selectedChange: '_updateSectionPosition',
        },
        items: [
          {
            type: Keyboard,
            rowWrap: false,
            w: 416,
            h: 320,
            keyComponent: Key,
            centerKeyboard: true,
            centerKeys: true,
            style: {
              screenW: context.theme.layout.screenW,
              marginX: 756,
              keySpacing: 10,
              itemTransition: { duration: 0 },
            },
            formats: {
              lowercase: [
                [
                  {
                    title: '1',
                    size: 'md',
                    keyId: '1',
                    announce: 'one, button',
                  },
                  {
                    title: '2',
                    size: 'md',
                    keyId: '2',
                    announce: 'two, button',
                  },
                  {
                    title: '3',
                    size: 'md',
                    keyId: '3',
                    announce: 'three, button',
                  },
                ],
                [
                  {
                    title: '4',
                    size: 'md',
                    keyId: '4',
                    announce: 'four, button',
                  },
                  {
                    title: '5',
                    size: 'md',
                    keyId: '5',
                    announce: 'five, button',
                  },
                  {
                    title: '6',
                    size: 'md',
                    keyId: '6',
                    announce: 'six, button',
                  },
                ],
                [
                  {
                    title: '7',
                    size: 'md',
                    keyId: '7',
                    announce: 'seven, button',
                  },
                  {
                    title: '8',
                    size: 'md',
                    keyId: '8',
                    announce: 'eight, button',
                  },
                  {
                    title: '9',
                    size: 'md',
                    keyId: '9',
                    announce: 'nine, button',
                  },
                ],
                [
                  {
                    title: '0',
                    size: 'md',
                    keyId: '0',
                    announce: 'zero, button',
                  },
                  {
                    title: 'Delete',
                    size: 'xl',
                    keyId: 'delete',
                    announce: 'delete, button',
                  },
                ],
              ],
            },
          },
          {
            type: Column,
            centerInParent: true,
            w: 416,
            extraItemSpacing: 48,
            style: {
              itemSpacing: 12,
              itemTransition: { duration: 0 },
            },
            items: [
              {
                type: XGButton,
                title: ' ',
                w: 416,
                fixed: true,
                rounded: corner.topRight,
              },
            ],
          },
        ],
      },
      Spinner: {
        type: SpinningIcon,
        skipFocus: true,
        alpha: 0,
        x: 817,
        y: 809,
      },
    }
  }

  _bindFunctions() {
    this.updateEnteredCode = this.updateEnteredCode.bind(this)
    this._patchButton = this._patchButton.bind(this)
    this.setError = this.setError.bind(this)
    this.showSpinner = this.showSpinner.bind(this)
  }

  _init() {
    super._init()
    this._Title.content = this.title
    this._Subtitle.content = this.subtitle
    if (this.secondaryCta) {
      this._Buttons.items = [...this._Buttons.items, this.secondaryCta]
    }
    // if override for default number of code digits is provided, set it here
    if (this.numDigits) {
      this._CODE_NUM_DIGITS = this.numDigits
    }

    this.announce = [this._Title.content, this._Subtitle.content]

    for (let i = 0; i < this._Buttons.items.length; i++) {
      this._Buttons.items[i].announceContext = [
        Language.translate('ANNOUNCE_LENGTH', i + 1, this._Buttons.items.length),
        'PAUSE-2',
      ]
    }
  }

  _updateSectionPosition() {
    if (
      this.performControllerCheck &&
      this._InteractiveColumn.selected === this._Buttons &&
      this._Buttons.selected === this._PrimaryCta &&
      this._enteredCode.length === this._CODE_NUM_DIGITS
    ) {
      this._controllerConnectionChange.renderWithControllerData()
    }
  }

  _patchButton(controllerConnected) {
    if (this.performControllerCheck && !controllerConnected) {
      this._PrimaryCta.patch({
        title: 'Pair a controller to join',
        onEnter: () => Router.navigate(RouteHash.BLUETOOTH, { keepAlive: true }),
      })
    } else {
      this._initializePrimaryCta()
    }
  }

  _initializePrimaryCta() {
    this._PrimaryCta.patch({
      title: this.primaryCta?.title,
      onEnter: () => this.primaryCta?.onEnter(),
    })
    this._primaryCtaDefaultX = this._PrimaryCta?._Title?.x
  }

  _focusFirstKey() {
    this._InteractiveColumn.selectedIndex = 0
    this._Keyboard._currentKeyboard.selectedIndex = 0
    this._Keyboard._currentKeyboard.selected.selectedIndex = 0
  }

  _active() {
    this._bindFunctions()
    if (this.performControllerCheck) {
      this._controllerConnectionChange = new ControllerConnectionChange(this._patchButton)
    }

    this._initializePrimaryCta()

    if (this.performControllerCheck && Router.getActiveHash() === RouteHash.BLUETOOTH) {
      // if coming from the bluetooth route check that controller is connected
      // and don't clear code input or reset error
      this._updateSectionPosition()
    } else {
      this._focusFirstKey()
      this.resetCodeAndInlineError()
    }
  }

  _inactive() {
    this.performControllerCheck && this._controllerConnectionChange.removeListener()

    this._resetPrimaryCta()
    clearTimeout(this._primaryCtaFocusDelay)
  }

  resetCodeAndInlineError() {
    this._enteredCode = []
    this._codeError && this.setError(null)
    // set the input text shown in UI to correct number of underscores
    this._CodeInput.patch({
      content: this._getUnderscores(this._CODE_NUM_DIGITS),
    })
  }

  _resetPrimaryCta() {
    this._PrimaryCta?._Title?.patch({
      x: this._primaryCtaDefaultX,
    })
    this._PrimaryCta.patch({
      title: this.primaryCta?.title,
      mode: 'unfocused',
    })
    this._Spinner.stopAnimation()
    this._Spinner.alpha = 0
  }

  _updateFocusAndPrimaryCta() {
    if (this._codeError) {
      // focus on keyboard since they'll need to update the code
      this._InteractiveColumn.selectedIndex = 0
      this._resetPrimaryCta()
    } else {
      // move focus to primary call to action button with slight delay
      this._primaryCtaFocusDelay = setTimeout(() => {
        this._InteractiveColumn.selectedIndex = 1
        // save selected as the delete button so when user presses up or gets code
        // error, it's focused rather than the last key they pressed
        this._Keyboard._currentKeyboard.selectedIndex = 3
        this._Keyboard._currentKeyboard.selected.selectedIndex = 1
      }, 400)
    }
  }

  $onSoftKey(event) {
    this.updateEnteredCode(event)
  }

  // _focusKeys moves the focus around the keyboard in accordance with the user's
  // numeric inputs (support for X1 remote keypad)
  _focusKeys(params) {
    // if the keyboard isn't already the selected index, set it to be
    this._InteractiveColumn.selectedIndex !== 0 && (this._InteractiveColumn.selectedIndex = 0)

    if (params.num) {
      // iterate through rows (this._Keyboard._currentKeyboard.items)
      // to find the row with a key with keyId === key
      for (let i = 0; i < this._Keyboard._currentKeyboard.items.length; i++) {
        const row = this._Keyboard._currentKeyboard.items[i]
        for (let j = 0; j < row.items.length; j++) {
          if (row.items[j].keyId === params.key && row.items[j].mode !== 'focused') {
            row.items[j].mode = 'focused'
            this._Keyboard._currentKeyboard.selectedIndex = i
            this._Keyboard._currentKeyboard.selected.selectedIndex = j
          }
        }
      }
    } else if (params.delete) {
      this._InteractiveColumn.selectedIndex = 0
      this._Keyboard._currentKeyboard.selectedIndex = 3
      this._Keyboard._currentKeyboard.selected.selectedIndex = 1
    }
  }

  _getErrorContent(code) {
    switch (code) {
      case 404:
        return `That's not the right code. Please try again.`
      case 400:
        return 'No code was entered. Please enter a code.'
      default:
        return ''
    }
  }

  setError(codeErrorStatus) {
    this._codeError = Boolean(codeErrorStatus)
    this._codeError && this._updateFocusAndPrimaryCta()
    this._InlineError.patch({
      visible: this._codeError,
      content: this._getErrorContent(codeErrorStatus),
    })
    if (this._InlineError.content) {
      this.fireAncestors('$announceNotification', this._InlineError.content)
    }
  }

  updateEnteredCode(event) {
    let { key } = event
    key = key.toLowerCase()

    if (key.length === 1 && !isNaN(parseInt(key))) {
      if (this._enteredCode.length < this._CODE_NUM_DIGITS) {
        this._focusKeys({ key, num: true })
        this._enteredCode.push(key)
      } else if (this._codeError && this._enteredCode.length === this._CODE_NUM_DIGITS) {
        this._enteredCode.splice(0, this._enteredCode.length, key)
      }
      this._codeError && this.setError(null)
    } else if (this._enteredCode.length > 0) {
      if (
        key === 'delete' ||
        key === 'backspace' ||
        key === 'back' ||
        key === 'b' ||
        key === 'last' ||
        key === 'controllerb'
      ) {
        this._focusKeys({ key, delete: true })
        this._enteredCode.pop()
        this._codeError && this.setError(null)
      }
    }
    this.updateInputText()
  }

  _getUnderscores(num) {
    return Array(num + 1).join('_')
  }

  updateInputText() {
    // if the entered code is < the required number of digits, append
    // an underscore for each missing digit to show this in the UI
    let content = this.inputText
    content += this._getUnderscores(this._CODE_NUM_DIGITS - this._enteredCode.length)
    this._CodeInput.patch({
      content,
    })
    this._enteredCode.length === this._CODE_NUM_DIGITS && this._updateFocusAndPrimaryCta()
  }

  showSpinner(processingTitle) {
    this._PrimaryCta?._Title?.patch({
      x: this._primaryCtaDefaultX + 28,
    })
    this._Spinner.alpha = 1
    this._Spinner.startAnimation()
    processingTitle &&
      this._PrimaryCta.patch({
        title: processingTitle,
      })
  }

  _getFocused() {
    return this._InteractiveColumn
  }

  get inputText() {
    return this._enteredCode?.join('')
  }

  get _InteractiveColumn() {
    return this.tag('InteractiveColumn')
  }

  get _Keyboard() {
    return this._InteractiveColumn.items[0]
  }

  get _CodeInput() {
    return this.tag('Code')
  }

  get _Buttons() {
    return this._InteractiveColumn.items[1]
  }

  get _PrimaryCta() {
    return this._Buttons.items[0]
  }

  get _SecondaryCta() {
    return this._Buttons.items[1]
  }

  get _Spinner() {
    return this.tag('Spinner')
  }

  get _NavDrawer() {
    return this.widgets.navigation
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Subtitle() {
    return this.tag('Subtitle')
  }

  get _InlineError() {
    return this.tag('ErrorMessage')
  }

  _handleKey(event) {
    this.updateEnteredCode(event)
    return true
  }

  _handleLeft() {
    this.focusNav && this.focusNav()
  }

  _handleBack(e) {
    if (this._enteredCode.length === 0 && this.goBack) {
      this.goBack(e)
    } else {
      this._handleKey(e)
    }
  }

  _handleControllerB(e) {
    this._handleBack(e)
  }

  _handleLast(e) {
    this._handleBack(e)
  }
}
