export class BluetoothError {
    constructor(method, error) {
        this.name = 'Bluetooth error';
        if (error instanceof Error) {
            this.error = error;
        }
        else {
            this.error = new BluetoothFailureError(error, method);
        }
        this.message = `Bluetooth ${method} call error: ${JSON.stringify(error)}`;
        this.method = method;
    }
}
export class BluetoothFailureError {
    constructor(result, method) {
        this.name = 'Bluetooth false success';
        this.method = method;
        this.result = result;
        this.message = `Bluetooth ${method} call failed with "success: false" response`;
    }
}
