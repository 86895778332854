import { Column, TextBox, context } from '@lightning/ui'
import PageBase from '../PageBase'
import ContentControls from '../../assets/content-controls.png'
import { Language, Router } from '@lightningjs/sdk'
import XGButton, { corner } from '../../components/XGButton'
import { RouteHash } from '../../constants/routeEnums'
import routeNotifier from '../../RouteNotifier'

export default class FTUContentControls extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announceContext: Language.translate('UP_DOWN_NAVIGATION'),
      Text: {
        type: Column,
        autoResizeWidth: true,
        autoResizeHeight: true,
        mountX: 0.5,
        y: 136,
        x: context.theme.layout.screenW / 2,
        items: [
          {
            type: TextBox,
            centerInParent: true,
            content: 'Go to your settings to unlock age-restricted games',
            style: {
              textStyle: { ...context.theme.typography.display2, textAlign: 'center' },
            },
            skipFocus: true,
          },
          {
            type: TextBox,
            centerInParent: true,
            content: `To keep everyone safe, games for teens and adults are automatically restricted.\n\nGo to your content control settings to unlock every game. `,
            style: {
              textStyle: { ...context.theme.typography.body1, textAlign: 'center' },
            },
            skipFocus: true,
            extraItemSpacing: 125,
          },
        ],
      },
      ControlsPic: {
        src: ContentControls,
        w: 1066,
        h: 256,
        x: context.theme.layout.screenW / 2,
        y: context.theme.layout.screenH / 2,
        mountX: 0.5,
        mountY: 0.5,
        centerInParent: true,
      },
      Buttons: {
        type: Column,
        autoResizeWidth: true,
        autoResizeHeight: true,
        mountX: 0.5,
        y: 805,
        x: context.theme.layout.screenW / 2,
        items: [
          {
            type: XGButton,
            rounded: corner.topRight,
            title: 'Start playing',
          },
          {
            type: XGButton,
            rounded: corner.bottomLeft,
            title: 'Go to settings',
            onEnter: () =>
              Router.navigate(RouteHash.CONTENT_CONTROLS + RouteHash.UPDATE_CONTENT_CONTROLS_PATH),
          },
        ],
      },
    }
  }

  _init() {
    super._init()
    routeNotifier.setLaunchHash()
    this.announce = this._Text.items.map((item) => {
      return item.content
    })
    this._Buttons.items[0].patch({
      onEnter: () => {
        this.setSmooth('alpha', 0, { delay: 0.25, duration: 1 })
        this._navDelay = setTimeout(() => this._routeToNextPage(), 1000)
      },
    })
    for (let i = 0; i < this._Buttons.items.length; i++) {
      this._Buttons.items[i].announceContext = [
        Language.translate('ANNOUNCE_LENGTH', i + 1, this._Buttons.items.length),
        'PAUSE-2',
      ]
    }
  }

  _routeToNextPage() {
    if (routeNotifier.requestedHash) {
      Router.navigate(routeNotifier.requestedHash)
      routeNotifier.clearRequestedHash()
    } else {
      Router.navigate(RouteHash.PLAY)
    }
  }

  _disable() {
    clearTimeout(this._navDelay)
  }

  _getFocused() {
    return this._Buttons
  }

  get _Text() {
    return this.tag('Text')
  }

  get _Buttons() {
    return this.tag('Buttons')
  }
}
