import { Button, Column, Icon, Row, TextBox, context } from '@lightning/ui'
import thumbsUpIcon from '../../assets/icons/thumbs-up.png'
import thumbsDownIcon from '../../assets/icons/thumbs-down.png'
import lng from '@lightningjs/core'
import { SurveyAnalyticsService } from '../../lib/analytics/surveyAnalyticsService'
import XGButton, { corner } from '../../components/XGButton'

export default class ThumbsSurveyPage extends lng.Component {
  static _template() {
    return {
      Content: {
        type: Column,
        autoResizeWidth: true,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 297,
        w: context.theme.layout.screenW,
        style: {
          itemSpacing: 60,
        },
      },
    }
  }

  _init() {
    this._surveyAnalyticsService = new SurveyAnalyticsService()
  }

  _active() {
    this._Content.patch({
      items: [
        {
          type: TextBox,
          centerInParent: true,
          w: 845,
          content: 'How was your gameplay experience?',
          style: {
            textStyle: 'display2',
          },
          skipFocus: true,
        },
        {
          type: Row,
          h: 249,
          w: 562,
          centerInParent: true,
          extraItemSpacing: 20,
          style: {
            itemSpacing: 64,
          },
          items: [
            {
              type: Button,
              w: 249,
              h: 249,
              fixed: true,
              prefix: [
                {
                  type: Icon,
                  icon: thumbsUpIcon,
                  w: 85,
                },
              ],
              onEnter() {
                this.fireAncestors('$onThumbsUpPressed')
              },
            },
            {
              type: Button,
              w: 249,
              h: 249,
              fixed: true,
              prefix: [
                {
                  type: Icon,
                  icon: thumbsDownIcon,
                  w: 85,
                },
              ],
              onEnter() {
                this.fireAncestors('$onThumbsDownPressed')
              },
            },
          ],
        },
        {
          type: XGButton,
          rounded: corner.bottomLeftAndTopRight,
          title: 'Skip',
          centerInParent: true,
          w: 386,
          onEnter() {
            this.fireAncestors('$onSubmitSurvey', false)
          },
        },
      ],
    })

    // prevent rapid clicking to go through feedback
    this._canSubmit = false
    this._canSubmitTimeout = setTimeout(() => {
      this._canSubmit = true
    }, 500)
  }

  _inactive() {
    clearTimeout(this._canSubmitTimeout)
    clearTimeout(this._onSubmitTimeout)
  }

  _getFocused() {
    return this._Content
  }

  get _Content() {
    return this.tag('Content')
  }

  get _ThumbsUpBtn() {
    return this._Content.items[1].items[0]
  }

  get _ThumbsDownBtn() {
    return this._Content.items[1].items[1]
  }

  get _SkipBtn() {
    return this._Content.items[2]
  }

  $onThumbsUpPressed() {
    if (this._canSubmit) {
      this._surveyAnalyticsService.sendPositiveFeedback()
      this.disableBtns(this._ThumbsDownBtn, this._SkipBtn)
      this.updateThumbsUpBtn('Thank you!')
      this._onSubmitTimeout = setTimeout(() => {
        this.$onSubmitSurvey()
      }, 1000)
    }
  }

  $onThumbsDownPressed() {
    if (this._canSubmit) {
      this.fireAncestors('$nextSurveyPage')
    }
  }

  $onSubmitSurvey(notify = true) {
    this.fireAncestors('$onExitSurvey', notify)
  }

  disableBtns(...buttons) {
    buttons.forEach((btn) => {
      btn.patch({
        mode: 'disabled',
      })
    })
  }

  updateThumbsUpBtn(title) {
    this._ThumbsUpBtn.patch({
      title: title,
      prefix: [],
    })
  }
}
