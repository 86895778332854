export const base = (theme) => {
  return {
    h: theme.spacer.md * 8,
    screenW: theme.layout.screenW,
    marginX: theme.layout.marginX,
    marginY: theme.layout.safe,
    itemPadding: theme.spacer.xl,
    logoHeight: theme.typography.display2.lineHeight,
    statusIconHeight: theme.spacer.xl,
    timeTextStyle: theme.typography.headline2,
    timeTextColor: theme.color.textNeutralSecondary,
  }
}
