export const arrowRightEvent = Object.freeze({
    key: 'ArrowRight',
    keyCode: 39,
    code: 'ArrowRight',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const arrowLeftEvent = Object.freeze({
    key: 'ArrowLeft',
    keyCode: 37,
    code: 'ArrowLeft',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const backEvent = Object.freeze({
    key: 'Backspace',
    keyCode: 8,
    code: 'Backspace',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
// used to distinguish between remote back and controller B
export const controllerB = Object.freeze({
    key: 'ControllerB',
    keyCode: 66,
    code: 'ControllerB',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const arrowUpEvent = Object.freeze({
    key: 'ArrowUp',
    keyCode: 38,
    code: 'ArrowUp',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const arrowDownEvent = Object.freeze({
    key: 'ArrowDown',
    keyCode: 40,
    code: 'ArrowDown',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
// controller A maps to Enter
export const enterEvent = Object.freeze({
    key: 'Enter',
    keyCode: 13,
    code: 'Enter',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const homeEvent = Object.freeze({
    key: 'Home',
    keyCode: 72,
    code: 'Home',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const leftEvent = Object.freeze({
    key: 'X',
    keyCode: 88,
    code: 'X',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const topEvent = Object.freeze({
    key: 'Y',
    keyCode: 89,
    code: 'Y',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const rightBumperEvent = Object.freeze({
    key: 'RightBumper',
    keyCode: 49,
    code: 'RightBumper',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const leftBumperEvent = Object.freeze({
    key: 'LeftBumper',
    keyCode: 50,
    code: 'LeftBumper',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const rightTriggerEvent = Object.freeze({
    key: 'RightTrigger',
    keyCode: 9,
    code: 'RightTrigger',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const leftTriggerEvent = Object.freeze({
    key: 'LeftTrigger',
    keyCode: 16,
    code: 'LeftTrigger',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const selectEvent = Object.freeze({
    key: 'Select',
    keyCode: 52,
    code: 'Select',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
export const startEvent = Object.freeze({
    key: 'Start',
    keyCode: 53,
    code: 'Start',
    location: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,
    repeat: false,
});
