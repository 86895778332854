import { Column, context, InlineContent, TextBox } from '@lightning/ui'
import { Language, Router } from '@lightningjs/sdk'
import { CustomButton } from '../components/suite/Hero/Hero'
import PageBase from './PageBase'
import dataLayerAnalytics from '../lib/analytics/dataLayerAnalyticsService'
import { RouteHash } from '../constants/routeEnums'
import { corner } from '../components/XGButton'
import routeNotifier from '../RouteNotifier'

export default class NoticesPage extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      Title: {
        type: TextBox,
        content: 'Please review our Terms of Service and our Privacy Policy',
        style: {
          textStyle: { ...context.theme.typography.display2 },
        },
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 280,
      },
      Description: {
        type: TextBox,
        content:
          'Welcome to Xfinity Games Beta. By using the service, you agree to our Terms of Service and Privacy Policy, which are available at the URLs referenced below.',
        style: {
          textStyle: {
            ...context.theme.typography.body1,
            wordWrap: true,
            wordWrapWidth: 1201,
          },
        },
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 377,
      },
      NoticesList: {
        type: Column,
        skipFocus: true,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 505,
      },
      Button: {
        type: CustomButton,
        rounded: corner.bottomLeftAndTopRight,
        title: 'Okay',
        announceContext: [
          Language.translate('ANNOUNCE_LENGTH', 1, 1),
          Language.translate('PRESS_CENTER_TO_SELECT'),
          'PAUSE-2',
        ],
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 801,
      },
    }
  }

  _onDataProvided() {
    this._noticesAnnounceText = ''
    this._noticesItems = this.data.map((notice) => {
      this._noticesAnnounceText += `${notice.header}: ${notice.link} `
      return {
        type: InlineContent,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        w: 959,
        contentWrap: true,
        contentProperties: {
          marginBottom: 8,
        },
        justify: 'center',
        style: {
          lineHeight: 60,
        },
        content: [
          {
            text: this._getHeaderText(notice.header),
            style: { ...context.theme.typography.body1 },
          },
          { newline: true },
          { text: notice.link, style: { ...context.theme.typography.headline2 } },
        ],
        alpha: 0.001,
      }
    })
    dataLayerAnalytics.processComplete()
  }

  _getHeaderText(noticeType) {
    return `To read our ${noticeType}, visit:`
  }

  _init() {
    super._init()
    routeNotifier.setLaunchHash()
    this._Button.patch({ onEnter: () => this._acknowledgeAndRoute() })
  }

  _acknowledgeAndRoute() {
    this._Button.setSmooth('visible', false, { delay: 0.25, duration: 1 })
    this.setSmooth('alpha', 0, { delay: 0.25, duration: 1 })
    Router.navigate(RouteHash.WELCOME)
  }

  _active() {
    this._NoticesList.patch({ items: this._noticesItems })
    for (let i = 0; i < this._NoticesList.items.length; i++) {
      this._NoticesList.items[i].setSmooth('alpha', 1, {
        delay: 0.5,
        duration: 1,
      })
    }
    this.announce = [this._Title.announce, this._noticesAnnounceText]
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Button() {
    return this.tag('Button')
  }

  get _NoticesList() {
    return this.tag('NoticesList')
  }

  _getFocused() {
    return this._Button
  }
}
