import { context, TextBox, Keyboard } from '@lightning/ui'
import { PanelTemplate } from './PanelTemplate'

const keyboards = {
  lowercase: [
    ['a', 'b', 'c', 'd', 'e', 'f'],
    ['g', 'h', 'i', 'j', 'k', 'l'],
    ['m', 'n', 'o', 'p', 'q', 'r'],
    ['s', 't', 'u', 'v', 'w', 'x'],
    ['y', 'z', '1', '2', '3', '4'],
    ['5', '6', '7', '8', '9', '0'],
    [
      {
        title: 'Shift',
        size: 'lg',
        toggle: 'uppercase',
        announce: 'shift on, button',
        keyId: 'shift',
      },
      {
        title: '#@!',
        size: 'lg',
        toggle: 'symbols',
        announce: 'symbol mode, button',
        keyId: 'symbols',
      },
    ],
    [
      {
        title: 'Space',
        size: 'lg',
        keyId: 'space',
        announce: 'space, button',
      },
      {
        title: 'Delete',
        size: 'lg',
        keyId: 'delete',
        announce: 'delete, button',
      },
    ],
    [
      {
        title: 'Done',
        size: 'xl',
        keyId: 'done',
        announce: 'done, button',
      },
    ],
  ],
  uppercase: [
    ['A', 'B', 'C', 'D', 'E', 'F'],
    ['G', 'H', 'I', 'J', 'K', 'L'],
    ['M', 'N', 'O', 'P', 'Q', 'R'],
    ['S', 'T', 'U', 'V', 'W', 'X'],
    ['Y', 'Z', '1', '2', '3', '4'],
    ['5', '6', '7', '8', '9', '0'],
    [
      {
        title: 'Shift',
        size: 'lg',
        toggle: 'lowercase',
        announce: 'shift off, button',
        keyId: 'shift',
      },
      {
        title: '#@!',
        size: 'lg',
        toggle: 'symbols',
        announce: 'symbol mode, button',
        keyId: 'symbols',
      },
    ],
    [
      {
        title: 'Space',
        size: 'lg',
        keyId: 'space',
        announce: 'space, button',
      },
      {
        title: 'Delete',
        size: 'lg',
        keyId: 'delete',
        announce: 'delete, button',
      },
    ],
    [
      {
        title: 'Done',
        size: 'xl',
        keyId: 'done',
        announce: 'done, button',
      },
    ],
  ],
  symbols: [
    [
      { title: '!', announce: 'exclamation, button' },
      '@',
      '#',
      '$',
      '%',
      { title: '^', announce: 'caret circumflex, button' },
    ],
    [
      '&',
      '*',
      { title: '(', announce: 'open parenthesis, button' },
      { title: ')', announce: 'close parenthesis, button' },
      { title: '{', announce: 'open brace, button' },
      { title: '}', announce: 'close brace, button' },
    ],
    [
      { title: '[', announce: 'open bracket, button' },
      { title: ']', announce: 'close bracket, button' },
      { title: ';', announce: 'semicolon, button' },
      { title: '"', announce: 'doublequote, button' },
      { title: ',', announce: 'comma, button' },
      { title: '|', announce: 'vertical bar, button' },
    ],
    [
      { title: '\\', announce: 'backslash, button' },
      { title: '/', announce: 'forwardslash, button' },
      { title: '<', announce: 'less than, button' },
      { title: '>', announce: 'greater than, button' },
      { title: '?', announce: 'question mark, button' },
      { title: '=', announce: 'equal sign, button' },
    ],
    [
      { title: '`', announce: 'grave accent, button' },
      { title: '~', announce: 'tilde, button' },
      { title: '_', announce: 'underscore, button' },
      { title: ':', announce: 'colon, button' },
      { title: '-', announce: 'dash, button' },
      { title: '+', announce: 'plus sign, button' },
    ],
    [
      {
        title: 'abc',
        size: 'xl',
        toggle: 'lowercase',
        announce: 'alpha mode, button',
      },
    ],
    [
      {
        title: 'Space',
        size: 'lg',
        keyId: 'space',
        announce: 'space, button',
      },
      {
        title: 'Delete',
        size: 'lg',
        keyId: 'delete',
        announce: 'delete, button',
      },
    ],
    [
      {
        title: 'Done',
        size: 'xl',
        keyId: 'done',
        announce: 'done, button',
      },
    ],
  ],
}

class SidekickKeyboard extends Keyboard {
  _update() {
    if (!this._currentFormat) {
      this._currentFormat = this.defaultFormat
    }

    if (this._formatsChanged || this.shouldUpdateTheme) {
      this._createFormat(this._currentFormat)
      this._refocus()
      this._formatsChanged = false
      this.shouldUpdateTheme = false
    } else {
      this._formatKeys()
    }
  }

  $toggleKeyboard(next) {
    this.alpha = 0.001
    this.fireAncestors('$toggleKeyboardStart')
    setTimeout(() => {
      super.$toggleKeyboard(next)
    }, 20)

    // hide keyboard transition
    setTimeout(() => {
      this.alpha = 1
      this.fireAncestors('$toggleKeyboardEnd')
    }, 3000)
  }
}

export class KeyboardPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      w: 420,
      shouldSmooth: false,
      items: [
        {
          type: TextBox,
          extraItemSpacing: 24,
          content: 'Keyboard loading...',
          maxLines: 2,
          w: 426,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              wordWrapWidth: 426,
            },
          },
        },
      ],
    }
  }

  $toggleKeyboardStart() {
    this._Text.patch({ content: 'Keyboard loading...' })
  }

  $toggleKeyboardEnd() {
    this._Text.patch({ content: 'Keyboard input' })
  }

  $onSoftKey(event) {
    if (event.toggle) {
      return
    }

    let { key } = event
    let lowerKey = key.toLowerCase()
    let msg = ''
    if (key.length === 1) {
      msg = key
    } else if (lowerKey === 'space') {
      msg = ' '
    } else if (
      lowerKey === 'delete' ||
      lowerKey === 'backspace' ||
      lowerKey === 'back' ||
      lowerKey === 'last'
    ) {
      msg = '\b'
    } else if (lowerKey === 'done') {
      this.fireAncestors('$closeSidekick')
    }

    if (msg.length > 0) {
      this.fireAncestors('$submitInput', msg)
    }
  }

  showPanel() {
    this.alpha = 1
    this.selectedIndex = 1
    if (!this._keyboardRendered) {
      // to prevent rendering if keyboard affecting the loading time of this tab
      setTimeout(() => {
        this.appendItems([
          {
            type: SidekickKeyboard,
            centerInParent: true,
            w: 426,
            h: 728,
            formats: keyboards,
            alpha: 0.001,
          },
        ])
      }, 100)

      // hide keyboard transition
      setTimeout(() => {
        this._Keyboard.alpha = 1
        this._Text.patch({ content: 'Keyboard input', skipFocus: true })
        this.selectedIndex = 1
      }, 3000)
      this._keyboardRendered = true
    }
  }

  hidePanel() {
    this.alpha = 0
  }

  _handleBack(e) {
    e.preventDefault()
    return true
  }

  _handleLast() {
    return true
  }

  get _Text() {
    return this.items[0]
  }

  get _Keyboard() {
    return this.items[1]
  }
}
