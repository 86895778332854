import axios from 'axios'
import FireboltAuthService from '../../lib/firebolt/fireboltAuthService'
import { RemoteConfig } from '../../lib/remoteConfig'

// https://axios-http.com/docs/cancellation
function newAbortSignal(timeoutMs) {
  const abortController = new AbortController()
  setTimeout(() => abortController.abort(), timeoutMs || 0)

  return abortController.signal
}

async function get(
  url,
  params = { queryParams: undefined, authHeader: false, timeout: 5000 },
  retry = { retry: 0, retryDelay: 0 },
  extraInterceptorSetups = []
) {
  setUpRetry()
  extraInterceptorSetups.forEach((interceptorSetup) => {
    interceptorSetup()
  })
  const config = {
    // Add auth header / query params to GET request config if they are truthy
    ...(params.authHeader && {
      headers: {
        ...(await FireboltAuthService.getInstance().getAuthHeaderWithToken()),
      },
    }),
    ...(params.queryParams && { params: { ...params.queryParams } }),
    timeout: params.timeout,
    signal: newAbortSignal(7000),
    retry: retry.retry,
    retryDelay: retry.retryDelay,
  }
  return axios.get(url, config)
}

async function post(
  url,
  params = { queryParams: undefined, requestBody: undefined, timeout: 5000 },
  retry = { retry: 0, retryDelay: 0 },
  extraInterceptorSetups = []
) {
  setUpRetry()
  extraInterceptorSetups.forEach((interceptorSetup) => {
    interceptorSetup()
  })
  const config = {
    headers: {
      ...(await FireboltAuthService.getInstance().getAuthHeaderWithToken()),
    },
    // Add auth query params to POST request config if truthy
    ...(params.queryParams && { params: { ...params.queryParams } }),
    timeout: params.timeout,
    signal: newAbortSignal(7000),
    retry: retry.retry,
    retryDelay: retry.retryDelay,
  }
  return axios.post(url, params.requestBody, config)
}

async function put(
  url,
  params = { queryParams: undefined, requestBody: undefined, timeout: 5000 },
  retry = { retry: 0, retryDelay: 0 },
  extraInterceptorSetups = []
) {
  setUpRetry()
  extraInterceptorSetups.forEach((interceptorSetup) => {
    interceptorSetup()
  })
  const config = {
    headers: {
      ...(await FireboltAuthService.getInstance().getAuthHeaderWithToken()),
    },
    // Add auth query params to POST request config if truthy
    ...(params.queryParams && { params: { ...params.queryParams } }),
    timeout: params.timeout,
    signal: newAbortSignal(7000),
    retry: retry.retry,
    retryDelay: retry.retryDelay,
  }
  return axios.put(url, params.requestBody, config)
}

async function deleteReq(
  url,
  params = { queryParams: undefined, requestBody: undefined, timeout: 5000 },
  retry = { retry: 0, retryDelay: 0 },
  extraInterceptorSetups = []
) {
  setUpRetry()
  extraInterceptorSetups.forEach((interceptorSetup) => {
    interceptorSetup()
  })
  const config = {
    headers: {
      ...(await FireboltAuthService.getInstance().getAuthHeaderWithToken()),
    },
    // Add auth query params to POST request config if truthy
    ...(params.queryParams && { params: { ...params.queryParams } }),
    timeout: params.timeout,
    signal: newAbortSignal(7000),
    retry: retry.retry,
    retryDelay: retry.retryDelay,
  }
  return axios.delete(url, params.requestBody, config)
}

// from https://javascript.plainenglish.io/how-to-retry-requests-using-axios-64c2da8340a7
export function setUpRetry() {
  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err
    if (!config || config.retry <= 0) {
      return Promise.reject(err)
    }

    // handle AxiosError in the case of unauthorized gameplay request - workaround for known and open
    // axios issue https://github.com/axios/axios/issues/4921 & https://github.com/axios/axios/issues/5173
    if (err.config?.url === RemoteConfig.getInstance().platformURL && !err.status) {
      err.status = 401
      err.message = 'Unauthorized'
    }

    // retry only for server error or timeout error
    if (!err.response || (err.response.status < 500 && err.response.status != 408)) {
      return Promise.reject(err)
    }

    config.retry -= 1
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, config.retryDelay || 1000)
    })
    return delayRetryRequest.then(() => axios(config))
  })
}

export default {
  get,
  post,
  put,
  deleteReq,
}
