import { Base, Icon, Row, TextBox, context } from '@lightning/ui'
import { SpinningIcon } from '../../SpinningIcon'
import { CouchplayPanelNames } from '../panelNames'
import { playerIcons } from '../../../formatters/notifications'
import { CustomButton } from '../../suite/Hero/Hero'
import { PanelTemplate } from '../PanelTemplate'
import { corner } from '../../XGButton'

export class SessionCode extends Base {
  static _template() {
    return {
      w: 420,
      h: 64,
      Code: {
        type: TextBox,
        x: (w) => w / 2,
        mountX: 0.5,
        style: {
          textStyle: context.theme.typography.display2,
        },
        alpha: 1,
      },
      Spinner: {
        type: SpinningIcon,
        skipFocus: true,
        alpha: 0,
        x: (w) => w / 2,
        mountX: 0.5,
      },
    }
  }

  _update() {
    if (this.showSpinner) {
      this._Code.alpha = 0
      this._Spinner.startAnimation()
      this._Spinner.alpha = 1
    } else {
      this._Code.patch({ content: this.code })
      this._Spinner.stopAnimation()
      this._Spinner.alpha = 0
      this._Code.alpha = 1
    }
  }

  get _Spinner() {
    return this.tag('Spinner')
  }

  get _Code() {
    return this.tag('Code')
  }

  static get properties() {
    return ['showSpinner', 'code']
  }
}

export class SessionCodePanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      w: 420,
      style: { ...super._template().style, itemSpacing: 0 },
      plinko: true,
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Party code',
          extraItemSpacing: 40,
          style: {
            textStyle: context.theme.typography.headline2,
          },
        },
        {
          type: SessionCode,
          skipFocus: true,
          centerInParent: true,
          extraItemSpacing: 40,
          code: '',
          showSpinner: false,
        },
        {
          type: TextBox,
          skipFocus: true,
          centerInParent: true,
          content: `Share this code with friends and family so they can join the party.\n\nYour code will reset in:`,
          style: {
            textStyle: {
              ...context.theme.typography.body3,
              wordWrapWidth: 405,
              textAlign: 'center',
            },
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          centerInParent: true,
          content: '',
          extraItemSpacing: 48,
          style: {
            textStyle: {
              ...context.theme.typography.body3,
              wordWrapWidth: 341,
              textAlign: 'center',
            },
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          content: 'Players 1/4',
          extraItemSpacing: 24,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              textAlign: 'center',
            },
          },
        },
        {
          type: Row,
          w: 400,
          h: 88,
          skipFocus: true,
          centerInParent: true,
          items: [],
          style: { ...super._template().style, itemSpacing: 24, itemTransition: { duration: 0 } },
        },
        {
          type: Row,
          extraItemSpacing: 24,
          centerInParent: true,
          w: 400,
          h: 88,
          skipFocus: true,
          items: [],
          style: { ...super._template().style, itemSpacing: 24, itemTransition: { duration: 0 } },
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeftAndTopRight,
          w: 386,
          title: 'End the party',
          centerInParent: true,
        },
      ],
    }
  }

  _bindFunctions() {
    this._updateSessionCodeLoop = this._updateSessionCodeLoop.bind(this)
    this._updateSessionCodePanel = this._updateSessionCodePanel.bind(this)
    this._updateSessionCodeExpiration = this._updateSessionCodeExpiration.bind(this)
    this._updateSessionCode = this._updateSessionCode.bind(this)
    this._updatePlayerInfo = this._updatePlayerInfo.bind(this)
  }

  _init() {
    super._init()
    this._bindFunctions()
    this._EndPartyBtn.patch({
      onEnter: () => {
        this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.END_PARTY_CONFIRMATION)
      },
    })
  }

  _active() {
    super._active()

    if (!this.gamingClient) {
      return
    }

    this._updateSessionCodeLoop()
    this._patchPlayerInfo()
    for (let i = 5; i < this.items.length; i++) {
      if (!this.items[i].skipFocus) {
        this.selectedIndex = i
        return
      }
    }
  }

  _inactive() {
    super._inactive()
    cancelAnimationFrame(this._codeUpdateLoop) // remove loop to check for session code
  }

  // initial patching of player info
  _patchPlayerInfo() {
    this._FirstPlayerBtnRow.appendItems([
      {
        type: CustomButton,
        mode: 'disabled',
        skipFocus: true,
        w: 184,
        h: 72,
        fixed: true,
        title: 'You',
      },
      {
        type: CustomButton,
        mode: 'disabled',
        skipFocus: true,
        // TODO: once manage players functionality is in, uncomment this
        // mode: this.gamingClient.partyPlayers[1] ? 'unfocused' : 'disabled',
        // skipFocus: this.gamingClient.partyPlayers[1] ? false : true,
        w: 184,
        h: 72,
        fixed: true,
        title: this.gamingClient.partyPlayers[1] ? '' : '-',
        prefix: this.gamingClient.partyPlayers[1]
          ? [
              {
                type: Icon,
                icon: playerIcons[1].icon,
                w: 33,
                h: 28,
              },
            ]
          : [],
        onEnter: () => {
          // TODO: once manage players functionality is in, uncomment this
          // this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.MANAGE_PLAYER, { index: 1 })
        },
      },
    ])

    this._SecondPlayerBtnRow.appendItems([
      {
        type: CustomButton,
        mode: 'disabled',
        skipFocus: true,
        // TODO: once manage players functionality is in, uncomment this
        // mode: this.gamingClient.partyPlayers[2] ? 'unfocused' : 'disabled',
        // skipFocus: this.gamingClient.partyPlayers[2] ? false : true,
        w: 184,
        h: 72,
        fixed: true,
        title: this.gamingClient.partyPlayers[2] ? '' : '-',
        prefix: this.gamingClient.partyPlayers[2]
          ? [
              {
                type: Icon,
                icon: playerIcons[2].icon,
                w: 33,
                h: 28,
              },
            ]
          : [],
        onEnter: () => {
          // TODO: once manage players functionality is in, uncomment this
          // this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.MANAGE_PLAYER, { index: 2 })
        },
      },
      {
        type: CustomButton,
        mode: 'disabled',
        skipFocus: true,
        // TODO: once manage players functionality is in, uncomment this
        // mode: this.gamingClient.partyPlayers[3] ? 'unfocused' : 'disabled',
        // skipFocus: this.gamingClient.partyPlayers[3] ? false : true,
        w: 184,
        h: 72,
        fixed: true,
        title: this.gamingClient.partyPlayers[3] ? '' : '-',
        prefix: this.gamingClient.partyPlayers[3]
          ? [
              {
                type: Icon,
                icon: playerIcons[3].icon,
                w: 33,
                h: 28,
              },
            ]
          : [],
        onEnter: () => {
          // TODO: once manage players functionality is in, uncomment this
          // this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.MANAGE_PLAYER, { index: 3 })
        },
      },
    ])

    this._PlayerCount.patch({
      content: `Players ${this.gamingClient.partyPlayers.filter(Boolean).length}/${
        this.gamingClient.partyPlayers.length
      }`,
    })

    // TODO: once manage players functionality is in, uncomment this
    // this._FirstPlayerBtnRow.patch({
    //   skipFocus: !this.gamingClient.partyPlayers[1],
    // })
    // this._SecondPlayerBtnRow.patch({
    //   skipFocus: !(this.gamingClient.partyPlayers[2] || this.gamingClient.partyPlayers[3]),
    // })

    // this._FirstPlayerBtnRow.selectedIndex = 1
    // this._SecondPlayerBtnRow.selectedIndex = this.gamingClient.partyPlayers[2] ? 0 : 1

    // this._FirstPlayerBtnRow._refocus()
    // this._SecondPlayerBtnRow._refocus()

    // this._refocus()
  }

  _updatePlayerInfo() {
    const btns = this._PlayerBtns
    let playerCount = 0
    let focusedIndex = -1

    if (this.selectedIndex === 5) {
      focusedIndex = 1
    } else if (this.selectedIndex === 6) {
      focusedIndex = this._SecondPlayerBtnRow.selectedIndex === 0 ? 2 : 3
    }

    this.gamingClient.partyPlayers.forEach((playerJoined, index) => {
      if (playerJoined) {
        playerCount += 1
        if (index === this.gamingClient.playerIndex) {
          btns[index].patch({
            title: 'You',
            prefix: [],
            mode: 'disabled',
            skipFocus: true,
          })
        } else {
          btns[index].patch({
            title: '',
            prefix: [
              {
                type: Icon,
                icon: playerIcons[index].icon,
                w: 33,
                h: 28,
              },
            ],
            onEnter: () => {
              this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.MANAGE_PLAYER, {
                index,
              })
            },
            mode: 'disabled',
            skipFocus: true,
            // TODO: uncomment when manage players functionality is implemented
            // mode: focusedIndex === index ? 'focused' : 'unfocused',
            // skipFocus: false,
          })
        }
      } else {
        btns[index].patch({
          title: '-',
          prefix: [],
          mode: 'disabled',
          onEnter: () => {},
          skipFocus: true,
        })
      }
    })

    this._PlayerCount.patch({
      content: `Players ${playerCount}/${this.gamingClient.partyPlayers.length}`,
    })

    // TODO: uncomment when manage players functionality is implemented
    // this._FirstPlayerBtnRow.patch({
    //   skipFocus: !this.gamingClient.partyPlayers[1],
    // })
    // this._SecondPlayerBtnRow.patch({
    //   skipFocus: !(this.gamingClient.partyPlayers[2] || this.gamingClient.partyPlayers[3]),
    // })

    // this._FirstPlayerBtnRow.selectedIndex = 1
    // this._SecondPlayerBtnRow.selectedIndex = this.gamingClient.partyPlayers[2] ? 0 : 1
    // this._FirstPlayerBtnRow._refocus()
    // this._SecondPlayerBtnRow._refocus()

    // this._refocus()
  }

  _updateSessionCodeLoop() {
    this._updateSessionCodePanel()
    this._codeUpdateLoop = requestAnimationFrame(this._updateSessionCodeLoop)
  }

  _updateSessionCodePanel() {
    let partyDetails = this.gamingClient.getPartyCode()

    // session code is the same as before, just update expiration countdown
    if (partyDetails.sessionCode === this._sessionCode && !partyDetails.isExpired) {
      this._updateSessionCodeExpiration(partyDetails)
      return
    }

    // session code is different, code needs to be updated
    this._sessionCode = partyDetails.sessionCode
    this._updateSessionCode(partyDetails)
  }

  _updateSessionCodeExpiration(partyDetails) {
    if (!partyDetails || !partyDetails.expiresAt) {
      return
    }

    let timeLeftSeconds = partyDetails.expiresAt - Math.round(Date.now() / 1000)
    let minutes = Math.floor(timeLeftSeconds / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    let seconds = (timeLeftSeconds % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })

    if (timeLeftSeconds < 0) {
      minutes = '00'
      seconds = '00'
    }

    this._ExpirationCountdown.patch({ content: `${minutes}:${seconds}` })
  }

  _updateSessionCode(partyDetails) {
    if (partyDetails.isExpired === false) {
      this._SessionCode.patch({
        code: partyDetails.sessionCode,
        showSpinner: false,
      })
    } else {
      this._SessionCode.patch({ showSpinner: true })
    }

    this._updateSessionCodeExpiration(partyDetails)
  }

  get _SessionCode() {
    return this.items[1]
  }

  get _ExpirationCountdown() {
    return this.items[3]
  }

  get _EndPartyBtn() {
    return this.items[7]
  }

  get _PlayerCount() {
    return this.items[4]
  }

  get _FirstPlayerBtnRow() {
    return this.items[5]
  }

  get _SecondPlayerBtnRow() {
    return this.items[6]
  }

  get _PlayerBtns() {
    let btns = []

    for (let i = 5; i < this.items.length - 1; i++) {
      let row = this.items[i]
      row.items.forEach((btn) => {
        btns.push(btn)
      })
    }

    return btns
  }
}
