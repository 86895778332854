export var GameCategories;
(function (GameCategories) {
    GameCategories["ALL_GAMES"] = "All games";
    GameCategories["FAMILY_FRIENDLY"] = "Family friendly";
    GameCategories["MULTIPLAYER"] = "Multiplayer";
    GameCategories["POPULAR"] = "Popular";
    GameCategories["RPG"] = "rpg";
    GameCategories["REMOTE_MULTI"] = "Party";
})(GameCategories || (GameCategories = {}));
export var GamesSectionHeadingText;
(function (GamesSectionHeadingText) {
    GamesSectionHeadingText["ALPHABETICAL_SORT"] = "Sorted alphabetically";
    GamesSectionHeadingText["POPULARITY_SORT"] = "Sorted by popularity";
})(GamesSectionHeadingText || (GamesSectionHeadingText = {}));
