import { Column, context, Icon, TextBox } from '@lightning/ui'
import Sparkles from '../assets/icons/sparkles-icon.png'
import Rocket from '../assets/icons/rocket-icon.png'
import Controller from '../assets/icons/controller-icon.png'
import { WelcomeMetadata } from '../components/WelcomeMetadata'
import { Language, Router } from '@lightningjs/sdk'
import { CustomButton } from '../components/suite/Hero/Hero'
import PageBase from './PageBase'
import UserAccountProvider from '../api/providers/userAccountProvider'
import Logo from '../assets/icons/welcome_screen_logo.png'
import { RemoteConfig } from '../lib/remoteConfig'
import { RouteHash } from '../constants/routeEnums'
import { corner } from '../components/XGButton'
import routeNotifier from '../RouteNotifier'

export default class WelcomePage extends PageBase {
  _construct() {
    super._construct()
    this._animation = { delay: 0.5, duration: 1 }
    this._content = [
      {
        image: Rocket,
        title: 'Play immersive, high-quality games without the expensive console.',
      },
      {
        image: Controller,
        title: 'Pair and start playing. Compatible with  most popular gaming controllers. ',
      },
      {
        image: Sparkles,
        title: 'Skip the downloads and long load times with cloud gaming technology.',
      },
    ]
  }

  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announceContext: Language.translate('PRESS_CENTER_TO_SELECT'),
      Background: {
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        src: `${RemoteConfig.getInstance().documentsBaseURL}${
          RemoteConfig.getInstance().welcomeImgPath
        }`,
      },
      Title: {
        type: Icon,
        icon: Logo,
        y: 214,
        x: 1127,
        w: 723,
        h: 73,
      },
      Notes: {
        type: Column,
        x: 1133,
        y: 384,
        w: 724,
        itemSpacing: 60,
        items: [],
      },
      ComingSoonText: {
        type: TextBox,
        style: {
          textStyle: { ...context.theme.typography.body2, wordWrapWidth: 710 },
        },
        y: 309,
        x: 1127,
        alpha: 0,
      },
      Button: {
        type: CustomButton,
        rounded: corner.bottomLeftAndTopRight,
        title: UserAccountProvider.getInstance().atMaxCapacity ? 'Exit' : 'Join the beta',
        announceContext: [Language.translate('ANNOUNCE_LENGTH', 1, 1), 'PAUSE-2'],
        h: 80,
        w: 386,
        x: 1133,
        y: 808,
        fixed: true,
        alpha: 0.001,
      },
    }
  }

  _init() {
    super._init()
    routeNotifier.setLaunchHash()
    this._Notes.patch({ items: this._getNotesItems() })
    for (let i = 0; i < this._Notes.items.length; i++) {
      this._Notes.items[i].setSmooth('alpha', 1, this._animation)
    }
    if (UserAccountProvider.getInstance().atMaxCapacity) {
      // TODO: when final copy is released, update this
      this._ComingSoon.patch({
        content: 'Our Beta is full right now - check back soon!',
      })
      this._ComingSoon.setSmooth('alpha', 1, this._animation)
      this._Notes.patch({ y: 429 })
    }
    this._Button.setSmooth('alpha', 1, this._animation)
    this._Button.patch({ onEnter: () => this._onEnter() })
    this.announce = this._ComingSoon.content
      ? this._announceText + ' ' + this._ComingSoon.content
      : this._announceText
  }

  async _onEnter() {
    if (
      UserAccountProvider.getInstance().isNewUser &&
      UserAccountProvider.getInstance().atMaxCapacity
    ) {
      this.fireAncestors('$closeLifecycle')
    } else {
      this._Button.setSmooth('visible', false, { delay: 0.25, duration: 1 })
      this.setSmooth('alpha', 0, { delay: 0.25, duration: 1 })
      // only create an account if the user doesn't already exist
      UserAccountProvider.getInstance().isNewUser &&
        (await UserAccountProvider.getInstance().createUserAccount())
      Router.navigate(RouteHash.CONTENT_CONTROLS)
    }
  }

  _inactive() {
    clearTimeout(this._navDelay)
  }

  _getNotesItems() {
    this._announceText = [this._Title.content]
    const items = this._content.map((item) => {
      this._announceText.push(`${item.title} ${item.subtitle}`)
      return {
        type: WelcomeMetadata,
        title: item.title,
        image: item.image,
        alpha: 0.001,
      }
    })
    this._announceText.join(' ')
    return items
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Notes() {
    return this.tag('Notes')
  }

  get _Button() {
    return this.tag('Button')
  }

  get _ComingSoon() {
    return this.tag('ComingSoonText')
  }

  _getFocused() {
    return this._Button
  }
}
