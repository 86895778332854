import { Icon } from '@lightning/ui'
import checkmark from '../assets/icons/checkmark.png'
import { CustomButton } from './suite/Hero/Hero'

export default class SurveyButton extends CustomButton {
  _init() {
    super._init()
    this._bindFunctions()
    this.isSelected = false
    this.onEnter = this.toggleButton
  }

  _bindFunctions() {
    this.toggleButton = this.toggleButton.bind(this)
  }

  toggleButton() {
    this.isSelected = !this.isSelected
    if (this.isSelected) {
      this.patch(
        {
          prefix: [
            {
              type: Icon,
              icon: checkmark,
              w: 33,
              h: 28,
            },
          ],
        },
        true
      )
    } else {
      this.patch({
        prefix: [],
      })
    }

    this.fireAncestors('$selectSurveyOption', this.isSelected, this.reason)
  }

  _unfocus() {
    super._unfocus()
    if (this.isSelected) {
      this.mode = 'selected'
    }
  }
}
