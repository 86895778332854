// Values correspond to allowed event names https://github.com/comcast-labs/gamex-analytics-docs#gamex-v1-analytics
export var AnalyticsEventName;
(function (AnalyticsEventName) {
    AnalyticsEventName["ERROR"] = "error";
    AnalyticsEventName["GAME_SESSION_STARTING"] = "gameSessionStarting";
    AnalyticsEventName["GAME_SESSION_STARTED"] = "gameSessionStarted";
    AnalyticsEventName["GAME_SESSION_ENDED"] = "gameSessionEnded";
    AnalyticsEventName["GAME_SESSION_FAILED"] = "gameSessionFailed";
    AnalyticsEventName["GAME_SESSION_HEARTBEAT"] = "gameSessionHeartbeat";
    AnalyticsEventName["GAME_SESSION_FEEDBACK"] = "gameSessionFeedback";
    AnalyticsEventName["CONTROLLER_STATUS_CHANGED"] = "controllerStatusChanged";
    AnalyticsEventName["PAGE_TRACKING"] = "pageTracking";
    AnalyticsEventName["APP_SESSION_STARTED"] = "appSessionStarted";
    AnalyticsEventName["APP_SESSION_ENDED"] = "appSessionEnded";
})(AnalyticsEventName || (AnalyticsEventName = {}));
export var AnalyticsGeneralErrorCodes;
(function (AnalyticsGeneralErrorCodes) {
    AnalyticsGeneralErrorCodes["RESOURCE_NOT_FOUND"] = "1";
    AnalyticsGeneralErrorCodes["PERFORM_REQUEST_FAILED"] = "2";
})(AnalyticsGeneralErrorCodes || (AnalyticsGeneralErrorCodes = {}));
export var AnalyticsPlatformErrorCodes;
(function (AnalyticsPlatformErrorCodes) {
    AnalyticsPlatformErrorCodes["NO_CONTENT_CONTROLS"] = "1";
})(AnalyticsPlatformErrorCodes || (AnalyticsPlatformErrorCodes = {}));
export var AnalyticsErrorBucketCodes;
(function (AnalyticsErrorBucketCodes) {
    AnalyticsErrorBucketCodes[AnalyticsErrorBucketCodes["GENERAL"] = 1001] = "GENERAL";
    AnalyticsErrorBucketCodes[AnalyticsErrorBucketCodes["BLUETOOTH"] = 1011] = "BLUETOOTH";
    AnalyticsErrorBucketCodes[AnalyticsErrorBucketCodes["PLATFORM"] = 1013] = "PLATFORM";
    AnalyticsErrorBucketCodes[AnalyticsErrorBucketCodes["GAMEPLAY_SERVICE"] = 1014] = "GAMEPLAY_SERVICE";
})(AnalyticsErrorBucketCodes || (AnalyticsErrorBucketCodes = {}));
// Enums pertaining to Firebolt Metrics
export var ErrorDescription;
(function (ErrorDescription) {
    ErrorDescription["BLUETOOTH"] = "Bluetooth error";
    ErrorDescription["GAMING_CLIENT"] = "Gaming client error";
    ErrorDescription["PLATFORM"] = "Platform error";
    ErrorDescription["GENERAL"] = "General error";
    ErrorDescription["GAMEPLAY_SERVICE"] = "Gameplay service error";
})(ErrorDescription || (ErrorDescription = {}));
// See https://developer.comcast.com/firebolt-apis/latest/getting-started-with-firebolt section: 'Reporting Errors'
export var ErrorType;
(function (ErrorType) {
    ErrorType["NETWORK"] = "network";
    ErrorType["MEDIA"] = "media";
    ErrorType["RESTRICTION"] = "restriction";
    ErrorType["ENTITLEMENT"] = "entitlement";
    ErrorType["OTHER"] = "other";
})(ErrorType || (ErrorType = {}));
