import { context, Base } from '@lightning/ui'
import GameXBk from '../../assets/gamex-bk-default.png'
import FullScreenScrollingText from '../FullScreenScrollingText'

export default class ArticleOverlay extends Base {
  static _template() {
    return {
      Background: {
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        src: GameXBk,
      },
      Overview: { type: FullScreenScrollingText },
    }
  }

  _active() {
    this._Overview._Title.patch({
      y: 117,
      style: {
        ...this._Overview._Title.style,
        textStyle: {
          ...this._Overview._Title.style.textStyle,
          ...context.theme.typography.display2,
        },
      },
    })
    this._Overview._Description.patch({
      y: 202,
      w: 1080,
      scrollStep: 400, // move 10 lines per scroll
    })
    this._Overview.patch({
      title: this.title,
      description: this.content,
    })
  }

  _getFocused() {
    return this._Overview
  }

  get _Overview() {
    return this.tag('Overview')
  }
}
