/**
 * Mixin for tile to broadcase an update to the hero area
 */
export default function withBackgroundUpdate(Base) {
  return class extends Base {
    _focus() {
      super._focus()
      this.execBackgroundUpdate()
    }
    // Expose update as public method to be explicity called by some layouts before tile is focused
    execBackgroundUpdate() {
      if (this.backgroundUpdateData) {
        this.fireAncestors(
          '$backgroundUpdate',
          this.backgroundUpdateData.image,
          this.backgroundUpdateData.metadata
        )
      }
    }
  }
}
