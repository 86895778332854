import { Base, context, Icon, TextBox } from '@lightning/ui'
import lng from '@lightningjs/core'
import spinnerIcon from '../../assets/icons/spinnerIcon'

export class SubSectionHeader extends Base {
  static get properties() {
    return ['showSpinner']
  }

  static _template() {
    return {
      flex: {
        direction: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      skipFocus: true,
      h: 40,
      w: 812,
      Title: {
        type: TextBox,
        content: '',
        skipFocus: true,
        style: {
          textStyle: 'callout1',
        },
      },
      Icon: {
        type: SpinningIcon,
        alpha: 0.001,
        skipFocus: true,
        flexItem: {
          marginRight: 15,
        },
      },
    }
  }

  _update() {
    if (this.showSpinner) {
      this._Icon.startAnimation()
      this._Icon.alpha = 1
    } else {
      this._Icon.stopAnimation()
      this._Icon.alpha = 0
    }
  }

  set content(newContent) {
    this._content = newContent
    this._Title.patch({ content: this._content })
  }

  get content() {
    return this._content
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Icon() {
    return this.tag('Icon')
  }
}

export class SectionHeader extends lng.Component {
  static _template() {
    return {
      extraItemSpacing: 55,
      skipFocus: true,
      h: 50,
      w: 796,
      Title: {
        type: TextBox,
        mountY: 0.5,
        content: '',
        style: {
          textStyle: { ...context.theme.typography.display1 },
        },
        skipFocus: true,
      },
    }
  }

  set content(newContent) {
    this._content = newContent
    this._Title.patch({ content: this._content })
  }

  get content() {
    return this._content
  }

  get _Title() {
    return this.tag('Title')
  }
}

export class SpinningIcon extends lng.Component {
  static _template() {
    return {
      w: 40,
      h: 40,
      Icon: {
        type: Icon,
        icon: spinnerIcon,
        w: 40,
        h: 40,
      },
    }
  }

  startAnimation() {
    if (!this._animation) {
      this._animation = this._Icon.animation({
        duration: 1,
        repeat: -1,
        actions: [{ p: 'rotation', v: { 0: 0, 1: 6.29 } }],
      })
    }

    if (this._animation.isPaused() || !this._animation.isActive()) {
      this._animation.start()
    }
  }

  stopAnimation() {
    this._animation && this._animation.stop()
  }

  get _Icon() {
    return this.tag('Icon')
  }
}
