import { Base } from '@lightning/ui'
import { Router } from '@lightningjs/sdk'
import routeNotifier from '../RouteNotifier'
import UserAccountProvider from '../api/providers/userAccountProvider'
import { RouteHash } from '../constants/routeEnums'
import { RemoteConfig } from '../lib/remoteConfig'

const BOUNDS = 400

// These values should probably be deteremined from theme layout values,
// But I don't quite know how to do that yet.
export const PAGE_EXPANDED_X = 572
export const PAGE_COLLAPSED_X = 156
const PAGE_EXPANDED_X_DELTA = PAGE_EXPANDED_X - PAGE_COLLAPSED_X

export default class PageBase extends Base {
  PAGE_COLLAPSED_X = PAGE_COLLAPSED_X
  PAGE_EXPANDED_X_DELTA = PAGE_EXPANDED_X_DELTA
  PAGE_EXPANDED_X = PAGE_EXPANDED_X

  static _template() {
    return {
      boundsMargin: [BOUNDS, BOUNDS, BOUNDS, BOUNDS],
      w: 1920,
      h: 1080,
    }
  }

  set params(args) {
    // init page with nav open is set on a focus of nav item, rather than enter press
    if (args.initWithNavOpen) {
      this._expandForNav(false)
    }
    this.initWithNavOpen = args?.initWithNavOpen
  }

  _init() {
    // if page is deeplinked to, we need to hide the loading animation on init and ensure
    // user has acknowledged our notices - may need to redirect them to notices
    // if hitting play route while not in retroMode or arcade route while in retroMode,
    // we'll let it handle hiding the loading animation
    if (
      (!RemoteConfig.getInstance().retroModeFlag && routeNotifier.activeHash !== RouteHash.PLAY) ||
      (RemoteConfig.getInstance().retroModeFlag && routeNotifier.activeHash !== RouteHash.ARCADE)
    ) {
      this.fireAncestors('$hideLoading')
    }

    if (RemoteConfig.getInstance().noAccessFlag) {
      Router.navigate(RouteHash.NO_ACCESS)
      return
    }

    if (
      routeNotifier.activeHash !== RouteHash.EXIT &&
      routeNotifier.activeHash !== RouteHash.NOTICES &&
      routeNotifier.activeHash !== RouteHash.WELCOME &&
      UserAccountProvider.getInstance().isNewUser
    ) {
      // if a user is new - store their requested route and navigate them to notices on all pages
      // but those that need to be viewed before their account is created (Exit, Notices, Welcome)
      Router.navigate(RouteHash.NOTICES, { keepAlive: true })
      routeNotifier.setRequestedHash()
      return
    }
  }

  _handleLeft() {
    if (!this.hideNav) {
      Router.focusWidget('Navigation')
      this._expandForNav()
      return true
    }
  }

  _handleControllerB() {
    Router.back()
  }

  _handleLast(e) {
    e.preventDefault()
    Router.back()
  }

  _handleBack(e) {
    e.preventDefault()
    Router.back()
  }

  _handleHome() {
    RemoteConfig.getInstance().retroModeFlag
      ? Router.navigate(RouteHash.ARCADE)
      : Router.navigate(RouteHash.PLAY)
  }

  _focus() {
    this._collapseForNav()
  }

  _expandForNav(smooth = true) {
    if (smooth) {
      this.smooth = { x: PAGE_EXPANDED_X_DELTA }
    } else {
      this.patch({ x: PAGE_EXPANDED_X_DELTA })
    }
  }
  _collapseForNav() {
    this.smooth = { x: 0 }
  }

  get _Navigation() {
    return this.tag('Navigation')
  }
}
