import '@babel/polyfill'
import { Launch } from '@lightningjs/sdk'
import appSettings from './appSettings'
import platformSettings from './platformSettings'
import { install } from 'resize-observer'
import App from './App'

// Need to polyfill resizeobserver for one of the samsung tv's on CTC38
if (!window.ResizeObserver) {
  install()
}

Promise.allSettled =
  Promise.allSettled ||
  ((promises) =>
    Promise.all(
      promises.map((p) =>
        p
          .then((value) => ({
            status: 'fulfilled',
            value,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          }))
      )
    ))
// Wait for the window to load, create the app, and append the canvas to the DOM
window.onload = function () {
  const launcher = Launch(App, appSettings, platformSettings, {})
  // Expose the App for debugging on Flex box
  window.App = launcher
  document.body.appendChild(launcher.stage.getCanvas())
}
