import { context, TextBox, Row, ProgressBar, utils } from '@lightning/ui'
import PageBase from '../PageBase'
import { RemoteConfig } from '../../lib/remoteConfig'
import { Router, Language } from '@lightningjs/sdk'
import { RouteHash } from '../../constants/routeEnums'
import XGButton, { corner } from '../../components/XGButton'

class CustomProgressBar extends ProgressBar {
  _update() {
    // override the announce functionality on ProgressBar
    this._updateTextures()
    this._updateProgress()
  }
}

export default class PartyMode extends PageBase {
  _construct() {
    super._construct()
    this._selectedIndex = 0
    this._directions = {
      NEXT: 'next',
      PREV: 'prev',
    }
    this._announceText = []
  }

  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      ProgressBars: {
        type: Row,
        w: 1620,
        h: 10,
        y: 48,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        items: [],
        style: { itemTransition: { duration: 0 } },
      },
      Heading: {
        type: TextBox,
        content: 'Learn about party mode',
        style: {
          textStyle: {
            ...context.theme.typography.headline3,
            fontSize: 24,
          },
        },
        y: 89,
        x: 150,
        w: 560,
      },
      Image: {
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 210,
      },
      Title: {
        type: TextBox,
        style: {
          textStyle: {
            ...context.theme.typography.display2,
          },
        },
        y: 848,
        x: 150,
        w: 1100,
      },
      Description: {
        type: TextBox,
        style: {
          textStyle: {
            ...context.theme.typography.headline2,
            textColor: utils.getHexColor('#B1B1BD'),
            wordWrapWidth: 1100,
          },
        },
        y: 912,
        x: 150,
        w: 1100,
      },
      Button: {
        type: XGButton,
        rounded: corner.bottomLeftAndTopRight,
        h: 80,
        w: 386,
        x: 1368,
        y: 904,
        fixed: true,
      },
    }
  }

  _onDataProvided() {
    this._progressBars = this.data.map((slide) => {
      this._announceText.push(slide.title + ' ' + slide.description)
      return {
        type: CustomProgressBar,
        w: this._ProgressBarsRow.w / this.data.length - 24,
        h: 8,
        style: {
          animation: { duration: 0 },
        },
      }
    })
  }

  _updateProgress() {
    clearInterval(this._progressInterval)
    this._progressInterval = setInterval(() => {
      this._ProgressBars[this._selectedIndex].progress += 0.0007
      if (this._ProgressBars[this._selectedIndex].progress > 1) {
        clearInterval(this._progressInterval)
        this._slideIndexChange({ direction: this._directions.NEXT })
      }
    }, 5)
  }

  _exitPage() {
    const history = Router.getHistory()
    if (history.length > 0) {
      Router.back()
    } else {
      Router.navigate(RouteHash.PARTY)
    }
  }

  _setAssests() {
    this._updateProgress()
    this._Title.patch({ content: this.data[this._selectedIndex].title })
    this._Description.patch({ content: this.data[this._selectedIndex].description })
    this._Button.patch({
      title: this.data[this._selectedIndex].button,
      onEnter: () => {
        this._isLastSlide
          ? this._exitPage()
          : this._slideIndexChange({ direction: this._directions.NEXT })
      },
    })
    this._Image.patch({
      src: `${RemoteConfig.getInstance().documentsBaseURL}${
        RemoteConfig.getInstance().learnPartyPath
      }${this.data[this._selectedIndex].imagePath}`,
    })
  }

  _resetProgressBars() {
    for (let i = 0; i < this._ProgressBars.length; i++) {
      if (i < this._selectedIndex) {
        this._ProgressBars[i].progress = 1
      } else if (i >= this._selectedIndex) {
        this._ProgressBars[i].progress = 0
      }
    }
  }

  _slideIndexChange({ direction }) {
    if (!direction) {
      return
    } else if (direction === this._directions.NEXT) {
      if (this._isLastSlide) {
        return
      }
      this._selectedIndex++
    } else if (direction === this._directions.PREV) {
      if (this._isFirstSlide) {
        return
      }
      this._selectedIndex--
    }
    this._resetProgressBars()
    this._setAssests()
  }

  _active() {
    this._ProgressBars = this._progressBars
    this._setAssests()
    // slides progress faster than voiceover can read, therefore read all slides'
    // content like a paragraph of text and then give navigation instructions
    this.announce = this.tag('Heading').content + this._announceText.join(' ')
    this._Button.patch({
      announce: this.data[this.data.length - 1].button,
      announceContext: [
        'Button',
        Language.translate('ANNOUNCE_LENGTH', 1, 1),
        Language.translate('LEARN_PARTY_NAVIGATION'),
        'PAUSE-2',
      ],
    })
  }

  _inactive() {
    clearInterval(this._progressInterval)
  }

  _handleRight() {
    this._slideIndexChange({ direction: this._directions.NEXT })
  }

  _handleLeft() {
    this._slideIndexChange({ direction: this._directions.PREV })
  }

  _handleBack(e) {
    if (!this._isFirstSlide) {
      this._slideIndexChange({ direction: this._directions.PREV })
      return
    }
    super._handleBack(e)
  }

  _handleLast(e) {
    this._handleBack(e)
  }

  _handleControllerB() {
    if (!this._isFirstSlide) {
      this._slideIndexChange({ direction: this._directions.PREV })
      return
    }
    super._handleControllerB()
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _ProgressBarsRow() {
    return this.tag('ProgressBars')
  }

  set _ProgressBars(items) {
    this._ProgressBarsRow.items = items
  }

  get _ProgressBars() {
    return this._ProgressBarsRow.items
  }

  get _Image() {
    return this.tag('Image')
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Description() {
    return this.tag('Description')
  }

  get _Button() {
    return this.tag('Button')
  }

  get _isFirstSlide() {
    return this._selectedIndex === 0
  }

  get _isLastSlide() {
    return this._selectedIndex === this._ProgressBars.length - 1
  }

  _getFocused() {
    return this._Button
  }
}
