import { Column, context } from '@lightning/ui'
import lng from '@lightningjs/core'
import { Language } from '@lightningjs/sdk'

const MARGIN = 7

export default class ClippedColumn extends lng.Component {
  static _template() {
    return {
      clipping: true,
      h: context.theme.layout.screenH,
      Content: {
        type: Column,
        x: MARGIN,
        y: MARGIN,
        h: 700,
        autoResizeWidth: true,
      },
    }
  }

  _init() {
    if (this.columnHeight) {
      this._Content.patch({ h: this.columnHeight })
    }
    if (this.scrollIndex != null) {
      this._Content.patch({ scrollIndex: this.scrollIndex })
    }
  }

  appendItems(items) {
    this._Content.appendItems(items)
    this._resize()
  }

  appendItemsAt(items, index) {
    this._Content.appendItemsAt(items, index)
    this._resize()
  }

  removeItemAt(index) {
    this._Content.removeItemAt(index)
  }

  // calculate announce context for buttons
  generateAnnounceContext() {
    let numButtons = this._contentItems.filter((item) => {
      return item.countInAnnounceLength
    }).length

    let index = 1
    this._contentItems.forEach((item) => {
      if (item.countInAnnounceLength) {
        item.patch({
          announceContext: [Language.translate('ANNOUNCE_LENGTH', index, numButtons), 'PAUSE-2'],
        })
        index += 1
      }
    })
  }

  setContent(newContent) {
    this._Content = newContent
  }

  _getFocused() {
    return this._Content
  }

  get _contentItems() {
    return this._Content.items
  }

  get _Content() {
    return this.tag('Content')
  }

  set _Content(content) {
    if (content == null || content.length === 0) {
      if (this.placeholder != null) {
        content = [this.placeholder]
      }
    }

    if (content == null) {
      content = []
    }

    while (this._contentItems.length > content.length) {
      this._Content.removeItemAt(this._contentItems.length - 1)
    }

    content.forEach((item, index) => {
      if (index > this._contentItems.length - 1) {
        this._Content.appendItems([item])
      } else {
        if (Object.getPrototypeOf(this._contentItems[index])._routedType == item.type) {
          this._contentItems[index].patch(item)
        } else {
          this._Content.removeItemAt(index)
          this._Content.appendItemsAt([item], index)
        }
      }
    })

    this._resize()
    this.generateAnnounceContext()
    if (this._Content.selected == null || this._Content.selected.skipFocus) {
      this._refocusFirst()
    }
  }

  _focus() {
    this.generateAnnounceContext()
    if (this._Content.selected && this._Content.selected.skipFocus) {
      this._refocusFirst()
    }
    this._focused = true
  }

  _unfocus() {
    this._focused = false
  }

  _resize() {
    let maxWidth = 0
    this._contentItems.forEach((item) => {
      maxWidth = Math.max(maxWidth, item.w)
    })
    this.w = maxWidth + MARGIN * 2
  }

  _refocusFirst() {
    for (let i = 0; i < this._contentItems.length; i++) {
      if (this._contentItems[i].skipFocus == null || this._contentItems[i].skipFocus === false) {
        this._Content.selectedIndex = i
        return
      }
    }
  }
}
