import { context, TextBox } from '@lightning/ui'
import { Language, Router } from '@lightningjs/sdk'
import { CustomButton } from '../../components/suite/Hero/Hero'
import PageBase from '../PageBase'
import { corner } from '../../components/XGButton'
import QRCode from '../../assets/add-mobile-qr-code.png'
import lng from '@lightningjs/core'

export default class NoMobilePhone extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      Title: {
        type: TextBox,
        content: 'Add a mobile number',
        style: {
          textStyle: { ...context.theme.typography.display2 },
        },
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 208,
      },
      Subtitle: {
        type: TextBox,
        content:
          'To enable one-time codes, add a mobile number to your contact methods.\nScan the QR code or visit customer.xfinity.com/settings/communications.',
        style: {
          textStyle: { ...context.theme.typography.body1, textAlign: 'center' },
        },
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 296,
      },
      QRCode: {
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 448,
        w: 350,
        h: 350,
        rect: true,
        shader: { type: lng.shaders.RoundedRectangle, radius: 20 },
        src: QRCode,
      },
      Button: {
        type: CustomButton,
        rounded: corner.bottomLeftAndTopRight,
        title: 'Back',
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 872,
        onEnter: () => Router.back(),
      },
    }
  }

  _init() {
    super._init()
    this.announce = [this._Title.content, this._Subtitle.content]
    this._Button.announceContext = [
      Language.translate('ANNOUNCE_LENGTH', 1, 1),
      Language.translate('PRESS_CENTER_TO_SELECT'),
      'PAUSE-2',
    ]
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Subtitle() {
    return this.tag('Subtitle')
  }

  get _QRCode() {
    return this.tag('QRCode')
  }

  get _Button() {
    return this.tag('Button')
  }

  _getFocused() {
    return this._Button
  }
}
