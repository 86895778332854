import * as ratingsData from '../../../static/rating/data.json';
export class Rating {
}
export default class RatingsProvider {
    constructor() {
        this._ratingsMap = new Map();
    }
    loadRatings() {
        this._ratingsMap = new Map(Object.entries(ratingsData));
    }
    getRating(key) {
        return this._ratingsMap.get(key);
    }
    getRatings() {
        return this._ratingsMap;
    }
    // Singleton
    static getInstance() {
        if (!RatingsProvider.instance) {
            RatingsProvider.instance = new RatingsProvider();
        }
        return RatingsProvider.instance;
    }
}
