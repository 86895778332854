export const doGet = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()

    if (data?.Items && data.Items[0]) {
      if (data.Items.length > 1) {
        const dataIndex = data.Items.length
        const index = Math.floor(Math.random() * dataIndex)
        console.log('ID ..', data.Items[index].Id)
        return data.Items[index].Id
      }
      return data.Items[0].Id
    } else {
      console.warn('No valid Id found in response data:', data)
      return null
    }
  } catch (e) {
    console.error('GET -> caught error', e)
  }
}

export const doPost = async (url, data) => {
  try {
    console.log(await data)
    let newBody = {
      streamGroupId: await data.streamGroupId,
      SignalRequest: await (await data).SignalRequest,
    }
    console.log(newBody)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newBody),
    })

    if (response.status < 200 || response.status >= 300) {
      console.log(JSON.stringify(response))
      throw new Error(`POST ${url} returned non-2xx status code ${response.status}`)
    }
    console.log(response)
    return response.json()
  } catch (e) {
    console.error('POST -> caught error', e)
  }
}
