import xfinityGamesTheme from '@suite-themes/xfinity-games-lightning-tv'
import { focusRingExtensionGenerator, dropShadowExtensionGenerator } from '@lightning/ui-extensions'
import { utils } from '@lightning/ui'
import * as styles from '../components/NavDrawerItem/NavDrawerItem.style'

const extensions = [
  {
    targetComponent: ['Surface', 'Knob'],
    extension: focusRingExtensionGenerator({ zOffset: -2 }),
  },
  {
    targetComponent: ['Surface'],
    extension: dropShadowExtensionGenerator({
      componentsToMask: ['Button', 'Card'],
      zOffset: -1,
    }),
  },
]

const xfinityGamesWithExtensions = { ...xfinityGamesTheme, extensions }
export const XgTheme = {
  ...xfinityGamesWithExtensions,
  layout: {
    ...xfinityGamesWithExtensions.layout,
    gutterX: 24,
    gutterY: 24,
  },
  componentConfig: {
    ...xfinityGamesWithExtensions.componentConfig,
    Hero: {
      style: {
        titleStyle: {
          wordWrapWidth: 796,
        },
        descriptionMaxLines: 10,
      },
    },
    Shadow: {
      styleConfig: {
        mode: {
          selected: {
            alpha: 'theme.alpha.secondary',
            offsetY: 'theme.spacer.xxl',
          },
        },
      },
    },
    Surface: {
      style: {
        backgroundColor: 'theme.color.fillInverseSecondary',
      },
      styleConfig: {
        mode: {
          focused: {
            backgroundColor: 'theme.color.interactiveInverseFocus',
          },
          disabled: {
            backgroundColor: ['#141417', 0.3],
          },
        },
      },
      tone: 'inverse',
    },
    Button: {
      tone: 'inverse',
      style: {
        radius: 'theme.radius.md',
        mode: {
          focused: {
            backgroundColor: 'theme.color.interactiveInverseFocus',
          },
          selected: {
            backgroundColor: 'theme.color.interactiveNeutralFocusSoft',
          },
          unfocused: {
            backgroundColor: 'theme.color.fillInverseSecondary',
          },
          offScreen: {
            textStyle: { textColor: 'theme.color.textNeutralDisabled' },
            backgroundColor: 'theme.color.fillTransparent',
          },
          disabled: {
            textStyle: { textColor: 'theme.color.textNeutralDisabled' },
            backgroundColor: utils.getHexColor('#141417', 0.3),
          },
        },
      },
    },
    NavItem: {
      style: {
        radius: 'theme.radius.md',
        backgroundColor: ['#1B032D', 0],
        logoStyle: {
          w: 40,
          h: 40,
        },
        logoFocusedStyle: {
          w: 40,
          h: 40,
        },
        mode: styles.mode(),
      },
    },
    ContentControlsListItem: {
      style: {
        descriptionTextStyle: {
          maxLinesSuffix: '',
        },
      },
      styleConfig: {
        mode: {
          focused: {
            descriptionTextStyle: { maxLines: 4 },
          },
        },
      },
    },
    Card: {
      style: {
        mode: {
          focused: {
            tone: {
              inverse: {
                backgroundColor: ['#1B032D', 0.8],
              },
            },
          },
        },
      },
    },
    ProgressBar: {
      tone: 'netural',
    },
    Key: {
      tone: 'inverse',
    },
    Badge: {
      style: {
        textStyle: 'theme.typography.caption1',
      },
    },
  },
}

export const SidekickSubTheme = {
  componentConfig: {
    Surface: {
      style: {
        backgroundColor: utils.getHexColor('#141417', 0.6),
        mode: {
          focused: {
            backgroundColor: 'theme.color.interactiveInverseFocus',
          },
          disabled: {
            backgroundColor: ['#141417', 0.3],
          },
          unfocused: {
            backgroundColor: utils.getHexColor('#141417', 0.6),
          },
        },
      },
    },
    Button: {
      style: {
        tone: 'inverse',
        radius: 'theme.radius.md',
        mode: {
          focused: {
            backgroundColor: 'theme.color.interactiveInverseFocus',
          },
          selected: {
            backgroundColor: 'theme.color.interactiveNeutralFocusSoft',
          },
          unfocused: {
            backgroundColor: utils.getHexColor('#141417', 0.6),
          },
          offScreen: {
            textStyle: { textColor: 'theme.color.textNeutralDisabled' },
            backgroundColor: 'theme.color.fillTransparent',
          },
          disabled: {
            textStyle: { textColor: 'theme.color.textNeutralDisabled' },
            backgroundColor: utils.getHexColor('#141417', 0.3),
          },
        },
      },
    },
    Key: {
      style: {
        sizes: {
          xl: 392,
        },
      },
    },
  },
}
