import { Device } from '@firebolt-js/sdk';
import Thunder from '../thunder/thunder';
var RDKDeviceAttributes;
(function (RDKDeviceAttributes) {
    RDKDeviceAttributes["DEVICE_TYPE"] = "TV";
    RDKDeviceAttributes["OS_NAME"] = "RDK";
})(RDKDeviceAttributes || (RDKDeviceAttributes = {}));
export default class RdkDevice {
    constructor() {
        this._osVersion = 'unknown';
        this._rdkMac = undefined;
        this._physicalDeviceId = 'unknown';
        this._deviceMake = 'unknown';
        this._deviceModel = '';
        this._networkType = 'unknown';
    }
    async initialize() {
        await this._setDeviceAttributes();
    }
    async _setDeviceId() {
        try {
            this._physicalDeviceId = await Device.id();
        }
        catch (err) {
            console.error(`device id error ${JSON.stringify(err)}`);
        }
    }
    async _setDeviceMake() {
        try {
            this._deviceMake = await Device.model();
        }
        catch (err) {
            console.error(`device model error ${JSON.stringify(err)}`);
        }
    }
    async _setDeviceModel() {
        try {
            this._deviceModel = await Device.sku();
        }
        catch (err) {
            console.error(`device sku error ${JSON.stringify(err)}`);
        }
    }
    async _setNetworkType() {
        try {
            const network = (await Device.network());
            this._networkType = network.type;
        }
        catch (err) {
            console.error(`device network error ${JSON.stringify(err)}`);
        }
    }
    async _setMacAddress() {
        try {
            const estbMac = await Thunder.getInstance().getMacAddress();
            this._rdkMac = estbMac.estbMac;
        }
        catch (exception) {
            console.error(exception);
        }
    }
    async _setOsVersion() {
        try {
            const osVersion = await Thunder.getInstance().getOsVersion();
            this._osVersion = osVersion.osVersion;
        }
        catch (exception) {
            console.error(exception);
        }
    }
    async _setDeviceAttributes() {
        await Promise.allSettled([
            this._setDeviceId(),
            this._setDeviceMake(),
            this._setDeviceModel(),
            this._setNetworkType(),
            this._setMacAddress(),
        ]);
        // not sure why for this one, if we add it into the Promise.all array, it will be stuck
        await this._setOsVersion();
    }
    get details() {
        return {
            physicalDeviceId: this._physicalDeviceId,
            deviceType: RDKDeviceAttributes.DEVICE_TYPE,
            deviceMake: this._deviceMake,
            deviceModel: this._deviceModel,
            rdk: {
                ...(this._rdkMac && { macAddress: this._rdkMac }),
            },
            osName: RDKDeviceAttributes.OS_NAME,
            osVersion: this._osVersion,
            networkType: this._networkType,
        };
    }
}
