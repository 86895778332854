import { Authentication } from '@firebolt-js/sdk'

export default class FireboltAuthService {
  constructor() {
    this._authToken = ''
    this._expiratonTime = ''
    this._bindFunctions()
  }

  _bindFunctions() {
    this.setAuthToken = this.setAuthToken.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getAuthHeaderWithToken = this.getAuthHeaderWithToken.bind(this)
  }

  async setAuthToken() {
    try {
      const token = await Authentication.token('platform', null)
      this._authToken = token.value
      // set expiration time in epoch (milliseconds)
      this._expiratonTime = new Date(token.expires).getTime()
    } catch (err) {
      console.error(`Firebolt authentication token error ${JSON.stringify(err)}`)
    }
  }

  async getAuthToken() {
    // if token is mocked due to running in browser, bypass token refresh logic
    if (this.isHardware) {
      // set current time in epoch (milliseconds)
      const currentTime = new Date().getTime()
      const difference = this._expiratonTime - currentTime
      // if token has expired, refresh it
      if (difference <= 0) {
        await this.setAuthToken()
      }
    }
    return this._authToken
  }

  async getAuthHeaderWithToken() {
    const token = await this.getAuthToken()
    return { Authorization: `Bearer ${token}` }
  }

  // taken from https://github.comcast.com/ottx/flex-app/blob/4e258f82380ac48406ee6b990d42909d128c1e50/src/AppLaunchUtils.js#L161
  get isHardware() {
    return typeof ServiceManager !== 'undefined' && ServiceManager !== null
  }

  // Singleton
  static getInstance() {
    if (!FireboltAuthService.instance) {
      FireboltAuthService.instance = new FireboltAuthService()
    }

    return FireboltAuthService.instance
  }
}
