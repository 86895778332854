import api from '../../api/providers/api';
import LoggingService from '../logging/loggingService';
import { LogLevels } from '../logging/loggingEnums';
import { AnalyticsConstants } from '../../constants/analytics';
import FireboltAuthService from '../firebolt/fireboltAuthService';
import { RemoteConfig } from '../remoteConfig';
import { AnalyticsEventName } from './analyticsEnums';
export class Metadata {
}
export function sendAnalyticsEvent(event, metadata = {
    version: AnalyticsConstants.SCHEMA_VERSION,
}) {
    // Return early and don't send analytics events when analytics is off or when running in browser
    if (RemoteConfig.getInstance().analyticsOff || !FireboltAuthService.getInstance().isHardware) {
        return;
    }
    const requestBody = {
        eventRecord: event,
        metadata: metadata,
    };
    return api
        .post(RemoteConfig.getInstance().analyticsEndpoint, {
        requestBody: requestBody,
    })
        .then((response) => {
        let eventDetailsString = '';
        if (event.event) {
            eventDetailsString = `; details: ${JSON.stringify(event.event)}`;
        }
        if (event.eventName === AnalyticsEventName.GAME_SESSION_HEARTBEAT) {
            eventDetailsString = eventDetailsString + `; Time: ${new Date().toLocaleTimeString()}`;
        }
        logAnalytics(`${event.eventName} sent; response: ${response.status}${eventDetailsString}`);
    })
        .catch((err) => {
        console.error(`Error sending analytics ${event.eventName} event: ${err.message}`);
    });
}
function logAnalytics(text) {
    LoggingService.getInstance().logMessage(LogLevels.ANALYTICS, text);
}
