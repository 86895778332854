import { ListItem } from '@lightning/ui'
import Section from './Section'
import { Language } from '@lightningjs/sdk'

export default class ArticleSection extends Section {
  static _template() {
    return {
      ...super._template(),
      wrapSelected: true,
      columnHeight: 850,
      scrollIndex: 4,
    }
  }

  _init() {
    super._init()
    this.header = this.title ? this.title : ''

    this._listItems = this.content.map((article, index) => {
      return {
        type: ListItem,
        title: article.title,
        description: article.description,
        shouldCollapse: true,
        fixed: true,
        w: 796,
        h: 96,
        style: {
          minWidth: 796,
        },
        onEnter: () => this.fireAncestors('$openOverlay', { ...article, label: this.label }),
        announce: article.title + article.description + 'Button',
        announceContext: [
          Language.translate('ANNOUNCE_LENGTH', index + 1, this.content.length),
          'PAUSE-2',
        ],
      }
    })

    this._Content = this._listItems
  }
}
