import { context, Column, TextBox, utils } from '@lightning/ui'
import { Language, Router } from '@lightningjs/sdk'
import PageBase from './PageBase'
import { RouteHash } from '../constants/routeEnums'
import XGButton, { corner } from '../components/XGButton'
import routeNotifier from '../RouteNotifier'
import { RemoteConfig } from '../lib/remoteConfig'

export class FullScreenMessage extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announceContext: Language.translate('PRESS_CENTER_TO_SELECT'),
      Title: {
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 378,
        type: TextBox,
        content: 'Error',
        style: {
          textStyle: {
            ...context.theme.typography.display2,
            textAlign: 'center',
            wordWrapWidth: 900,
          },
        },
        w: 900,
        skipFocus: true,
        wordWrap: true,
      },
      Subtitle: {
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 466,
        type: TextBox,
        content: '',
        style: {
          textStyle: {
            ...context.theme.typography.body1,
            textAlign: 'center',
            wordWrapWidth: 959,
          },
        },
        w: 959,
        skipFocus: true,
        wordWrap: true,
      },
      Buttons: {
        type: Column,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 600,
        items: [
          {
            type: XGButton,
            rounded: corner.topRight,
            title: 'Try Again',
            x: context.theme.layout.screenW / 2,
            mountX: 0.5,
            onEnter() {
              Router.back()
            },
          },
          {
            type: XGButton,
            rounded: corner.bottomLeft,
            title: 'Back',
            x: context.theme.layout.screenW / 2,
            mountX: 0.5,
            onEnter() {
              Router.back()
            },
          },
        ],
      },
    }
  }

  generateAnnounceContext() {
    let visibleBtns = this._Buttons.items.filter((btn) => {
      return btn.visible
    })
    for (let i = 0; i < visibleBtns.length; i++) {
      visibleBtns[i].patch({
        announceContext: [
          Language.translate('ANNOUNCE_LENGTH', i + 1, visibleBtns.length),
          'PAUSE-2',
        ],
      })
    }

    if (visibleBtns.length > 1) {
      this.announceContext = Language.translate('UP_DOWN_NAVIGATION')
    } else {
      this._ButtonOkay.patch({ rounded: corner.bottomLeftAndTopRight })
    }
  }

  set Title(title) {
    this.Title.content = title
  }

  set Subtitle(subtitle) {
    this.Subtitle.content = subtitle
  }

  get announce() {
    if (this._announce) {
      return this._announce
    }

    return [this.Title.content, this.Subtitle.content]
  }

  get Title() {
    return this.tag('Title')
  }
  get Subtitle() {
    return this.tag('Subtitle')
  }

  get _Buttons() {
    return this.tag('Buttons')
  }

  get _ButtonOkay() {
    return this._Buttons?.items?.[0]
  }
  get _ButtonCancel() {
    return this._Buttons?.items?.[1]
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  _getFocused() {
    return this.tag('Buttons')
  }
}

export class GameplayErrorPage extends FullScreenMessage {
  set params(args) {
    this._args = args
    if (this._isConnectivityLossError()) {
      this._updateConnectionLossUI()
    } else if (this._isBadNetworkError()) {
      this._updateBadNetworkUI()
    } else {
      this._updateDefaultErrUI()
    }

    super.generateAnnounceContext()
  }

  _isConnectivityLossError() {
    return this._args.isConnected === false
  }

  _isBadNetworkError() {
    // TODO: determine how we know it's a bad network error code
    return false
  }

  _updateDefaultErrUI() {
    const regex = /{|}/g // search string for curly braces
    const isStringifiedObj = regex.test(this._args.message)
    this.Title.patch({
      content: `Error code: ${this._args.code}`,
    })
    this.Subtitle.patch({
      // if message is missing or if it's a stringified object, print readable message
      content:
        !this._args.message || isStringifiedObj ? 'error initializing game' : this._args.message,
    })

    if (this._args.tryAgain) {
      this._ButtonOkay.patch({
        onEnter: () =>
          Router.navigate(`${RouteHash.GAMEPLAY_PATH}${this._args.gameLabel}`, {
            gameDetails: this._args.gameDetails,
            couchMode: this._args?.couchMode,
            matchID: this._args?.matchID,
            gamePlayTitle: this._args?.gamePlayTitle,
          }),
      })
    } else {
      this._ButtonOkay.patch({
        onEnter: () => Router.back(),
        title: 'Back to Xfinity Games',
      })
      this._ButtonCancel.patch({ visible: false, skipFocus: true })
    }
  }

  _updateBadNetworkUI() {
    this.Title.patch({
      content: 'Your internet connection is too weak to play right now.',
    })
    this.Subtitle.patch({
      content: 'Check your connection and try again in a few minutes.',
    })
    this._ButtonOkay.patch({
      onEnter: () => Router.back(),
      title: 'Back to Xfinity Games',
    })
    this._ButtonCancel.patch({ visible: false, skipFocus: true })
  }

  _updateConnectionLossUI() {
    this.Title.patch({
      content: `Your internet connection was lost.`,
    })
    this.Subtitle.patch({
      content: 'Check your internet connection and try again in a few minutes.',
    })
    this._ButtonOkay.patch({
      onEnter: () => this.fireAncestors('$closeLifecycle'),
      title: 'Exit Xfinity Games',
    })
    this._ButtonCancel.patch({ visible: false, skipFocus: true })
  }
}

export class BluetoothErrorPage extends FullScreenMessage {
  _init() {
    this.Title.patch({
      content: 'Looks like something went wrong',
    })
    this.Subtitle.patch({
      content: 'That device cannot be paired at this time. Try again later.',
    })
    this._ButtonOkay.title = 'Okay'
    this._ButtonCancel.patch({ visible: false, skipFocus: true })

    super.generateAnnounceContext()
  }
}

export class ExitConfirmationPage extends FullScreenMessage {
  _init() {
    if (RemoteConfig.getInstance().retroModeFlag) {
      this.patch(
        {
          Version: {
            type: TextBox,
            content: process.env.VERSION,
            style: {
              textStyle: {
                ...context.theme.typography.body3,
                textColor: utils.getHexColor('#F6F6F9', 0.5),
              },
            },
            skipFocus: true,
            x: 675,
            y: context.theme.layout.screenH - 100,
          },
        },
        true // createMode
      )
    }
    this.Title.patch({
      content: 'Sure you want to exit Xfinity Games?',
      w: 1000,
      wordWrapWidth: 1000,
    })
    this.Subtitle.patch({
      content: `There's always time for one more round.`,
    })
    this._ButtonOkay.title = 'Exit'
    this._ButtonOkay.onEnter = () => this.fireAncestors('$closeLifecycle')
    this._ButtonCancel.title = 'Cancel'
    this._ButtonCancel.onEnter = () => {
      // if in the launch sequence (launch pages are not stored in Router history) launchHash will
      // be truthy with last launch page and we will navigate to that on Cancel
      if (routeNotifier.launchHash) {
        Router.navigate(routeNotifier.launchHash)
      } else if (Router.getHistory().length > 0) {
        Router.back()
      } else {
        Router.navigate(RouteHash.PLAY)
      }
    }

    super._init()
    super.generateAnnounceContext()
  }
}

export class HostEndParty extends FullScreenMessage {
  _init() {
    this.Title.patch({
      content: 'The host ended the party',
    })
    this.Subtitle.patch({
      content: 'Still want to play? Explore one-player games or start your own party.',
    })
    this._ButtonOkay.title = 'Explore Xfinity Games'
    this._ButtonOkay.onEnter = () => Router.navigate(RouteHash.PLAY)
    this._ButtonCancel.patch({ visible: false, skipFocus: true })

    super.generateAnnounceContext()
  }
}

export class GameIdlePage extends FullScreenMessage {
  set params(args) {
    this.gameSession = args.gameSession
  }

  _init() {
    this.Title.patch({
      content: 'Your session has ended',
    })
    this.Subtitle.patch({
      content: `We didn't hear from you for 15 minutes. If you want to continue\nplaying, all you have to do is relaunch your game.`,
    })
    this._ButtonOkay.title = 'Got it'
    this._ButtonOkay.onEnter = () =>
      Router.navigate(RouteHash.GAMEPLAY_SURVEY, {
        gameSession: this.gameSession,
      })
    this._ButtonCancel.patch({ visible: false, skipFocus: true })

    super.generateAnnounceContext()
  }
}

export class CatalogErrorPage extends FullScreenMessage {
  _init() {
    this.Title.patch({
      content: 'Looks like something went wrong',
    })
    this.Subtitle.patch({
      content: `We're unable to load Xfinity Games at this time. Try again later.`,
    })
    this._ButtonOkay.title = 'Exit Xfinity Games'
    this._ButtonOkay.onEnter = () => this.fireAncestors('$closeLifecycle')
    this._ButtonCancel.patch({ visible: false, skipFocus: true })

    super.generateAnnounceContext()
  }
}

export class GameplayComingSoonPage extends FullScreenMessage {
  _init() {
    this.Title.patch({
      content: 'Coming Soon!',
    })
    this.Subtitle.patch({
      content: `We're currently leveling up our system for the adventures ahead. Hang tight and we'll be back in action shortly.`,
    })
    this._ButtonOkay.title = 'Back'
    this._ButtonOkay.onEnter = () => Router.back()
    this._ButtonCancel.patch({ visible: false, skipFocus: true })

    super.generateAnnounceContext()
  }
}
