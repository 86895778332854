import { context } from '@lightning/ui'
import { playerIcons } from '../../../formatters/notifications'
import { CustomButton } from '../../suite/Hero/Hero'
import { CouchplayPanelNames } from '../panelNames'
import { OverrideIconYInlineContent } from '../../ToastNotification'
import { PanelTemplate } from '../PanelTemplate'
import { corner } from '../../XGButton'

export class ManagePlayerPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      style: { ...super._template().style, itemSpacing: 20 },
      w: 420,
      skipFocus: true,
      items: [
        {
          type: OverrideIconYInlineContent,
          skipFocus: true,
          content: ['Player'],
          extraItemSpacing: 28,
          justify: 'flex-start',
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
            },
          },
        },
        {
          type: CustomButton,
          rounded: corner.topRight,
          w: 386,
          title: 'Restore controller',
          mode: 'disabled',
          skipFocus: true,
          centerInParent: true,
        },
        {
          type: CustomButton,
          rounded: corner.none,
          w: 386,
          title: 'Remove from the party',
          mode: 'disabled',
          skipFocus: true,
          centerInParent: true,
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeft,
          w: 386,
          title: 'Back',
          centerInParent: true,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._bindFunctions()
  }

  _active() {
    super._active()
    this._Player.patch({
      content: [
        'Player',
        {
          icon: playerIcons[this.details.index].icon,
        },
      ],
    })

    this._BackBtn.patch({
      onEnter: () => {
        this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.SESSION_CODE)
      },
    })
  }

  get _Player() {
    return this.items[0]
  }

  get _BackBtn() {
    return this.items[3]
  }
}
