import generateUUID from '../../utils/generateUUID';
import DeviceInfo from '../eventInfoFactory/deviceInfo';
import ApplicationDetails from '../eventInfoFactory/applicationDetails';
import UserAccountProvider from '../../api/providers/userAccountProvider';
export default class CoreEventCreator {
    constructor() {
        this._gameSession = null;
    }
    _getControllers() {
        if (!window.navigator || !navigator.getGamepads) {
            return [];
        }
        return navigator
            .getGamepads()
            .filter((controller) => {
            if (controller == null) {
                return false;
            }
            return true;
        })
            .map((controller) => {
            return {
                id: controller.id,
                connected: controller.connected,
            };
        });
    }
    get gameSession() {
        return this._gameSession;
    }
    _removeGameSession() {
        this._gameSession = null;
    }
    _setGameSession(gameSession) {
        this._gameSession = gameSession;
    }
    _getHeader() {
        return {
            timing: {
                clientGeneratedTimestamp: Date.now(),
            },
            uuid: generateUUID(),
        };
    }
    createEvent() {
        var _a, _b;
        let gameTitleExists = false;
        if (this.gameSession && this.gameSession.gameTitle) {
            gameTitleExists = true;
        }
        return {
            header: this._getHeader(),
            session: {
                appSessionId: ApplicationDetails.getInstance().appSessionId,
                gameplayStateId: UserAccountProvider.getInstance().gameplayStateId || 'unknown',
                ...(this.gameSession && ((_a = this.gameSession) === null || _a === void 0 ? void 0 : _a.session)),
            },
            device: {
                ...DeviceInfo.getInstance().physicalDevice.details,
                ...DeviceInfo.getInstance().customerAccount.details,
            },
            application: ApplicationDetails.getInstance().details,
            ...(gameTitleExists && {
                gameTitle: (_b = this.gameSession) === null || _b === void 0 ? void 0 : _b.gameTitle,
            }),
            controllers: this._getControllers(),
        };
    }
    static getInstance() {
        if (!CoreEventCreator._instance) {
            CoreEventCreator._instance = new CoreEventCreator();
        }
        return CoreEventCreator._instance;
    }
}
