import CoreEventCreator from './coreEventCreator';
import { AnalyticsConstants } from '../../constants/analytics';
import { AnalyticsEventName } from './analyticsEnums';
import FireboltAuthService from '../../lib/firebolt/fireboltAuthService';
import { RemoteConfig } from '../remoteConfig';
export var DLEventType;
(function (DLEventType) {
    DLEventType["PAGE_START"] = "c-tracking-log-page-start";
    DLEventType["PAGE_END"] = "c-tracking-log-page";
    DLEventType["PROCESS_START"] = "c-tracking-process-pending";
    DLEventType["PROCESS_END"] = "c-tracking-process-complete";
})(DLEventType || (DLEventType = {}));
function dispatchEvent(type, customData) {
    // Return early and don't send analytics events when analytics is off or when running in browser
    if (RemoteConfig.getInstance().analyticsOff || !FireboltAuthService.getInstance().isHardware) {
        return;
    }
    const event = {
        ...(customData && { detail: customData }),
        bubbles: true,
    };
    document.dispatchEvent(new CustomEvent(type, event));
}
function pageTrackingStart() {
    dispatchEvent(DLEventType.PAGE_START);
}
async function pageTrackingComplete(pageName) {
    const token = await FireboltAuthService.getInstance().getAuthToken();
    dispatchEvent(DLEventType.PAGE_END, {
        token,
        endpoint: RemoteConfig.getInstance().analyticsEndpoint,
        pageName,
        core: {
            eventRecord: {
                ...CoreEventCreator.getInstance().createEvent(),
                eventName: AnalyticsEventName.PAGE_TRACKING,
                event: undefined,
            },
            metadata: { version: AnalyticsConstants.SCHEMA_VERSION },
        },
    });
}
// See https://github.comcast.com/pages/experience-tracking/dl-docs/page-view/#pending-processes
function processPending() {
    dispatchEvent(DLEventType.PROCESS_START);
}
function processComplete() {
    dispatchEvent(DLEventType.PROCESS_END);
}
export default {
    pageTrackingStart,
    pageTrackingComplete,
    processPending,
    processComplete,
};
