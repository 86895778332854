import { context, Icon, TextBox } from '@lightning/ui'
import { Language } from '@lightningjs/sdk'
import { CustomButton } from '../components/suite/Hero/Hero'
import PageBase from './PageBase'
import Logo from '../assets/icons/welcome_screen_logo.png'
import { RemoteConfig } from '../lib/remoteConfig'
import { corner } from '../components/XGButton'

export default class NoAccessPage extends PageBase {
  _construct() {
    super._construct()
  }

  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announceContext: Language.translate('PRESS_CENTER_TO_SELECT'),
      Background: {
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        src: `${RemoteConfig.getInstance().documentsBaseURL}${
          RemoteConfig.getInstance().welcomeImgPath
        }`,
      },
      Title: {
        type: Icon,
        icon: Logo,
        y: 214,
        x: 1127,
        w: 723,
        h: 73,
      },
      Message: {
        type: TextBox,
        content: `Hey there, gamer!\n\nWe're currently leveling up our system for the adventures ahead. Hang tight and we'll be back in action shortly.`,
        style: {
          textStyle: { ...context.theme.typography.body2, wordWrapWidth: 710 },
        },
        y: 375,
        x: 1127,
      },
      Button: {
        type: CustomButton,
        rounded: corner.bottomLeftAndTopRight,
        title: 'Exit',
        announceContext: [Language.translate('ANNOUNCE_LENGTH', 1, 1), 'PAUSE-2'],
        h: 80,
        w: 386,
        x: 1133,
        y: 675,
        fixed: true,
      },
    }
  }

  _init() {
    super._init()
    this._Button.patch({ onEnter: () => this.fireAncestors('$closeLifecycle') })
    this.announce = ['Xfinity Games Beta', this._Message.content]
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Button() {
    return this.tag('Button')
  }

  get _Message() {
    return this.tag('Message')
  }

  _getFocused() {
    return this._Button
  }
}
