import { context } from '@lightning/ui'
import { RouteHash } from '../constants/routeEnums'
import { Router } from '@lightningjs/sdk'
import RatingsProvider from '../api/providers/ratingsProvider'
import { FullScreenMessage } from './FullScreenMessage'

export default class PartyError extends FullScreenMessage {
  set params(args) {
    this._gameInfo = args?.gameInfo
    this._setErrorSubtitle(this._gameInfo)
    this.generateAnnounceContext()
  }

  _init() {
    super._init()
    this.Title = 'Your settings restrict this content.'
    this.Subtitle.patch({
      style: {
        textStyle: {
          ...context.theme.typography.body1,
          textAlign: 'center',
          wordWrapWidth: 1162,
        },
      },
      extraItemSpacing: 89,
    })

    this._ButtonOkay.patch({
      title: 'Go to Content Controls',
      onEnter: () =>
        Router.navigate(RouteHash.CONTENT_CONTROLS + RouteHash.UPDATE_CONTENT_CONTROLS_PATH),
    })

    this._ButtonCancel.patch({
      title: 'Back',
      onEnter: () => {
        Router.back()
      },
    })
  }

  _setErrorSubtitle(gameInfo) {
    if (!gameInfo) {
      return
    }

    const ratingTitle = RatingsProvider.getInstance().getRating(gameInfo.rating).title

    this.Subtitle = `${gameInfo.name} (${ratingTitle}) has been blocked by your content settings. To join this party, go to Settings > Content Controls, and unlock ${gameInfo.rating}-${ratingTitle} content.`
  }
}
