import { Button, NavItem } from '@lightning/ui'
import { Router } from '@lightningjs/sdk'
import * as styles from './NavDrawerItem.style'

const CONTRACTED_WIDTH = 64
const EXPANDED_WIDTH = 380
const CONTRACTED_HEIGHT = 64
const EXPANDED_HEIGHT = 80

export class XGNavItem extends NavItem {
  _init() {
    super._init()
    this._bindFunctions()
  }

  _bindFunctions() {
    this.onEnter = this.onEnter.bind(this)
  }

  _focus() {
    super._focus()
    this._navigateTimeout = setTimeout(() => {
      Router.navigate(this.route, {
        keepAlive: true,
        initWithNavOpen: true,
      })
    }, 600)
  }

  _unfocus() {
    super._unfocus()
    if (this._navigateTimeout) {
      clearTimeout(this._navigateTimeout)
    }
  }

  onEnter() {
    Router.navigate(this.route, {
      keepAlive: false,
      initWithNavOpen: false,
    })
    Router.focusPage()
    this._setResetForAllOtherPages()
  }

  _setResetForAllOtherPages() {
    Router.getHistory().forEach((entry) => {
      if (this.route !== entry.hash) {
        const needsReset = { needsReset: true }
        const newState = entry.state ? { ...entry.state, ...needsReset } : { ...needsReset }
        Router.replaceHistoryState(newState, entry.hash)
      }
    })
  }

  _handleRight() {
    this._setResetForAllOtherPages()
    // propagate up
    return false
  }

  get announce() {
    return this.title + ', Button'
  }
}

class NavDrawerItem extends Button {
  static get __componentName() {
    return 'NavBarButton'
  }

  static get __themeStyle() {
    return styles
  }

  _init() {
    this.fixed = true
    this.prefix = this.iconOutline

    if (this.hasFocus()) {
      this.expand()
    } else {
      this.contract()
    }
  }

  set softFocused(val) {
    this.prefix = val || this.hasFocus() ? this.iconFilled : this.iconOutline
    this._softFocused = val
    this._updateModeSetting()
  }

  _update() {
    super._update()
    this._updateModeSetting()
  }
  _updateModeSetting() {
    this.mode = this.hasFocus()
      ? 'focused'
      : this._expanded
      ? 'expanded'
      : this._softFocused
      ? 'softFocused'
      : 'collapsed'
  }

  _handleRight() {
    // right press resets other page routes like enter, then allows event to propagate
    this._setResetForAllOtherPages()
    return false
  }

  _setResetForAllOtherPages() {
    Router.getHistory().forEach((entry) => {
      if (this.route !== entry.hash) {
        const needsReset = { needsReset: true }
        const newState = entry.state ? { ...entry.state, ...needsReset } : { ...needsReset }
        Router.replaceHistoryState(newState, entry.hash)
      }
    })
  }

  onEnter() {
    if (this.route) {
      // On enter press of nav, all other pages should be tagged to reset on load
      this._setResetForAllOtherPages()
      Router.navigate(this.route, {
        keepAlive: false,
      })
      Router.focusPage()
    }
  }
  _focus() {
    super._focus()
    this.prefix = this.iconFilled
    this.navigateTimeout = setTimeout(() => {
      Router.navigate(this.route, {
        keepAlive: true,
        initWithNavOpen: true,
      })
    }, 600)
  }
  _unfocus() {
    super._unfocus()
    this.prefix = this.iconOutline
    clearTimeout(this.navigateTimeout)
  }

  expand() {
    this.w = EXPANDED_WIDTH
    this.h = EXPANDED_HEIGHT
    this._expanded = true
  }
  contract() {
    this.w = CONTRACTED_WIDTH
    this.h = CONTRACTED_HEIGHT
    this._expanded = false
  }

  // Override contentDimenions so size changes on focus don't shift layout
  _updateContentDimensions() {
    this.applySmooth(this._Content, {
      w: 150,
    })
  }
}

export default NavDrawerItem
