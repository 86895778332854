import { Row, context } from '@lightning/ui'
import videoPlayerLoadStart from '../components/VideoManager'
import { Language, Router } from '@lightningjs/sdk'
import withHorizontalFader from '../mixins/withHorizontalFader'
import { DeviceButton, InProgressButton } from '../components/settings/SettingsButton'
import { imagePath } from '../utils/imagePath'
import withBackgroundUpdate from '../mixins/withBackgroundUpdate'
import { BluetoothWrapper } from '../lib/bluetooth/bluetoothWrapper'
import strictUriComponentEncode from '../utils/strictUriComponentEncode'
import Tile from '../components/Tile'
import { RouteHash } from '../constants/routeEnums'
import PairConnectSection from '../components/settings/PairConnectSection'
import LunarLaunch from '../components/LunarTesting'

const FadingRow = withHorizontalFader(Row)
//this.fireAncestors('$hideAppBackground')

export function formatHeroRow(items) {
  return {
    announce: Language.translate('LAUNCHPAD'),
    items: items.filter(Boolean).map((item) => {
      return formatHeroTile(item)
    }),
    type: FadingRow,
    itemSpacing: 24,
    h: 304,
    w: context.theme.layout.screenW,
    alwaysScroll: true,
    transitions: { alpha: { duration: 0.25, timingFunction: 'ease' } },
  }
}

export function formatHeroTile(data) {
  const encodedTitle = strictUriComponentEncode(data.gamePlayTitle)

  return {
    isRecent: data.isRecent,
    descriptionOverride: data.descriptionOverride || data.description?.short || data.author,
    announce: [data.name, data.descriptionOverride ? data.descriptionOverride : data.author],
    rating: data.rating,
    gamePlayTitle: data.gamePlayTitle,
    encodedTitle,
    type: withBackgroundUpdate(Tile),
    backgroundUpdateData: {
      image: imagePath(data, data.assets?.entity),
      metadata: {
        title: data.name,
        description: data.descriptionOverride ? data.descriptionOverride : data.author,
      },
    },
    width: 304,
    height: 304,
    imgSrc: imagePath(data, data.assets?.logo),
    navigate: () =>
      Router.navigate(`${RouteHash.ENTITY_PATH}${encodedTitle}`, {
        keepAlive: true,
      }),
    transitions: { alpha: { duration: 0.25, timingFunction: 'ease' } },
  }
}

export function formatLatencyTestTile() {
  const title = 'Latency Test'
  const description = 'Lightning based latency test'
  return {
    descriptionOverride: description,
    announce: [title, description],
    rating: 'E',
    gamePlayTitle: 'latency-test',
    type: withBackgroundUpdate(Tile),
    backgroundUpdateData: {
      image: undefined,
      metadata: {
        title,
        description,
      },
    },
    width: 304,
    height: 304,
    imgSrc: undefined,
    navigate: () =>
      Router.navigate(RouteHash.LATENCY_TEST, {
        keepAlive: true,
      }),
    transitions: { alpha: { duration: 0.25, timingFunction: 'ease' } },
  }
}

export function formatGameCatalogResponse(games, numGamesPerRow) {
  let gameRows = []
  let currentRow = []

  for (let i = 0; i < games.length; i++) {
    if (i % numGamesPerRow == 0 && currentRow.length > 0) {
      gameRows.push({ items: [...currentRow] })

      currentRow = []
    }

    currentRow.push(games[i])
  }

  if (currentRow.length > 0) {
    gameRows.push({ items: [...currentRow] })
  }

  return gameRows
}

export function formatArcadeGames(games, numGamesPerRow) {
  let formatted = formatGameCatalogResponse(games, numGamesPerRow)
  return formatted.map((row) => {
    return formatArcadeGameRow(row)
  })
}

export function formatGames(games, numGamesPerRow) {
  let formatted = formatGameCatalogResponse(games, numGamesPerRow)
  return formatted.map((row) => {
    return formatGameRow(row)
  })
}

export function formatArcadeGameRow(row) {
  return {
    ...row,
    type: Row,
    items: row.items.filter(Boolean).map((item) => formatArcadeGamingTitle(item)),
    h: 249,
    w: (w) => w,
  }
}

export function formatGameRow(row) {
  return {
    ...row,
    type: Row,
    items: row.items.filter(Boolean).map((item) => formatGamingTile(item)),
    h: 249,
    w: (w) => w,
  }
}

export function formatGamingTile(data) {
  return {
    ...data,
    type: Tile,
    centerInParent: false,
    gradient: true,
    imgSrc: imagePath(data, data.assets?.logo),
    title: data.name,
    description: data.author,
    navigate: () => {
      Router.navigate(`${RouteHash.ENTITY_PATH}${strictUriComponentEncode(data.gamePlayTitle)}`, {
        keepAlive: true,
      })
    },
    transitions: { alpha: { duration: 0.25, timingFunction: 'ease' } },
  }
}

export function formatArcadeGamingTitle(data) {
  return {
    ...data,
    type: Tile,
    centerInParent: false,
    gradient: true,
    imgSrc: data.imgSrc,
    title: data.name,
    description: data.author,
    navigate: () => {
      if (data.romName != null) {
        Router.navigate(`${RouteHash.EMULATOR}`, {
          keepAlive: true,
          gameData: data,
        })
      }
    },
    transitions: { alpha: { duration: 0.25, timingFunction: 'ease' } },
  }
}

export function pairConnectButtonFormatter(
  device,
  pairCallback,
  unpairCallback,
  connectCallback,
  disconnectCallback
) {
  if (device == null) {
    return []
  }

  if (!device.paired) {
    return [
      {
        type: InProgressButton,
        device: { ...device },
        visibleIcon: BluetoothWrapper.getInstance().isPairing(device.deviceID),
        title: 'Pair this device',
        onEnter() {
          pairCallback()
        },
      },
    ]
  } else if (!device.connected) {
    return [
      {
        type: InProgressButton,
        device: { ...device },
        visibleIcon: BluetoothWrapper.getInstance().isConnecting(device.deviceID),
        title: 'Connect',
        onEnter() {
          connectCallback()
        },
      },
      {
        type: InProgressButton,
        device: { ...device },
        visibleIcon: BluetoothWrapper.getInstance().isUnpairing(device.deviceID),
        title: 'Forget this device',
        onEnter() {
          unpairCallback()
        },
      },
    ]
  } else {
    return [
      {
        type: InProgressButton,
        device: { ...device },
        visibleIcon: BluetoothWrapper.getInstance().isDisconnecting(device.deviceID),
        title: 'Disconnect',
        onEnter() {
          disconnectCallback()
        },
      },
      {
        type: InProgressButton,
        device: { ...device },
        visibleIcon: BluetoothWrapper.getInstance().isUnpairing(device.deviceID),
        title: 'Forget this device',
        onEnter() {
          unpairCallback()
        },
      },
    ]
  }
}

export function bluetoothDeviceItemFormatter(device) {
  return {
    type: DeviceButton,
    device: device,
    title: device.name,
    announce:
      device.paired || device.connected
        ? [
            Language.translate('PAIRED_CONTROLLERS'),
            device.name,
            device.connected ? Language.translate('CONNECTED') : '',
          ]
        : [Language.translate('AVAILABLE_TO_PAIR'), device.name],
    description: device.connected ? 'Connected' : '',
    visibleIcon: device.connected,
    extraItemSpacing: 0,
    onEnter() {
      this.fireAncestors('$focusOnNextSection')
    },
    nextSection: {
      type: PairConnectSection,
      device: device,
    },
  }
}
