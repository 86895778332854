import lng from '@lightningjs/core'
import { Icon, Marquee, utils } from '@lightning/ui'
import backIcon from '../assets/icons/backIcon'
import { Router } from '@lightningjs/sdk'

export class StaticBreadcrumb extends lng.Component {
  static _template() {
    return {
      alpha: 0,
      x: 152,
      y: 85,
      flex: {
        direction: 'row',
        alignItems: 'center',
        justContent: 'start',
      },
      h: 50,
      Icon: {
        type: Icon,
        icon: backIcon,
        w: 25,
        h: 15,
      },
      Title: {
        x: 30,
        w: 325,
        y: -20,
        type: Marquee,
        title: {
          text: 'Back',
          fontFace: 'XfinityBrownBold',
          fontSize: 32,
          textColor: utils.getHexColor('#F6F6F9', 0.6),
          maxLines: 1,
        },
        repeat: 0,
        autoStart: true,
      },
    }
  }

  show(newTitle, smooth = true) {
    if (newTitle != null) {
      this.Title = newTitle
    }

    if (smooth) {
      this.setSmooth('alpha', 1, { duration: 0.25 })
    } else {
      this.patch({ alpha: 1 })
    }
  }

  hide(smooth = true) {
    if (smooth) {
      this.setSmooth('alpha', 0, { duration: 0.25 })
    } else {
      this.patch({ alpha: 0 })
    }
  }

  get Icon() {
    return this.tag('Icon')
  }

  get Title() {
    return this.tag('Title')
  }

  set Title(newTitle) {
    this.Title.patch({
      title: {
        ...this.Title.title,
        text: newTitle,
      },
    })
  }
}

export class Breadcrumb extends StaticBreadcrumb {
  _onActivated(page) {
    this.showBasedOnHistory()
  }

  showBasedOnHistory() {
    if (Router.getHistoryState()?.breadcrumbTitle) {
      this.show(Router.getHistoryState().breadcrumbTitle)
    } else {
      this.hide()
    }
  }
}
