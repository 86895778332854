import PageBase from './PageBase'
import { context, TextBox, utils } from '@lightning/ui'
import { SettingsButton } from '../components/settings/SettingsButton'
import { BluetoothWrapper } from '../lib/bluetooth/bluetoothWrapper'
import BluetoothSection from '../components/settings/BluetoothSection'
import ScrollingRow from '../components/ScrollingRow'
import Section from '../components/settings/Section'
import FireboltAuthService from '../lib/firebolt/fireboltAuthService'
import { Language, Router } from '@lightningjs/sdk'
import { RemoteConfig, MockBluetoothType } from '../lib/remoteConfig'
import dataLayerAnalytics from '../lib/analytics/dataLayerAnalyticsService'
import { RouteHash } from '../constants/routeEnums'

export default class Settings extends PageBase {
  static _template() {
    return {
      ...super._template(),
      Settings: {
        type: ScrollingRow,
        autoResizeWidth: true,
        itemSpacing: 40,
        alwaysScroll: true,
        x: 562,
        y: 85,
        items: [
          {
            type: Section,
            header: 'Settings',
            announceContext: Language.translate('SETTINGS_NAVIGATION'),
            TextBox: {
              type: TextBox,
              content: `${process.env.VERSION || ''}`,
              style: {
                textStyle: {
                  ...context.theme.typography.body2,
                  textColor: utils.getHexColor('#f6f6f9'),
                },
              },
              x: 788,
              y: 772,
              mountX: 1,
              skipFocus: true,
            },
          },
        ],
      },
    }
  }

  historyState() {
    if (!this._NavDrawer.expanded) {
      return {
        breadcrumbTitle: 'Settings',
      }
    } else {
      // if nav drawer is expanded when navigating away from the page
      // reset the selectedIndex to the first button
      this._Section.selectedIndex = 1
    }
  }

  _onDataProvided() {
    this._noticesContent = this.data.map((notice) => {
      return {
        type: SettingsButton,
        title: notice.header,
        description: notice.link,
        shouldCollapse: true,
      }
    })
    dataLayerAnalytics.processComplete()
  }

  _enable() {
    if (
      RemoteConfig.getInstance().mockBTType === MockBluetoothType.FAKE_CONTROLLERS ||
      FireboltAuthService.getInstance().isHardware
    ) {
      BluetoothWrapper.getInstance()
        .startContinuousScan(30)
        .catch((err) => {
          console.error(JSON.stringify(err))
        })
    }
  }

  _disable() {
    if (
      RemoteConfig.getInstance().mockBTType === MockBluetoothType.FAKE_CONTROLLERS ||
      FireboltAuthService.getInstance().isHardware
    ) {
      BluetoothWrapper.getInstance()
        .stopContinuousScan()
        .catch((err) => {
          console.error(JSON.stringify(err))
        })
    }
  }

  _init() {
    super._init()
    this._selectNext = this._selectNext.bind(this)
  }

  _firstActive() {
    this._Section._ContentContainer.appendItems([
      this._getControllersButton(),
      {
        type: SettingsButton,
        title: 'How to pair a controller',
        announce: Language.translate('HOW_TO_PAIR_A_CONTROLLER'),
        onEnter: () => {
          Router.navigate(RouteHash.SELECT_CONTROLLER_LEARN, { keepAlive: true })
        },
      },
      {
        type: SettingsButton,
        title: 'Content controls',
        description: 'Only the primary account holder can change these settings.',
        onEnter: () =>
          Router.navigate(RouteHash.CONTENT_CONTROLS + RouteHash.UPDATE_CONTENT_CONTROLS_PATH),
        shouldCollapse: true,
      },
      ...this._noticesContent,
    ])
  }

  _inactive() {
    BluetoothWrapper.getInstance().clearTimeouts()
  }

  _getControllersButton() {
    if (BluetoothWrapper.getInstance().isActivated()) {
      return {
        type: SettingsButton,
        title: 'Connect controllers',
        nextSection: {
          type: BluetoothSection,
        },
        onEnter: this._selectNext,
      }
    } else {
      return {
        type: SettingsButton,
        title: 'Controllers API unavailable',
      }
    }
  }

  _selectNext() {
    this._Settings.selectNext()
  }

  $sectionUpdated(section, sectionIndex) {
    if (sectionIndex > 0) {
      let prevSection = this._Settings.Content[sectionIndex - 1]
      let newTitle = prevSection.header == null ? '' : prevSection.header
      this._Breadcrumb.show(newTitle)
    } else {
      this._Breadcrumb.hide()
    }
  }

  _getFocused() {
    return this._Settings
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _NavDrawer() {
    return this.widgets.navigation
  }

  get _BackSection() {
    return this.tag('BackSection')
  }

  get _BackTitle() {
    return this._BackSection.tag('Title')
  }

  get _Settings() {
    return this.tag('Settings')
  }

  get _Section() {
    return this._Settings.items[0]
  }
}
