import lng from '@lightningjs/core'
import { TextBox, context } from '@lightning/ui'

export class WelcomeMetadata extends lng.Component {
  static _template() {
    return {
      h: 80,
      w: 724,
      flex: {
        direction: 'row',
      },
      Image: {
        src: '',
        w: 53,
        h: 53,
        flexItem: {
          marginRight: 47,
          alignSelf: 'center',
        },
      },
      Title: {
        type: TextBox,
        content: 'Title',
        style: {
          textStyle: {
            ...context.theme.typography.body1,
            wordWrapWidth: 639,
          },
        },
      },
    }
  }

  _init() {
    super._init()
    this.Title.patch({ content: this.title })
    this.Image.patch({ src: this.image })
  }

  get Title() {
    return this.tag('Title')
  }

  get Image() {
    return this.tag('Image')
  }
}
