import { Router, Utils, VideoPlayer } from '@lightningjs/sdk'
import { context, withAnnouncer } from '@lightning/ui'
import routes from './routes'
import GameXBk from './assets/gamex-bk-default.png'
import { BluetoothWrapper, PairingChangeStatus } from './lib/bluetooth/bluetoothWrapper'
import { navBarConfig, couchplayNavConfig } from './constants'
import logo from './assets/xfinity-games-logo-white.png'
import { Status } from './components/suite/Status'
import formatTime from './utils/formatTime'
import { BluetoothEventTypes } from './lib/bluetooth/bluetoothServiceInterface'
import { LogLevels } from './lib/logging/loggingEnums'
import { Accessibility, Lifecycle } from '@firebolt-js/sdk'
import FireboltAuthService from './lib/firebolt/fireboltAuthService'
import {
  FireboltLifecycleState,
  FireboltLifecycleEvent,
} from './lib/firebolt/fireboltLifecycleEnums'
import { Breadcrumb } from './components/Breadcrumb'
import LoggingService, { LoggingServiceConfig } from './lib/logging/loggingService'
import { RemoteConfig } from './lib/remoteConfig'
import { ControllerService } from './lib/controller/controllerService'
import AppSessionAnalyticsService from './lib/analytics/appSessionAnalyticsService'
import NotificationsContainer from './components/NotificationsContainer'
import { notificationHelper } from './formatters/notifications'
import { NotificationType } from './constants/notificationEnums'
import { RouteHash } from './constants/routeEnums'
import { XGNavigationLeft } from './components/NavDrawer'
import { helpNavConfig, playNavConfig } from './constants/navBarConfig'

export default class App extends withAnnouncer(Router.App) {
  static _template() {
    return {
      ...super._template(),
      Widgets: {
        Navigation: {
          type: XGNavigationLeft,
          x: context.theme.layout.safe,
          y: 344,
          itemSpacing: 24,
          items: [],
        },
        Status: {
          alpha: 0.001,
          x: 1308,
          type: Status,
          logo: logo,
          time: '',
        },
        Breadcrumb: {
          type: Breadcrumb,
          mountY: 0.5,
        },
      },
      Background: {
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        zIndex: -999,
        src: GameXBk,
        alpha: 1,
      },
      NotificationsContainer: {
        type: NotificationsContainer,
      },
    }
  }

  static language() {
    return {
      file: Utils.asset('locale/translations.json'),
      language: 'en',
    }
  }

  async _init() {
    const settings = await Accessibility.voiceGuidanceSettings()

    if (FireboltAuthService.getInstance().isHardware) {
      // TODO: change to true if you want to run voice guidance on browser or run it without setting in accessibility settings
      this.announcerEnabled = settings.enabled
    } else if (this._getVoiceGuidanceParam() === 'on') {
      this.announcerEnabled = true
    } else {
      this.announcerEnabled = false
    }

    // TODO: uncomment if you want to see table of lightning components and the attached voice guidance text
    // this.debug = true

    this._bindFunctions()
  }

  _bindFunctions() {
    this._showPairingStatusNotification = this._showPairingStatusNotification.bind(this)
    this._onRequestFailedCallback = this._onRequestFailedCallback.bind(this)
    this._logNotification = this._logNotification.bind(this)
  }

  _setup() {
    Router.startRouter(routes, this)
    this._addFireboltLifecycleListeners()
  }

  _detach() {
    this._removeListeners()
    BluetoothWrapper.getInstance().deactivate()
    ControllerService.getInstance().deactivate()
    clearTimeout(this._appCloseDelay)
    clearTimeout(this._sendAppSessionEventDelay)
    clearInterval(this._clockInterval)
    clearTimeout(this._notificationAnnounceTimeout)
  }

  _firstActive() {
    // patch the new time in every 5 seconds so that if the screen loads at the
    // tail end of a minute the clock will only appear to be minimally 'behind'
    this.tag('Status').patch({
      time: formatTime(new Date()),
    })
    this._clockInterval = setInterval(
      () =>
        this.tag('Status').patch({
          time: formatTime(new Date()),
        }),
      5000
    )
    this._setUpNav()
  }

  _getVoiceGuidanceParam() {
    const voiceGuidance = new URLSearchParams(window.location.search).get('voiceGuidance')
    return voiceGuidance
  }

  _appReady() {
    this._addListeners()
    this.$showAppBackground()

    Lifecycle.ready()
      .then(() =>
        LoggingService.getInstance().logMessage(LogLevels.FIREBOLT, 'Firebolt lifecycle ready')
      )
      .catch((err) => console.error(`Firebolt lifecycle ready error ${JSON.stringify(err)}`))
  }

  $hideLoading() {
    // if we've already hidden the loading screen then we don't need to call this again.
    if (this._hasLoaded) {
      return
    }

    VideoPlayer.close()
    this._appReady()
    this._hasLoaded = true
  }

  $hideAppBackground() {
    this.tag('Background').alpha = 0.001
  }

  $fadeOutAppBackground() {
    this.tag('Background').setSmooth('alpha', 0.001, { duration: 3 })
  }

  $showAppBackground() {
    this.tag('Background').alpha = 1
    this.tag('Status').setSmooth('alpha', 1, { duration: 1 })
  }

  _setUpNav() {
    let navBarItems = navBarConfig
    if (RemoteConfig.getInstance().retroModeFlag) {
      this.tag('Navigation').items = navBarItems
      return
    }
    navBarItems.unshift(playNavConfig)
    navBarItems.push(helpNavConfig)

    if (RemoteConfig.getInstance().couchplayEnabled) {
      navBarItems = [...navBarItems.slice(0, 2), couchplayNavConfig, ...navBarItems.slice(2)]
    }

    this.tag('Navigation').items = navBarItems
  }

  _activateLoggingService() {
    const loggingServiceConfig = new LoggingServiceConfig(this._logParams)
    LoggingService.getInstance().activate(loggingServiceConfig)
  }

  _addListeners() {
    BluetoothWrapper.getInstance().addControllerStatusChangeListener(
      this._showPairingStatusNotification
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._onRequestFailedCallback
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_STATUS_CHANGED,
      this._logNotification
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._logNotification
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_DEVICE_FOUND,
      this._logNotification
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_DEVICE_LOST,
      this._logNotification
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_DISCOVERED_DEVICE,
      this._logNotification
    )
  }

  _removeListeners() {
    BluetoothWrapper.getInstance().removeControllerStatusChangeListener(
      this._showPairingStatusNotification
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._onRequestFailedCallback
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_STATUS_CHANGED,
      this._logNotification
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._logNotification
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_DEVICE_FOUND,
      this._logNotification
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_DEVICE_LOST,
      this._logNotification
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_DISCOVERED_DEVICE,
      this._logNotification
    )
  }

  _showPairingStatusNotification(notifications) {
    notifications.forEach((notification, _) => {
      switch (notification.status) {
        case PairingChangeStatus.CONNECTED:
          this.$sendNotification({ type: NotificationType.CONNECT })
          break
        case PairingChangeStatus.DISCONNECTED:
          this.$sendNotification({
            type: NotificationType.DISCONNECT,
            controller: notification.device.name,
          })
          break
        case PairingChangeStatus.UNPAIRED:
          this.$sendNotification({ type: NotificationType.UNPAIR })
          break
        default:
          break
      }
    })
  }

  _logNotification(notification) {
    this._log(JSON.stringify(notification))
  }

  _addFireboltLifecycleListeners() {
    // Listen for every lifecycle event and log each lifecycle state
    Lifecycle.listen((event, value) => {
      if (value.state === FireboltLifecycleState.INACTIVE) {
        // Firebolt lifecycle state inactive
        if (value.previous === FireboltLifecycleState.SUSPENDED) {
          // Firebolt lifecycle state inactive, previously suspended
        }
      } else if (value.state === FireboltLifecycleState.BACKGROUND) {
        // Firebolt lifecycle state background
      } else if (value.state === FireboltLifecycleState.FOREGROUND) {
        // Firebolt lifecycle state foreground, app is now user's main experience
        if (value.previous === FireboltLifecycleState.INACTIVE) {
          FireboltAuthService.getInstance().setAuthToken()
        }
      } else if (
        event === FireboltLifecycleEvent.UNLOADING &&
        Lifecycle.state() === FireboltLifecycleState.INACTIVE
      ) {
        // if unloading event, clear listeners before finishing the lifecycle
        // Firebolt lifecycle unloading event
        Lifecycle.clear()
        Lifecycle.finished()
          .then(() => {
            // Firebolt lifecycle finished
            this.application.closeApp()
          })
          .catch((err) => console.error(`Firebolt finish lifecycle error ${err}`))
      }
    })
  }

  _onRequestFailedCallback(notification) {
    this.$sendNotification({ type: NotificationType.FAILED })
  }

  _dismissNotification() {
    this._Notification.dismiss()
    this._notificationTimeout = null
    this._showingNotification = false
  }

  _closeLifecycleCallback() {
    // wait to call closeApp in case 'unloading' lifecycle event gets sent
    // which is being listened for in _addFireboltLifecycleListeners
    this._appCloseDelay = setTimeout(() => {
      LoggingService.getInstance().logMessage(LogLevels.FIREBOLT, 'Firebolt lifecycle closed')
      this.application.closeApp()
    }, 500)
  }

  _cleanCloseLifecycle() {
    Lifecycle.close('remoteButton')
      .then(() => {
        this._closeLifecycleCallback()
      })
      .catch((err) => console.error(`Firebolt clean close lifecycle error ${err}`))
  }

  async $closeLifecycle() {
    const appSessionAnalyticsService = new AppSessionAnalyticsService()
    await appSessionAnalyticsService.sendAppSessionEnded()
    this._sendAppSessionEventDelay = setTimeout(() => {
      this._cleanCloseLifecycle()
    }, 700)
  }

  // Method exposed as signal and can be called in other components with fireAncestors
  $unrecoverableErrorCloseLifecycle() {
    Lifecycle.close('error')
      .then(() => {
        this._closeLifecycleCallback()
      })
      .catch((err) => console.error(`Firebolt close lifecycle error ${err}`))
  }

  // this method is called whenever user presses back but there is no route history
  _handleAppClose() {
    if (VideoPlayer.playing) {
      VideoPlayer.close()
      this.$showAppBackground()
    }

    if (Router.getActiveHash() === RouteHash.APP_LAUNCH) {
      // if app is loading and user presses back to exit app, immediately exit app
      this._cleanCloseLifecycle()
    } else if (
      (RemoteConfig.getInstance().retroModeFlag && Router.getActiveHash() === RouteHash.ARCADE) ||
      Router.getActiveHash() === RouteHash.PLAY ||
      Router.getActiveHash() === RouteHash.NOTICES ||
      Router.getActiveHash() === RouteHash.WELCOME ||
      Router.getActiveHash() === RouteHash.CONTENT_CONTROLS
    ) {
      // any of the landing pages after app launch should prompt with exit on back with no history
      Router.navigate(RouteHash.EXIT, { keepAlive: true })
    } else {
      // all other pages should go to home page on back with no history
      RemoteConfig.getInstance().retroModeFlag
        ? Router.navigate(RouteHash.ARCADE)
        : Router.navigate(RouteHash.PLAY)
    }
  }

  // once connected, controller home button will always bring user to home page
  _handleHome() {
    RemoteConfig.getInstance().retroModeFlag
      ? Router.navigate(RouteHash.ARCADE)
      : Router.navigate(RouteHash.PLAY)
  }

  get _Notification() {
    return this.tag('Notification')
  }

  _log(text) {
    LoggingService.getInstance().logMessage(LogLevels.BLUETOOTH, text, 'App: ')
  }

  get _NotificationsContainer() {
    return this.tag('NotificationsContainer')
  }

  $offsetXNotificationsContainer(xOffset) {
    this._NotificationsContainer.patch({
      x: this._NotificationsContainer.x - xOffset,
    })
  }

  $restoreXNotificationsContainer() {
    this._NotificationsContainer.patch({
      x: this._NotificationsContainer.defaultX,
    })
  }

  get idleNotificationCurrentIndex() {
    return this._NotificationsContainer.getItemIndex(NotificationType.IDLE)
  }

  // need to override in parent class due to several un-safe references that cause many console errors
  $announce(toAnnounce, { append = false, notification = false } = {}) {
    if (!toAnnounce) {
      return
    }

    if (this.announcerEnabled) {
      this._debounceAnnounceFocusChanges.flush()
      if (append && this._currentlySpeaking && this._currentlySpeaking.active) {
        this._currentlySpeaking.append(toAnnounce)
        if (notification) {
          this._voiceOutDisabled = true
          this._currentlySpeaking.series.finally(() => {
            this._voiceOutDisabled = false
            this.$announcerRefresh()
          })
        }
      } else {
        this.$announcerCancel()

        // need to add a timeout to solve for this problem: https://stackoverflow.com/questions/39391502/js-speechsynthesis-problems-with-the-cancel-method
        setTimeout(() => {
          this._currentlySpeaking = this._voiceOut(toAnnounce)
          if (notification) {
            this._voiceOutDisabled = true
            this._currentlySpeaking.series.finally(() => {
              this._voiceOutDisabled = false
              this.$announcerRefresh()
            })
          }
        }, 250)
      }
    }
  }

  $announceNotification(announceText) {
    // announcement will get cut off by the natural refocus of app, so delay announce
    this._notificationAnnounceTimeout = setTimeout(() => {
      this.$announcerCancel()
      this.$announce([...announceText, 'PAUSE-2'], {
        notification: true,
      })
    }, 500)
  }

  $sendNotification(params) {
    // if the notification is for idle timeout and it already exists, perform a
    // patch on the existing item instead of adding a new item
    if (params.type === NotificationType.IDLE && this.idleNotificationCurrentIndex >= 0) {
      this._NotificationsContainer.items[this.idleNotificationCurrentIndex].patch(
        notificationHelper(params)
      )
      return
    }

    this._NotificationsContainer.addAndAnnounceItem(notificationHelper(params))
  }

  $removeIdleNotification() {
    this.idleNotificationCurrentIndex >= 0 &&
      this._NotificationsContainer.$removeNotification(NotificationType.IDLE)
  }
}
