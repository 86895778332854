import { Language, Router } from '@lightningjs/sdk'
import { pairConnectButtonFormatter } from '../../formatters'
import { BluetoothWrapper, PairingChangeStatus } from '../../lib/bluetooth/bluetoothWrapper'
import Section from './Section'
import { BluetoothEventTypes } from '../../lib/bluetooth/bluetoothServiceInterface'
import { SubSectionHeader } from './SectionHeaders'
import { RouteHash } from '../../constants/routeEnums'

export default class PairConnectSection extends Section {
  static get properties() {
    return [...Section.properties, 'device']
  }

  static _template() {
    return {
      ...super._template(),
      announceContext: Language.translate('PAIR_CONNECT_NAVIGATION'),
    }
  }

  _init() {
    super._init()
    this._bindFunctions()

    this.header = this.device == null ? '' : this.device.name
  }

  _focus() {
    super._focus()
    this._Content.selectedIndex = 1
  }

  _update() {
    this.header = this.device == null ? '' : this.device.name
    super._update()
    this.updateSection(this.device)
  }

  _active() {
    this.updateSection(this.device)
  }

  _attach() {
    BluetoothWrapper.getInstance().addControllerStatusChangeListener(
      this._onControllerStatusChangedCallback
    )
    BluetoothWrapper.getInstance().addUpdateListener(this._renderHelper)

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._navigateToError
    )
  }

  _detach() {
    super._detach()

    BluetoothWrapper.getInstance().removeControllerStatusChangeListener(
      this._onControllerStatusChangedCallback
    )
    BluetoothWrapper.getInstance().removeUpdateListener(this._renderHelper)
    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._navigateToError
    )
  }

  _onControllerStatusChangedCallback(notifications) {
    if (notifications.has(this.device.deviceID)) {
      let notification = notifications.get(this.device.deviceID)
      this._renderButtons(notification.status)
    }
  }

  _bindFunctions() {
    this.pair = this.pair.bind(this)
    this.unpair = this.unpair.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.updateSection = this.updateSection.bind(this)
    this._renderButtons = this._renderButtons.bind(this)
    this._onControllerStatusChangedCallback = this._onControllerStatusChangedCallback.bind(this)
    this._renderHelper = this._renderHelper.bind(this)
  }

  pair() {
    if (
      this._device != null &&
      !BluetoothWrapper.getInstance().isInProgress(this._device.deviceID)
    ) {
      this.showIcon(1)
      BluetoothWrapper.getInstance()
        .pair(this._device.deviceID)
        .catch((err) => {
          console.error(JSON.stringify(err))
          this.hideAllIcons()
          this._navigateToError()
        })
    }
  }

  unpair() {
    if (this.device != null && !BluetoothWrapper.getInstance().isInProgress(this.device.deviceID)) {
      this.showIcon(2)
      BluetoothWrapper.getInstance()
        .unpair(this.device.deviceID)
        .catch((err) => {
          console.error(JSON.stringify(err))
          this.hideAllIcons()
          this._navigateToError()
        })
    }
  }

  connect() {
    if (this.device != null && !BluetoothWrapper.getInstance().isInProgress(this.device.deviceID)) {
      this.showIcon(1)
      BluetoothWrapper.getInstance()
        .connect(this.device.deviceID, this.device.deviceType)
        .catch((err) => {
          console.error(JSON.stringify(err))
          this.hideAllIcons()
          this._navigateToError()
        })
    }
  }

  disconnect() {
    if (this.device != null && !BluetoothWrapper.getInstance().isInProgress(this.device.deviceID)) {
      this.showIcon(1)
      BluetoothWrapper.getInstance()
        .disconnect(this.device.deviceID, this.device.deviceType)
        .catch((err) => {
          console.error(JSON.stringify(err))
          this.hideAllIcons()
          this._navigateToError()
        })
    }
  }

  updateSection(device) {
    if (device != null) {
      this.device = device
    }

    if (this.device == null) {
      this.setContent([{ type: SubSectionHeader, alpha: 0.001 }])
      return
    }

    this.device = device

    let items = pairConnectButtonFormatter(
      this.device,
      this.pair,
      this.unpair,
      this.connect,
      this.disconnect
    )

    this.setContent([{ type: SubSectionHeader, alpha: 0.001 }, ...items])
  }

  _renderButtons(status) {
    switch (status) {
      case PairingChangeStatus.UNPAIRED:
        this.device.paired = false
        this._moveBack = true
        this.updateSection(this.device)
        break
      case PairingChangeStatus.CONNECTED:
        this.device.paired = true
        this.device.connected = true
        this._moveBack = true
        this.updateSection(this.device)
        break
      case PairingChangeStatus.DISCONNECTED:
        this.device.connected = false
        this._moveBack = true
        this.updateSection(this.device)
        break
    }
  }

  _renderHelper() {
    if (
      this._focused &&
      !BluetoothWrapper.getInstance().isInProgress(this.device.deviceID) &&
      this._moveBack
    ) {
      this.hideAllIcons()
      this._moveBack = false
      this.fireAncestors('$focusOnPreviousSection')
    }
  }

  getBtnByIndex(index) {
    return this._ContentItems[index]
  }

  hideAllIcons() {
    this._ContentItems.forEach((item) => {
      item.visibleIcon = false
    })
  }

  showIcon(index) {
    this.getBtnByIndex(index).visibleIcon = true
  }

  hideIcon(index) {
    this.getBtnByIndex(index).visibleIcon = false
  }

  _navigateToError() {
    Router.navigate(RouteHash.BLUETOOTH_ERROR)
  }
}
