import { context } from '@lightning/ui'
import EntityPage from './pages/Entity/EntityPage'
import Play from './pages/Play'
import { SidekickSubTheme, XgTheme } from './themes/GameXTheme'
import Settings from './pages/Settings'
import routeNotifier from './RouteNotifier'
import VideoManager from './components/VideoManager'
import {
  GameplayErrorPage,
  BluetoothErrorPage,
  ExitConfirmationPage,
  GameIdlePage,
  CatalogErrorPage,
  HostEndParty,
  GameplayComingSoonPage,
} from './pages/FullScreenMessage'
import SelectController from './pages/Learn/SelectController'
import PairingMode from './pages/Learn/PairingMode'
import BluetoothDeeplinkPage from './pages/BluetoothDeeplinkPage'
import Survey from './pages/Survey/Survey'
import EntityOverview from './pages/Entity/EntityOverview'
import Couchplay from './pages/Couchplay'
import EntityGallery from './pages/Entity/EntityGallery'
import { RemoteConfig } from './lib/remoteConfig'
import dataLayerAnalytics from './lib/analytics/dataLayerAnalyticsService'
import TestSidekickPage from './pages/TestSidekickPage'
import WelcomePage from './pages/WelcomePage'
import NoticesPage from './pages/NoticesPage'
import routesProvider from './api/providers/routesProvider'
import HelpAndSupport from './pages/HelpAndSupport'
import PartyMode from './pages/Learn/PartyMode'
import ContentControlsCodeEntry from './pages/ContentControls/CodeEntry'
import FTUContentControls from './pages/ContentControls/FTUContentControls'
import { RouteHash } from './constants/routeEnums'
import UpdateContentControls from './pages/ContentControls/UpdateContentControls'
import NoMobilePhone from './pages/ContentControls/NoMobilePhone'
import UserAccountProvider from './api/providers/userAccountProvider'
import NoAccessPage from './pages/NoAccessPage'
import PartyError from './pages/PartyError'
import EmulatorPage from './pages/Emulator'
import Arcade from './pages/Arcade'
import Lunar from './pages/Lunar'
import LatencyTestPage from './pages/LatencyTestPage'

let routes = [
  {
    path: RouteHash.APP_LAUNCH,
    component: VideoManager,
  },
  {
    path: RouteHash.NO_ACCESS,
    component: NoAccessPage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.ARCADE,
    component: Arcade,
    widgets: ['Navigation', 'Status'],
  },
  {
    path: RouteHash.LUNAR,
    component: Lunar,
    widgets: ['Navigation', 'Status'],
  },
  {
    path: RouteHash.EMULATOR,
    component: EmulatorPage,
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.BLUETOOTH,
    component: BluetoothDeeplinkPage,
    widgets: RemoteConfig.getInstance().retroModeFlag
      ? ['Status', 'Breadcrumb', 'Navigation']
      : ['Status', 'Breadcrumb'],
  },
  {
    path: RouteHash.BLUETOOTH_ERROR,
    component: BluetoothErrorPage,
    widgets: ['Status', 'Breadcrumb'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.EXIT,
    component: ExitConfirmationPage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.SIDEKICK_TEST,
    component: TestSidekickPage,
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.LATENCY_TEST,
    component: LatencyTestPage,
    options: {
      preventStorage: true,
    },
  },
]

const nonRetroRoutes = [
  {
    path: RouteHash.NOTICES,
    component: NoticesPage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
    before: routesProvider.getNotices,
  },
  {
    path: RouteHash.WELCOME,
    component: WelcomePage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },

  {
    path: RouteHash.CONTENT_CONTROLS,
    component: FTUContentControls,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.PLAY,
    component: Play,
    widgets: ['Navigation', 'Status'],
    options: {
      clearHistory: true,
    },
  },
  {
    path: RouteHash.PARTY,
    component: Couchplay,
    widgets: ['Navigation', 'Status'],
  },
  {
    path: `${RouteHash.PARTY_RATING_RESTRICTED}`,
    component: PartyError,
    widgets: ['Status', 'Breadcrumb'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.SETTINGS,
    component: Settings,
    widgets: ['Navigation', 'Status', 'Breadcrumb'],
    before: routesProvider.getNotices,
  },
  {
    path: RouteHash.HELP_AND_SUPPORT,
    component: HelpAndSupport,
    before: routesProvider.getHelpArticles,
    widgets: ['Navigation', 'Status', 'Breadcrumb'],
  },
  {
    path: RouteHash.CONTENT_CONTROLS + RouteHash.UPDATE_CONTENT_CONTROLS_PATH,
    component: UpdateContentControls,
    widgets: ['Status', 'Breadcrumb'],
    after: UserAccountProvider.getInstance().getUserAccount,
  },
  {
    path:
      RouteHash.CONTENT_CONTROLS + RouteHash.ENTER_CODE_CONTENT_CONTROLS_PATH + '/:devModeCode?',
    component: ContentControlsCodeEntry,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.CONTENT_CONTROLS + RouteHash.NO_PHONE_CONTENT_CONTROLS_PATH,
    component: NoMobilePhone,
    widgets: ['Status', 'Breadcrumb'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.ENTITY_PATH + ':entityId',
    component: EntityPage,
    before: routesProvider.getEntity,
    cache: 60 * RemoteConfig.getInstance().storedRouteClearCacheAt, // store data for x minutes
  },
  {
    path: RouteHash.ENTITY_PATH + ':entityId' + RouteHash.ENTITY_OVERVIEW_PATH,
    component: EntityOverview,
    widgets: ['Breadcrumb'],
    options: {
      preventStorage: true,
    },
    before: routesProvider.getEntity,
  },
  {
    path: RouteHash.ENTITY_PATH + ':entityId' + RouteHash.ENTITY_GALLERY_PATH + ':imageIndex',
    component: EntityGallery,
    widgets: ['Breadcrumb'],
    options: {
      preventStorage: true,
    },
    before: routesProvider.getEntity,
  },
  {
    path: RouteHash.GAMEPLAY_PATH + ':gameLabel',
    component: GameplayComingSoonPage,
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.GAMEPLAY_ERROR,
    component: GameplayErrorPage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.GAME_CATALOG_ERROR,
    component: CatalogErrorPage,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.GAMEPLAY_SURVEY,
    component: Survey,
    widgets: ['Status'],
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.GAMEPLAY_IDLE,
    component: GameIdlePage,
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.GAMEPLAY_END_PARTY,
    component: HostEndParty,
    options: {
      preventStorage: true,
    },
  },
  {
    path: RouteHash.SELECT_CONTROLLER_LEARN,
    component: SelectController,
    widgets: ['Status', 'Breadcrumb'],
  },
  {
    path: RouteHash.PAIRING_MODE_LEARN_PATH + ':controller',
    component: PairingMode,
    widgets: ['Status', 'Breadcrumb'],
  },
  {
    path: RouteHash.PARTY_MODE_LEARN,
    component: PartyMode,
    options: {
      preventStorage: true,
    },
    before: routesProvider.getPartySlides,
  },
]

if (!RemoteConfig.getInstance().retroModeFlag) {
  routes = [...routes, ...nonRetroRoutes] // if not retro mode, merge all routes
}

export default {
  boot: async () => {
    await context.setTheme(XgTheme)
    await context.setSubTheme('Sidekick', SidekickSubTheme)
  },
  beforeEachRoute: (to, from) => {
    routeNotifier.activeHash = from?._hash
    return new Promise((resolve) => {
      dataLayerAnalytics.pageTrackingStart()
      resolve(true)
    })
  },
  afterEachRoute: (request) => {
    routeNotifier.updateRoute(request._hash)
    // on loading route we need to call pageTrackingComplete only after device
    // details have been fetched and set in order to pass schema validation
    request?._hash !== RouteHash.APP_LAUNCH && dataLayerAnalytics.pageTrackingComplete()
  },
  root: () => {
    return new Promise((resolve) => {
      if (RemoteConfig.getInstance().noAccessFlag) {
        resolve(RouteHash.NO_ACCESS)
      } else if (RemoteConfig.getInstance().retroModeFlag) {
        resolve(RouteHash.LUNAR)
      } else {
        resolve(RouteHash.PLAY)
      }
    })
  },
  // TODO reinstate and update per reqs with What's New page story GPE-1337 https://ccp.sys.comcast.net/browse/GPE-1337
  //   root: () => {
  //     return new Promise((resolve) => {
  //       if (RemoteConfig.getInstance().noAccessFlag) {
  //        resolve(RouteHash.NO_ACCESS)
  //       } else if (
  //         StoreVersion.getInstance().isNewVersion() &&
  //         !RemoteConfig.getInstance().skipWelcomeScreen
  //       ) {
  //         // navigate to welcome screen 1st time user launches each new app version released
  //         resolve(RouteHash.WELCOME)
  //       } else {
  //         resolve(RouteHash.PLAY)
  //       }
  //     }) http
  //   },
  routes,
}
