import { context, TextBox, Icon, Column } from '@lightning/ui'
import { Language, Router } from '@lightningjs/sdk'
import PlayProvider from '../api/providers/playProvider'
import PageBase from './PageBase'
import HeroBackground from '../components/HeroBackground'
import HeroMetadata from '../components/HeroMetadata'
import FilterRow from '../components/FilterRow'
import CaretIcon from '../assets/icons/caret.png'
import dataLayerAnalytics from '../lib/analytics/dataLayerAnalyticsService'
import { RouteHash } from '../constants/routeEnums'
import routeNotifier from '../RouteNotifier'
import ControllerConnectionChange from '../lib/bluetooth/controllerConnectionChange'
import UserAccountProvider from '../api/providers/userAccountProvider'

const HERO_Y = 576
const LAUNCHPAD_HEIGHT = 375
const SECTION_ANIM_DURATION = 0.3
const MARGIN_Y = 52
const CARET_HEIGHT = 17
const CARET_PADDING = 70
const animation = {
  timingFunction: context.theme.animation.standardEntrance.timingFunction,
  duration: SECTION_ANIM_DURATION,
}

export class GameTilesContainer extends Column {
  static _template() {
    return {
      ...super._template(),
      clipping: true,
      w: context.theme.layout.screenW - context.theme.layout.marginX,
      h: context.theme.layout.screenH - MARGIN_Y * 2 + 48, // little extra cheat value to fix scroll
      items: [
        {
          type: Column,
          items: [],
          plinko: true,
          alpha: 0,
          alwaysScroll: true,
          // set x and y positions so focus ring isn't clipped
          x: 8,
          y: 8,
        },
      ],
    }
  }

  get _GameTiles() {
    return this.items[0]
  }

  // override in parent class to allow for setting x and y positions on items
  _update() {}
}

export default class Play extends PageBase {
  _construct() {
    super._construct()
    dataLayerAnalytics.processPending()
  }

  static _template() {
    return {
      ...super._template(),
      announce: Language.translate('PLAY'),
      announceContext: Language.translate('PLAY_NAVIGATION'),
      HeroBackground: {
        type: HeroBackground,
        zIndex: 0,
      },
      HeroMetadata: {
        type: HeroMetadata,
        x: context.theme.layout.marginX,
        y: 504,
        mountY: 1,
        zIndex: 10,
      },
      SectionsContainer: {
        type: Column,
        x: context.theme.layout.marginX,
        y: HERO_Y,
        w: context.theme.layout.screenW - context.theme.layout.marginX,
        h: context.theme.layout.screenH - MARGIN_Y * 2 + 48, // little extra cheat value to fix scroll
        style: { itemSpacing: 0 },
        zIndex: 10,
        signals: {
          selectedChange: '_updateSectionPosition',
        },
        neverScroll: true,
        items: [],
      },
      CaretIcon: {
        type: Icon,
        icon: CaretIcon,
        w: 27,
        h: 0,
        x: context.theme.layout.screenW / 2,
        y: CARET_PADDING,
        alpha: 0,
      },
    }
  }

  _bindFunctions() {
    this._updateRecentHeroTileOnEnter = this._updateRecentHeroTileOnEnter.bind(this)
  }

  _init() {
    super._init()
    this._bindFunctions()
    routeNotifier.clearLaunchHash()

    this._loadSectionsContainerContent()

    this._pageReadyTimeout = setTimeout(() => {
      this._pageReady()
    }, 1000)

    // if initializing page with nav open (sidebar nav. peek state)
    // fire the first tile's background update manually b/c we have not focused the tile yet
    if (this.initWithNavOpen) {
      this._loadFirstHeroMetadata()
    }
  }

  _loadSectionsContainerContent() {
    this._SectionsContainer.appendItems([
      // HERO - TODO: need to handle layout if hero load fails
      { ...PlayProvider.getInstance().getHeroContent(), extraItemSpacing: 88 },
      // FILTERS - not visible if catalog call fails
      {
        type: FilterRow,
        filters:
          !PlayProvider.getInstance().catalogFromCache && PlayProvider.getInstance().categories,
        extraItemSpacing: !PlayProvider.getInstance().catalogFromCache ? MARGIN_Y : 0,
        visible: !PlayProvider.getInstance().catalogFromCache,
        skipFocus: PlayProvider.getInstance().catalogFromCache,
        h: !PlayProvider.getInstance().catalogFromCache ? 72 : 0,
      },
      // GAME TILES HEADING AND GRID
      {
        type: Column,
        items: [
          {
            type: TextBox,
            style: {
              textStyle: 'headline2',
            },
            skipFocus: true,
            w: (w) => w - 24,
            h: 48,
            visible: false,
          },
          { type: GameTilesContainer },
        ],
      },
    ])

    PlayProvider.getInstance().catalogFromCache && this._loadCachedGameState()

    this._filterCaretHidden = true
    this._SectionsContainer.selectedIndex = 0
    this.setSmooth('alpha', 1, { delay: 0.25, duration: 0.25 })
  }

  _pageReady() {
    this.fireAncestors('$hideLoading')
    dataLayerAnalytics.processComplete()
  }

  _loadFirstHeroMetadata() {
    if (this._HeroItemsRow.items[0].execBackgroundUpdate) {
      this._HeroItemsRow.items[0].execBackgroundUpdate()
    }
  }

  _updateRecentHeroTileOnEnter(controllerConnected) {
    for (let i = 0; i < this._HeroItemsRow.items.length; i++) {
      const item = this._HeroItemsRow.items[i]
      if (
        item.isRecent &&
        !UserAccountProvider.getInstance().contentControls[item.rating] &&
        controllerConnected
      ) {
        item.patch({
          onEnter: () =>
            Router.navigate(`${RouteHash.GAMEPLAY_PATH}${item.encodedTitle}`, {
              keepAlive: true,
              gamePlayTitle: item.gamePlayTitle,
            }),
        })
      } else {
        item.patch({ onEnter: item.navigate })
      }
    }
  }

  _active() {
    this._controllerConnectionChange = new ControllerConnectionChange(
      this._updateRecentHeroTileOnEnter
    )
    this._controllerConnectionChange.renderWithControllerData()
  }

  _inactive() {
    clearTimeout(this._resetPageTimeout)
    clearTimeout(this._pageReadyTimeout)
    this._controllerConnectionChange.removeListener()
  }

  _onChanged() {
    // If you're coming back to the page and it was kept in memory, onChanged will fire.
    // We need to know if you enter-pressed on another nav item. If you have, reset the page.
    // Otherwise, just keep it in its existing memory state.
    if (Router.getHistoryState(RouteHash.PLAY)?.needsReset) {
      this._resetPage()
    }

    if (
      PlayProvider.getInstance().recentlyPlayed.length > 0 &&
      PlayProvider.getInstance().recentlyPlayed[0] !==
        this._SectionsContainer.items[0].items[0].gamePlayTitle
    ) {
      // if the first hero item isn't the most recently played game, we need to update hero row
      const updatedHero = PlayProvider.getInstance().getHeroContent()
      this._HeroItemsRow.items = updatedHero.items
    }
  }

  _resetPage() {
    //  Hide page while reseting layout
    this.patch({ alpha: 0 })
    this._resetPageTimeout = setTimeout(() => {
      // then fade back in
      this.setSmooth('alpha', 1)
    }, SECTION_ANIM_DURATION * 100)

    this._HeroItemsRow.patch({ selectedIndex: 0 })
    this._SectionsContainer.patch({ selectedIndex: 0 })
    this._GridItemsColumn.scrollTo(0, 1)
    this._loadFirstHeroMetadata()
  }

  _expandForNav(smooth = true) {
    if (smooth) {
      this._SectionsContainer.smooth = { x: this.PAGE_EXPANDED_X }
      this._HeroMetadata.smooth = {
        x: this.PAGE_EXPANDED_X_DELTA + context.theme.layout.marginX,
      }
      this._FilterRowCaret.smooth = {
        alpha: 0,
        h: this._filterCaretHidden ? 0 : CARET_HEIGHT,
      }
    } else {
      this._SectionsContainer.patch({ x: this.PAGE_EXPANDED_X })
      this._HeroMetadata.patch({
        x: this.PAGE_EXPANDED_X_DELTA + context.theme.layout.marginX,
      })
      this._FilterRowCaret.patch({
        alpha: 0,
        h: this._filterCaretHidden ? 0 : CARET_HEIGHT,
      })
    }
  }

  _collapseForNav() {
    this._SectionsContainer.smooth = { x: this.PAGE_COLLAPSED_X }
    this._HeroMetadata.smooth = { x: context.theme.layout.marginX }
    !this._filterCaretHidden && (this._FilterRowCaret.smooth = { alpha: 1, h: CARET_HEIGHT })
  }

  _updateSectionPosition() {
    clearTimeout(this.metadataTimeout)
    if (this._SectionsContainer.selectedIndex === 0) {
      this._SectionsContainer.setSmooth('y', HERO_Y, animation)
      this.metadataTimeout = setTimeout(() => {
        this._HeroMetadata.smooth = { alpha: 1 }
      }, 150)
      this._HeroBackground.smooth = { alpha: 1 }
      this._HeroItemsRow.smooth = { alpha: 1 }
      this._unfocusGameTiles()
      return
    }
    this._unfocusSectionsContainer()
  }

  _unfocusSectionsContainer() {
    this._SectionsContainer.setSmooth('y', MARGIN_Y - LAUNCHPAD_HEIGHT + CARET_PADDING, animation)
    this._HeroMetadata.setSmooth('alpha', 0, { duration: 0.25 })
    this._HeroBackground.smooth = { alpha: 0 }
    this._HeroItemsRow.smooth = { alpha: 0 }
    this._focusGameTiles()
  }

  _focusGameTiles() {
    this._FilterRowCaret.setSmooth('alpha', 1, { duration: 0.25 })
    this._FilterRowCaret.patch({ h: CARET_HEIGHT })
    this._filterCaretHidden = false
    if (!PlayProvider.getInstance().catalogFromCache) {
      // state with filters
      this._SectionTitle.patch({
        visible: true,
        content: PlayProvider.getInstance().heading,
      })
      this._GameTiles.patch({ alpha: 1 })
    }
  }

  _unfocusGameTiles() {
    this._FilterRowCaret.setSmooth('alpha', 0, { duration: 0.25 })
    this._FilterRowCaret.patch({ h: 0 })
    this._filterCaretHidden = true
    if (!PlayProvider.getInstance().catalogFromCache) {
      // state with filters
      this._SectionTitle.patch({ visible: false })
      this._GameTiles.patch({ alpha: 0 })
    }
  }

  _loadCachedGameState() {
    const gameRows = PlayProvider.getInstance().getGamingRows()
    if (gameRows == null || gameRows.length === 0) {
      this._pageReady()
      Router.navigate(RouteHash.GAME_CATALOG_ERROR)
      return
    }
    // if no filters, show heading and peek of game tiles on init
    this._SectionTitle.patch({
      visible: true,
      content: PlayProvider.getInstance().heading,
    })
    this._GameTiles.patch({
      alpha: 1,
      items: gameRows,
      announce: PlayProvider.getInstance().heading,
    })
  }

  _getFocused() {
    return this._SectionsContainer
  }

  $backgroundUpdate(imageSrc, metadata) {
    this._HeroBackground.background = imageSrc
    if (metadata) {
      this._HeroMetadata.content = [metadata.title, metadata.description]
    }
  }

  async $updateGames(filter) {
    await PlayProvider.getInstance().filterByCategory(filter)
    this._SectionTitle.patch({
      content: PlayProvider.getInstance().heading,
    })
    this._GameTiles.patch({
      announce: PlayProvider.getInstance().heading,
      items: PlayProvider.getInstance().getGamingRows(),
    })
  }

  $focusNav() {
    this._handleLeft()
  }

  get _HeroBackground() {
    return this.tag('HeroBackground')
  }

  get _HeroMetadata() {
    return this.tag('HeroMetadata')
  }

  get _SectionsContainer() {
    return this.tag('SectionsContainer')
  }

  get _FilterRowCaret() {
    return this.tag('CaretIcon')
  }

  get _HeroItemsRow() {
    return this._SectionsContainer.Items.children[0]
  }

  get _FilterRow() {
    return this._SectionsContainer.Items.children[1]
  }

  get _GridItemsColumn() {
    return this._SectionsContainer.Items.children[2]
  }

  get _SectionTitle() {
    return this._GridItemsColumn.items[0]
  }

  get _GameTilesContainer() {
    return this._GridItemsColumn.items[1]
  }

  get _GameTiles() {
    return this._GameTilesContainer._GameTiles
  }

  _handleControllerB() {
    // prevent user from going back out of xfinity games using b button
    return true
  }
}
