import { context, TextBox } from '@lightning/ui'
import { ControllerAbbreviations, ControllerNames } from '../../lib/controller/controllerNamesEnums'
import { Language, Router } from '@lightningjs/sdk'
import PlayStationImg from '../../assets/playstation-silhouette.png'
import XboxImg from '../../assets/xbox-silhouette.png'
import GenericImg from '../../assets/generic-silhouette.png'
import { BluetoothWrapper } from '../../lib/bluetooth/bluetoothWrapper'
import PageBase from '../PageBase'
import { RouteHash } from '../../constants/routeEnums'
import XGButton, { corner } from '../../components/XGButton'

export default class PairingModeEd extends PageBase {
  _construct() {
    super._construct()
    this._btAvailable = BluetoothWrapper.getInstance().isActivated()
    this._article = 'a'
    this._controllerName = ControllerNames.GENERIC
    this._controllerAbbrevation = ControllerNames.GENERIC
    this._instructions = `Follow the instructions provided by your controller's manufacturer\nto activate pairing mode.`
  }

  historyState() {
    return {
      breadcrumbTitle: `How to pair ${this._article} ${this._controllerAbbrevation} controller`,
    }
  }

  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announceContext: Language.translate('PRESS_CENTER_TO_SELECT'),
      Image: {
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        src: GenericImg,
        y: 263,
        h: 201,
        w: 372,
        alpha: 0.001,
      },
      Title: {
        type: TextBox,
        content: '',
        style: {
          textStyle: {
            ...context.theme.typography.display2,
            textAlign: 'center',
          },
        },
        y: 524,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
      },
      Text: {
        type: TextBox,
        content: '',
        style: {
          textStyle: { ...context.theme.typography.body2, textAlign: 'center' },
        },
        w: 200,
        y: 612,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
      },
      Button: {
        type: XGButton,
        rounded: corner.bottomLeftAndTopRight,
        title: 'Connect controllers',
        announceContext: [Language.translate('ANNOUNCE_LENGTH', 1, 1), 'PAUSE-2'],
        h: 80,
        w: 386,
        x: 767,
        y: 866,
        fixed: true,
        visible: false,
      },
    }
  }

  set params(args) {
    if (args.controller) {
      this._controllerName = ControllerNames[args.controller]
      this._controllerAbbrevation = ControllerAbbreviations[args.controller]
      this._setInstructions()
    }
    if (args.article) {
      this._article = args.article
    }
  }

  _setInstructions() {
    switch (this._controllerName) {
      case ControllerNames.PS5:
        this._instructions =
          'Press and hold the PS button and CREATE button. The light bar on the front or\nback of your controller will begin flashing once pairing mode is active.'
        break
      case ControllerNames.PS4:
        this._instructions =
          'Press and hold the PS button and SHARE button. The light bar on the front\nor back of your controller will begin flashing once pairing mode is active.'
        break
      case ControllerNames.XBOX:
        this._instructions = `Turn on the ${ControllerNames.XBOX} controller. Press and hold the PAIR button on the top of your controller\nfor a few seconds. The ${ControllerNames.XBOX} logo will begin to flash once pairing mode is active.`
        break
      default:
        break
    }
  }
  _enable() {
    this._setControllerImage()
    this._Title.patch({
      content: `How to put your ${this._controllerName} controller in pairing mode`,
    })
    this._Text.patch({
      content: `${this._instructions} \n\nOnce your controller is in pairing mode, continue to "Connect controllers"\nto pair your device.`,
    })
    this._btAvailable &&
      this._Button.patch({
        visible: true,
        onEnter: () => {
          Router.navigate(RouteHash.BLUETOOTH)
        },
      })

    this.announce = [this._Title.content, this._Text.content]
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Button() {
    return this.tag('Button')
  }

  get _Image() {
    return this.tag('Image')
  }

  get _Text() {
    return this.tag('Text')
  }

  _setControllerImage() {
    switch (this._controllerName) {
      case ControllerNames.PS5:
      case ControllerNames.PS4:
        this._Image.patch({
          src: PlayStationImg,
          y: 239,
          h: 224.5,
          w: 358,
        })
        break
      case ControllerNames.XBOX:
        this._Image.patch({
          src: XboxImg,
          y: 167,
          h: 300,
          w: 369,
        })
        break
      default:
        break
    }
    this._Image.setSmooth('alpha', 1, { duration: 1 })
  }

  _getFocused() {
    return this._Button
  }
}
