import { TitleRow, CardAboutLarge } from '@lightning/ui'
import XGCardAboutStacked from './XGCardAboutStacked'
import { Language } from '@lightningjs/sdk'
import RatingCard from './RatingCard'

export default class EntityDetails extends TitleRow {
  static _template() {
    return {
      ...super._template(),
      title: 'Details',
      items: [
        {
          type: CardAboutLarge,
          title: 'Overview',
          style: { descriptionTextStyle: { maxLines: 5 } },
        },
        {
          type: RatingCard,
          title: 'Rating',
        },
        {
          type: XGCardAboutStacked,
          title: 'Genre',
          titleBottom: 'Languages',
        },
      ],
      lazyScroll: true,
      h: 294,
    }
  }

  _active() {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].announceContext = [
        Language.translate('ANNOUNCE_LENGTH', i + 1, this.items.length),
        'PAUSE-2',
      ]
    }
    this._Overview.patch({
      description: this.overview,
      onEnter: () => this.overviewOnEnter(),
    })
    this._Rating.patch({
      rating: this.rating,
    })
    this._GenreAndLanguages.patch({
      description: this.genres,
      descriptionBottom: this.languages,
    })
  }

  get _Overview() {
    return this.items[0]
  }

  get _Rating() {
    return this.items[1]
  }

  get _GenreAndLanguages() {
    return this.items[2]
  }
}
