import { AnalyticsErrorBucketCodes } from './analyticsEnums';
import CoreEventCreator from './coreEventCreator';
import { sendAnalyticsEvent } from './analyticsService';
import { createErrorEvent } from './eventCreators';
export default class GeneralAnalyticsService {
    constructor() {
        this._bindFunctions();
    }
    _bindFunctions() {
        this.sendGeneralError = this.sendGeneralError.bind(this);
    }
    sendGeneralError(code, errorResponse) {
        var _a, _b, _c;
        const requestDetails = errorResponse
            ? {
                requestURL: ((_a = errorResponse.config) === null || _a === void 0 ? void 0 : _a.url) || 'unknown',
                requestType: ((_c = (_b = errorResponse.config) === null || _b === void 0 ? void 0 : _b.method) === null || _c === void 0 ? void 0 : _c.toUpperCase()) || 'unknown',
                status: errorResponse.status || 0,
            }
            : errorResponse;
        sendAnalyticsEvent(createErrorEvent(CoreEventCreator.getInstance().createEvent(), AnalyticsErrorBucketCodes.GENERAL, code, requestDetails));
    }
}
