import { TextBox } from '@lightning/ui'
import Section from './Section'
import { bluetoothDeviceItemFormatter } from '../../formatters'
import { BluetoothEventTypes, BluetoothStatus } from '../../lib/bluetooth/bluetoothServiceInterface'
import { BluetoothWrapper } from '../../lib/bluetooth/bluetoothWrapper'
import { Language, Router } from '@lightningjs/sdk'
import { SubSectionHeader } from './SectionHeaders'
import { RouteHash } from '../../constants/routeEnums'

export default class BluetoothSection extends Section {
  static _template() {
    return {
      ...super._template(),
      announceContext: Language.translate('CONNECT_CONTROLLERS_NAVIGATION'),
      header: 'Connect controllers',
      columnHeight: 600,
      scrollIndex: 4,
    }
  }

  _init() {
    this._focusComponent = this._Header
    super._init()
    this._bindFunctions()
  }

  _active() {
    super._active()
    this._renderControllers()
  }

  _attach() {
    if (!BluetoothWrapper.getInstance().isActivated()) {
      console.error('Bluetooth page: Bluetooth not activated!')
      return
    } else {
      this._initialRequests()
    }
  }

  _detach() {
    super._detach()
    this._detachListeners()
  }

  _focus() {
    this._focusComponent = this._Header
    super._focus()
    this._focused = true
    this._checkEmpty()
    this._focusTimeout = setTimeout(() => {
      this._focusComponent = this._Content
      this._refocus()
    }, 50)
  }

  _unfocus() {
    super._unfocus()
    this._focused = false
    this._focusComponent = this._Header
    if (this._focusTimeout != null) {
      clearTimeout(this._focusTimeout)
    }
  }

  _bindFunctions() {
    this._renderControllers = this._renderControllers.bind(this)
    this._renderHelper = this._renderHelper.bind(this)
    this._onStatusChangedCallback = this._onStatusChangedCallback.bind(this)
    this._initialRequests = this._initialRequests.bind(this)
    this._checkEmpty = this._checkEmpty.bind(this)
  }

  _initialRequests() {
    this._addListeners()
    BluetoothWrapper.getInstance().getUpdatedDevices()
  }

  _addListeners() {
    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_STATUS_CHANGED,
      this._onStatusChangedCallback
    )

    BluetoothWrapper.getInstance().addListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._navigateToError
    )

    BluetoothWrapper.getInstance().addUpdateListener(this._renderHelper)
  }

  _detachListeners() {
    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_STATUS_CHANGED,
      this._onStatusChangedCallback
    )

    BluetoothWrapper.getInstance().removeListener(
      BluetoothEventTypes.ON_REQUEST_FAILED,
      this._navigateToError
    )

    BluetoothWrapper.getInstance().removeUpdateListener(this._renderHelper)
  }

  _checkEmpty() {
    if (this._isEmpty && this._focused) {
      this.fireAncestors('$removeNextSection')
    }
  }

  _onStatusChangedCallback(notification) {
    // we only want to save a notification if it's a pairing or connection notification, to
    // prevent focus on a new discovered device button
    if (
      notification &&
      notification.newStatus &&
      notification.newStatus === BluetoothStatus.CONNECTION_CHANGE
    ) {
      this._maintainFocus(notification)
    }
  }

  _renderControllers() {
    // grab discovered and paired devices cached and render those
    let discoveredDevices = BluetoothWrapper.getInstance().getDiscoveredDevicesCached()
    let pairedDevices = BluetoothWrapper.getInstance().getPairedDevicesCached()
    let connectedDevices = BluetoothWrapper.getInstance().getConnectedDevicesCached()
    this._renderHelper(discoveredDevices, pairedDevices, connectedDevices)
  }

  _renderHelper(discoveredDevices, pairedDevices, connectedDevices) {
    // focus not on list so the empty button problem doesn't occur
    this._focusComponent = this._Header

    let finalPairedControllerIndex = -1

    let connectedDeviceIDs = new Set()
    let pairedDeviceIDs = new Set()

    pairedDevices.forEach((pairedDevice) => {
      if (!pairedDevice.connected) {
        pairedDeviceIDs.add(pairedDevice.deviceID, pairedDevice)
      }
    })

    connectedDevices.forEach((connectedDevice) => {
      connectedDeviceIDs.add(connectedDevice.deviceID, connectedDevice)
    })

    // we only want non-paired devices in the available to pair section
    discoveredDevices = discoveredDevices.filter((device) => {
      return !connectedDeviceIDs.has(device.deviceID) && !pairedDeviceIDs.has(device.deviceID)
    })

    this._isEmpty = connectedDeviceIDs.size + pairedDeviceIDs.size + discoveredDevices.length === 0

    this._checkEmpty()

    let newContent = []

    if (connectedDeviceIDs.size + pairedDeviceIDs.size > 0) {
      // we have paired controllers, so we need to create the header
      newContent.push(this._createPairedControllersHeader())

      // create connected devices buttons
      for (let i = connectedDevices.length - 1; i >= 0; i--) {
        let device = connectedDevices[i]
        if (connectedDeviceIDs.has(device.deviceID)) {
          newContent.push(
            bluetoothDeviceItemFormatter({ ...device, paired: true, connected: true })
          )
        }
      }

      // create paired but disconnected devices buttons
      for (let i = pairedDevices.length - 1; i >= 0; i--) {
        let device = pairedDevices[i]
        if (pairedDeviceIDs.has(device.deviceID)) {
          newContent.push(bluetoothDeviceItemFormatter({ ...device, paired: true }))
        }
      }

      // save the last paired button to add some extra space between paired controllers section and discovered controllers section
      // we save the index to the newContent list's current end index + 1 to animate the spinner icon after
      // we update the this.items array.
      finalPairedControllerIndex = newContent.length - 1
    }

    // create discovered controllers section header
    newContent.push(this._createDiscoveredControllersHeader())

    // create placeholder if no discovered devices yet
    if (discoveredDevices.length === 0) {
      newContent.push({
        ...this._createDiscoveredControllersPlaceholder(),
      })
    }

    // finally, create the discovered devices buttons
    for (let i = 0; i < discoveredDevices.length; i++) {
      newContent.push(bluetoothDeviceItemFormatter({ ...discoveredDevices[i] }))
    }

    // update the buttons
    this.setContent(newContent)

    // patch some extra space between paired and discovered controllers section
    if (finalPairedControllerIndex >= 0) {
      // we want to patch all previous paired device buttons so that they don't have extra spacing at the bottom.
      // if there is a paired controllers section, the index of the first paired device button should be 2.
      for (let i = 1; i < finalPairedControllerIndex; i++) {
        this._ContentItems[i].patch({ extraItemSpacing: 0 })
      }

      this._ContentItems[finalPairedControllerIndex].patch({
        extraItemSpacing: 50,
      })
    }

    if (this._focused) {
      this._focusComponent = this._Content
    }
  }

  _maintainFocus(device) {
    if (device) {
      for (let i = 0; i < this._ContentItems.length; i++) {
        if (
          this._ContentItems[i].device != null &&
          this._ContentItems[i].device.deviceID === device.deviceID
        ) {
          if (this._Content.selectedIndex === i) {
            break
          }

          this._Content.selectedIndex = i
          break
        }
      }

      this._Content._refocus()
      this._Content.scrollTo(1, 500)
    }
  }

  _createPairedControllersHeader() {
    return {
      type: SubSectionHeader,
      content: 'PAIRED CONTROLLERS',
      showSpinner: false,
      w: 800,
      skipFocus: true,
    }
  }

  _createDiscoveredControllersHeader() {
    return {
      type: SubSectionHeader,
      content: 'AVAILABLE TO PAIR',
      showSpinner: true,
      w: 800,
      skipFocus: true,
    }
  }

  _createDiscoveredControllersPlaceholder() {
    return {
      type: TextBox,
      content: 'Searching for available devices',
      announce: [
        Language.translate('AVAILABLE_TO_PAIR'),
        Language.translate('SEARCHING_FOR_AVAILABLE_DEVICES'),
      ],
      style: {
        textStyle: 'body3',
      },
      skipFocus: true,
    }
  }

  _navigateToError() {
    Router.navigate(RouteHash.BLUETOOTH_ERROR, {
      keepAlive: true,
    })
  }

  _getFocused() {
    return this._focusComponent
  }
}
