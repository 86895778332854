export default {
  path: '.',
  // Create and Destroy pages as needed, don't keep alive
  router: {
    lazyCreate: true,
    lazyDestroy: true,
    gcOnUnload: true,
  },
  // showFps: {
  //   interval: 300,
  //   log: true,
  //   threshold: 3,
  // },
  log: false,
  showVersion: false, // version tag show up in UI
  inspector: true,
}
