export var BluetoothMethods;
(function (BluetoothMethods) {
    BluetoothMethods["START_SCAN"] = "startScan";
    BluetoothMethods["STOP_SCAN"] = "stopScan";
    BluetoothMethods["GET_DISCOVERED_DEVICES"] = "getDiscoveredDevices";
    BluetoothMethods["GET_PAIRED_DEVICES"] = "getPairedDevices";
    BluetoothMethods["GET_CONNECTED_DEVICES"] = "getConnectedDevices";
    BluetoothMethods["CONNECT"] = "connect";
    BluetoothMethods["DISCONNECT"] = "disconnect";
    BluetoothMethods["UNPAIR"] = "unpair";
    BluetoothMethods["PAIR"] = "pair";
    BluetoothMethods["ACTIVATE"] = "activate";
    BluetoothMethods["RESPOND_TO_EVENT"] = "respondToEvent";
})(BluetoothMethods || (BluetoothMethods = {}));
export var BluetoothEventTypes;
(function (BluetoothEventTypes) {
    BluetoothEventTypes["ON_STATUS_CHANGED"] = "onStatusChanged";
    BluetoothEventTypes["ON_DISCOVERED_DEVICE"] = "onDiscoveredDevice";
    BluetoothEventTypes["ON_REQUEST_FAILED"] = "onRequestFailed";
    BluetoothEventTypes["ON_DEVICE_FOUND"] = "onDeviceFound";
    BluetoothEventTypes["ON_DEVICE_LOST"] = "onDeviceLost";
    BluetoothEventTypes["ON_CONNECTION_REQUEST"] = "onConnectionRequest";
})(BluetoothEventTypes || (BluetoothEventTypes = {}));
export var BluetoothStatus;
(function (BluetoothStatus) {
    BluetoothStatus["DISCOVERY_STARTED"] = "DISCOVERY_STARTED";
    BluetoothStatus["DISCOVERY_COMPLETED"] = "DISCOVERY_COMPLETED";
    BluetoothStatus["PAIRING_CHANGE"] = "PAIRING_CHANGE";
    BluetoothStatus["PAIRING_FAILED"] = "PAIRING_FAILED";
    BluetoothStatus["CONNECTION_CHANGE"] = "CONNECTION_CHANGE";
})(BluetoothStatus || (BluetoothStatus = {}));
export var DiscoveryTypes;
(function (DiscoveryTypes) {
    DiscoveryTypes["DISCOVERED"] = "DISCOVERED";
    DiscoveryTypes["LOST"] = "LOST";
})(DiscoveryTypes || (DiscoveryTypes = {}));
export var AcceptedValues;
(function (AcceptedValues) {
    AcceptedValues["ACCEPTED"] = "ACCEPTED";
    AcceptedValues["REJECTED"] = "REJECTED";
})(AcceptedValues || (AcceptedValues = {}));
export class SuccessResponse {
}
export class Device {
    static isEqual(device1, device2) {
        return (device1 != null &&
            device2 != null &&
            device1.name === device2.name &&
            device1.deviceID === device2.deviceID &&
            device1.paired === device2.paired &&
            device1.connected === device2.connected);
    }
}
export class BluetoothEvent {
}
export class OnStatusChangedEvent {
}
export class OnDiscoveredDeviceEvent extends BluetoothEvent {
}
