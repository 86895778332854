import ToastNotification from '../components/ToastNotification'
import generateUUID from '../utils/generateUUID'
import { NotificationType } from '../constants/notificationEnums'
import playerIcon from '../assets/icons/player-icon.png'
import sunIcon from '../assets/icons/sun-icon.png'
import lightningIcon from '../assets/icons/lightning-icon.png'
import moonIcon from '../assets/icons/moon-icon.png'
import checkIcon from '../assets/icons/check-color-icon.png'
import xIcon from '../assets/icons/x-color-icon.png'
import exclamationIcon from '../assets/icons/exclamation-color-icon.png'
import badNetworkIcon from '../assets/icons/bad-network.png'

export function notificationHelper(params) {
  switch (params.type) {
    case NotificationType.UNPAIR:
      return createUnpairNotification()
    case NotificationType.CONNECT:
      return createConnectNotification()
    case NotificationType.DISCONNECT:
      return createDisconnectNotification(params.controller)
    case NotificationType.FAILED:
      return createReqFailedNotification()
    case NotificationType.UNAVAILABLE:
      return createBTUnavailableNotification()
    case NotificationType.JOIN:
      return createJoinNotification({
        playerIndex: params.playerIndex,
        currentPlayer: params.currentPlayer,
      })
    case NotificationType.LEAVE:
      return createLeaveNotification({
        playerIndex: params.playerIndex,
        currentPlayer: params.currentPlayer,
      })
    case NotificationType.REMOVE:
      return createRemoveNotification({
        playerIndex: params.playerIndex,
        currentPlayer: params.currentPlayer,
      })
    case NotificationType.MUTE:
      return createMuteNotification({
        playerIndex: params.playerIndex,
        currentPlayer: params.currentPlayer,
      })
    case NotificationType.UNMUTE:
      return createUnmuteNotification({
        playerIndex: params.playerIndex,
        currentPlayer: params.currentPlayer,
      })
    case NotificationType.IDLE:
      return createIdleTimeoutNotification(params.mins)
    case NotificationType.PARTY_ERROR:
      return createPartyErrorNotification()
    case NotificationType.BAD_NETWORK:
      return createBadNetworkNotification(params.dismissCallback)
    case NotificationType.SURVEY_FEEDBACK:
      return createSurveyFeedbackNotification()
    case NotificationType.CHANGES_SAVED:
      return createChangesSavedNotification()
    case NotificationType.CHANGES_FAILED:
      return createChangesFailedNotification()
    case NotificationType.SEND_CODE_FAILED:
      return sendCodeFailedNotification()
    case NotificationType.RESEND_CODE_FAILED:
      return resendCodeFailedNotification()
    case NotificationType.AUDIO:
      return createNoAudioNotification()
    default:
      return addNotification({
        title: params.title,
        description: params.description,
        icon: params.icon,
      })
  }
}

function createAnnounceText(field) {
  if (Array.isArray(field)) {
    return field
      .map((item) => {
        return item.title ?? item
      })
      .join(' ')
  }
}

function addNotification({
  title,
  description,
  icon,
  extraItemSpacing = 80,
  notificationId,
  persist,
  dismissCallback,
}) {
  return {
    type: ToastNotification,
    title,
    description,
    icon,
    extraItemSpacing,
    // add unique id to help with removing from notifications container but allow for
    // override if needed
    notificationId: notificationId ?? generateUUID(),
    // if truthy, this notification won't be dismissed automatically
    persist,
    // if a func needs to be called post-dismissal
    dismissCallback,
    customAnnounce: [
      createAnnounceText(title) ?? title,
      createAnnounceText(description) ?? description,
    ],
  }
}

function createUnpairNotification() {
  return addNotification({
    title: 'Device forgotten',
    description:
      'To connect this device again, ensure it is in pairing mode and select it from the Available to Pair list.',
    icon: xIcon,
    extraItemSpacing: 176,
  })
}

function createConnectNotification() {
  return addNotification({
    title: 'Controller connected',
    description:
      'You can press the logo or home button on your controller to return to Xfinity Games.',
    icon: checkIcon,
    extraItemSpacing: 146,
  })
}

function createDisconnectNotification(name) {
  return addNotification({
    title: 'Controller disconnected',
    description: `${name} no longer connected.`,
    icon: xIcon,
    extraItemSpacing: 120,
  })
}

function createReqFailedNotification() {
  return addNotification({
    title: 'Could not connect',
    description: 'Ensure this controller is in pairing mode, or try a different device.',
    icon: exclamationIcon,
  })
}

function createBTUnavailableNotification() {
  return addNotification({
    title: 'Bluetooth unavailable',
    description: 'Could not enable Bluetooth services on this device',
    icon: exclamationIcon,
  })
}

export const playerIcons = [
  '',
  { icon: sunIcon, title: 'sun' },
  { icon: lightningIcon, title: 'lightning' },
  { icon: moonIcon, title: 'moon' },
]

function createJoinNotification({ playerIndex, currentPlayer }) {
  if (playerIndex === currentPlayer) {
    return addNotification({
      title: 'You have joined the party',
      description: [`You're player`, playerIcons[playerIndex]],
      icon: playerIcon,
    })
  }
  return addNotification({
    title: ['Player', playerIcons[playerIndex], 'has joined the party'],
    icon: playerIcon,
  })
}

function createLeaveNotification({ playerIndex, currentPlayer }) {
  if (playerIndex === currentPlayer) {
    return addNotification({
      title: 'You have left the party',
      description: ['You will need the latest code to re-join.'],
      icon: playerIcon,
    })
  }
  return addNotification({
    title: ['Player', playerIcons[playerIndex], 'has left the party'],
    icon: playerIcon,
  })
}

function createRemoveNotification({ playerIndex, currentPlayer }) {
  if (playerIndex === currentPlayer) {
    return
  }
  return addNotification({
    title: ['Player', playerIcons[playerIndex], 'has been removed'],
    description: 'They will need the latest code to rejoin the party.',
    extraItemSpacing: 114,
    icon: playerIcon,
  })
}

function createMuteNotification({ playerIndex, currentPlayer }) {
  if (playerIndex === currentPlayer) {
    return addNotification({
      title: 'You are now spectating',
      description:
        'The host has disabled your controls and you are now in watch mode until they re-enable your controls.',
      extraItemSpacing: 146,
      icon: playerIcon,
    })
  }
  return addNotification({
    title: ['Player', playerIcons[playerIndex], `'s controller has been disabled`],
    description: `If you want them to play, you'll have to restore their controller.`,
    extraItemSpacing: 154,
    icon: playerIcon,
  })
}

function createUnmuteNotification({ playerIndex, currentPlayer }) {
  if (playerIndex === currentPlayer) {
    return addNotification({
      title: 'You are back in the game',
      description: 'Your controller has been restored.',
      icon: playerIcon,
    })
  }
  return addNotification({
    title: ['Player', playerIcons[playerIndex], 'is back in the game'],
    description: 'Their controller has been restored.',
    icon: playerIcon,
  })
}

function createIdleTimeoutNotification(mins) {
  const description = `Your session will end in ${15 - mins} minutes unless you continue playing.`

  if (mins >= 14) {
    return {
      description: 'Your session will end soon. Any unsaved progress may be lost.',
    }
  } else if (mins >= 6) {
    return {
      description,
    }
  } else if (mins === 5) {
    return addNotification({
      title: 'Are you still there?',
      description,
      icon: checkIcon,
      extraItemSpacing: 114,
      notificationId: NotificationType.IDLE,
      persist: true,
    })
  }
}

function createPartyErrorNotification() {
  return addNotification({
    title: 'Error joining party',
    description: 'An error occurred, please try again.',
    icon: exclamationIcon,
  })
}

function createBadNetworkNotification(dismissCallback) {
  return addNotification({
    title: 'Check your connection',
    description: 'A weak internet connection could interrupt your game.',
    icon: badNetworkIcon,
    extraItemSpacing: 114,
    dismissCallback,
  })
}

function createSurveyFeedbackNotification() {
  return addNotification({
    title: 'Thanks for your feedback!',
    description: 'Your feedback has been recorded.',
    icon: checkIcon,
  })
}

function createChangesSavedNotification() {
  return addNotification({
    title: 'Your changes have been saved',
    description: 'Your content control settings have been updated.',
    icon: checkIcon,
    extraItemSpacing: 114,
  })
}

function createChangesFailedNotification() {
  return addNotification({
    title: 'Something went wrong',
    description: `The changes you made weren't saved. Please try again.`,
    icon: exclamationIcon,
    extraItemSpacing: 114,
  })
}

function sendCodeFailedNotification() {
  return addNotification({
    title: 'Something went wrong',
    description: 'Request a new code and try again.',
    icon: exclamationIcon,
  })
}

function resendCodeFailedNotification() {
  return addNotification({
    title: `We couldn't reach you`,
    description: 'Check the phone number linked to this Xfinity account and try again.',
    icon: exclamationIcon,
    extraItemSpacing: 114,
  })
}

function createNoAudioNotification() {
  return addNotification({
    title: 'Web audio not enabled',
    description: 'Web audio has not been enabled on this device. Test will not play audio tones.',
    icon: exclamationIcon,
    extraItemSpacing: 146,
  })
}
