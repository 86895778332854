import MiniLogger from '../../utils/MiniLogger';
export class LoggingServiceConfig {
    constructor(loggingParams) {
        this.loggingParams = loggingParams;
    }
}
export default class LoggingService {
    constructor() {
        this._config = null;
        this._loggingParams = new Set();
        this._miniLogger = MiniLogger.getInstance();
        this._bindFunctions();
    }
    _bindFunctions() {
        this._log = this._log.bind(this);
        this.logMessage = this.logMessage.bind(this);
        this.activate = this.activate.bind(this);
    }
    _initialize() {
        this._loggingParams = this._config.loggingParams;
    }
    _log(text, doConsoleLog, prependingString) {
        if (Array.isArray(text)) {
            this._miniLogger.log(prependingString, ...text);
        }
        else {
            this._miniLogger.log(prependingString, text);
        }
        if (doConsoleLog) {
            console.log(prependingString + text);
        }
    }
    logMessage(param, text, prependingString = '') {
        this._log(text, this._loggingParams.has(param), prependingString);
    }
    activate(config) {
        if (config != null) {
            this._config = config;
        }
        this._initialize();
    }
    // Singleton
    static getInstance() {
        if (!LoggingService._instance) {
            LoggingService._instance = new LoggingService();
        }
        return LoggingService._instance;
    }
}
