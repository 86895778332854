import PageBase from '../PageBase'
import { Row, context, Icon } from '@lightning/ui'
import { imagePath } from '../../utils/imagePath'
import fallbackImage from '../../../static/images/gameBackgrounds/fallback-entity-background.png'
import EntityBackground from '../../components/EntityBackground'
import ChevronLeft from '../../assets/icons/chevron-left.png'
import ChevronLeftDisabled from '../../assets/icons/chevron-left-disabled.png'
import ChevronRight from '../../assets/icons/chevron-right.png'
import ChevronRightDisabled from '../../assets/icons/chevron-right-disabled.png'
import Tile from '../../components/Tile'
import { setImage } from '../../utils/setImage'
import lng from '@lightningjs/core'
import dataLayerAnalytics from '../../lib/analytics/dataLayerAnalyticsService'

export default class EntityGallery extends PageBase {
  static _template() {
    return {
      hideNav: true,
      Background: {
        type: EntityBackground,
        blur: true,
      },
      PageContent: {
        zIndex: 2,
        Image: {
          w: 1260,
          h: 710,
          x: context.theme.layout.screenW / 2,
          mountX: 0.5,
          y: 160,
          rect: true,
          shader: { type: lng.shaders.RoundedRectangle, radius: 16 },
          alpha: 0.001,
        },
        Arrows: {
          w: 1366,
          h: 80,
          x: context.theme.layout.screenW / 2,
          mountX: 0.5,
          y: 926,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          LeftIcon: {
            type: Icon,
            icon: ChevronLeftDisabled,
            w: 72,
            h: 64,
            alpha: 0,
          },
          RightIcon: {
            type: Icon,
            icon: ChevronRight,
            w: 72,
            h: 64,
            alpha: 0,
          },
        },
        Thumbnails: {
          type: Row,
          x: 357,
          y: 915,
          w: 1260,
          scrollIndex: 5,
          signals: {
            selectedChange: '_thumbnailIndexChange',
          },
          alpha: 0,
        },
      },
    }
  }

  set params(args) {
    if (args.imageIndex) {
      this._imageIndex = args.imageIndex
    }
  }

  _onDataProvided() {
    this._background = this.data.assets?.entity && imagePath(this.data, this.data.assets?.entity)

    dataLayerAnalytics.processComplete()
  }

  _thumbnailIndexChange(selected, prev) {
    const selectedIndex = this._Thumbnails.items.indexOf(selected)
    const prevIndex = this._Thumbnails.items.indexOf(prev)

    this._updateImage(selectedIndex)
    // tile and arrow updates only need to occur when there are more than 6 thumbnails
    if (this._Thumbnails.items.length > 6) {
      this._updateArrows(selectedIndex)
      // show or hide thumbnails:
      if (prevIndex < selectedIndex && selectedIndex >= 6) {
        // if scrolling right and selected index is 6 or higher, show the item and hide
        // the item that are 6 or more items before (e.g. show item at index 7 and hide
        // the items at indices 0 and 1)
        selected.setSmooth('alpha', 1, {
          delay: 0.125,
          duration: 0.125,
        })

        for (let i = 0; i < this._Thumbnails.items.length; i++) {
          if (i <= selectedIndex - 6) {
            this._Thumbnails.items[i].patch({ alpha: 0 })
          }
        }
      } else if (prevIndex > selectedIndex && prevIndex >= 6) {
        // if scrolling left and previous index is 6 or higher, hide the item and show the
        // item that is 6 items before (e.g. hide index 6 and show index 0)
        prev.patch({ alpha: 0 })
        this._Thumbnails.items[prevIndex - 6].setSmooth('alpha', 1, {
          delay: 0.125,
          duration: 0.125,
        })
      }
    }
  }

  _smoothInImage() {
    // make sure main image doesn't flash as it fades in
    if (this._Image.alpha !== 1) {
      this._smoothImageTimeout = setTimeout(() => {
        this._Image.setSmooth('alpha', 1, { duration: 1 })
      }, 1200)
    }
  }

  _inactive() {
    clearTimeout(this._smoothImageTimeout)
  }

  _updateImage(selectedIndex) {
    const path = this.data.assets?.gallery[selectedIndex]
    this._imageSrc = imagePath(this.data, `gallery/${path}`)
    setImage({
      url: this._imageSrc,
      loadCallback: () => {
        this._Image.src = this._imageSrc
        this._smoothInImage()
      },
      errCallback: () => {
        this._Image.src = fallbackImage
        this._smoothInImage()
      },
    })
  }

  _updateArrows(selectedIndex) {
    // show the arrow icons if they're not already showing
    if (this._LeftIcon.alpha !== 1 && this._RightIcon.alpha !== 1) {
      this._LeftIcon.patch({ alpha: 1 })
      this._RightIcon.patch({ alpha: 1 })
    }

    // left arrow:
    // if selected is the first item disable the left arrow if not already disabled
    if (selectedIndex === 0 && this._LeftIcon.icon !== ChevronLeftDisabled) {
      this._LeftIcon.patch({ icon: ChevronLeftDisabled })
    } else if (selectedIndex !== 0 && this._LeftIcon.icon !== ChevronLeft) {
      // if selected isn't the first item enable the left arrow if not already enabled
      this._LeftIcon.patch({ icon: ChevronLeft })
    }

    // right arrow:
    if (
      selectedIndex === this._Thumbnails.items.length - 1 &&
      this._RightIcon.icon !== ChevronRightDisabled
    ) {
      // if selected is the last item disable right arrow if not already disabled
      this._RightIcon.patch({ icon: ChevronRightDisabled })
    } else if (
      selectedIndex !== this._Thumbnails.items.length - 1 &&
      this._RightIcon.icon !== ChevronRight
    ) {
      // if selected isn't the last item enable the right arrow if not already enabled
      this._RightIcon.patch({ icon: ChevronRight })
    }
  }

  _getThumbnails() {
    const imageTiles = this.data.assets?.galleryThumbnails.map((image, index) => {
      return {
        type: Tile,
        width: 181,
        height: 102,
        imgSrc: imagePath(this.data, `galleryThumbnails/${image}`),
        alpha:
          // default the thumbnail to show if the selected index is 0-5 and the thumbnail is also
          // index 0-5
          (this._imageIndex < 6 && index < 6) ||
          // or default to show if the thumbnail is selected index 6+ and the thumbnail is prior to
          // the selected index and the thumbnail is 5 or less indices away from the selected index
          (this._imageIndex >= 6 && index <= this._imageIndex && this._imageIndex - index <= 5)
            ? 1
            : 0,
      }
    })
    return imageTiles
  }

  _xOffset() {
    const paddingLeft = 18
    switch (this._Thumbnails.items.length) {
      case 5:
        return paddingLeft + this._Thumbnails.selected.w / 2
      case 4:
        return paddingLeft * 2 + this._Thumbnails.selected.w
      case 3:
        return paddingLeft * 2 + this._Thumbnails.selected.w * 1.5
      case 2:
        return paddingLeft * 3 + this._Thumbnails.selected.w * 2
      default:
        return paddingLeft * 4 + this._Thumbnails.selected.w * 2.5
    }
  }

  _setupThumbnails() {
    this._Thumbnails.patch({ items: this._getThumbnails() })
    this._Thumbnails.selectedIndex = this._imageIndex
    this._Thumbnails.items.length < 6 &&
      this._Thumbnails.patch({ x: this._Thumbnails.x + this._xOffset() })
    this.data.assets?.galleryThumbnails.length > 1 &&
      this._Thumbnails.setSmooth('alpha', 1, { duration: 1, delay: 1.5 })
  }

  _active() {
    this._Background.patch({
      imgSrc: this._background,
    })
    this._setupThumbnails()
    this._updateImage(this._imageIndex)
  }

  get _Background() {
    return this.tag('Background')
  }

  get _Image() {
    return this.tag('Image')
  }

  get _Thumbnails() {
    return this.tag('Thumbnails')
  }

  get _LeftIcon() {
    return this.tag('LeftIcon')
  }

  get _RightIcon() {
    return this.tag('RightIcon')
  }

  _getFocused() {
    if (this.data.assets?.galleryThumbnails.length > 1) {
      return this._Thumbnails
    }
    return undefined
  }
}
