import lng from '@lightningjs/core'
import { VideoPlayer, Router } from '@lightningjs/sdk'
import { AnalyticsGeneralErrorCodes } from '../lib/analytics/analyticsEnums'
import GeneralAnalyticsService from '../lib/analytics/generalAnalyticsService'
import { BluetoothWrapper, BluetoothWrapperConfig } from '../lib/bluetooth/bluetoothWrapper'
import { Platform } from '../lib/eventInfoFactory/eventInfoFactoryInterface'
import { LogLevels } from '../lib/logging/loggingEnums'
import LoggingService, { LoggingServiceConfig } from '../lib/logging/loggingService'
import { RemoteConfig, DefaultConfig } from '../lib/remoteConfig'
import gameplayInit from '../lib/gameplay/gameplayInitialization'
import api from '../api/providers/api'
import FireboltAuthService from '../lib/firebolt/fireboltAuthService'
import { ControllerService } from '../lib/controller/controllerService'
import DeviceInfo from '../lib/eventInfoFactory/deviceInfo'
import ApplicationDetails from '../lib/eventInfoFactory/applicationDetails'
import Thunder from '../lib/thunder/thunder'
import PlayProvider from '../api/providers/playProvider'
import UserAccountProvider from '../api/providers/userAccountProvider'
import dataLayerAnalytics from '../lib/analytics/dataLayerAnalyticsService'
import AppSessionAnalyticsService from '../lib/analytics/appSessionAnalyticsService'
import { NotificationType } from '../constants/notificationEnums'
import CouchplayDev from '../lib/gameplay/couchplayDev'
import RatingsProvider from '../api/providers/ratingsProvider'
import CodeGenerator from '../lib/codeGenerator'
import ArcadeProvider from '../api/providers/arcadeProvider'

function activateBluetooth() {
  let bluetoothWrapperConfig = new BluetoothWrapperConfig(
    RemoteConfig.getInstance().mockBTType,
    RemoteConfig.getInstance().mockBTServer
  )
  return BluetoothWrapper.getInstance().activate(bluetoothWrapperConfig)
}

function buildRequest() {
  return {
    application: {
      version: process.env.VERSION ? process.env.VERSION : 'unknown',
    },
    platform: {
      type: DeviceInfo.getInstance().platform,
    },
    os: {
      version: DeviceInfo.getInstance().physicalDevice.details.osVersion,
      ...(DeviceInfo.getInstance().physicalDevice.details.rdk.appEnvironment && {
        rdk: DeviceInfo.getInstance().physicalDevice.details.rdk.appEnvironment,
      }),
    },
    device: {
      ...DeviceInfo.getInstance().physicalDevice.details,
      ...DeviceInfo.getInstance().customerAccount.details,
      rdk: DeviceInfo.getInstance().physicalDevice.details.rdk,
    },
  }
}

function remoteConfigCall() {
  // if running on web browser or in retroMode, return early and do not config request
  if (DefaultConfig.app.retroMode || !FireboltAuthService.getInstance().isHardware) {
    return Promise.resolve({ data: DefaultConfig })
  }

  const configURL = process.env.CONFIG_URL
  return api.post(configURL, {
    requestBody: buildRequest(),
  })
}

const LOGO_LOADING_TIME = 5.0

export default class VideoManager extends lng.Component {
  _construct() {
    super._construct()
    dataLayerAnalytics.processPending()
  }

  _init() {
    this._bindFunctions()
    VideoPlayer.consumer(this)

    this.initialize()
    this.setUpVideo()
  }

  _bindFunctions() {
    this.setUpVideo = this.setUpVideo.bind(this)
  }

  async setUpVideo() {
    let BrandHammerVideoURL = 'video/XG-Launch_Animation.mp4'
    // Video player gets scaled as well, so seems like we can just use full size here
    VideoPlayer.size(1920, 1080)
    VideoPlayer.loop(false)

    // if we try to unmute for laptop browser, the browser will stop playing the video if the user
    // has not interacted with the window yet
    if (FireboltAuthService.getInstance().isHardware) {
      VideoPlayer.mute(false)
    } else {
      VideoPlayer.mute()
    }

    VideoPlayer.open(BrandHammerVideoURL)
  }

  // get remote config and setup
  async initialize() {
    // load Thunder
    let thunder = Thunder.getInstance()
    // we need to wait a tad bit after loading ThunderJS to initialize thunder
    await new Promise((resolve) => {
      this._thunderTimeout = setTimeout(() => {
        thunder.initialize()
        resolve()
      })
    })

    // wait a bit to minimize conflict with thunder initialization
    await new Promise((resolve) => {
      this._deviceInfoTimeout = setTimeout(async function () {
        await DeviceInfo.getInstance().setDetails(Platform.RDK)
        resolve()
      }, 500)
    })

    remoteConfigCall()
      .then((response) => {
        RemoteConfig.getInstance().setConfig(response.data)
        // set application details using remote config info
        ApplicationDetails.getInstance().setDetails()
      })
      .catch((err) => {
        RemoteConfig.getInstance().setConfig(DefaultConfig)
        // set application details using remote config info
        ApplicationDetails.getInstance().setDetails()
        if (FireboltAuthService.getInstance().isHardware) {
          console.error('Unable to get remote config', err)
          const generalAnalyticsService = new GeneralAnalyticsService()
          generalAnalyticsService.sendGeneralError(
            AnalyticsGeneralErrorCodes.PERFORM_REQUEST_FAILED,
            err.response
          )
        }
      })
      .finally(async () => {
        this._getCodeEntryDev()
        await UserAccountProvider.getInstance().getCapacityAndUserAccount()
        dataLayerAnalytics.pageTrackingComplete()
        const appSessionAnalyticsService = new AppSessionAnalyticsService()
        appSessionAnalyticsService.sendAppSessionStarted()
        // create the gameplay div
        gameplayInit()
        // center the gameplay container
        document.querySelector('#gamePlayContainer').style.top = `${this.stage.h / 2}px`
        document.querySelector('#gamePlayContainer').style.left = `${this.stage.w / 2}px`
        document.querySelector('#gamePlayContainer').style.transform = `translate(-50%, -50%)`
        this.fireAncestors('$onRemoteConfigLoad')

        // TODO: uncomment and switch to this once there is an easy way to change rules
        // const loggingServiceConfig = new LoggingServiceConfig(
        //   new Set(RemoteConfig.getInstance().logging)
        // )
        LoggingService.getInstance().activate(new LoggingServiceConfig(this._getLoggingParams()))
        this._getCouchplayDev()
        RatingsProvider.getInstance().loadRatings()
        ArcadeProvider.getInstance().getAllGames()
        // get Play page data so there's no delay after loading
        PlayProvider.getInstance()
          .getAllGames()
          .catch((err) => {
            console.error('Error caught in PlayProvider or UserAccountProvider', err)
          })
          .finally(() => {
            Promise.allSettled([
              !UserAccountProvider.getInstance().isNewUser &&
                PlayProvider.getInstance().loadRecentlyPlayed(),
              activateBluetooth(),
            ])
              .then(async (results) => {
                if (results[1].status === 'rejected') {
                  LoggingService.getInstance().logMessage(
                    LogLevels.BLUETOOTH,
                    `Activate bluetooth error: ${JSON.stringify(results[1].reason)}`
                  )
                  this.fireAncestors('$sendNotification', {
                    type: NotificationType.UNAVAILABLE,
                  })
                } else {
                  await BluetoothWrapper.getInstance().getUpdatedDevices()
                }
              })
              .finally(() => {
                // ensure that ControllerService is initialized
                ControllerService.getInstance()
                this._infoFetched = true
                dataLayerAnalytics.processComplete()

                const currentVideoTime = VideoPlayer.currentTime
                if (currentVideoTime >= LOGO_LOADING_TIME) {
                  this._resumeRouting()
                } else {
                  setTimeout(() => {
                    this._resumeRouting()
                  }, (LOGO_LOADING_TIME - currentVideoTime) * 1000)
                }
              })
          })
      })
  }

  $videoPlayerLoadStart() {
    this.fireAncestors('$hideAppBackground')
  }

  _resumeRouting() {
    this.fireAncestors('$showAppBackground')
    Router.resume()
  }

  _inactive() {
    clearTimeout(this._thunderTimeout)
    clearTimeout(this._deviceInfoTimeout)
    clearTimeout(this._resumeRouterTimeout)
  }

  _getCouchplayDev() {
    const couchplayDev = new URLSearchParams(window.location.search).get('couchplayDev')
    if (couchplayDev === 'true' || couchplayDev === 'on') {
      CouchplayDev.getInstance().couchplayDev = true
    }
  }

  _getCodeEntryDev() {
    const regex = /([0-9]{3})([0-9]{3})([0-9]{4})/g
    this._codeEntryDev = new URLSearchParams(window.location.search).get('codeEntryDev')
    if (this._codeEntryDev && (this._codeEntryDev === 'true' || this._codeEntryDev === 'on')) {
      CodeGenerator.getInstance().codeEntryDev = true
    } else if (this._codeEntryDev && regex.test(this._codeEntryDev)) {
      UserAccountProvider.getInstance().devMobilePhone = this._codeEntryDev
      CodeGenerator.getInstance().codeEntryDev = true
    } else {
      CodeGenerator.getInstance().codeEntryDev = false
    }
  }

  // TODO: remove once there is an easier way of setting rules for config
  _getLoggingParams() {
    const logging = new URLSearchParams(window.location.search).getAll('log')
    const urlLogSearchParams = []
    if (logging.find((query) => query === LogLevels.ALL)) {
      return new Set(Object.values(LogLevels))
    } else {
      const logLevelEnumSet = new Set(Object.values(LogLevels))
      logging.forEach((query) => {
        if (logLevelEnumSet.has(query)) {
          urlLogSearchParams.push(query)
        }
      })
      return new Set(urlLogSearchParams)
    }
  }
}
