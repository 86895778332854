import PageBase from './PageBase'
import { Sidekick } from '../components/sidekick/Sidekick'
import { NotificationType } from '../constants/notificationEnums'
import { Router } from '@lightningjs/sdk'

export default class TestSidekickPage extends PageBase {
  $closeSidekick() {
    if (this._sidekickOpen) {
      this._unpatchSidekickTimeout = setTimeout(() => {
        this.patch({
          Sidekick: undefined,
        })
        this.fireAncestors('$restoreXNotificationsContainer')
      }, 500)
      this._sidekickOpen = false
      this._getFocused()
    }
  }

  _clearTimers() {
    clearTimeout(this._unpatchSidekickTimeout)
    clearTimeout(this.player2JoinTimer)
    clearTimeout(this.player3JoinTimer)
    clearTimeout(this.player1RemoveTimer)
    clearTimeout(this.player2MuteTimer)
    clearTimeout(this.player3UnmuteTimer)
    clearTimeout(this.currentPlayerJoinTimer)
    clearTimeout(this.currentPlayerRemoveTimer)
    clearTimeout(this.currentPlayerMuteTimer)
    clearTimeout(this.currentPlayerUnmuteTimer)
  }

  _disable() {
    this._clearTimers()
  }

  _toggleSidekick() {
    if (this._sidekickOpen && this._Sidekick.alpha === 1) {
      this.$closeSidekick()
      return
    } else if (!this._sidekickOpen) {
      this._showSidekick()
    }
  }

  _showSidekick() {
    this._sidekickOpen = true
    // create Sidekick
    this.patch(
      {
        Sidekick: {
          type: Sidekick,
          alpha: 1,
          gameDetails: {
            version: 'v2',
            isTouchScreenControl: false,
            rating: '3+',
            imgPath: 'hoa',
            locale: ['English'],
            promoted: false,
            isKeyboardMouseControl: true,
            playable: true,
            isJoystickControl: true,
            name: 'Hoa',
            playCount: 39,
            'aws:rep:deleting': false,
            assets: {
              logo: 'logo.jpg',
              galleryThumbnails: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
              entity: 'entity.jpg',
              gallery: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
            },
            categories: ['adventure', 'platform', 'puzzle'],
            dateAdded: '2023-06-23 17:55:30',
            gamePlayTitle: 'Hoa.PMStudio.pc.2021',
            multiPlay: '1p',
            'aws:rep:updateregion': 'us-east-1',
            description: {
              short: '',
              long: 'Hoa is a beautiful puzzle-platforming game that features breathtaking hand-painted art, lovely music, and a peaceful, relaxing atmosphere. Experience the magic of nature and imagination as you play the main character, Hoa, on her journey through breathtaking environments back to where it all began.\n\nStep into a painting that lives - explore a vast world of meticulously crafted sceneries of stunning hand-painted art.',
            },
            'aws:rep:updatetime': 1687542931.396002,
            author: 'Skrollcat Studio',
          },
          sessionCode: null,
          couchMode: null,
          gamingClient: null,
          zIndex: 2,
          exitOverride: () => this._exit(),
        },
      },
      // createMode
      true
    )
  }

  _exit() {
    this.fireAncestors('$restoreXNotificationsContainer')
    Router.back()
  }

  _handleHome() {
    return true
  }

  _handleHomeRelease() {
    this._toggleSidekick()
  }

  _handleLast(e) {
    return this._goBack()
  }

  _handleBack(e) {
    return this._goBack()
  }

  _goBack() {
    this._toggleSidekick()
    return true
  }

  _handleControllerB() {
    if (this._sidekickOpen) {
      this.$closeSidekick()
    }
    // stop propagation
    return true
  }

  _getFocused() {
    if (this._sidekickOpen) {
      return this._Sidekick
    } else {
      return this.tag('Items')
    }
  }

  get _Sidekick() {
    return this.tag('Sidekick')
  }

  _handleDevFlip() {
    this.fireAncestors('$sendNotification', {
      type: NotificationType.PARTY_ERROR,
    })

    this.fireAncestors('$sendNotification', {
      type: NotificationType.INVALID_CODE,
    })

    this.fireAncestors('$sendNotification', {
      type: NotificationType.SURVEY_FEEDBACK,
    })

    this.fireAncestors('$sendNotification', {
      type: NotificationType.BAD_NETWORK,
    })

    this.fireAncestors('$sendNotification', {
      type: NotificationType.JOIN,
      playerIndex: 1,
      currentPlayer: 0,
    })

    this.player2JoinTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.JOIN,
        playerIndex: 2,
        currentPlayer: 0,
      })
    }, 1000)

    this.player3JoinTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.JOIN,
        playerIndex: 3,
        currentPlayer: 0,
      })
    }, 2000)

    this.player1RemoveTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.REMOVE,
        playerIndex: 1,
        currentPlayer: 0,
      })
    }, 6000)

    this.player2MuteTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.MUTE,
        playerIndex: 2,
        currentPlayer: 0,
      })
    }, 9000)

    this.player3UnmuteTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.UNMUTE,
        playerIndex: 3,
        currentPlayer: 0,
      })
    }, 11000)

    this.currentPlayerJoinTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.JOIN,
        playerIndex: 1,
        currentPlayer: 1,
      })
    }, 13000)

    this.currentPlayerRemoveTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.REMOVE,
        playerIndex: 1,
        currentPlayer: 1,
      })
    }, 15000)

    this.currentPlayerMuteTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.MUTE,
        playerIndex: 1,
        currentPlayer: 1,
      })
    }, 17000)

    this.currentPlayerUnmuteTimer = setTimeout(() => {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.UNMUTE,
        playerIndex: 1,
        currentPlayer: 1,
      })
    }, 19000)
  }
}
