import { Base, Icon } from '@lightning/ui'
import spinnerIcon from '../assets/icons/spinnerIcon'

export class SpinningIcon extends Base {
  static _template() {
    return {
      w: 40,
      h: 40,
      Icon: {
        type: Icon,
        icon: spinnerIcon,
        w: 40,
        h: 40,
      },
    }
  }

  startAnimation() {
    if (!this._animation) {
      this._animation = this._Icon.animation({
        duration: 1,
        repeat: -1,
        actions: [{ p: 'rotation', v: { 0: 0, 1: 6.29 } }],
      })
    }

    if (this._animation.isPaused() || !this._animation.isActive()) {
      this._animation.start()
    }
  }

  stopAnimation() {
    this._animation && this._animation.stop()
  }

  get _Icon() {
    return this.tag('Icon')
  }
}
