import { Account } from '@firebolt-js/sdk';
export default class XBOAccount {
    constructor() {
        this._serviceAccountId = 'unknown';
    }
    async initialize() {
        await this._setAccountDetails();
    }
    async _setAccountId() {
        try {
            this._serviceAccountId = await Account.id();
        }
        catch (err) {
            console.error(`account id error ${JSON.stringify(err)}`);
        }
    }
    async _setAccountDetails() {
        await this._setAccountId();
    }
    get details() {
        return {
            serviceAccountId: this._serviceAccountId,
        };
    }
}
