import { KEYS } from './constants'

// Detect 720 or 1080p
const appResolution =
  window.innerWidth < 1920
    ? { w: 1280, h: 720, precision: 2 / 3 }
    : { w: 1920, h: 1080, precision: 1 }

const appSettings = {
  stage: {
    ...appResolution,
    clearColor: 0x000000000,
    canvas2d: false,
    fontSharp: false,
    useImageWorker: true, // Required for transparent images
    forceTxCanvasSource: true, // apparently a fix for text rendering as white blocks issue
    pauseRafLoopOnIdle: true,
    // debugFrame: false, // when true logs debug info about each frame including how many render-to-texture elements were re-rendered
  },
  keys: KEYS,
}

export default appSettings
