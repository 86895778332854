const gamepad = `<svg id="gamepad" width="350" viewBox="0 0 441 383" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="controller">
    <path id="LOutline" d="M220.5 294.5C220.5 294.5 195 294.5 150 294.5C105 294.5 81.5 378.5 49.5 378.5C17.5 378.5 4 363.9 4 317.5C4 271.1 43.5 165.5 55 137.5C66.5 109.5 95.5 92.0001 128 92.0001C154 92.0001 200.5 92.0001 220.5 92.0001" stroke="rgba(97, 56, 246)" stroke-width="3" stroke-opacity="1"/>
    <path id="ROutline" d="M220 294.5C220 294.5 245.5 294.5 290.5 294.5C335.5 294.5 359 378.5 391 378.5C423 378.5 436.5 363.9 436.5 317.5C436.5 271.1 397 165.5 385.5 137.5C374 109.5 345 92.0001 312.5 92.0001C286.5 92.0001 240 92.0001 220 92.0001" stroke="rgba(97, 56, 246)" stroke-width="3" stroke-opacity="1"/>
    <circle id="LStickOutline" cx="113" cy="160" r="37.5" stroke="rgb(240,0,77,0.6)" stroke-opacity="1" stroke-width="3"/>
    <circle id="RStickOutline" cx="278" cy="238" r="37.5" stroke="rgb(240,0,77,0.6)" stroke-opacity="1" stroke-width="3"/>
    <circle id="DOutline" cx="166" cy="238" r="37.5" stroke="rgb(240,0,77,0.6)" stroke-opacity="1" stroke-width="3"/>
    <circle id="LetterOutline" cx="329" cy="160" r="37.5" stroke="rgb(240,0,77,0.6)" stroke-opacity="1" stroke-width="3"/>
    <circle id="LStick" cx="113" cy="160" r="28" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <circle id="RStick" cx="278" cy="238" r="28" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <circle id="Home"cx="221" cy="135" r="16" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <circle id="Select" cx="189" cy="172" r="6" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <circle id="Start" cx="254" cy="172" r="6" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <g>
      <mask id="path-8-inside-1" fill="white">
        <path d="M177.669 222.335C180.793 219.21 180.816 213.997 176.868 212.014C176.327 211.743 175.776 211.491 175.215 211.258C172.182 210.002 168.931 209.355 165.648 209.355C162.365 209.355 159.114 210.002 156.081 211.258C155.521 211.491 154.969 211.743 154.429 212.014C150.48 213.997 150.503 219.21 153.627 222.335L159.991 228.698C163.116 231.823 168.181 231.823 171.305 228.698L177.669 222.335Z"/>
      </mask>
      <path id="Up" d="M177.669 222.335C180.793 219.21 180.816 213.997 176.868 212.014C176.327 211.743 175.776 211.491 175.215 211.258C172.182 210.002 168.931 209.355 165.648 209.355C162.365 209.355 159.114 210.002 156.081 211.258C155.521 211.491 154.969 211.743 154.429 212.014C150.48 213.997 150.503 219.21 153.627 222.335L159.991 228.698C163.116 231.823 168.181 231.823 171.305 228.698L177.669 222.335Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="6" mask="url(#path-8-inside-1)"/>
    </g>
    <g>
      <mask id="path-9-inside-2" fill="white">
        <path d="M181.447 249.669C184.571 252.793 189.785 252.816 191.768 248.868C192.039 248.327 192.291 247.776 192.523 247.215C193.78 244.182 194.426 240.931 194.426 237.648C194.426 234.365 193.78 231.114 192.523 228.081C192.291 227.521 192.039 226.969 191.768 226.429C189.785 222.48 184.571 222.503 181.447 225.627L175.083 231.991C171.959 235.116 171.959 240.181 175.083 243.305L181.447 249.669Z"/>
      </mask>
      <path id="Right" d="M181.447 249.669C184.571 252.793 189.785 252.816 191.768 248.868C192.039 248.327 192.291 247.776 192.523 247.215C193.78 244.182 194.426 240.931 194.426 237.648C194.426 234.365 193.78 231.114 192.523 228.081C192.291 227.521 192.039 226.969 191.768 226.429C189.785 222.48 184.571 222.503 181.447 225.627L175.083 231.991C171.959 235.116 171.959 240.181 175.083 243.305L181.447 249.669Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="6" mask="url(#path-9-inside-2)"/>
    </g>
    <g>
      <mask id="path-10-inside-3" fill="white">
        <path d="M154.113 253.447C150.989 256.571 150.966 261.785 154.914 263.767C155.455 264.039 156.006 264.291 156.566 264.523C159.6 265.78 162.85 266.426 166.134 266.426C169.417 266.426 172.667 265.78 175.701 264.523C176.261 264.291 176.812 264.039 177.353 263.767C181.301 261.785 181.279 256.571 178.154 253.447L171.79 247.083C168.666 243.959 163.601 243.959 160.477 247.083L154.113 253.447Z"/>
      </mask>
      <path id="Down" d="M154.113 253.447C150.989 256.571 150.966 261.785 154.914 263.767C155.455 264.039 156.006 264.291 156.566 264.523C159.6 265.78 162.85 266.426 166.134 266.426C169.417 266.426 172.667 265.78 175.701 264.523C176.261 264.291 176.812 264.039 177.353 263.767C181.301 261.785 181.279 256.571 178.154 253.447L171.79 247.083C168.666 243.959 163.601 243.959 160.477 247.083L154.113 253.447Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="6" mask="url(#path-10-inside-3)"/>
    </g>
    <g>
      <mask id="path-11-inside-4" fill="white">
        <path d="M150.335 226.113C147.21 222.989 141.997 222.966 140.014 226.914C139.743 227.455 139.491 228.006 139.258 228.566C138.002 231.6 137.355 234.85 137.355 238.134C137.355 241.417 138.002 244.667 139.258 247.701C139.491 248.261 139.743 248.812 140.014 249.353C141.997 253.301 147.21 253.279 150.335 250.154L156.698 243.79C159.823 240.666 159.823 235.601 156.698 232.477L150.335 226.113Z"/>
      </mask>
      <path id="Left" d="M150.335 226.113C147.21 222.989 141.997 222.966 140.014 226.914C139.743 227.455 139.491 228.006 139.258 228.566C138.002 231.6 137.355 234.85 137.355 238.134C137.355 241.417 138.002 244.667 139.258 247.701C139.491 248.261 139.743 248.812 140.014 249.353C141.997 253.301 147.21 253.279 150.335 250.154L156.698 243.79C159.823 240.666 159.823 235.601 156.698 232.477L150.335 226.113Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="6" mask="url(#path-11-inside-4)"/>
    </g>
    <circle id="Y" cx="328.5" cy="142" r="10" fill="rgba(0,0,0,0)" stroke="rgb(97, 56, 246, 1)" stroke-width="3"/>
    <circle id="B" cx="347" cy="160" r="10" fill="rgba(0,0,0,0)" stroke="rgb(97, 56, 246, 1)" stroke-width="3"/>
    <circle id="A" cx="328.5" cy="178" r="10" fill="rgba(0,0,0,0)" stroke="rgb(97, 56, 246, 1)" stroke-width="3"/>
    <circle id="X" cx="310" cy="160" r="10" fill="rgba(0,0,0,0)" stroke="rgb(97, 56, 246, 1)" stroke-width="3"/>
    <rect id="LB" x="112.5" y="61.5" width="41" height="13" rx="6.5" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <rect id="RB" x="289.5" y="61.5" width="41" height="13" rx="6.5" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <path id="LT" d="M152.5 37C152.5 41.1421 149.142 44.5 145 44.5H132C127.858 44.5 124.5 41.1421 124.5 37V16.5C124.5 8.76801 130.768 2.5 138.5 2.5C146.232 2.5 152.5 8.76801 152.5 16.5V37Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 246,1)" stroke-width="3"/>
    <path id="RT" d="M317.5 37C317.5 41.1421 314.142 44.5 310 44.5H297C292.858 44.5 289.5 41.1421 289.5 37V16.5C289.5 8.76801 295.768 2.5 303.5 2.5C311.232 2.5 317.5 8.76801 317.5 16.5V37Z" fill="rgba(0,0,0,0)" stroke="rgba(97, 56, 2461)" stroke-width="3"/>
  </g>
</svg>`

export default gamepad
