import PageBase from './PageBase'
import { SettingsButton } from '../components/settings/SettingsButton'
import ScrollingRow from '../components/ScrollingRow'
import Section from '../components/settings/Section'
import ArticleSection from '../components/settings/ArticleSection'
import ArticleOverlay from '../components/settings/ArticleOverlay'
import dataLayerAnalytics from '../lib/analytics/dataLayerAnalyticsService'
export default class HelpAndSupport extends PageBase {
  _construct() {
    super._construct()
    this._pageTitle = 'Help & support'
  }

  static _template() {
    return {
      ...super._template(),
      Help: {
        type: ScrollingRow,
        autoResizeWidth: true,
        itemSpacing: 40,
        alwaysScroll: true,
        x: 562,
        y: 85,
        mountY: 0.5,
        items: [
          {
            type: Section,
            scrollIndex: 4,
            header: 'Help & support',
            content: [],
          },
        ],
      },
    }
  }

  historyState() {
    if (!this._NavDrawer.expanded) {
      return {
        breadcrumbTitle: this._pageTitle,
      }
    }
  }

  _onDataProvided() {
    this._contentItems = this.data.map((topic) => {
      return {
        type: SettingsButton,
        title: topic.label,
        nextSection: {
          type: ArticleSection,
          title: topic.label,
          content: topic.articles,
          label: topic.label,
        },
        onEnter: () => this._selectNext(),
      }
    })
    dataLayerAnalytics.processComplete()
  }

  _firstActive() {
    super._firstActive()
    this._selectNext = this._selectNext.bind(this)
    this._Section.appendItems(this._contentItems)
  }

  _selectNext() {
    this._Help.selectNext()
  }

  $sectionUpdated(section, sectionIndex) {
    if (sectionIndex > 0) {
      let prevSection = this._Help.Content[sectionIndex - 1]
      let newTitle = prevSection.header == null ? '' : prevSection.header
      this._Breadcrumb.show(newTitle)
    } else {
      this._Breadcrumb.hide()
    }
  }

  $openOverlay(article) {
    this._overlayOpen = true
    this._NavDrawer.patch({ alpha: 0.001 })
    this._Status.patch({ alpha: 0.001 })
    this._Breadcrumb.Title = article.label
    this.patch(
      {
        Overlay: {
          type: ArticleOverlay,
          title: article.title,
          content: article.content ? article.content : 'Coming soon...',
        },
      },
      true
    )
    this._getFocused()
  }

  _removeOverlay() {
    this._overlayOpen = false
    this.patch({ Overlay: undefined })
    this._Breadcrumb.Title = this._pageTitle
    this._NavDrawer.patch({ alpha: 1 })
    this._Status.patch({ alpha: 1 })
  }

  _getFocused() {
    if (this._Overlay) {
      return this._Overlay
    }
    return this._Help
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _NavDrawer() {
    return this.widgets.navigation
  }

  get _Status() {
    return this.widgets.status
  }

  get _BackSection() {
    return this.tag('BackSection')
  }

  get _BackTitle() {
    return this._BackSection.tag('Title')
  }

  get _Help() {
    return this.tag('Help')
  }

  get _Overlay() {
    return this.tag('Overlay')
  }

  // override key handlers when overlay exists to dismiss it

  _handleBack(e) {
    if (this._Overlay) {
      this._removeOverlay()
      return
    }
    super._handleBack(e)
  }

  _handleLast(e) {
    if (this._Overlay) {
      this._removeOverlay()
      return
    }
    super._handleLast(e)
  }

  _handleHome() {
    if (this._Overlay) {
      this._removeOverlay()
      return
    }
    super._handleHome()
  }

  _handleEnter() {
    if (this._Overlay) {
      this._removeOverlay()
      return
    }
    super._handleEnter()
  }

  _handleControllerB() {
    if (this._Overlay) {
      this._removeOverlay()
      return
    }
  }

  // Do not focus nav when overlay is open because nav is hidden
  _handleLeft() {
    if (this._overlayOpen) {
      return
    }
    super._handleLeft()
  }
  get _Section() {
    return this._Help.items[0]
  }
}
