import { NavigationLeft } from '@lightning/ui'
import { Router } from '@lightningjs/sdk'
import routeNotifier from '../RouteNotifier'

export class XGNavigationLeft extends NavigationLeft {
  _init() {
    super._init()
    // subscribe UI to Router updates
    routeNotifier.subcribe(this._onRouteUpdate.bind(this))
  }

  _onRouteUpdate(newRoute) {
    this._handleNavItemEnter()
    this._updateModes()
  }

  // Capture left press on Nav and do nothing
  _handleLeft() {
    return true
  }

  // Right press focuses back to page, and updates soft focus back to current route
  _handleRight() {
    Router.focusPage()
    return true
  }

  // Back press prevents back event and returns focus to page
  _handleBack(e) {
    e.preventDefault()
    Router.focusPage()
    return true
  }

  _handleControllerB(e) {
    this._handleBack(e)
  }

  _focus() {
    // On focus, selection should start on the currently soft-focused route item
    this.selectedIndex = this._activeRouteIndex()
    this._updateModes()
    super._focus()
    this._expanded = true
  }

  _unfocus() {
    // If router is navigating, don't update soft focus on unfocus, b/c it will be handled by route change
    // Otherwise, add soft focus back to current route
    if (!Router.isNavigating()) {
      this._updateModes()
    }

    super._unfocus()
    this._expanded = false
  }

  _handleNavItemEnter() {
    this.currentPage = this._activeRouteIndex()
  }

  /**
   * Gets the index of the active nav item based on the current route
   * @returns {number}
   */
  _activeRouteIndex() {
    const activeRoute = Router.getActiveRoute()
    const activeIndex = this.tag('Items').children.findIndex((child) =>
      activeRoute.startsWith(child.route)
    )
    if (activeIndex === -1) {
      console.warn('No active NavDrawer item route found.')
    }
    return activeIndex
  }

  get expanded() {
    return this._expanded
  }
}
