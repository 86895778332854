export var CouchplayPanelNames;
(function (CouchplayPanelNames) {
    CouchplayPanelNames["SESSION_CODE"] = "sessionCode";
    CouchplayPanelNames["DEFAULT_HOST"] = "defaultHost";
    CouchplayPanelNames["DEFAULT_PLAYER"] = "defaultPlayer";
    CouchplayPanelNames["END_PARTY_CONFIRMATION"] = "endPartyConfirmation";
    CouchplayPanelNames["LEAVE_PARTY_CONFIRMATION"] = "leavePartyConfirmation";
    CouchplayPanelNames["MANAGE_PLAYER"] = "managePlayer";
})(CouchplayPanelNames || (CouchplayPanelNames = {}));
export var OverviewPanelNames;
(function (OverviewPanelNames) {
    OverviewPanelNames["GAME_OVERVIEW"] = "gameOverview";
    OverviewPanelNames["EXIT_CONFIRMATION"] = "exitConfirmation";
})(OverviewPanelNames || (OverviewPanelNames = {}));
