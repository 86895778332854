import { ListItem, TextBox, Icon } from '@lightning/ui'

export default class ContentControlsListItem extends ListItem {
  static _template() {
    return {
      ...super._template(),
      w: 812,
      h: 112,
      fixed: true,
      style: {
        minWidth: 812,
      },
    }
  }

  _update() {
    super._update()
    super._updateTitle()
  }

  _onTextBoxChanged() {
    super._updateTruncation()
    this._updateDescription()
  }

  _init() {
    super._init()
    this._TextWrapper.patch({
      Description: {
        type: TextBox,
        signals: {
          textBoxChanged: '_onTextBoxChanged',
        },
        style: {
          textStyle: {
            ...this.style.descriptionTextStyle,
            wordWrap: true,
            wordWrapWidth: this.w - 68,
          },
        },
        visible: true,
      },
    })
  }

  _updateDescription() {
    this._Description.patch({
      content: this.description,
    })
    this.h = this._Title.h + this._Description.h + 40 // add some padding
  }

  _updatePrefixLogo() {
    if (this._hasPrefixLogo) {
      this._prefix = this._addLogoProps(this._prefixLogo, { type: 'prefix' })
    }
  }

  _updateSuffixLogo() {
    if (this._hasSuffixLogo) {
      this._suffix = this._addLogoProps(this._suffixLogo, { type: 'suffix' })
    }
  }

  _addLogoProps(icon, { type }) {
    return {
      type: Icon,
      icon,
      style: {
        color: undefined,
        ...(type === 'prefix' && {
          width: 50,
          height: 76,
        }),
        ...(type === 'suffix' && {
          width: 40,
          height: 40,
        }),
        radius: 0,
      },
    }
  }
}
