import { Column, context, TextBox } from '@lightning/ui'
import { ControllerAbbreviations } from '../../lib/controller/controllerNamesEnums'
import { Language, Router } from '@lightningjs/sdk'
import PageBase from '../PageBase'
import { RouteHash } from '../../constants/routeEnums'
import XGButton, { corner } from '../../components/XGButton'

export default class SelectControllerEd extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      announce: [
        Language.translate('HOW_TO_PAIR_A_CONTROLLER'),
        Language.translate('SELECT_THE_CONTROLLER'),
      ],
      announceContext: Language.translate('UP_DOWN_NAVIGATION'),
      Title: {
        type: TextBox,
        content: 'How to pair a controller',
        style: {
          textStyle: {
            ...context.theme.typography.display2,
            textAlign: 'center',
          },
        },
        y: 245,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
      },
      Text: {
        type: TextBox,
        content: `Select the controller you'd like to pair from the list below.`,
        style: {
          textStyle: {
            ...context.theme.typography.body1,
            textAlign: 'center',
          },
        },
        y: 333,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
      },
      Buttons: {
        type: Column,
        y: 425,
        x: 767,
        mountX: 0.5,
        itemSpacing: 32,
        items: [],
      },
    }
  }

  historyState() {
    return {
      breadcrumbTitle: 'How to pair a controller',
    }
  }

  _init() {
    super._init()
    this._Buttons.patch({ items: this._buttonsItems })
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Buttons() {
    return this.tag('Buttons')
  }

  get _buttonsItems() {
    const controllers = [
      ControllerAbbreviations.PS5,
      ControllerAbbreviations.PS4,
      ControllerAbbreviations.XBOX,
      ControllerAbbreviations.GENERIC,
    ]

    const buttons = controllers.map((name, index) => {
      const article = name === ControllerAbbreviations.XBOX ? 'an' : 'a'
      const enumMatch = name.toUpperCase() // used on PairingModeEd page to get correct name + abbreviation from ControllerNames + ControllerAbbreviations enums

      return {
        type: XGButton,
        title: `Pair ${article} ${name} controller`,
        announceContext: [
          Language.translate('ANNOUNCE_LENGTH', index + 1, controllers.length),
          'PAUSE-2',
        ],
        h: 80,
        w: 406,
        fixed: true,
        onEnter: () => {
          Router.navigate(`${RouteHash.PAIRING_MODE_LEARN_PATH}${enumMatch}`, {
            article: article,
            keepAlive: true,
          })
        },
        rounded:
          index === 0
            ? corner.topRight
            : index === controllers.length - 1
            ? corner.bottomLeft
            : corner.none,
      }
    })
    return buttons
  }

  _getFocused() {
    return this._Buttons
  }
}
