import { TextBox, context, utils } from '@lightningjs/ui-components'
import { OverviewPanelNames } from '../panelNames'
import { imagePath } from '../../../utils/imagePath'
import Tile from '../../Tile'
import { PanelTemplate } from '../PanelTemplate'
import XGButton, { corner } from '../../XGButton'

export class GameOverviewPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      style: { ...super._template().style, itemSpacing: 24 },
      w: 420,
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Currently playing',
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              textColor: utils.getHexColor('#F6F6F9'),
            },
          },
        },
        {
          type: Tile,
          description: 'Return to game',
          centerInParent: true,
          width: 386,
          height: 386,
          gradient: true,
        },
        {
          type: XGButton,
          centerInParent: true,
          title: 'Exit',
          w: 386,
          h: 80,
          rounded: corner.bottomLeftAndTopRight,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._ExitBtn.patch({
      onEnter: () => {
        this.fireAncestors('$showOverviewPanel', OverviewPanelNames.EXIT_CONFIRMATION)
      },
    })
  }

  _firstActive() {
    this._Thumbnail.patch({
      imgSrc: this.gameDetails
        ? this.gameDetails.emulator
          ? this.gameDetails.imgSrc
          : imagePath(this.gameDetails, this.gameDetails.assets?.logo)
        : '',
      title: this.gameDetails ? this.gameDetails.name : '',
      onEnter: () => {
        this.fireAncestors('$closeSidekick')
      },
    })
  }

  set gameDetails(gameDetails) {
    if (gameDetails == null) {
      return
    }

    this._gameDetails = gameDetails
  }

  get gameDetails() {
    return this._gameDetails
  }

  get _Thumbnail() {
    return this.items[1]
  }

  get _ExitBtn() {
    return this.items[2]
  }
}
