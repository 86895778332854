import { TextBox, context, Base } from '@lightning/ui'
import ClippedColumn from './ClippedColumn'

// parameters:
// columnHeight: adjusts the height of the column that is nested in the ClippedColumn component. Column uses this to determine whether to scroll or not.
// scrollIndex: gets passed to the nested Column and sets the scrollIndex to that column.
export default class Section extends Base {
  static get properties() {
    return ['header', 'scrollIndex', 'columnHeight']
  }

  static _template() {
    return {
      w: 800,
      Header: {
        type: TextBox,
        mountY: 0.5,
        content: '',
        style: {
          textStyle: { ...context.theme.typography.display1 },
        },
      },
      Content: {
        type: ClippedColumn,
        y: 96,
      },
    }
  }

  _update() {
    this._Header.patch({ content: this.header })
    this.announce = this.header

    if (this.columnHeight && this._Content.h != this.columnHeight) {
      this._Content.patch({ h: this.columnHeight })
    }

    if (this.scrollIndex != null && this._Content.scrollIndex != this.scrollIndex) {
      this._Content.patch({ scrollIndex: this.scrollIndex })
    }
  }

  setContent(newContent) {
    this._ContentContainer.setContent(newContent)
  }

  _focus() {
    this._focused = true
  }

  _unfocus() {
    this._focused = false
  }

  appendItems(items) {
    this._ContentContainer.appendItems(items)
  }

  // updateSection is used to determine how to update the section based on
  // new information passed in.
  // can be overidden
  updateSection(newParams) {}

  fullHide() {
    this.setSmooth('alpha', 0)
    this.hideTitle()
  }

  halfHide(delay = 0) {
    this.setSmooth('alpha', 0.5, { delay: delay })
    this.hideTitle()
  }

  show() {
    this.setSmooth('alpha', 1, { duration: 0.1 })
    this.showTitle()
  }

  hideTitle() {
    this._Header.setSmooth('alpha', 0, { duration: 0.1 })
  }

  showTitle() {
    this._Header.patch({ content: this.header })
    this.announce = this.header
    this._Header.setSmooth('alpha', 1, { duration: 0.1 })
  }

  get selected() {
    return this._Content.selected
  }

  get _Header() {
    return this.tag('Header')
  }

  get _ContentContainer() {
    return this.tag('Content')
  }

  set _Content(newContent) {
    this._ContentContainer.setContent(newContent)
  }

  get _Content() {
    return this._ContentContainer._Content
  }

  get _ContentItems() {
    return this._ContentContainer._contentItems
  }

  _getFocused() {
    return this._ContentContainer
  }
}
