import PageBase from '../PageBase'
import DialpadColumn from '../../components/DialpadColumn'
import { Router } from '@lightningjs/sdk'
import XGButton, { corner } from '../../components/XGButton'
import CodeGenerator from '../../lib/codeGenerator'
import UserAccountProvider from '../../api/providers/userAccountProvider'
import { context, TextBox } from '@lightning/ui'
import { RouteHash } from '../../constants/routeEnums'
import { SpinningIcon } from '../../components/SpinningIcon'
import { NotificationType } from '../../constants/notificationEnums'

const resendCodeTitle = 'Resend code'

export default class ContentControlsCodeEntry extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      Dialpad: {
        type: DialpadColumn,
        title: 'Enter the code sent to your phone',
        subtitle: 'A one-time code was sent to the phone number (***) ***-',
        numDigits: 4,
        goBack: () => Router.back(),
        secondaryCta: {
          type: XGButton,
          title: resendCodeTitle,
          w: 402,
          fixed: true,
          rounded: corner.bottomLeft,
        },
      },
      ResendHelperText: {
        type: TextBox,
        content: 'Please wait a few seconds before requesting another code.',
        mountX: 0.5,
        x: context.theme.layout.screenW / 2,
        y: 976,
        style: {
          textStyle: {
            ...context.theme.typography.caption1,
          },
        },
        skipFocus: true,
        centerInParent: true,
        alpha: 0,
      },
      Spinner: {
        type: SpinningIcon,
        skipFocus: true,
        alpha: 0,
        x: 797,
        y: 902,
      },
    }
  }

  set params(args) {
    this._rating = args.gameRating
    this._game = args.gameName
    this._onSubmit = args.onSubmit
  }

  _init() {
    super._init()
    this._Dialpad.patch({
      subtitle: (this._Dialpad._Subtitle.content += `${
        UserAccountProvider.getInstance().mobilePhone?.slice(-4) ?? ''
      }.`),
      primaryCta: {
        title: 'Submit',
        onEnter: () => this._submitCode(),
      },
    })
    this._Dialpad._PrimaryCta.patch({ w: 402 })
    this._Dialpad._SecondaryCta.patch({ onEnter: () => this._resendCode() })
  }

  _active() {
    this._validCodeTimer = setTimeout(() => {
      CodeGenerator.getInstance().invalidateCode()
    }, 60 * 1000 * 10) // code becomes invalid after 10 min on this page
  }

  _inactive() {
    // code becomes invalid if you leave this page
    CodeGenerator.getInstance().invalidateCode()
    clearTimeout(this._validCodeTimer)
    clearTimeout(this._nextPageTimer)
    clearTimeout(this._resendDisabledTimer)
  }

  _submitCode() {
    const code = this._Dialpad.inputText
    const passed = CodeGenerator.getInstance().verifyCode(code)
    if (passed && !this._onSubmit) {
      this._nextPageTimer = setTimeout(() => Router.back(), 500)
    } else if (passed && this._onSubmit) {
      this._onSubmit()
    } else {
      let codeErrorStatus = code.length === 0 ? 400 : 404
      this._Dialpad.setError(codeErrorStatus)
      // reset error status after dispatching the error
      codeErrorStatus = undefined
    }
  }

  _resendCode() {
    this._processingStateSecondaryCta()
    // generate the new code and send text
    CodeGenerator.getInstance().generateCode()
    // if dev mode update the code in URL path
    this._appendCodeDevMode()
    this._sendText()
  }

  _processingStateSecondaryCta() {
    this._Spinner.alpha = 1
    this._Spinner.startAnimation()
    this._Dialpad._SecondaryCta.patch({ title: 'Resending code' })
  }

  _appendCodeDevMode() {
    CodeGenerator.getInstance().codeEntryDev &&
      Router.navigate(
        `${RouteHash.CONTENT_CONTROLS}${RouteHash.ENTER_CODE_CONTENT_CONTROLS_PATH}/${
          CodeGenerator.getInstance().code
        }`,
        {
          keepAlive: true,
          ...(this.gameParamsExist && {
            gameRating: this._rating,
            gameName: this._game,
            onSubmit: this._onSubmit,
          }),
        }
      )
  }

  _sendText() {
    UserAccountProvider.getInstance()
      .unlockUserAccount({
        code: CodeGenerator.getInstance().code,
        ...(this.gameParamsExist && { rating: this._rating, game: this._game }),
      })
      .then(() => {
        this._Dialpad.resetCodeAndInlineError()
        this._disableResendButton()
        this._resendDisabledTimer = setTimeout(() => {
          this._enableResendButton()
        }, 30 * 1000) // resend code button is to be disabled for 30 seconds after each successful use
      })
      .catch(() => {
        this.fireAncestors('$sendNotification', {
          type: NotificationType.RESEND_CODE_FAILED,
        })
      })
      .finally(() => this._resetSecondaryCta())
  }

  _disableResendButton() {
    this._Dialpad._Buttons.selectedIndex = 0
    this._Dialpad._SecondaryCta.patch({ skipFocus: true })
    this._Dialpad._SecondaryCta.mode = 'disabled'
    this._ResendHelperText.setSmooth('alpha', 1)
    this._Dialpad._focusFirstKey()
  }

  _enableResendButton() {
    this._Dialpad._SecondaryCta.mode = 'unfocused'
    this._Dialpad._SecondaryCta.patch({ skipFocus: false })
    this._ResendHelperText.setSmooth('alpha', 0)
  }

  _resetSecondaryCta() {
    this._Spinner.alpha = 0
    this._Spinner.stopAnimation()
    this._Dialpad._SecondaryCta.patch({ title: resendCodeTitle })
  }

  get gameParamsExist() {
    return this._rating && this._game
  }

  get _Dialpad() {
    return this.tag('Dialpad')
  }

  get _ResendHelperText() {
    return this.tag('ResendHelperText')
  }

  get _Spinner() {
    return this.tag('Spinner')
  }

  _getFocused() {
    return this._Dialpad
  }
}
