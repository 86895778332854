import { LogLevels } from '../logging/loggingEnums';
import LoggingService from '../logging/loggingService';
import { BluetoothEventTypes, BluetoothStatus, DiscoveryTypes, } from './bluetoothServiceInterface';
import { MockDevices } from './mockDevices';
export default class MockBluetoothService {
    constructor(timeoutLength = 2000) {
        this._registeredEvents = new Map();
        this._devices = new Map();
        this._discoveredDevices = new Set();
        this._pairedDevices = new Set();
        this._connectedDevices = new Set();
        this._timeoutLength = timeoutLength;
        this._timeouts = new Set();
        this._discoveredDeviceTimeouts = new Set();
        this._bindFunctions();
        this._addDevices();
    }
    addListener(eventType, callback) {
        if (!this._registeredEvents.has(eventType)) {
            this._registeredEvents.set(eventType, new Set());
        }
        this._registeredEvents.get(eventType).add(callback);
    }
    removeListener(eventType, callback) {
        if (this._registeredEvents.has(eventType)) {
            const registeredEvents = this._registeredEvents.get(eventType);
            registeredEvents.delete(callback);
        }
    }
    activate() {
        return new Promise((resolve) => {
            resolve({ success: true });
        });
    }
    deactivate() {
        this._registeredEvents.clear();
        this._discoveredDevices.clear();
        this._pairedDevices.clear();
        this._connectedDevices.clear();
        this._clearAllTimeouts();
    }
    connect(deviceID, deviceType) {
        const response = {
            success: true,
        };
        if (!this._devices.has(deviceID)) {
            response.success = false;
        }
        return new Promise((resolve) => {
            if (this._devices.has(deviceID)) {
                const timerID = setTimeout(() => {
                    const device = this._devices.get(deviceID);
                    this._connectedDevices.add(deviceID);
                    this._pairedDevices.add(deviceID);
                    device.connected = true;
                    const onStatusChangedEvent = {
                        newStatus: BluetoothStatus.CONNECTION_CHANGE,
                        deviceID: deviceID,
                        name: device.name,
                        deviceType: device.deviceType,
                        rawDeviceType: device.rawDeviceType,
                        // TODO: we don't seem to currently do anything with this value right now, but might want to actually change it to reflect the most currently connected device
                        lastConnectedState: device.lastConnectedState,
                        paired: this._pairedDevices.has(deviceID),
                        connected: this._connectedDevices.has(deviceID),
                    };
                    this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, onStatusChangedEvent);
                    this._timeouts.delete(timerID);
                }, this._timeoutLength);
                this._timeouts.add(timerID);
            }
            setTimeout(() => {
                resolve(response);
            }, this._timeoutLength);
        });
    }
    disconnect(deviceID, deviceType) {
        const response = {
            success: true,
        };
        if (!this._connectedDevices.has(deviceID) || !this._devices.has(deviceID)) {
            response.success = false;
        }
        return new Promise((resolve) => {
            if (this._connectedDevices.has(deviceID) && this._devices.has(deviceID)) {
                const timerID = setTimeout(() => {
                    const connectedDevice = this._devices.get(deviceID);
                    this._connectedDevices.delete(deviceID);
                    connectedDevice.connected = false;
                    const onStatusChangedEvent = {
                        newStatus: BluetoothStatus.CONNECTION_CHANGE,
                        deviceID: deviceID,
                        name: connectedDevice.name,
                        deviceType: connectedDevice.deviceType,
                        rawDeviceType: connectedDevice.rawDeviceType,
                        // TODO: we don't seem to currently do anything with this value right now, but might want to actually change it to reflect the most currently connected device
                        lastConnectedState: false,
                        paired: this._pairedDevices.has(deviceID),
                        connected: this._connectedDevices.has(deviceID),
                    };
                    this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, onStatusChangedEvent);
                    this._timeouts.delete(timerID);
                }, this._timeoutLength);
                this._timeouts.add(timerID);
            }
            setTimeout(() => {
                resolve(response);
            }, this._timeoutLength);
        });
    }
    pair(deviceID) {
        const response = {
            success: true,
        };
        if (!this._discoveredDevices.has(deviceID) || !this._devices.has(deviceID)) {
            response.success = false;
        }
        return new Promise((resolve) => {
            if (this._discoveredDevices.has(deviceID) && this._devices.has(deviceID)) {
                const timerID = setTimeout(() => {
                    const discoveredDevice = this._devices.get(deviceID);
                    this._pairedDevices.add(deviceID);
                    discoveredDevice.paired = true;
                    const onStatusChangedEvent = {
                        newStatus: BluetoothStatus.PAIRING_CHANGE,
                        deviceID: deviceID,
                        name: discoveredDevice.name,
                        deviceType: discoveredDevice.deviceType,
                        rawDeviceType: discoveredDevice.rawDeviceType,
                        // TODO: we don't seem to currently do anything with this value right now, but might want to actually change it to reflect the most currently connected device
                        lastConnectedState: discoveredDevice.lastConnectedState,
                        paired: this._pairedDevices.has(deviceID),
                        connected: this._connectedDevices.has(deviceID),
                    };
                    this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, onStatusChangedEvent);
                    this._timeouts.delete(timerID);
                }, this._timeoutLength);
                this._timeouts.add(timerID);
            }
            setTimeout(() => {
                resolve(response);
            }, this._timeoutLength);
        });
    }
    unpair(deviceID) {
        const response = {
            success: true,
        };
        if (!this._pairedDevices.has(deviceID) || !this._devices.has(deviceID)) {
            response.success = false;
        }
        return new Promise((resolve) => {
            if (this._pairedDevices.has(deviceID) && this._devices.has(deviceID)) {
                const timerID = setTimeout(() => {
                    const pairedDevice = this._devices.get(deviceID);
                    this._pairedDevices.delete(deviceID);
                    this._connectedDevices.delete(deviceID);
                    pairedDevice.connected = false;
                    pairedDevice.paired = false;
                    const onStatusChangedEvent = {
                        newStatus: BluetoothStatus.PAIRING_CHANGE,
                        deviceID: deviceID,
                        name: pairedDevice.name,
                        deviceType: pairedDevice.deviceType,
                        rawDeviceType: pairedDevice.rawDeviceType,
                        // TODO: we don't seem to currently do anything with this value right now, but might want to actually change it to reflect the most currently connected device
                        lastConnectedState: pairedDevice.lastConnectedState,
                        paired: this._pairedDevices.has(deviceID),
                        connected: this._connectedDevices.has(deviceID),
                    };
                    this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, onStatusChangedEvent);
                    this._timeouts.delete(timerID);
                }, this._timeoutLength);
                this._timeouts.add(timerID);
            }
            setTimeout(() => {
                resolve(response);
            }, this._timeoutLength);
        });
    }
    /**
     * Function to start scanning for bluetooth devices.
     */
    startScan(timeout) {
        const response = {
            status: 'AVAILABLE',
            success: true,
        };
        return new Promise((resolve) => {
            const discoveryStartedEvent = {
                newStatus: BluetoothStatus.DISCOVERY_STARTED,
            };
            this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, discoveryStartedEvent);
            let currentIndex = 1;
            for (const [deviceID, deviceDetails] of this._devices) {
                const timerID = setTimeout(() => {
                    if (!this._discoveredDevices.has(deviceID)) {
                        const discoveryEvent = {
                            deviceID: deviceDetails.deviceID,
                            discoveryType: DiscoveryTypes.DISCOVERED,
                            name: deviceDetails.name,
                            deviceType: deviceDetails.deviceType,
                            rawDeviceType: deviceDetails.rawDeviceType,
                            lastConnectedState: deviceDetails.lastConnectedState,
                            paired: deviceDetails.paired,
                        };
                        this._discoveredDevices.add(deviceID);
                        this._activateCallbacks(BluetoothEventTypes.ON_DISCOVERED_DEVICE, discoveryEvent);
                    }
                    this._discoveredDeviceTimeouts.delete(timerID);
                }, this._timeoutLength * currentIndex);
                this._discoveredDeviceTimeouts.add(timerID);
                currentIndex += 1;
            }
            const timerID = setTimeout(() => {
                const discoveryCompleteEvent = {
                    newStatus: BluetoothStatus.DISCOVERY_COMPLETED,
                };
                this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, discoveryCompleteEvent);
                this._discoveredDeviceTimeouts.delete(timerID);
            }, this._timeoutLength * currentIndex);
            this._discoveredDeviceTimeouts.add(timerID);
            resolve(response);
        });
    }
    stopScan() {
        for (const timeoutID in this._discoveredDeviceTimeouts) {
            clearTimeout(timeoutID);
        }
        this._discoveredDeviceTimeouts.clear();
        const discoveryCompletedEvent = {
            newStatus: BluetoothStatus.DISCOVERY_COMPLETED,
        };
        this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, discoveryCompletedEvent);
        return new Promise((resolve) => {
            resolve({ success: true });
        });
    }
    getDiscoveredDevices() {
        const discoveredDevices = [];
        for (const deviceID of this._discoveredDevices) {
            const discoveredDevice = this._createDiscoveredDevice(deviceID);
            if (discoveredDevice) {
                discoveredDevices.push(discoveredDevice);
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    success: true,
                    discoveredDevices: discoveredDevices,
                });
            }, 3000);
        });
    }
    getPairedDevices() {
        const pairedDevices = [];
        for (const deviceID of this._pairedDevices) {
            const pairedDevice = this._createPairedDevice(deviceID);
            if (pairedDevice) {
                pairedDevices.push(pairedDevice);
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    success: true,
                    pairedDevices: pairedDevices,
                });
            }, 3000);
        });
    }
    getConnectedDevices() {
        const connectedDevices = [];
        for (const deviceID of this._connectedDevices) {
            const connectedDevice = this._createConnectedDevice(deviceID);
            if (connectedDevice) {
                connectedDevices.push(connectedDevice);
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    success: true,
                    connectedDevices: connectedDevices,
                });
            }, 3000);
        });
    }
    respondToEvent(deviceID, eventType, responseValue) {
        return new Promise((resolve) => {
            resolve({ success: true });
        });
    }
    _bindFunctions() {
        this.addListener = this.addListener.bind(this);
        this.removeListener = this.removeListener.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.startScan = this.startScan.bind(this);
        this.stopScan = this.stopScan.bind(this);
        this.pair = this.pair.bind(this);
        this.unpair = this.unpair.bind(this);
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.getDiscoveredDevices = this.getDiscoveredDevices.bind(this);
        this.getPairedDevices = this.getPairedDevices.bind(this);
        this.getConnectedDevices = this.getConnectedDevices.bind(this);
        this._addDevices = this._addDevices.bind(this);
        this._createDiscoveredDevice = this._createDiscoveredDevice.bind(this);
        this._createPairedDevice = this._createPairedDevice.bind(this);
        this._createConnectedDevice = this._createConnectedDevice.bind(this);
        this._clearAllTimeouts = this._clearAllTimeouts.bind(this);
        this._log = this._log.bind(this);
    }
    _addDevices() {
        MockDevices.forEach((device) => {
            this._devices.set(device.deviceID, device);
        });
    }
    _createDiscoveredDevice(deviceID) {
        const deviceDetails = this._devices.get(deviceID);
        if (deviceDetails != null) {
            return {
                deviceID: deviceDetails.deviceID,
                name: deviceDetails.name,
                deviceType: deviceDetails.deviceType,
                connected: this._connectedDevices.has(deviceDetails.deviceID),
                paired: this._pairedDevices.has(deviceDetails.deviceID),
            };
        }
        return undefined;
    }
    _activateCallbacks(eventType, event) {
        const callbacks = this._registeredEvents.get(eventType);
        if (callbacks) {
            callbacks.forEach((callback) => callback(event));
        }
    }
    _createPairedDevice(deviceID) {
        const deviceDetails = this._devices.get(deviceID);
        if (deviceDetails != null) {
            return {
                deviceID: deviceDetails.deviceID,
                name: deviceDetails.name,
                deviceType: deviceDetails.deviceType,
                connected: this._connectedDevices.has(deviceDetails.deviceID),
            };
        }
        return undefined;
    }
    _createConnectedDevice(deviceID) {
        const deviceDetails = this._devices.get(deviceID);
        if (deviceDetails != null) {
            return {
                deviceID: deviceDetails.deviceID,
                name: deviceDetails.name,
                deviceType: deviceDetails.deviceType,
                activeState: '2',
            };
        }
        return undefined;
    }
    _clearAllTimeouts() {
        for (const timeoutID in this._timeouts) {
            clearTimeout(timeoutID);
        }
        for (const timeoutID in this._discoveredDeviceTimeouts) {
            clearTimeout(timeoutID);
        }
        this._timeouts.clear();
        this._discoveredDeviceTimeouts.clear();
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.BLUETOOTH, text);
    }
}
