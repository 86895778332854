import { AnalyticsBluetoothErrorCodes } from './bluetoothAnalyticsInterface';
import { BluetoothEventTypes, BluetoothMethods, } from './bluetoothServiceInterface';
import { BluetoothError } from './errors';
import LoggingService from '../logging/loggingService';
import { LogLevels } from '../logging/loggingEnums';
import Thunder from '../thunder/thunder';
import BluetoothAnalyticsService from './bluetoothAnalyticsService';
export default class ThunderBluetooth {
    constructor() {
        this._activated = false;
        this._registeredEvents = new Map();
        this._callsign = 'org.rdk.Bluetooth';
        this._bluetoothAnalyticsService = new BluetoothAnalyticsService();
        this._bindFunctions();
    }
    _bindFunctions() {
        this.activate = this.activate.bind(this);
        this.pair = this.pair.bind(this);
        this.unpair = this.unpair.bind(this);
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.getDiscoveredDevices = this.getDiscoveredDevices.bind(this);
        this.getPairedDevices = this.getPairedDevices.bind(this);
        this.getConnectedDevices = this.getConnectedDevices.bind(this);
        this.addListener = this.addListener.bind(this);
        this.removeListener = this.removeListener.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.startScan = this.startScan.bind(this);
        this.stopScan = this.stopScan.bind(this);
        this._registerForEvent = this._registerForEvent.bind(this);
        this._activateCallbacks = this._activateCallbacks.bind(this);
        this._log = this._log.bind(this);
    }
    addListener(eventType, callback) {
        if (!this._registeredEvents.has(eventType)) {
            this._registeredEvents.set(eventType, new Set());
        }
        this._registeredEvents.get(eventType).add(callback);
    }
    removeListener(eventType, callback) {
        if (this._registeredEvents.has(eventType)) {
            const registeredEvents = this._registeredEvents.get(eventType);
            registeredEvents.delete(callback);
        }
    }
    _throwErrorIfSuccessFalse(success) {
        if (!success) {
            throw '{success: false}';
        }
    }
    /**
     * Function to activate the bluetooth plugin
     */
    activate() {
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .activateWithRetry(this._callsign, 5, 1000)
                .then((result) => {
                this._log('ThunderBluetooth: activated successfully');
                this._activated = true;
                this._registerForEvent(BluetoothEventTypes.ON_STATUS_CHANGED);
                this._registerForEvent(BluetoothEventTypes.ON_REQUEST_FAILED);
                this._registerForEvent(BluetoothEventTypes.ON_DISCOVERED_DEVICE);
                this._registerForEvent(BluetoothEventTypes.ON_DEVICE_FOUND);
                this._registerForEvent(BluetoothEventTypes.ON_DEVICE_LOST);
                resolve(result);
            })
                .catch((err) => {
                this._log(`error activating ThunderBluetooth: ${JSON.stringify(err)}`);
                reject(err);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.ACTIVATE_ERROR);
            });
        });
    }
    deactivate() {
        this._log('ThunderBluetooth: deactivate ThunderBluetooth');
        this._registeredEvents = new Map();
    }
    startScan(timeout) {
        this._log('ThunderBluetooth: startScan called');
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.START_SCAN, {
                timeout: timeout.toString(),
                profile: 'KEYBOARD, MOUSE, JOYSTICK',
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`startScan result: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`startScan error: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.START_SCAN_ERROR);
                reject(new BluetoothError(BluetoothMethods.START_SCAN, err));
            });
        });
    }
    /**
     * Function to stop the scan for bluetooth devices.
     */
    stopScan() {
        this._log('ThunderBluetooth: stopScan called');
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.STOP_SCAN, {})
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`stopScan result: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`stopScan err: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.STOP_SCAN_ERROR);
                reject(new BluetoothError(BluetoothMethods.STOP_SCAN, err));
            });
        });
    }
    /**
     * Function returns the discovered bluetooth devices.
     */
    getDiscoveredDevices() {
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.GET_DISCOVERED_DEVICES)
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`getDiscoveredDevices result: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`getDiscoveredDevices err: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.GET_DISCOVERED_DEVICES_ERROR);
                reject(new BluetoothError(BluetoothMethods.GET_DISCOVERED_DEVICES, err));
            });
        });
    }
    /**
     * Function returns the paired bluetooth devices.
     */
    getPairedDevices() {
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.GET_PAIRED_DEVICES)
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`getPairedDevices result: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`getPairedResults err: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.GET_PAIRED_DEVICES_ERROR);
                reject(new BluetoothError(BluetoothMethods.GET_PAIRED_DEVICES, err));
            });
        });
    }
    /**
     * Function returns the connected bluetooth devices.
     */
    getConnectedDevices() {
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.GET_CONNECTED_DEVICES)
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`getConnectedDevices result: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`getConnectedDevices err: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.GET_CONNECTED_DEVICES_ERROR);
                reject(new BluetoothError(BluetoothMethods.GET_CONNECTED_DEVICES, err));
            });
        });
    }
    pair(deviceID) {
        this._log(`pair called for ${deviceID}`);
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.PAIR, {
                deviceID: deviceID,
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`pair result for ${deviceID}: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`pair err for ${deviceID}: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.PAIR_ERROR);
                reject(new BluetoothError(BluetoothMethods.PAIR, err));
            });
        });
    }
    unpair(deviceID) {
        this._log(`unpair called for ${deviceID}`);
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.UNPAIR, {
                deviceID: deviceID,
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`unpair result for ${deviceID}: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`unpair err for ${deviceID}: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.UNPAIR_ERROR);
                reject(new BluetoothError(BluetoothMethods.UNPAIR, err));
            });
        });
    }
    connect(deviceID, deviceType) {
        this._log(`connect called: {deviceID: ${deviceID}, deviceType: ${deviceType}}`);
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.CONNECT, {
                deviceID: deviceID,
                deviceType: deviceType,
                profile: 'JOYSTICK',
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`connect result for ${deviceID}: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`connect err for ${deviceID}: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.CONNECT_ERROR);
                reject(new BluetoothError(BluetoothMethods.CONNECT, err));
            });
        });
    }
    disconnect(deviceID, deviceType) {
        this._log(`disconnect called: {deviceID: ${deviceID}, deviceType: ${deviceType}}`);
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.DISCONNECT, {
                deviceID: deviceID,
                deviceType: deviceType,
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`disconnect result for ${deviceID}: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`disconnect err for ${deviceID}: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.DISCONNECT_ERROR);
                reject(new BluetoothError(BluetoothMethods.DISCONNECT, err));
            });
        });
    }
    respondToEvent(deviceID, eventType, responseValue) {
        return new Promise((resolve, reject) => {
            Thunder.getInstance()
                .call(this._callsign, BluetoothMethods.RESPOND_TO_EVENT, {
                deviceID: deviceID,
                eventType: eventType,
                responseValue: responseValue,
            })
                .then((result) => {
                this._throwErrorIfSuccessFalse(result.success);
                this._log(`respondToEvent result for ${deviceID}: ${JSON.stringify(result)}`);
                resolve(result);
            })
                .catch((err) => {
                this._log(`respondToEvent err for ${deviceID}: ${JSON.stringify(err)}`);
                this._bluetoothAnalyticsService.sendBluetoothError(AnalyticsBluetoothErrorCodes.RESPONSE_TO_EVENT_ERROR);
                reject(new BluetoothError(BluetoothMethods.RESPOND_TO_EVENT, err));
            });
        });
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.BLUETOOTH, text, 'Thunder Bluetooth: ');
    }
    _registerForEvent(eventType) {
        Thunder.getInstance().ThunderJS.on(this._callsign, eventType, (notification) => {
            this._log(`eventType: ${eventType}, event: ${JSON.stringify(notification)}`);
            this._activateCallbacks(eventType, notification);
        });
    }
    _activateCallbacks(eventType, notification) {
        const callbacks = this._registeredEvents.get(eventType);
        if (callbacks) {
            callbacks.forEach((callback) => callback(notification));
        }
    }
}
