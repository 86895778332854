export default class CodeGenerator {
    generateCode() {
        this._code = (Math.floor(Math.random() * 9000) + 1000).toString();
        this._codeChallengePassed = false;
    }
    invalidateCode() {
        this._code = undefined;
    }
    verifyCode(code) {
        this._codeChallengePassed = code === this._code;
        this._codeChallengePassed && this.invalidateCode();
        return this._codeChallengePassed;
    }
    get code() {
        return this._code;
    }
    set codeChallengePassed(passed) {
        this._codeChallengePassed = passed;
    }
    get codeChallengePassed() {
        return this._codeChallengePassed;
    }
    set codeEntryDev(isDev) {
        this._codeEntryDev = isDev;
    }
    get codeEntryDev() {
        return this._codeEntryDev;
    }
    static getInstance() {
        if (!CodeGenerator._instance) {
            CodeGenerator._instance = new CodeGenerator();
        }
        return CodeGenerator._instance;
    }
}
