export default {
  8: 'Back',
  9: 'RightTrigger', // Tab key
  13: 'Enter', // PS X, Switch B
  16: 'LeftTrigger', // Shift key
  27: 'Exit',
  33: 'PageUp',
  34: 'PageDown',
  36: 'Menu',
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  49: 'RightBumper', // 1 key
  50: 'LeftBumper', // 2 key
  51: 'DevFlip',
  52: 'Select', // 4 key
  53: 'Start', // 5 key
  55: 'Xumo',
  56: 'Xfinity',
  57: 'Spectrum',
  66: 'ControllerB', // PS circle, Switch A
  71: 'Guide',
  72: 'Home',
  73: 'Info',
  76: 'Last',
  77: 'Xfinity',
  84: 'Readability',
  86: 'VoiceGuidance',
  88: 'ControllerX', // PS square, Switch Y
  89: 'ControllerY', // PS triangle, Switch X
  112: 'Info',
  113: 'Settings',
  126: 'Inputs',
  179: 'PlayPause',
  227: 'Rewind',
  228: 'FastForward',
  219: 'Rewind',
  221: 'FastForward',
  403: 'ColorA',
  404: 'ColorB',
  405: 'ColorC',
  406: 'ColorD',
}
