import { Surface, Marquee, context, utils } from '@lightning/ui'
import fallbackImage from '../../static/images/thumbnails/fallback-thumbnail.png'
import lng from '@lightningjs/core'
import { setImage } from '../utils/setImage'

const defaultDim = 249
const metadataPadding = 24

export default class Tile extends Surface {
  static _template() {
    return {
      ...super._template(),
      w: defaultDim,
      h: defaultDim,
      Image: {
        rect: true,
        shader: { type: lng.shaders.RoundedRectangle, radius: 16 },
        zIndex: 2,
        w: defaultDim,
        h: defaultDim,
        alpha: 0,
      },
      Gradient: {
        alpha: 0,
        rect: true,
        shader: { type: lng.shaders.RoundedRectangle, radius: 16 },
        colorTop: 0x00141417,
        colorBottom: 0xff141417,
        w: defaultDim,
        h: defaultDim,
        zIndex: 3,
      },
      Metadata: {
        alpha: 0,
        y: 161,
        zIndex: 4,
        Title: {
          type: Marquee,
          repeat: 0,
          title: {
            text: '',
            ...context.theme.typography.headline3,
            textColor: utils.getHexColor('#F6F6F9'),
          },
          zIndex: 4,
          w: defaultDim - metadataPadding * 2,
          h: defaultDim - metadataPadding * 2,
          x: metadataPadding,
        },
        Description: {
          type: Marquee,
          repeat: 0,
          title: {
            text: '',
            ...context.theme.typography.body3,
            textColor: utils.getHexColor('#F6F6F9', 0.6),
          },
          zIndex: 4,
          w: defaultDim - metadataPadding * 2,
          h: defaultDim - metadataPadding * 2,
          x: metadataPadding,
          y: 35,
        },
      },
    }
  }

  _smoothInImage() {
    this._Image.setSmooth('alpha', 1, { duration: 0.5 })
  }

  _init() {
    super._init()
    if (this.width && this.height) {
      this.patch({ w: this.width, h: this.height })
      this._Image.patch({
        w: this.width,
        h: this.height,
      })

      this._Metadata.patch({ y: this.height - 75 })

      this._Gradient.patch({ w: this.width, h: this.height })
    }
    if (this.title && this.description) {
      this._Title.patch({
        title: { ...this._Title.title, text: this.title },
      })
      this._Description.patch({
        title: { ...this._Description.title, text: this.description },
      })
    }
    this.patch({ onEnter: (e) => this.navigate(e) })
  }

  _focus() {
    super._focus()

    if (this.title && this._Title.title.text !== this.title) {
      this._Title.patch({
        title: { ...this._Title.title, text: this.title },
      })
    }

    if (this.description && this._Description.title.text !== this.description) {
      this._Description.patch({
        title: { ...this._Description.title, text: this.description },
      })
    }

    // if Metadata isn't already visible (due to image failure in errCallback),
    // then show it on focus
    this._Metadata.alpha !== 1 && this._Metadata.patch({ alpha: 1 })
    this._Description.alpha !== 1 && this._Description.patch({ alpha: 1 })

    this.gradient && this._Gradient.patch({ alpha: 1 })

    if (this.title && this.description) {
      this._Title.startScrolling()
      this._Description.startScrolling()
    }
  }

  _unfocus() {
    super._unfocus()

    if (this._Image.src !== fallbackImage) {
      // if src isn't the fallback then hide Metadata on unfocus
      this._Metadata.patch({ alpha: 0 })
    } else {
      // if it is the fallback then just hide Description
      this._Description.patch({ alpha: 0 })
    }
    this.gradient && this._Gradient.patch({ alpha: 0 })

    if (this.title && this.description) {
      this._Title.stopScrolling()
      this._Description.stopScrolling()
    }
  }

  set imgSrc(newImgSrc) {
    this._imgSrc = newImgSrc
    setImage({
      url: this.imgSrc,
      loadCallback: () => {
        this._Image.src = this.imgSrc
        this._smoothInImage()
      },
      errCallback: () => {
        this._Image.src = fallbackImage
        // show Metadata Title when image src fails
        this._Metadata.alpha = 1
        this._Description.alpha = 0
        this._smoothInImage()
      },
    })
  }

  get imgSrc() {
    return this._imgSrc
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Description() {
    return this.tag('Description')
  }

  get _Metadata() {
    return this.tag('Metadata')
  }

  get _Image() {
    return this.tag('Image')
  }

  get _Gradient() {
    return this.tag('Gradient')
  }
}
