// Values correspond to allowed controller status: https://github.com/comcast-labs/gamex-analytics-docs/blob/main/events/controllerstatuschanged.md
export var ControllerStatus;
(function (ControllerStatus) {
    ControllerStatus["PAIRED"] = "paired";
    ControllerStatus["UNPAIRED"] = "unpaired";
    ControllerStatus["CONNECTED"] = "connected";
    ControllerStatus["DISCONNECTED"] = "disconnected";
})(ControllerStatus || (ControllerStatus = {}));
// Codes correspond to https://github.com/comcast-labs/gamex-analytics-docs/blob/main/errorCodes/errorCodes.md
export var AnalyticsBluetoothErrorCodes;
(function (AnalyticsBluetoothErrorCodes) {
    AnalyticsBluetoothErrorCodes["ACTIVATE_ERROR"] = "1";
    AnalyticsBluetoothErrorCodes["START_SCAN_ERROR"] = "2";
    AnalyticsBluetoothErrorCodes["STOP_SCAN_ERROR"] = "3";
    AnalyticsBluetoothErrorCodes["GET_DISCOVERED_DEVICES_ERROR"] = "4";
    AnalyticsBluetoothErrorCodes["GET_PAIRED_DEVICES_ERROR"] = "5";
    AnalyticsBluetoothErrorCodes["GET_CONNECTED_DEVICES_ERROR"] = "6";
    AnalyticsBluetoothErrorCodes["PAIR_ERROR"] = "7";
    AnalyticsBluetoothErrorCodes["UNPAIR_ERROR"] = "8";
    AnalyticsBluetoothErrorCodes["CONNECT_ERROR"] = "9";
    AnalyticsBluetoothErrorCodes["DISCONNECT_ERROR"] = "10";
    AnalyticsBluetoothErrorCodes["RESPONSE_TO_EVENT_ERROR"] = "11";
})(AnalyticsBluetoothErrorCodes || (AnalyticsBluetoothErrorCodes = {}));
export class BluetoothEvent {
}
