import lng from '@lightningjs/core'
import { TextBox, context } from '@lightning/ui'
import { HERO_ANIMATION } from './HeroBackground'

export default class HeroMetadata extends lng.Component {
  static _template() {
    return {
      w: 796,
      h: 144,
      TextContent: {
        alpha: 0.001,
        Title: {
          type: TextBox,
          content: '',
          maxLines: 2,
          w: 836,
          skipFocus: true,
          mountY: 1,
          y: 96,
          style: {
            textStyle: {
              ...context.theme.typography.display1,
              wordWrapWidth: 836,
            },
          },
        },
        Description: {
          type: TextBox,
          y: 144,
          mountY: 1,
          content: '',
          maxLines: 1,
          w: 836,
          skipFocus: true,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              wordWrapWidth: 836,
            },
          },
        },
      },
    }
  }

  init() {
    this._title = ''
    this._description = ''
  }

  set content([title, description]) {
    if (title !== this._title || description !== this._description) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const skipFadeout = !this._title && !this._description
      if (skipFadeout) {
        this.patch({
          TextContent: {
            Title: {
              content: title,
            },
            Description: {
              content: description,
            },
          },
        })
        this._TextContent.setSmooth('alpha', 1, HERO_ANIMATION)
      } else {
        this._TextContent.setSmooth('alpha', 0.001, {
          duration: HERO_ANIMATION.duration / 2,
        })
        this.timeout = setTimeout(() => {
          this.patch({
            TextContent: {
              Title: {
                content: title,
              },
              Description: {
                content: description,
              },
            },
          })
          this._TextContent.setSmooth('alpha', 1, HERO_ANIMATION)
        }, (HERO_ANIMATION.duration / 2) * 1000)
      }

      this._title = title
      this._description = description
    }
  }

  get _TextContent() {
    return this.tag('TextContent')
  }
}
