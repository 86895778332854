import { FireboltMetricsService } from '../firebolt/fireboltMetricsService';
import { AnalyticsEventName } from './analyticsEnums';
export function createAppSessionStartedEvent(event) {
    return {
        ...event,
        eventName: AnalyticsEventName.APP_SESSION_STARTED,
    };
}
export function createAppSessionEndedEvent(event) {
    return {
        ...event,
        eventName: AnalyticsEventName.APP_SESSION_ENDED,
    };
}
export function createGameSessionStartingEvent(event) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_STARTING,
    };
}
export function createGameSessionStartedEvent(event, eventDetails) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_STARTED,
        event: eventDetails,
    };
}
export function createGameSessionEndedEvent(event, eventDetails) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_ENDED,
        event: eventDetails,
    };
}
export function createGameSessionFailedEvent(event, eventDetails) {
    const metrics = new FireboltMetricsService(eventDetails.bucket, eventDetails.code);
    metrics.sendFireboltMetricsError();
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_FAILED,
        event: eventDetails,
    };
}
export function createGameSessionHeartbeatEvent(event, eventDetails) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_HEARTBEAT,
        event: eventDetails,
    };
}
export function createPositiveFeedbackEvent(event) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_FEEDBACK,
        event: {
            isPositive: true,
        },
    };
}
export function createNegativeFeedbackEvent(event, negativeReasons) {
    return {
        ...event,
        eventName: AnalyticsEventName.GAME_SESSION_FEEDBACK,
        event: {
            isPositive: false,
            ...(negativeReasons.length > 0 && {
                negativeReasons: negativeReasons,
            }),
        },
    };
}
export function createControllerStatusChangedEvent(event, eventDetails) {
    return {
        ...event,
        event: eventDetails,
        eventName: AnalyticsEventName.CONTROLLER_STATUS_CHANGED,
    };
}
export function createErrorEvent(event, bucket, code, requestDetails) {
    const metrics = new FireboltMetricsService(bucket, code);
    metrics.sendFireboltMetricsError();
    return {
        ...event,
        eventName: AnalyticsEventName.ERROR,
        event: {
            bucket: bucket,
            code: code,
            ...(requestDetails && requestDetails),
        },
    };
}
