export var ControllerNames;
(function (ControllerNames) {
    ControllerNames["PS5"] = "PlayStation 5";
    ControllerNames["PS4"] = "PlayStation 4";
    ControllerNames["XBOX"] = "Xbox";
    ControllerNames["GENERIC"] = "generic";
})(ControllerNames || (ControllerNames = {}));
export var ControllerAbbreviations;
(function (ControllerAbbreviations) {
    ControllerAbbreviations["PS5"] = "PS5";
    ControllerAbbreviations["PS4"] = "PS4";
    ControllerAbbreviations["XBOX"] = "Xbox";
    ControllerAbbreviations["GENERIC"] = "generic";
})(ControllerAbbreviations || (ControllerAbbreviations = {}));
