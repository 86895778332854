import generateUUID from '../../utils/generateUUID';
import { RemoteConfig } from '../remoteConfig';
import { AnalyticsConstants } from '../../constants/analytics';
export default class ApplicationDetails {
    constructor() {
        this._appSessionId = generateUUID();
        this._partnerSDKs = [];
    }
    setDetails() {
        this._partnerSDKs = [];
        RemoteConfig.getInstance().partners.forEach((partner) => {
            this._partnerSDKs.push({
                partnerName: partner === null || partner === void 0 ? void 0 : partner.partnerName,
                sdkVersion: partner === null || partner === void 0 ? void 0 : partner.sdkVersion,
            });
        });
    }
    get appSessionId() {
        return this._appSessionId;
    }
    get version() {
        return process.env.VERSION ? process.env.VERSION : 'unknown';
    }
    get details() {
        return {
            name: AnalyticsConstants.APP_NAME,
            ...(process.env.VERSION && { version: process.env.VERSION }),
            partnerSDKs: this._partnerSDKs,
        };
    }
    static getInstance() {
        if (!ApplicationDetails.instance) {
            ApplicationDetails.instance = new ApplicationDetails();
        }
        return ApplicationDetails.instance;
    }
}
