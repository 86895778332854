import { ListItem } from '@lightning/ui'
import checkmarkIcon from '../../assets/icons/checkmarkIcon'
import spinnerIcon from '../../assets/icons/spinnerIcon'

export class SettingsButton extends ListItem {
  static _template() {
    return {
      ...super._template(),
      w: 796,
      h: 96,
      fixed: true,
      style: {
        minWidth: 796,
      },
      countInAnnounceLength: true,
    }
  }

  _init() {
    super._init()
    this._bindFunctions()
    if (this.description == null) {
      this.description = ''
    }
  }

  _bindFunctions() {
    this._updateNextSection = this._updateNextSection.bind(this)
  }

  _focus() {
    super._focus()
    this._focused = true

    // immediate rendering of next section cuts off voice guidance, so set a timeout
    this._updateCallback = setTimeout(() => {
      this._updateNextSection()
    })
  }

  _unfocus() {
    super._unfocus()
    this._focused = false
    clearTimeout(this._updateCallback)
  }

  _updateNextSection() {
    this.fireAncestors('$updateNextSection')
  }

  set visibleIcon(isVisible) {
    this._isVisible = isVisible
    if (this._Suffix) {
      if (this._isVisible) {
        this._Suffix.alpha = 1
      } else {
        this._Suffix.alpha = 0.01
      }
    }
  }

  get visibleIcon() {
    return this._isVisible
  }

  _updateSuffix() {
    super._updateSuffix()

    if (this._Suffix) {
      if (this.visibleIcon) {
        this._Suffix.alpha = 1
      } else {
        this._Suffix.alpha = 0.01
      }
    }
  }

  set announce(announce) {
    super.announce = announce
  }

  get announce() {
    if (this._announce) {
      return this._announce
    }

    return this.title + this.description + ', Button'
  }
}

export class InProgressButton extends SettingsButton {
  static _template() {
    return {
      ...super._template(),
      suffixLogo: spinnerIcon,
      style: {
        logoStyle: {
          w: 40,
          h: 40,
        },
      },
    }
  }

  _active() {
    super._active()
    if (!this._animation) {
      let animation = {
        duration: 1,
        repeat: -1,
        actions: [{ p: 'rotation', v: { 0: 0, 1: 6.29 } }],
      }
      this._animation = this._Suffix.animation(animation)
    }

    if (this._animation.isPaused() || !this._animation.isActive()) {
      this._animation.start()
    }
  }
}

export class DeviceButton extends SettingsButton {
  static _template() {
    return {
      ...super._template(),
      suffixLogo: checkmarkIcon,
      style: {
        ...super._template().style,
        logoStyle: {
          h: 46,
          w: 54,
        },
      },
    }
  }

  _updateNextSection() {
    this.fireAncestors('$updateNextSection', this.device, false)
  }

  set device(newDevice) {
    this._device = newDevice
    if (this._device.connected) {
      this.patch({ description: 'Connected' })
      this.visibleIcon = true
    } else {
      this.patch({ description: '' })
      this.visibleIcon = false
    }

    if (this._focused) {
      this._updateNextSection()
    }
  }

  get device() {
    return this._device
  }
}
