export var RouteHash;
(function (RouteHash) {
    RouteHash["PLAY"] = "play";
    RouteHash["ARCADE"] = "arcade";
    RouteHash["LUNAR"] = "lunar";
    RouteHash["EMULATOR"] = "emulator";
    RouteHash["PARTY"] = "party";
    RouteHash["SETTINGS"] = "settings";
    RouteHash["HELP_AND_SUPPORT"] = "help";
    RouteHash["APP_LAUNCH"] = "$";
    RouteHash["WELCOME"] = "welcome";
    RouteHash["NO_ACCESS"] = "no_access";
    RouteHash["NOTICES"] = "notices";
    RouteHash["ENTITY_PATH"] = "entity/";
    RouteHash["ENTITY_OVERVIEW_PATH"] = "/overview";
    RouteHash["ENTITY_GALLERY_PATH"] = "/gallery/";
    RouteHash["GAMEPLAY_PATH"] = "gamestream/";
    RouteHash["GAMEPLAY_SURVEY"] = "survey/gameplay";
    RouteHash["GAMEPLAY_IDLE"] = "idle/gameplay";
    RouteHash["GAMEPLAY_END_PARTY"] = "gameplay/endParty";
    RouteHash["GAMEPLAY_ERROR"] = "error/gameplay";
    RouteHash["BLUETOOTH"] = "bluetooth";
    RouteHash["BLUETOOTH_ERROR"] = "error/bluetooth";
    RouteHash["GAME_CATALOG_ERROR"] = "error/catalog";
    RouteHash["PARTY_RATING_RESTRICTED"] = "party/ratings-restricted";
    RouteHash["EXIT"] = "confirm/exit";
    RouteHash["SELECT_CONTROLLER_LEARN"] = "learn/select-controller";
    RouteHash["PAIRING_MODE_LEARN_PATH"] = "learn/pairing-mode/";
    RouteHash["PARTY_MODE_LEARN"] = "learn/party-mode";
    RouteHash["CONTENT_CONTROLS"] = "content-controls";
    RouteHash["UPDATE_CONTENT_CONTROLS_PATH"] = "/update";
    RouteHash["ENTER_CODE_CONTENT_CONTROLS_PATH"] = "/enter-code";
    RouteHash["NO_PHONE_CONTENT_CONTROLS_PATH"] = "/no-phone";
    RouteHash["SIDEKICK_TEST"] = "test/sidekick";
    RouteHash["LATENCY_TEST"] = "test/latency";
})(RouteHash || (RouteHash = {}));
