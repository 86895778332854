import { utils } from '@lightning/ui'

export const base = (theme) => ({
  titleStyle: theme.typography.display1,
  descriptionStyle: theme.typography.body1,
  descriptionMaxLines: 3,
  contentWidth: utils.getWidthByUpCount(theme, 2),
  titleLogoWidth: utils.getWidthByUpCount(theme, 3),
  padding: theme.layout.gutterY,
  buttonSectionPadding: theme.layout.gutterY,
})
