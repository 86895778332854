import { Router } from '@lightningjs/sdk'
import PageBase from './PageBase'
import XGLauncher from 'xfinity-games-launcher'
import { getGamePlayContainerId } from '../lib/gameplay/gameplayInitialization'
import FireboltAuthService from '../lib/firebolt/fireboltAuthService'
import { Sidekick } from '../components/sidekick/Sidekick'
import LunarLaunch from '../components/LunarTesting'

export default class EmulatorPage extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
    }
  }

  set params(args) {
    if (args.gameData) {
      this._game = args.gameData
    }
  }

  _init() {
    super._init()
    this._bindFunctions()
    this._romName = ''
    this._cloudDev = new URLSearchParams(window.location.search).get('cloudDev')
    if (this._cloudDev && (this._cloudDev === 'true' || this._cloudDev === 'on')) {
      this._cloudDev = true
    }
  }

  _bindFunctions() {
    this.logEvent = this.logEvent.bind(this)
    this.setupLogContainer = this.setupLogContainer.bind(this)
    this.updateTime = this.updateTime.bind(this)

  }

  updateTime() {
    if (document.getElementById("timerContainer") !== null) {
      const timerEl = document.getElementById("timerContainer")
      timerEl.textContent = Date.now()
    }
    requestAnimationFrame(this.updateTime);
  }
  logEvent(eventType, event, scrollContainer) {
    const textNode = document.createTextNode(`${eventType} at ${event.timeStamp}`)

    const logEntry = document.createElement('div')
    logEntry.appendChild(textNode)

    scrollContainer.appendChild(logEntry)

    scrollContainer.scrollTop = scrollContainer.scrollHeight
  }

  setupLogContainer() {
    const currContainer = document.getElementById('gamePlayContainer')
    let scrollContainer
    let timerContainer

    if (
      document.getElementById('timerContainer') === null ||
      document.getElementById('timerContainer') === undefined
    ) {
      timerContainer = document.createElement('timerContainer')
    } else {
      timerContainer = document.getElementById('timerContainer')
    }

    if (
      document.getElementById('eventScrollContainer') === null ||
      document.getElementById('eventScrollContainer') === undefined
    ) {
      scrollContainer = document.createElement('eventScrollContainer')
    } else {
      scrollContainer = document.getElementById('eventScrollContainer')
    }

    window.addEventListener('keydown', (event) => {
      this.logEvent('Keydown', event, scrollContainer)
      console.log('Keydown at', event.timeStamp)
    })

    window.addEventListener('keyup', (event) => {
      this.logEvent('Keyup', event, scrollContainer)
      console.log('Keyup at', event.timeStamp)
    })

    currContainer.style.color = 'red'
    timerContainer.style = "width: 300px; height: 40px; overflow-y: auto; border: 1px solid #ccc; padding: 10px; position: fixed; right: 20px; bottom: 120px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); background-color: white;"
    scrollContainer.style =
      'width: 300px; height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 10px; position: fixed; right: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); background-color: white;'
    currContainer.appendChild(scrollContainer)
    currContainer.appendChild(timerContainer)
  }

  _active() {
    const config = {
      target: 'lunar',
      containerId: getGamePlayContainerId(),
      // commenting this so it's forced to rerun
      canvasW: 1280,
      canvasH: 720,
      resource: '',
      ...(this._cloudDev && { cloudDev: this._cloudDev }), // conditionally pass cloudDev param if truthy
    }
    this._launcher = new LunarLaunch(config)
    this._setupEmulator()
    this.setupLogContainer()
    this.updateTime()
  }

  _handleKey(e) {
    this._launcher.gameCast.processKeyboardEvent(e)
    return false
  }

  _inactive() {
    !this._emulatorRemoved && this._removeEmulator()
  }

  _setupEmulator() {
    // this._launcher.gameCastInit()
    this.fireAncestors('$hideAppBackground')
    this._launcher.start()
  }

  _removeEmulator() {
    this.fireAncestors('$showAppBackground')
    this._launcher.stop()
    this._emulatorRemoved = true
  }

  _toggleSidekick() {
    if (this._sidekickOpen && this._Sidekick.alpha === 1) {
      this.$closeSidekick()
      return
    } else if (!this._sidekickOpen) {
      this._showSidekick()
    }
  }

  _showSidekick() {
    this._sidekickOpen = true
    this._launcher?.pause()
    // create Sidekick
    this.patch(
      {
        Sidekick: {
          type: Sidekick,
          alpha: 1,
          gameDetails: {
            emulator: true,
            name: this._game.name,
            author: this._game.publisher,
            imgSrc: this._game.imgSrc,
          },
          exitOverride: () => this._exit(),
          zIndex: 2,
        },
      },
      // createMode
      true
    )
  }

  $closeSidekick() {
    if (this._sidekickOpen) {
      this._unpatchSidekickTimeout = setTimeout(() => {
        this.patch({
          Sidekick: undefined,
        })
        this.fireAncestors('$restoreXNotificationsContainer')
      }, 500)
      this._sidekickOpen = false
      this._getFocused()
      this._launcher?.resume()
    }
  }

  _goBack() {
    this._toggleSidekick()
    return true
  }

  _exit() {
    this._removeEmulator()
    this.fireAncestors('$restoreXNotificationsContainer')
    Router.back()
  }

  _handleBack() {
    return this._goBack()
  }

  _handleLast() {
    return this._goBack()
  }

  _handleHome() {
    return true
  }

  _handleHomeRelease() {
    this._toggleSidekick()
  }

  _handleControllerB() {
    if (this._sidekickOpen) {
      this.$closeSidekick()
    }
    // stop propagation
    return true
  }

  _getFocused() {
    if (this._sidekickOpen) {
      return this._Sidekick
    }
  }

  get _Sidekick() {
    return this.tag('Sidekick')
  }
}
