import { TextBox, Icon, Base } from '@lightning/ui'
import * as styles from './Status.styles'

class Status extends Base {
  static get __componentName() {
    return 'Status'
  }

  static get __themeStyle() {
    return styles
  }

  static _template() {
    return {
      flex: { direction: 'row', alignItems: 'center' },
      mountX: 1,
      Logo: {
        type: Icon,
        alpha: 0,
        icon: '',
      },
      Clock: {
        type: TextBox,
        verticalAlign: 'bottom',
        signals: {
          textBoxChanged: '_update',
        },
        alpha: 0,
      },
      StatusIcon: {
        type: Icon,
        alpha: 0,
        icon: '',
      },
    }
  }

  static get properties() {
    return ['logo', 'time', 'statusIcon']
  }

  static get tags() {
    return ['Logo', 'Clock', 'StatusIcon']
  }

  _update() {
    super._update()
    this._updateHeaderLayout()
    this._updateContentPosition()
    this._updateStatusIcon()
    this._updateLogo()
    this._updateClock()
  }

  _updateContentPosition() {
    this.x = 1920 - 150
    this.y = 50
  }

  _updateHeaderLayout() {
    this.h = this.style.h
  }

  _updateStatusIcon() {
    if (this.statusIcon) {
      this._StatusIcon.patch({
        icon: this.statusIcon,
        alpha: 1,
        h: this.style.statusIconHeight,
        visible: true,
        flexItem: {
          marginLeft: this.style.itemPadding,
        },
      })
    } else {
      this._StatusIcon.patch({
        alpha: 0,
        w: 0,
        visible: false,
      })
    }
  }

  _updateLogo() {
    // Temporary fix for looping style updates
    if (this.logo) {
      if (this.logo !== this._Logo.icon) {
        this._Logo.patch({
          icon: this.logo,
          alpha: 1,
          visible: true,
          flexItem: {
            marginLeft: this.style.itemPadding,
          },
        })
      }
    } else {
      this._Logo.patch({
        alpha: 0,
        visible: false,
      })
    }
  }

  _updateClock() {
    if (this.time) {
      this._Clock.patch({
        alpha: 1,
        content: this.time,
        style: {
          textStyle: {
            ...this.style.timeTextStyle,
            textColor: this.style.timeTextColor,
          },
        },

        visible: true,
        flexItem: {
          marginLeft: this.style.itemPadding,
        },
      })
    } else {
      this._Clock.patch({
        alpha: 0,
        w: 0,
        visible: false,
      })
    }
  }
}

export default Status
