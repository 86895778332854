import { Base, InlineContent, ProgressBar, Row, TextBox } from '@lightning/ui'

import * as styles from './Hero.styles'
import XGButton from '../../XGButton'

export class CustomButton extends XGButton {
  // need to override _focus in the parent class because there's a bug where the title is blank on initial focus
  _focus() {
    this._focused = true
    this.mode = 'focused'
  }
}

class Hero extends Base {
  static get __componentName() {
    return 'Hero'
  }

  static get __themeStyle() {
    return styles
  }

  static _template() {
    return {
      flex: { direction: 'column' },
      TitleContainer: {
        ComponentLoose: false,
        Title: {
          type: TextBox,
          signals: {
            textBoxChanged: '_updateTitleHeight',
          },
        },
        TitleLogo: {},
      },
      Metadata: {
        visible: false,
        type: InlineContent,
        badgeProperties: {
          style: {
            strokeWidth: 0,
          },
        },
      },
      Description: {
        visible: false,
        type: TextBox,
      },
      ProgressBar: {
        visible: false,
        type: ProgressBar,
      },
      Buttons: {
        visible: false,
        type: Row,
        autoResizeHeight: true,
      },
      // Logos: {
      //   type: TBD
      // }
    }
  }

  static get properties() {
    return ['title', 'titleLogo', 'description', 'progress', 'metadata', 'buttons', 'logoArray']
  }

  static get tags() {
    return [
      'TitleContainer',
      { name: 'Title', path: 'TitleContainer.Title' },
      { name: 'TitleLogo', path: 'TitleContainer.TitleLogo' },
      'Description',
      'ProgressBar',
      'Metadata',
      'Buttons',
      'Logos',
    ]
  }

  _init() {
    super._init()
    this._TitleLogo.on('txLoaded', this._updateTitleLogoHeight.bind(this))
    this._TitleLogo.on('txError', () => {
      this.titleLogo = undefined
      this._updateTitleHeight({ h: this._Title.h })
      this._updateTitle()
    })
  }

  _update() {
    super._update()
    this._updateTitle()
    this._updateMetadata()
    this._updateDescription()
    this._updateProgress()
    this._updateButtons()
  }

  _updateTitleHeight({ h }) {
    if (!this.titleLogo) {
      this._TitleContainer.h = h
    }
  }

  _updateTitleLogoHeight() {
    this._TitleContainer.h = this._TitleLogo.renderHeight
  }

  _showFlexItem(element) {
    element.patch({ visible: true })
  }

  _hideFlexItem(element) {
    element.patch({ visible: false })
  }

  _applyPadding(element, margin = this.style.padding) {
    element.patch({ flexItem: { marginTop: margin } })
  }

  _updateTitle() {
    if (!this.titleLogo && !this.title) {
      this._hideFlexItem(this._TitleContainer)
      return
    }

    this._showFlexItem(this._TitleContainer)
    if (this.titleLogo) {
      this._Title.smooth = { alpha: 0 }
      this._TitleLogo.patch({
        src: this.titleLogo,
        w: this.style.titleLogoWidth,
      })
      this._TitleLogo.smooth = { alpha: 1 }
    } else {
      this._Title.smooth = { alpha: 1 }
      this._TitleLogo.smooth = { alpha: 0 }
      this._Title.patch({
        content: this.title,
        style: {
          textStyle: this.style.titleStyle,
        },
      })
    }
  }

  _updateMetadata() {
    if (!this.metadata) {
      this._hideFlexItem(this._Metadata)
      return
    }

    this._showFlexItem(this._Metadata)
    this._applyPadding(this._Metadata)
    this._Metadata.patch({
      content: this.metadata,
      w: this.style.contentWidth,
      justify: 'flex-start',
    })
  }

  _updateDescription() {
    if (!this.description) {
      this._hideFlexItem(this._Description)
      return
    }

    this._showFlexItem(this._Description)
    this._applyPadding(this._Description)
    this._Description.patch({
      content: this.description,
      style: {
        textStyle: {
          ...this.style.descriptionStyle,
          wordWrapWidth: this.style.contentWidth,
          maxLines: this.style.descriptionMaxLines,
        },
      },
    })
  }

  _updateProgress() {
    if (!this.progress) {
      this._hideFlexItem(this._ProgressBar)
    } else {
      this._showFlexItem(this._ProgressBar)
      this._applyPadding(this._ProgressBar)
    }

    this._ProgressBar.patch({
      w: this.style.contentWidth,
      progress: this.progress,
    })
  }

  _updateButtons() {
    if (!this.buttons) {
      this._hideFlexItem(this._Buttons)
      return
    }

    this._showFlexItem(this._Buttons)
    this._applyPadding(this._Buttons, this.style.buttonSectionPadding)

    const buttons = this.buttons.map((item) => ({
      type: XGButton,
      ...item,
    }))

    this._Buttons.patch({
      autoResize: true, // needed to make row height the same as buttons, but will prevent the row from scrolling
      items: buttons,
      itemSpacing: this.style.padding,
    })
  }

  _getFocused() {
    return this._Buttons
  }

  set announce(announce) {
    super.announce = announce
  }

  get announce() {
    if (this._announce) {
      return this._announce
    }
    return [
      this.title,
      this.description,
      this._Metadata.announce,
      this.progress ? `${this.progress * 100}'%'` : undefined,
    ]
  }
}

export default Hero
