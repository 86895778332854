import api from './api';
import { formatHeroRow, formatGames, formatLatencyTestTile } from '../../formatters/index';
import { RemoteConfig } from '../../lib/remoteConfig';
import FireboltAuthService from '../../lib/firebolt/fireboltAuthService';
import { GameCategories, GamesSectionHeadingText } from '../../constants/playProviderEnums';
import { sentenceCase } from '../../utils/sentenceCase';
import { Language, Storage } from '@lightningjs/sdk';
import { storageKeys } from '../../constants';
import GeneralAnalyticsService from '../../lib/analytics/generalAnalyticsService';
import { AnalyticsGeneralErrorCodes } from '../../lib/analytics/analyticsEnums';
import UserAccountProvider from './userAccountProvider';
import { isMultiplayer } from '../../utils/gameInfo';
import strictUriComponentEncode from '../../utils/strictUriComponentEncode';
export default class PlayProvider {
    constructor() {
        this._catalogRequestURL = `${RemoteConfig.getInstance().catalogURL}/${RemoteConfig.getInstance().catalogVersion}/`;
        this._historyRequestURL = `${RemoteConfig.getInstance().platformURL}/${UserAccountProvider.getInstance().gameplayStateId}${RemoteConfig.getInstance().gameHistoryPath}`;
        this._recentlyPlayed = [];
        this._allGames = [];
        this._gameCatalog = [];
        this._categories = [];
        this._heading = GamesSectionHeadingText.POPULARITY_SORT;
    }
    async getAllGames() {
        this._allGames = RemoteConfig.getInstance().retroModeFlag
            ? [] // don't make the catalog call in retroMode
            : await api
                .get(this._catalogRequestURL, undefined, { retry: 3, retryDelay: 4000 })
                .then((response) => {
                var _a, _b;
                const res = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.games) !== null && _b !== void 0 ? _b : [];
                res.length === 0 && (this._catalogFromCache = true);
                return res;
            })
                .catch((err) => {
                // in case of catalog error set this flag to true so we can pull data from
                // cache and alter layout of Play page
                this._catalogFromCache = true;
                console.error(`Error getting all games: ${JSON.stringify(err)}`);
                this._sendRequestFailedEvent(err.response);
            });
        if (this._catalogFromCache) {
            // get stored data, filter and combine it, and set all games
            const combined = this._filterAndCombine(Storage.get(storageKeys.CATALOG), Storage.get(storageKeys.RECENTLY_PLAYED));
            this._allGames = combined !== null && combined !== void 0 ? combined : [];
            this.filterByCategory(GameCategories.ALL_GAMES);
        }
        else {
            this._setAndSortCategories();
            this._gameCatalog = this._allGames;
        }
        // create the Map if there is game data
        if (this._allGames != null && this._allGames.length > 0) {
            this._allGamesMap = new Map(this._allGames.map((obj) => [obj.gamePlayTitle, obj]));
        }
    }
    _isPinnedCategory(category) {
        return (category === GameCategories.POPULAR ||
            category === GameCategories.MULTIPLAYER ||
            category === GameCategories.FAMILY_FRIENDLY);
    }
    _setAndSortCategories() {
        var _a, _b;
        // iterate through all games
        if ((_a = this._allGames) === null || _a === void 0 ? void 0 : _a.length) {
            for (let i = 0; i < this._allGames.length; i++) {
                const game = this._allGames[i];
                // if a game has categories iterate through them
                if ((_b = game.categories) === null || _b === void 0 ? void 0 : _b.length) {
                    for (let j = 0; j < game.categories.length; j++) {
                        let category = decodeURI(game.categories[j]); // in case a category is encoded, decode it for better UI formatting
                        // format the category name and add it to the categories array
                        // if it's not yet in it and if it's not a "pinned" category
                        if (category === GameCategories.RPG) {
                            category = category.toUpperCase();
                        }
                        else {
                            category = sentenceCase(category);
                        }
                        !this._isPinnedCategory(category) &&
                            !this._categories.includes(category) &&
                            this._categories.push(category);
                    }
                }
            }
            // sort the categories alphabetically
            this._categories.sort();
            // add "pinned" categories to beginning of array in requested order
            this._categories.unshift(GameCategories.ALL_GAMES, GameCategories.POPULAR, GameCategories.MULTIPLAYER, GameCategories.FAMILY_FRIENDLY);
            // if couchplay enabled, final "pinned" category should be couch games
            RemoteConfig.getInstance().couchplayEnabled &&
                this._categories.splice(4, 0, GameCategories.REMOTE_MULTI);
        }
    }
    _filterMultiplayer() {
        this._gameCatalog = this._gameCatalog.filter((game) => isMultiplayer(game));
    }
    _filterRemoteMulti() {
        this._gameCatalog = this._gameCatalog.filter((game) => {
            return game.remoteMultiPlayer;
        });
    }
    _storeTopPopularAndPromoted() {
        const topPopularGameData = this._gameCatalog.slice(0, 15);
        const promotedGameData = this.promotedGames.map((item) => {
            var _a;
            return item.gamePlayTitle && ((_a = this._allGamesMap) === null || _a === void 0 ? void 0 : _a.get(item.gamePlayTitle));
        });
        const combined = this._filterAndCombine(topPopularGameData, promotedGameData);
        if (this._needToStore(combined, storageKeys.CATALOG)) {
            Storage.set(storageKeys.CATALOG, combined);
        }
    }
    async filterByCategory(category) {
        if (category === GameCategories.ALL_GAMES) {
            // set game catalog to all games with corresponding heading and return early
            this._gameCatalog = this._allGames;
            this._heading = this._catalogFromCache
                ? GameCategories.ALL_GAMES
                : GamesSectionHeadingText.ALPHABETICAL_SORT;
            return;
        }
        else {
            // format the category to lowercase and if the category is multiplayer, we will
            // actually set the category to popular in order to first filter by popularity
            // then we will remove any 1 player games below
            let formattedCategory = category === GameCategories.MULTIPLAYER || category === GameCategories.REMOTE_MULTI
                ? GameCategories.POPULAR.toLowerCase()
                : category.toLowerCase();
            // if the category contains any non alphanumeric characters including whitespace, URL encode it
            const regex = /[^a-zA-Z\d]/g;
            if (regex.test(formattedCategory)) {
                formattedCategory = strictUriComponentEncode(formattedCategory);
            }
            this._gameCatalog = await api
                .get(this._catalogRequestURL, {
                queryParams: {
                    category: formattedCategory,
                },
            })
                .then((response) => response.data.games)
                .catch((err) => {
                console.error(`Error getting ${category} games: ${JSON.stringify(err)}`);
                this._sendRequestFailedEvent(err.response);
            });
        }
        if (category === GameCategories.POPULAR) {
            this._storeTopPopularAndPromoted();
        }
        // if category was multiplayer filter further
        if (category === GameCategories.MULTIPLAYER) {
            this._filterMultiplayer();
        }
        if (category === GameCategories.REMOTE_MULTI) {
            this._filterRemoteMulti();
        }
        // popularity sort heading is for all filters except "All games", so
        // reset it only when needed
        if (this._heading !== GamesSectionHeadingText.POPULARITY_SORT) {
            this._heading = GamesSectionHeadingText.POPULARITY_SORT;
        }
    }
    async loadRecentlyPlayed() {
        if (RemoteConfig.getInstance().retroModeFlag || !FireboltAuthService.getInstance().isHardware) {
            this._recentlyPlayed = [];
        }
        else {
            this._recentlyPlayed = await api
                .get(this._historyRequestURL, { authHeader: true })
                .then((response) => {
                if (!(response === null || response === void 0 ? void 0 : response.data)) {
                    return [];
                }
                return response.data
                    .map((gameObj) => {
                    return gameObj.partnerTitleId;
                })
                    .filter(Boolean);
            })
                .catch((err) => {
                console.error(`Error retrieving recent games from history: ${JSON.stringify(err)}`);
                this._sendRequestFailedEvent(err.response);
                return [];
            });
        }
        // assess if we should store recently played data each time it's fetched as long
        // as we're not using cached catalog data (meaning catalog API is not down)
        if (!this._catalogFromCache) {
            const recentData = this._recentlyPlayed.length > 0
                ? this._recentlyPlayed
                    .map((item) => {
                    var _a;
                    return (_a = this._allGamesMap) === null || _a === void 0 ? void 0 : _a.get(item);
                })
                    .filter(Boolean)
                : [];
            // only store if there's actually a difference
            if (this._needToStore(recentData, storageKeys.RECENTLY_PLAYED)) {
                Storage.set(storageKeys.RECENTLY_PLAYED, recentData);
            }
        }
    }
    _needToStore(currentData, storedData) {
        return JSON.stringify(currentData) !== JSON.stringify(Storage.get(storedData));
    }
    _filterAndCombine(mainArray, arrayToFilter) {
        const filteredArray = [];
        if (arrayToFilter != null && arrayToFilter.length > 0) {
            // if arrayToFilter is not falsy or empty array, iterate
            for (let i = 0; i < arrayToFilter.length; i++) {
                if (
                // if the items in array to filter does not yet exist in the main
                // array add it to filtered array
                mainArray.findIndex((main) => { var _a; return (main === null || main === void 0 ? void 0 : main.gamePlayTitle) === ((_a = arrayToFilter[i]) === null || _a === void 0 ? void 0 : _a.gamePlayTitle); }) < 0) {
                    filteredArray.push(arrayToFilter[i]);
                }
            }
        }
        // combine the main and filtered arrays and return result
        const combinedArray = filteredArray.length > 0 ? [...mainArray, ...filteredArray] : mainArray;
        return combinedArray;
    }
    getGamingRows() {
        const numGamesPerRow = 6;
        const games = formatGames(this._gameCatalog, numGamesPerRow);
        // add announce context for each game tile in the catalog
        games.forEach((row, rowIndex) => {
            row.items.forEach((game, index) => {
                game.announceContext = [
                    Language.translate('ANNOUNCE_LENGTH', index + 1 + numGamesPerRow * rowIndex, this._gameCatalog.length),
                    'PAUSE-2',
                ];
            });
        });
        return games;
    }
    getHeroContent() {
        var _a;
        // Filter out any recently played games from this list
        const promotedGames = this.promotedGames.filter((promotedGame) => {
            const promotedTitle = promotedGame === null || promotedGame === void 0 ? void 0 : promotedGame.gamePlayTitle;
            if (this._recentlyPlayed && promotedTitle) {
                return !this._recentlyPlayed.includes(promotedTitle);
            }
        });
        // find recent games in json
        const recent = this._recentlyPlayed // make sure to filter out recently played games that may no longer be in the catalog
            .filter((recentGameplayTitle) => {
            var _a;
            return ((_a = this._allGames) === null || _a === void 0 ? void 0 : _a.findIndex((gameDataObj) => (gameDataObj === null || gameDataObj === void 0 ? void 0 : gameDataObj.gamePlayTitle) === recentGameplayTitle)) !== -1;
        })
            .map((recentGameplayTitle) => {
            var _a;
            const gameData = (_a = this._allGames) === null || _a === void 0 ? void 0 : _a.find((gameData) => (gameData === null || gameData === void 0 ? void 0 : gameData.gamePlayTitle) === recentGameplayTitle);
            if (gameData) {
                gameData.descriptionOverride = 'Jump back in';
                gameData.isRecent = true;
            }
            return gameData;
        })
            .filter(Boolean);
        // find promoted games in json and add custom description
        const promoted = promotedGames.map((promotedGame) => {
            var _a;
            const gameData = (_a = this._allGames) === null || _a === void 0 ? void 0 : _a.find((gameData) => (gameData === null || gameData === void 0 ? void 0 : gameData.gamePlayTitle) === (promotedGame === null || promotedGame === void 0 ? void 0 : promotedGame.gamePlayTitle));
            gameData && (gameData.descriptionOverride = promotedGame.description.short);
            return gameData;
        });
        const playableGames = [...recent, ...promoted];
        const heroRow = playableGames.length > 0
            ? formatHeroRow(playableGames)
            : formatHeroRow([(_a = this._allGamesMap) === null || _a === void 0 ? void 0 : _a.values().next().value]); // if no playableGames return the first item in allGamesMap so hero row isn't blank
        heroRow.items = [...heroRow.items];
        !process.env.PROD && heroRow.items.splice(1, 0, formatLatencyTestTile()); // when not in a prod env, add the latency test tile as #2
        // Trim to max of 9 tiles
        const limit = Number(RemoteConfig.getInstance().gameHistoryLimit);
        if (heroRow.items.length > limit) {
            heroRow.items.length = limit;
        }
        // add announce context for each game tile in the hero row
        heroRow.items.forEach((item, index) => {
            item.announceContext = [
                Language.translate('ANNOUNCE_LENGTH', index + 1, heroRow.items.length),
                'PAUSE-2',
            ];
        });
        return heroRow;
    }
    _sendRequestFailedEvent(errorResponse) {
        const generalAnalyticsService = new GeneralAnalyticsService();
        generalAnalyticsService.sendGeneralError(AnalyticsGeneralErrorCodes.PERFORM_REQUEST_FAILED, errorResponse);
    }
    get promotedGames() {
        var _a;
        const promotedGames = (_a = this._allGames) === null || _a === void 0 ? void 0 : _a.filter((game) => {
            return Boolean(game === null || game === void 0 ? void 0 : game.promoted);
        });
        return promotedGames !== null && promotedGames !== void 0 ? promotedGames : [];
    }
    get recentlyPlayed() {
        return this._recentlyPlayed;
    }
    get categories() {
        return this._categories;
    }
    get heading() {
        return this._heading;
    }
    get allGames() {
        return this._allGames;
    }
    get allGamesMap() {
        return this._allGamesMap;
    }
    get gameCatalog() {
        return this._gameCatalog;
    }
    get catalogFromCache() {
        return this._catalogFromCache;
    }
    // Singleton
    static getInstance() {
        if (!PlayProvider._instance) {
            PlayProvider._instance = new PlayProvider();
        }
        return PlayProvider._instance;
    }
}
