import lng from '@lightningjs/core'
import { context, utils } from '@lightning/ui'
import fallbackImage from '../../static/images/gameBackgrounds/fallback-entity-background.png'
import { setImage } from '../utils/setImage'

export const HERO_ANIMATION = { duration: 0.4 }
export default class HeroBackground extends lng.Component {
  static _template() {
    return {
      w: context.theme.layout.screenW,
      h: context.theme.layout.screenH,
      rtt: true,
      BackgroundContainer: {
        w: context.theme.layout.screenW,
        h: context.theme.layout.screenH,
        Background: {
          Image1: {
            w: context.theme.layout.screenW,
            h: context.theme.layout.screenH,
            alpha: 0,
          },
          Image2: {
            w: context.theme.layout.screenW,
            h: context.theme.layout.screenH,
            alpha: 0,
          },
          Overlay: {
            rect: true,
            color: 0x3c141417,
            w: context.theme.layout.screenW,
            h: context.theme.layout.screenH,
            Gradient: {
              rect: true,
              colorTop: utils.getHexColor('#6138F5', 0),
              colorBottom: utils.getHexColor('#1B032D', 0.9),
              w: context.theme.layout.screenW,
              h: context.theme.layout.screenH,
            },
            GradientHorz: {
              w: 1280,
              h: context.theme.layout.screenH,
              rect: true,
              colorRight: utils.getHexColor('#6138F5', 0),
              colorLeft: utils.getHexColor('#5A23B9', 0.9),
            },
          },
        },
      },
    }
  }

  _init() {
    this._activeImage = this.tag('Image1')
    this._inactiveImage = this.tag('Image2')
  }

  set background(imageUrl) {
    // do not try to update the background image if it is already the right URL,
    // otherwise this will create blinking as the background continually tries to update
    // (as it will keep switching between the active and inactive images)
    if (this._activeImage.src !== imageUrl) {
      if (this._inactiveImage.src !== imageUrl) {
        setImage({
          url: imageUrl,
          loadCallback: () => {
            this._swapActiveImage(imageUrl)
          },
          errCallback: () => {
            this._swapActiveImage(fallbackImage)
          },
        })
      } else {
        this._swapActiveImage(imageUrl)
      }
    }
    this._backgroundImages.setSmooth('alpha', 1, HERO_ANIMATION)
  }

  _swapActiveImage(imageUrl) {
    this._inactiveImage.src = imageUrl
    this._inactiveImage.setSmooth('alpha', 1, HERO_ANIMATION)
    this._activeImage.setSmooth('alpha', 0, HERO_ANIMATION)

    let currentActiveImage = this._activeImage
    this._activeImage = this._inactiveImage
    this._inactiveImage = currentActiveImage
  }

  get background() {
    return this._activeImage.src
  }

  get _backgroundContainer() {
    return this.tag('BackgroundContainer')
  }

  get _backgroundImages() {
    return this.tag('BackgroundContainer.Background')
  }
}
