import ThunderJS from 'ThunderJS/module/thunderJS';
import { promiseRetryWithTimeout } from '../../utils/promise';
import { BluetoothMethods } from '../bluetooth/bluetoothServiceInterface';
const defaultThunderConfig = {
    host: '127.0.0.1',
    port: 9998,
    default: 1,
};
export default class Thunder {
    constructor() {
        this.bindFunctions();
    }
    bindFunctions() {
        this.activate = this.activate.bind(this);
        this.activateWithRetry = this.activateWithRetry.bind(this);
    }
    initialize() {
        // NOTE: doesn't work immediately; after loading thunderJS, need to wait a tick
        this._thunder = ThunderJS(defaultThunderConfig);
    }
    setConfig(config) {
        this._config = config;
        this._thunder = ThunderJS(this._config);
    }
    activate(callsign) {
        if (!this._thunder) {
            return Promise.reject(new Error('thunder has not been initialized yet'));
        }
        return this._thunder.call('Controller', BluetoothMethods.ACTIVATE, {
            callsign: callsign,
        });
    }
    async getMacAddress() {
        if (!this._thunder) {
            return Promise.reject(new Error('thunder has not been initialized yet'));
        }
        try {
            await this.activateWithRetry('org.rdk.System', 3, 500);
        }
        catch (err) {
            return {
                osVersion: '',
                error: `Error activating thunder DeviceInfo: ${JSON.stringify(err)}`,
            };
        }
        return this.call('org.rdk.System', 'getDeviceInfo')
            .then((thunderEstbMacResponse) => {
            return {
                estbMac: thunderEstbMacResponse.estb_mac,
                error: '',
            };
        })
            .catch((err) => {
            return {
                estbMac: undefined,
                error: `device info err: ${JSON.stringify(err)}`,
            };
        });
    }
    async getOsVersion() {
        if (!this._thunder) {
            return Promise.reject(new Error('thunder has not been initialized yet'));
        }
        try {
            await this.activateWithRetry('DeviceInfo', 3, 500);
        }
        catch (err) {
            return {
                osVersion: '',
                error: `Error activating thunder DeviceInfo: ${JSON.stringify(err)}`,
            };
        }
        return this.ThunderJS.DeviceInfo.firmwareversion()
            .then((result) => {
            return {
                osVersion: result.imagename,
                error: '',
            };
        })
            .catch((err) => {
            return {
                osVersion: '',
                error: `Error ${JSON.stringify(err)}`,
            };
        });
    }
    activateWithRetry(callsign, retryAttempts = 3, timeout = 1000) {
        return promiseRetryWithTimeout(this.activate(callsign), retryAttempts, timeout);
    }
    call(callsign, method, params = {}) {
        if (!this._thunder) {
            return Promise.reject(new Error('thunder has not been initialized yet'));
        }
        return this._thunder.call(callsign, method, params);
    }
    get ThunderJS() {
        return this._thunder;
    }
    // Singleton
    static getInstance() {
        if (!Thunder.instance) {
            Thunder.instance = new Thunder();
        }
        return Thunder.instance;
    }
}
