import lng from '@lightningjs/core'
import { Router } from '@lightningjs/sdk'
import { TextBox, context, utils } from '@lightning/ui'
import { NotificationType } from '../constants/notificationEnums'
import gamepad from '../assets/gamepad.js'
import { AxesDirectionalNames } from '../lib/controller/buttonMappingEnums'

const sticks = {
  LEFT: 'LStick',
  RIGHT: 'RStick',
}

const defaultPos = {
  LEFT_X: 113,
  LEFT_Y: 160,
  RIGHT_X: 278,
  RIGHT_Y: 238,
}

const axes = {
  Y: 'cy',
  X: 'cx',
}

const offsetVal = 8

const updateAttribute = {
  D: {
    attribute: axes.Y,
    operation: offsetVal,
  },
  U: {
    attribute: axes.Y,
    operation: -offsetVal,
  },
  R: {
    attribute: axes.X,
    operation: offsetVal,
  },
  L: {
    attribute: axes.X,
    operation: -offsetVal,
  },
}

export default class LatencyTestPage extends lng.Component {
  static _template() {
    return {
      Flash: {
        rect: true,
        color: utils.getHexColor('#FFFFFF'),
        w: 500,
        h: 500,
        x: 250,
        y: context.theme.layout.screenH / 2,
        mountY: 0.5,
        alpha: 0.001,
      },
      Text: {
        type: TextBox,
        content: 'Place sensor here',
        fixed: true,
        style: {
          textStyle: 'headline1',
        },
        w: 400,
        h: 400,
        x: 280,
        y: context.theme.layout.screenH / 2,
        mountY: 0.5,
      },
    }
  }

  _init() {
    this.fireAncestors('$hideLoading')
    this.fireAncestors('$hideAppBackground')
    const AC = window.AudioContext || window.webkitAudioContext
    if (AC) {
      this._audioCtx = new AC()
    } else {
      this.fireAncestors('$sendNotification', {
        type: NotificationType.AUDIO,
      })
    }
    this._directionsTimer = setTimeout(() => {
      this._Text.alpha = 0
    }, 10000)
    this._createGamepadSvg()
  }

  _inactive() {
    this.fireAncestors('$showAppBackground')
    this._container?.remove()
    clearTimeout(this._directionsTimer)
    clearTimeout(this._toneTimer)
    clearInterval(this._toneInterval)
    this._resetFlashLoop()
  }

  _createGamepadSvg() {
    const dim = 400
    this._container = document.createElement('div')
    this._container.id = 'gamepad-container'
    this._container.setAttribute(
      'style',
      `width: ${dim}px; height: ${dim}px; position: absolute; left: ${
        this.stage.w - dim - 250
      }px; top: 200px; text-align: center;`
    )
    this._container.innerHTML = gamepad
    document.body.appendChild(this._container)
  }

  _countTone() {
    this._count = 0
    this._playTone()
    this._toneInterval && clearInterval(this._toneInterval)
    this._toneInterval = setInterval(() => {
      this._playTone()
    }, 1000)
  }

  _playTone() {
    this._oscillator = this._audioCtx?.createOscillator()
    this._oscillator?.connect(this._audioCtx?.destination)
    this._oscillator?.start()
    this._toneTimer = setTimeout(() => {
      this._oscillator?.stop()
      this._oscillator = undefined
      this._count++
      if (this._count === 6) {
        this._resetFill()
        clearInterval(this._toneInterval)
        clearTimeout(this._toneTimer)
      }
    }, 350)
  }

  _flash(timestamp) {
    const duration = 3000
    const runtime = timestamp - this._starttime
    this._Flash.alpha = 1
    this._isPlaying = true
    if (runtime < duration) {
      this._flashLoop()
    } else {
      this._Flash.alpha = 0.001
      this._resetFlashLoop()
      this._isPlaying = false
    }
  }

  _resetFlashLoop() {
    this._starttime = 0
    cancelAnimationFrame(this._imageLoop)
  }

  _flashLoop() {
    requestAnimationFrame((timestamp) => {
      !this._starttime && (this._starttime = timestamp)
      this._flash(timestamp)
    })
  }

  _changeFillColor(color) {
    this._keyElement.setAttribute('fill', `rgba(${color})`)
  }

  _fillPurple() {
    this._changeFillColor('97,56,246,1')
  }

  _resetFill() {
    this._changeFillColor('0,0,0,0')
    if (this._keyId === sticks.LEFT || this._keyId === sticks.RIGHT) {
      this._resetStick()
    }
    this._keyId = null
  }

  _showStickMovement(code) {
    code = code.slice(-1)
    const attribute = updateAttribute[code].attribute
    this._defaultPosition = parseFloat(this._keyElement.getAttribute(attribute))
    this._keyElement.setAttribute(
      attribute,
      this._defaultPosition + updateAttribute[code].operation
    )
  }

  _resetStick() {
    this._keyElement.setAttribute(
      axes.X,
      this._keyId === sticks.LEFT ? defaultPos.LEFT_X : defaultPos.RIGHT_X
    )
    this._keyElement.setAttribute(
      axes.Y,
      this._keyId === sticks.LEFT ? defaultPos.LEFT_Y : defaultPos.RIGHT_Y
    )
  }

  _recognizeStick(code) {
    switch (code) {
      case AxesDirectionalNames.LEFT_UP:
      case AxesDirectionalNames.LEFT_DOWN:
      case AxesDirectionalNames.LEFT_LEFT:
      case AxesDirectionalNames.LEFT_RIGHT:
        this._onKeyDown(sticks.LEFT, code)
        return true
      case AxesDirectionalNames.RIGHT_UP:
      case AxesDirectionalNames.RIGHT_DOWN:
      case AxesDirectionalNames.RIGHT_LEFT:
      case AxesDirectionalNames.RIGHT_RIGHT:
        this._onKeyDown(sticks.RIGHT, code)
        return true
      default:
        return false
    }
  }

  _onKeyDown(id, code) {
    if (!this._isPlaying && !this._keyId) {
      this._keyId = id
      this._fillPurple()
      this._countTone()
      this._imageLoop = this._flashLoop()
      code && this._showStickMovement(code)
    }
  }

  _handleUp(e) {
    this._recognizeStick(e.code) || this._onKeyDown('Up')
  }

  _handleDown(e) {
    this._recognizeStick(e.code) || this._onKeyDown('Down')
  }

  _handleLeft(e) {
    this._recognizeStick(e.code) || this._onKeyDown('Left')
  }

  _handleRight(e) {
    this._recognizeStick(e.code) || this._onKeyDown('Right')
  }

  _handleEnter() {
    this._onKeyDown('A')
  }

  _handleControllerB() {
    this._onKeyDown('B')
  }

  _handleControllerX() {
    this._onKeyDown('X')
  }

  _handleControllerY() {
    this._onKeyDown('Y')
  }

  _handleLeftBumper() {
    this._onKeyDown('LB')
  }

  _handleRightBumper() {
    this._onKeyDown('RB')
  }

  _handleLeftTrigger() {
    this._onKeyDown('LT')
  }

  _handleRightTrigger() {
    this._onKeyDown('RT')
  }

  _handleHome() {
    this._onKeyDown('Home')
  }

  _handleSelect() {
    this._onKeyDown('Select')
  }

  _handleStart() {
    this._onKeyDown('Start')
  }

  _handleLast() {
    Router.back()
  }

  _handleBack(e) {
    e.preventDefault()
    Router.back()
  }

  get _Flash() {
    return this.tag('Flash')
  }

  get _Text() {
    return this.tag('Text')
  }

  get _keyElement() {
    return document.getElementById(this._keyId)
  }
}
