var _a;
import * as stagingConfig from '../../appconfig/defaultConfigStaging.json';
import * as prodConfig from '../../appconfig/defaultConfigProduction.json';
import api from '../api/providers/api';
class AnalyticsConfig {
}
class ApplicationConfig {
}
class BluetoothConfig {
}
class CatalogConfig {
}
class DocumentsConfig {
}
class GameplayConfig {
}
class InputConfig {
}
export class Config {
}
export var MockBluetoothType;
(function (MockBluetoothType) {
    MockBluetoothType["NONE"] = "none";
    MockBluetoothType["FAKE_CONTROLLERS"] = "fakeControllers";
    MockBluetoothType["MOCK_SERVER"] = "mockServer";
})(MockBluetoothType || (MockBluetoothType = {}));
const isProd = Boolean((_a = process.env.CONFIG_URL) === null || _a === void 0 ? void 0 : _a.includes('prod'));
export const DefaultConfig = isProd ? prodConfig : stagingConfig;
export class RemoteConfig {
    get config() {
        if (this._config) {
            return this._config;
        }
        else {
            console.warn('config has not been set yet');
            return DefaultConfig;
        }
    }
    setConfig(config) {
        this._config = config;
        this._setDevTeamAccess();
        this._setCatalogVersion();
    }
    async _setDevTeamAccess() {
        const inactiveProdFlavor = this.retroModeFlag
            ? undefined
            : await api
                .get('https://meta.np.gpe.xfinity.com/app/gamex-client/inactive')
                .then((res) => {
                return res.data;
            })
                .catch((err) => {
                console.error(err);
            });
        const devTeamAccessParam = new URLSearchParams(window.location.search).get('devTeamAccess');
        const paramIsOn = devTeamAccessParam === 'true' || devTeamAccessParam === 'on';
        const isInactiveProd = process.env.PROD && process.env.APP_FLAVOR === inactiveProdFlavor;
        // in non prod envs or inactive prod env, look for access param value and set accordingly
        if ((!process.env.PROD || isInactiveProd) && paramIsOn) {
            this._devTeamAccess = true;
        }
    }
    _setCatalogVersion() {
        const catalogVersion = new URLSearchParams(window.location.search).get('catalogVersion');
        const regex = /^v\d|\.\d/g;
        // if catalogVersion param exists and is formatted vX or vX.X (where X is 0-9) when in non-prod env
        if (catalogVersion && regex.test(catalogVersion) && !process.env.PROD) {
            this._catalogVersion = catalogVersion;
        }
    }
    get analyticsEndpoint() {
        return this.config.analytics.analyticsEndpoint;
    }
    get heartbeatInterval() {
        return this.config.analytics.heartbeatInterval;
    }
    get logging() {
        return this.config.app.logging;
    }
    get skipPostgameSurvey() {
        return this.config.app.skipPostgameSurvey;
    }
    get skipWelcomeScreen() {
        return this.config.app.skipWelcomeScreen;
    }
    get platformURL() {
        return this.config.app.platformURL;
    }
    get capacityCheckPath() {
        return this.config.app.capacityCheckPath;
    }
    get userAccountPath() {
        return this.config.app.userAccountPath;
    }
    get welcomeUserPath() {
        return this.config.app.welcomeUserPath;
    }
    get contentControlPath() {
        return this.config.app.contentControlPath;
    }
    get noAccessFlag() {
        return this._devTeamAccess ? false : this.config.app.noAccess;
    }
    get retroModeFlag() {
        return this.config.app.retroMode;
    }
    get analyticsOff() {
        return this.config.analytics.analyticsOff;
    }
    get storedRouteClearCacheAt() {
        return this.config.app.storedRouteClearCacheAt;
    }
    get mockBTServer() {
        return this.config.bluetooth.serverURL;
    }
    get mockBTType() {
        return this.config.bluetooth.mockBTType;
    }
    get catalogURL() {
        return this.config.catalog.catalogURL;
    }
    get imagesURL() {
        return this.config.catalog.imagesURL;
    }
    get catalogVersion() {
        return this._catalogVersion || this.config.catalog.catalogVersion;
    }
    get gameplayIdleTimeout() {
        return this.config.gameplay.idleTimeout;
    }
    get gameHistoryPath() {
        return this.config.gameplay.gameHistoryPath;
    }
    get gameHistoryLimit() {
        return this.config.gameplay.gameHistoryLimit;
    }
    get couchplayEnabled() {
        return this.config.gameplay.couchplayEnabled;
    }
    get inputAxisThreshold() {
        return this.config.input.axisThreshold;
    }
    get inputHoldTimeout() {
        return this.config.input.holdTimeout;
    }
    get inputFilterSelectDebounce() {
        return this.config.input.filterSelectDebounce;
    }
    get partners() {
        return this.config.partners;
    }
    get documentsBaseURL() {
        return this.config.documents.documentsBaseURL;
    }
    get helpAndSupportPath() {
        return this.config.documents.helpPath;
    }
    get noticesPath() {
        return this.config.documents.noticesPath;
    }
    get learnPartyPath() {
        return this.config.documents.learnPartyPath;
    }
    get esrbRatingPath() {
        return this.config.documents.esrbRatingPath;
    }
    get documentsResourcePath() {
        return this.config.documents.resourcePath;
    }
    get welcomeImgPath() {
        return this.config.documents.welcomeImgPath;
    }
    static getInstance() {
        if (!RemoteConfig._instance) {
            RemoteConfig._instance = new RemoteConfig();
        }
        return RemoteConfig._instance;
    }
}
