import { ControllerService } from '../controller/controllerService';
import { BluetoothWrapper } from './bluetoothWrapper';
import { BluetoothEventTypes, BluetoothStatus } from './bluetoothServiceInterface';
import FireboltAuthService from '../firebolt/fireboltAuthService';
export default class ControllerConnectionChange {
    constructor(updateCallback) {
        this._updateCallback = updateCallback;
        this._bindFunctions();
        this._addListener();
    }
    _bindFunctions() {
        this._addListener = this._addListener.bind(this);
        this.removeListener = this.removeListener.bind(this);
        this._onConnectionChangeCallback = this._onConnectionChangeCallback.bind(this);
        this.renderWithControllerData = this.renderWithControllerData.bind(this);
    }
    _addListener() {
        ControllerService.getInstance().addOnConnectionChangeCallback(this.renderWithControllerData);
        BluetoothWrapper.getInstance().addListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._onConnectionChangeCallback);
    }
    removeListener() {
        ControllerService.getInstance().removeOnConnectionChangeCallback(this.renderWithControllerData);
        BluetoothWrapper.getInstance().removeListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._onConnectionChangeCallback);
    }
    _onConnectionChangeCallback(event) {
        if (event.newStatus && event.newStatus === BluetoothStatus.CONNECTION_CHANGE) {
            this.renderWithControllerData();
        }
    }
    renderWithControllerData() {
        var _a;
        const connectedController = navigator && navigator.getGamepads
            ? (_a = navigator.getGamepads()) === null || _a === void 0 ? void 0 : _a.find((controller) => controller === null || controller === void 0 ? void 0 : controller.connected)
            : undefined;
        if (!connectedController) {
            // Check RDK to see if controller is connected through RDK
            BluetoothWrapper.getInstance()
                .getConnectedDevices()
                .then((connectedControllers) => {
                if (connectedControllers.length > 0) {
                    this._updateCallback(true);
                }
                else {
                    this._updateCallback(false);
                }
            })
                .catch((err) => {
                FireboltAuthService.getInstance().isHardware &&
                    console.error(`Unable to get connected devices: ${JSON.stringify(err)}`);
                this._updateCallback(false);
            });
        }
        else {
            this._updateCallback(true);
        }
    }
}
