export class Rdk {
}
export class PhysicalDeviceDetails {
}
export class FireboltNetwork {
}
export class CustomerAccountDetails {
}
export var Platform;
(function (Platform) {
    Platform["RDK"] = "RDK";
})(Platform || (Platform = {}));
