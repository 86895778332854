export class HeartbeatEvent {
}
export var CouchMode;
(function (CouchMode) {
    CouchMode["HOST"] = "host";
    CouchMode["PLAYER"] = "player";
})(CouchMode || (CouchMode = {}));
export class CouchplayDetails {
    constructor(couchMode, code) {
        this.couchMode = couchMode;
        this.code = code;
    }
}
export class GameDetails {
}
export class GameTitle {
}
export var GameplayEndReason;
(function (GameplayEndReason) {
    GameplayEndReason["USER"] = "user";
    GameplayEndReason["IDLE"] = "idle";
    GameplayEndReason["ERROR"] = "error";
    GameplayEndReason["HOST_END_PARTY"] = "hostEndParty";
})(GameplayEndReason || (GameplayEndReason = {}));
export class GameplayEndEvent {
}
export class VideoMetrics {
    constructor(resolutionX, resolutionY, framerate, bitrate) {
        this.resolutionX = resolutionX;
        this.resolutionY = resolutionY;
        this.framerate = framerate;
        this.bitrate = bitrate;
    }
}
export class GameSessionDetails {
    constructor(gameLabel, gameName, partnerGameSessionId, gameSessionId, videoMetrics, ping) {
        this.gameLabel = gameLabel;
        this.gameName = gameName.toLowerCase().replace(/\s+/g, '');
        this.partnerGameSessionId = partnerGameSessionId;
        this.gameSessionId = gameSessionId;
        this.videoMetrics = videoMetrics;
        this.ping = ping;
    }
}
export class GameplayError {
    constructor(error, code, bucket) {
        this.name = error.name;
        this.message = error.message;
        this.code = code;
        this.bucket = bucket;
    }
}
