import { SessionCodePanel } from './SessionCodePanel'
import { CouchplayPanelNames } from '../panelNames'
import { EndPartyConfirmationPanel, LeavePartyConfirmationPanel } from './ConfirmationPanels'
import { ManagePlayerPanel } from './ManagePlayerPanel'
import { PanelTemplate } from '../PanelTemplate'
import { DefaultHostPanel } from './DefaultHostPanel'
import { DefaultPlayerPanel } from './DefaultPlayerPanel'

export class CouchplayPanel extends PanelTemplate {
  _active() {
    super._active()
    this._patchDefault()
  }

  _patchDefault() {
    if (!this.gamingClient || !this.gamingClient.partyIsOngoing) {
      this.$showCouchplayPanel(CouchplayPanelNames.DEFAULT_HOST)
    } else if (this.gamingClient?.isHost && this.gamingClient.partyIsOngoing) {
      this.$showCouchplayPanel(CouchplayPanelNames.SESSION_CODE)
    } else if (!this.gamingClient?.isHost) {
      this.$showCouchplayPanel(CouchplayPanelNames.DEFAULT_PLAYER)
    }
  }

  $showCouchplayPanel(panelName, details) {
    switch (panelName) {
      case CouchplayPanelNames.SESSION_CODE:
        this.patch({
          items: [
            {
              type: SessionCodePanel,
              gamingClient: this.gamingClient,
              details: details,
            },
          ],
        })
        break
      case CouchplayPanelNames.DEFAULT_HOST:
        this.patch({
          items: [
            {
              type: DefaultHostPanel,
              gamingClient: this.gamingClient,
              gameHasCouchplay: this.gameHasCouchplay,
              details: details,
            },
          ],
        })
        break
      case CouchplayPanelNames.DEFAULT_PLAYER:
        this.patch({
          items: [
            {
              type: DefaultPlayerPanel,
              gamingClient: this.gamingClient,
              details: details,
            },
          ],
        })
        break
      case CouchplayPanelNames.LEAVE_PARTY_CONFIRMATION:
        this.patch({
          items: [
            {
              type: LeavePartyConfirmationPanel,
              gamingClient: this.gamingClient,
              details: details,
            },
          ],
        })
        break
      case CouchplayPanelNames.END_PARTY_CONFIRMATION:
        this.patch({
          items: [
            {
              type: EndPartyConfirmationPanel,
              gamingClient: this.gamingClient,
              details: details,
            },
          ],
        })
        break
      case CouchplayPanelNames.MANAGE_PLAYER:
        this.patch({
          items: [
            {
              type: ManagePlayerPanel,
              gamingClient: this.gamingClient,
              details: details,
            },
          ],
        })
        break
    }
  }

  showPanel() {
    this.setSmooth('alpha', 1, { duration: 0.3 })
  }

  hidePanel() {
    this.alpha = 0
  }
}
