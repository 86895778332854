import { TextBox, context } from '@lightning/ui'
import { CouchplayPanelNames } from '../panelNames'
import { GameplayEndReason } from '../../../lib/gameplay/gameplayInterface'
import { PanelTemplate } from '../PanelTemplate'
import { CustomButton } from '../../suite/Hero/Hero'
import { corner } from '../../XGButton'

export class LeavePartyConfirmationPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Are you sure you want to leave the party?',
          centerInParent: true,
          extraItemSpacing: 32,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          content: `If you want to join this party again, you'll need the latest party code.`,
          centerInParent: true,
          extraItemSpacing: 50,
          style: {
            textStyle: {
              ...context.theme.typography.body2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: CustomButton,
          rounded: corner.topRight,
          centerInParent: true,
          title: 'Leave the party',
          w: 386,
          h: 80,
          extraItemSpacing: 16,
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeft,
          centerInParent: true,
          title: 'Cancel',
          w: 386,
          h: 80,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._LeavePartyBtn.patch({
      onEnter: () => {
        this.fireAncestors('$endGame', GameplayEndReason.USER)
        this.fireAncestors('$closeSidekick')
      },
    })
    this._CancelBtn.patch({
      onEnter: () => {
        this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.DEFAULT_PLAYER)
      },
    })
  }

  get _LeavePartyBtn() {
    return this.items[2]
  }

  get _CancelBtn() {
    return this.items[3]
  }
}

export class EndPartyConfirmationPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Are you sure you want to end the party?',
          centerInParent: true,
          extraItemSpacing: 32,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          content: 'Ending the party will remove all other players from the game.',
          centerInParent: true,
          extraItemSpacing: 50,
          style: {
            textStyle: {
              ...context.theme.typography.body2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: CustomButton,
          rounded: corner.topRight,
          centerInParent: true,
          title: 'End the party',
          w: 386,
          h: 80,
          extraItemSpacing: 16,
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeft,
          centerInParent: true,
          title: 'Cancel',
          w: 386,
          h: 80,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._endParty = this._endParty.bind(this)

    this._EndPartyBtn.patch({
      onEnter: this._endParty,
    })

    this._CancelBtn.patch({
      onEnter: () => {
        this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.SESSION_CODE)
      },
    })
  }

  async _endParty() {
    this._EndPartyBtn.patch({
      mode: 'disabled',
      title: 'Ending the party...',
      onEnter: () => {},
    })

    try {
      await this.gamingClient.endParty()
      this.fireAncestors('$updateSessionIndicator')
      this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.DEFAULT_HOST)
    } catch (e) {
      console.error(e)
      this._EndPartyBtn.patch({
        mode: 'focused',
        title: 'End the party',
        onEnter: this._endParty,
      })
    }
  }

  get _EndPartyBtn() {
    return this.items[2]
  }

  get _CancelBtn() {
    return this.items[3]
  }
}
