import { Router } from '@lightningjs/sdk'
import strictUriComponentEncode from '../utils/strictUriComponentEncode'
import PageBase from './PageBase'
import { NotificationType } from '../constants/notificationEnums'
import DialpadColumn from '../components/DialpadColumn'
import { RouteHash } from '../constants/routeEnums'
import XGButton, { corner } from '../components/XGButton'
import PlayProvider from '../api/providers/playProvider'
import UserAccountProvider from '../api/providers/userAccountProvider'

export default class Couchplay extends PageBase {
  static _template() {
    return {
      ...super._template(),
      Dialpad: {
        type: DialpadColumn,
        title: 'Join the party!',
        subtitle: 'Got a code from a friend? Enter it and play Xfinity Games together.',
        performControllerCheck: true,
        secondaryCta: {
          type: XGButton,
          title: 'Learn about party mode',
          w: 416,
          fixed: true,
          onEnter: () => Router.navigate(RouteHash.PARTY_MODE_LEARN, { keepAlive: true }),
          rounded: corner.bottomLeft,
        },
      },
    }
  }

  historyState() {
    if (!this._NavDrawer.expanded) {
      return {
        breadcrumbTitle: 'Join party',
      }
    }
  }

  _bindFunctions() {
    this.startSession = this.startSession.bind(this)
  }

  _init() {
    super._init()
    this._bindFunctions()
    this._Dialpad.patch({
      primaryCta: {
        title: 'Join a party',
        onEnter: () => this.startSession(),
      },
      focusNav: () => super._handleLeft(),
      goBack: (e) => super._handleBack(e),
    })
  }

  async startSession() {
    this._Dialpad.showSpinner('Joining the party')
    this._code = this._Dialpad.inputText

    // TODO: placeholder for now
    setTimeout(() => {
      if (this._code?.length === 0) {
        // treat err as a 400 if no code was entered
        this._codeErrorStatus = 400
      } else {
        this._codeErrorStatus = 404
      }

      this._Dialpad.setError(this._codeErrorStatus)
      // reset the status after dispatching the error
      this._codeErrorStatus = undefined
    }, 500)
  }

  get _NavDrawer() {
    return this.widgets.navigation
  }

  get _Dialpad() {
    return this.tag('Dialpad')
  }

  _getFocused() {
    return this._Dialpad
  }
}
