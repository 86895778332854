import api from './api';
export default class ArcadeProvider {
    async getAllGames() {
        this._emulatorData = await api
            .get('https://cdn2.np.gpe.xfinity.com/emulatorData.json')
            .then((response) => {
            return response.data;
        })
            .catch((err) => {
            console.error(`Error getting arcade data: ${JSON.stringify(err)}`);
            return [];
        });
    }
    get emulatorData() {
        return this._emulatorData;
    }
    // Singleton
    static getInstance() {
        if (!ArcadeProvider._instance) {
            ArcadeProvider._instance = new ArcadeProvider();
        }
        return ArcadeProvider._instance;
    }
}
