import PageBase from '../PageBase'
import ContentControlsList from '../../components/ContentControlsList'
import { Column, TextBox, context } from '@lightning/ui'
import XGButton, { corner } from '../../components/XGButton'
import { Router, Language } from '@lightningjs/sdk'
import { RouteHash } from '../../constants/routeEnums'
import CodeGenerator from '../../lib/codeGenerator'
import UserAccountProvider from '../../api/providers/userAccountProvider'
import { NotificationType } from '../../constants/notificationEnums'
import * as KeyboardEvents from '../../lib/controller/keyboardEvents'
import dataLayerAnalytics from '../../lib/analytics/dataLayerAnalyticsService'

export default class UpdateContentControls extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: true,
      Title: {
        type: TextBox,
        x: 562,
        y: 48,
        w: 812,
        content: 'Content controls',
        style: {
          textStyle: {
            ...context.theme.typography.display2,
          },
        },
        skipFocus: true,
      },
      Subtitle: {
        type: TextBox,
        w: 830,
        x: context.theme.layout.screenW / 2 - 8,
        mountX: 0.5,
        y: 176,
        style: {
          textStyle: {
            ...context.theme.typography.body3,
            wordWrap: true,
            wordWrapWidth: 830,
          },
        },
      },
      ButtonsContainer: {
        type: Column,
        w: 815,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        y: 273,
        neverScroll: true,
        alpha: 0.001,
        style: { itemTransition: { duration: 0 } },
        items: [
          {
            type: ContentControlsList,
            extraItemSpacing: 60,
            skipFocus: true,
          },
          {
            type: XGButton,
            rounded: corner.bottomLeftAndTopRight,
            title: 'Unlock to make changes',
            centerInParent: true,
            alpha: 1,
          },
          {
            type: TextBox,
            content: `A one-time code will be sent to the account holder's mobile number.`,
            style: {
              textStyle: {
                ...context.theme.typography.caption1,
              },
            },
            skipFocus: true,
            centerInParent: true,
            alpha: 1,
          },
        ],
      },
    }
  }

  _onDataProvided() {
    dataLayerAnalytics.processComplete()
  }

  historyState() {
    return {
      breadcrumbTitle: 'Content controls',
    }
  }

  _getSubtitleCopy() {
    let numberCopy
    if (!UserAccountProvider.getInstance().mobilePhone) {
      numberCopy = `the account holder's mobile number.`
    } else {
      numberCopy = `the phone number (***) ***-${UserAccountProvider.getInstance().mobilePhone?.slice(
        -4
      )}`
    }
    return `To play a game with a locked rating, the player will need to enter a code sent to ${numberCopy}`
  }

  _init() {
    super._init()
    this._ButtonsContainer.setSmooth('alpha', 1, { delay: 0.7, duration: 0.5 })
    this._ButtonsContainer.selectedIndex = 1
    this._UnlockButton.announceContext = [
      Language.translate('ANNOUNCE_LENGTH', 1, 1),
      this._UnlockHelperText.content,
      'PAUSE-2',
    ]
  }

  _active() {
    this._Subtitle.patch({ content: this._getSubtitleCopy() })
    this.announce = [this._Title.content, this._Subtitle.content]
    if (CodeGenerator.getInstance().codeChallengePassed) {
      this._hideUnlockButtonAndText()
    } else {
      this._UnlockButton.patch({
        onEnter: () => {
          if (!UserAccountProvider.getInstance().mobilePhone) {
            Router.navigate(RouteHash.CONTENT_CONTROLS + RouteHash.NO_PHONE_CONTENT_CONTROLS_PATH)
          } else {
            this._createCodeAndSendText()
          }
        },
      })
    }
  }

  _createCodeAndSendText() {
    this._ContentControlsList.updateRatingsButtonsMode()
    CodeGenerator.getInstance().generateCode()
    UserAccountProvider.getInstance()
      .unlockUserAccount({
        code: CodeGenerator.getInstance().code,
      })
      .catch(() => {
        this.fireAncestors('$sendNotification', {
          type: NotificationType.SEND_CODE_FAILED,
        })
      })
    this._navigateToCodeEntry()
  }

  _navigateToCodeEntry() {
    const nextRoutePath = RouteHash.CONTENT_CONTROLS + RouteHash.ENTER_CODE_CONTENT_CONTROLS_PATH
    Router.navigate(
      CodeGenerator.getInstance().codeEntryDev
        ? `${nextRoutePath}/${CodeGenerator.getInstance().code}`
        : nextRoutePath,
      {
        keepAlive: true,
      }
    )
  }

  _detach() {
    // Reset this when the page is detach (this will be on navigation to anywhere but CodeEntry page due to 'keepAlive')
    CodeGenerator.getInstance().codeChallengePassed = false
  }

  get _Title() {
    return this.tag('Title')
  }

  get _Subtitle() {
    return this.tag('Subtitle')
  }

  get _ButtonsContainer() {
    return this.tag('ButtonsContainer')
  }

  get _ContentControlsList() {
    return this._ButtonsContainer.items[0]
  }

  get _UnlockButton() {
    return this._ButtonsContainer.items[1]
  }

  get _UnlockHelperText() {
    return this._ButtonsContainer.items[2]
  }

  _getFocused() {
    return this._ButtonsContainer
  }

  _hideUnlockButtonAndText() {
    this._UnlockHelperText.alpha = 0
    this._UnlockButton.patch({ alpha: 0, skipFocus: true, announceContext: [] })
    this._ContentControlsList.patch({ skipFocus: false })
    this._ButtonsContainer.selectedIndex = 0
  }

  _updateSettingsAndGoBack(event) {
    UserAccountProvider.getInstance()
      .updateUserAccount(this._ContentControlsList.currentContentControls)
      .then(() => {
        this.fireAncestors('$sendNotification', {
          type: NotificationType.CHANGES_SAVED,
        })
      })
      .catch((err) => {
        console.error(`Update content controls settings error ${JSON.stringify(err)}`)
        this.fireAncestors('$sendNotification', {
          type: NotificationType.CHANGES_FAILED,
        })
      })
      .finally(() => {
        switch (event.keyCode) {
          case KeyboardEvents.homeEvent.keyCode:
            super._handleHome()
            return
          default:
            super._handleBack(event)
            return
        }
      })
  }

  get _saveUpdates() {
    return this._UnlockButton.alpha === 0 && CodeGenerator.getInstance().codeChallengePassed
  }

  _handleBack(e) {
    this._saveUpdates ? this._updateSettingsAndGoBack(e) : Router.back()
  }

  _handleLast(e) {
    this._handleBack(e)
  }

  _handleControllerB(e) {
    this._handleBack(e)
  }

  _handleHome(e) {
    this._saveUpdates ? this._updateSettingsAndGoBack(e) : super._handleHome()
  }
}
