import { Column } from '@lightningjs/ui-components'

export class PanelTemplate extends Column {
  static _template() {
    return {
      ...super._template(),
      subTheme: 'Sidekick',
      style: { ...super._template().style, itemSpacing: 0 },
      w: 420,
      skipFocus: true,
      items: [],
    }
  }
}
