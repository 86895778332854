import { Column, Icon, Row, TextBox, context } from '@lightning/ui'
import { CouchplayPanelNames } from '../panelNames'
import { PanelTemplate } from '../PanelTemplate'
import { playerIcons } from '../../../formatters/notifications'
import { CustomButton } from '../../suite/Hero/Hero'
import { corner } from '../../XGButton'

export class DefaultPlayerPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Party mode',
          extraItemSpacing: 48,
          style: {
            textStyle: context.theme.typography.headline2,
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          content: 'Players 1/4',
          extraItemSpacing: 24,
          style: {
            textStyle: {
              ...context.theme.typography.headline3,
            },
            offsetX: 21,
          },
        },
        {
          type: Column,
          centerInParent: true,
          neverScroll: true,
          extraItemSpacing: 24,
          skipFocus: true,
          w: 400,
          h: 176,
          style: { itemSpacing: 0 },
          items: [
            {
              type: Row,
              centerInParent: true,
              skipFocus: true,
              w: 400,
              h: 88,
              items: [
                {
                  type: CustomButton,
                  mode: 'disabled',
                  w: 184,
                  h: 72,
                  fixed: true,
                  title: 'Host',
                },
                {
                  type: CustomButton,
                  mode: 'disabled',
                  w: 184,
                  h: 72,
                  fixed: true,
                  title: '-',
                },
              ],
            },
            {
              type: Row,
              w: 400,
              h: 88,
              centerInParent: true,
              skipFocus: true,
              items: [
                {
                  type: CustomButton,
                  mode: 'disabled',
                  w: 184,
                  h: 72,
                  fixed: true,
                  title: '-',
                },
                {
                  type: CustomButton,
                  mode: 'disabled',
                  w: 184,
                  h: 72,
                  fixed: true,
                  title: '-',
                },
              ],
            },
          ],
        },
        {
          type: CustomButton,
          rounded: corner.topRight,
          w: 386,
          title: 'Return to the game',
          centerInParent: true,
          extraItemSpacing: 16,
        },
        {
          type: CustomButton,
          rounded: corner.bottomLeft,
          w: 386,
          title: 'Leave the party',
          centerInParent: true,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._bindFunctions()
    this._ReturnBtn.patch({
      onEnter: () => {
        this.fireAncestors('$closeSidekick')
      },
    })

    this._LeavePartyBtn.patch({ onEnter: this._leaveParty })
    this._updatePlayerInfo()
  }

  _bindFunctions() {
    this._leaveParty = this._leaveParty.bind(this)
    this._updatePlayerInfo = this._updatePlayerInfo.bind(this)
  }

  _leaveParty() {
    this.fireAncestors('$showCouchplayPanel', CouchplayPanelNames.LEAVE_PARTY_CONFIRMATION)
  }

  _updatePlayerInfo() {
    const btns = this._PlayerBtns
    let playerCount = 0
    this.gamingClient.partyPlayers.forEach((playerJoined, index) => {
      if (playerJoined) {
        playerCount += 1
        if (index === 0) {
          btns[index].patch({ title: 'Host', prefix: [] })
        } else {
          btns[index].patch({
            title: index === this.gamingClient.playerIndex ? 'You' : '',
            prefix: [
              {
                type: Icon,
                icon: playerIcons[index].icon,
                w: 33,
                h: 28,
              },
            ],
          })
        }
      } else {
        btns[index].patch({ title: '-', prefix: [] })
      }
    })

    this._PlayerCount.patch({
      content: `Players ${playerCount}/${this.gamingClient.partyPlayers.length}`,
    })
  }

  get _ReturnBtn() {
    return this.items[3]
  }

  get _LeavePartyBtn() {
    return this.items[4]
  }

  get _PlayerCount() {
    return this.items[1]
  }

  get _PlayerBtns() {
    const btnCol = this.items[2]
    let btns = []

    btnCol.items.forEach((row) => {
      row.items.forEach((btn) => {
        btns.push(btn)
      })
    })

    return btns
  }
}
