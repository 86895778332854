import { BluetoothEventTypes, BluetoothStatus, BluetoothMethods, } from './bluetoothServiceInterface';
import { BluetoothError } from './errors';
import MockBluetoothAPI from './mockBluetoothApi';
import MockBluetoothService from './mockBluetoothService';
import ThunderBluetooth from './thunderBluetooth';
import BluetoothAnalyticsService from './bluetoothAnalyticsService';
import { ControllerStatus } from './bluetoothAnalyticsInterface';
import LoggingService from '../logging/loggingService';
import { LogLevels } from '../logging/loggingEnums';
import { MockBluetoothType } from '../remoteConfig';
export class BluetoothWrapperConfig {
    constructor(mockBluetoothType, mockBTServerURL) {
        this.mockBluetoothType = mockBluetoothType;
        this.mockBTServerURL = mockBTServerURL;
    }
}
const notActivatedErrorMsg = 'Bluetooth Wrapper: not yet activated';
var PairingStatus;
(function (PairingStatus) {
    PairingStatus["PAIRING"] = "PAIRING";
    PairingStatus["CONNECTING"] = "CONNECTING";
    PairingStatus["DISCONNECTING"] = "DISCONNECTING";
    PairingStatus["UNPAIRING"] = "UNPAIRING";
})(PairingStatus || (PairingStatus = {}));
export var PairingChangeStatus;
(function (PairingChangeStatus) {
    PairingChangeStatus["NONE"] = "NONE";
    PairingChangeStatus["PAIRED"] = "PAIRED";
    PairingChangeStatus["UNPAIRED"] = "UNPAIRED";
    PairingChangeStatus["DISCONNECTED"] = "DISCONNECTED";
    PairingChangeStatus["CONNECTED"] = "CONNECTED";
})(PairingChangeStatus || (PairingChangeStatus = {}));
export class PairingChangeNotification {
    constructor(status, device) {
        this.status = status;
        this.device = device;
    }
}
class BluetoothNotification {
    constructor(eventType, event, timestamp) {
        this.eventType = eventType;
        this.event = event;
        this.timestamp = timestamp;
    }
}
export class BluetoothWrapper {
    constructor(config) {
        if (config != null) {
            this._config = config;
        }
        this._thunderBluetoothSucceeded = false;
        this._activated = false;
        this._isScanning = false;
        this._continuousScanStarted = false;
        this._inProgressDevices = new Map();
        this._registeredEvents = new Map();
        this._updateCallbacks = new Set();
        this._controllerOnOffCallbacks = new Set();
        this._controllerStatusChangeCallbacks = new Set();
        this._discoveredDevicesMap = new Map();
        this._pairedDevicesMap = new Map();
        this._connectedDevicesMap = new Map();
        this._bluetoothAnalyticsService = new BluetoothAnalyticsService();
        this._notificationQueue = [];
        this._queueIsRunning = false;
        this._lastQueried = 0;
        this._bindFunctions();
    }
    addListener(eventType, callback) {
        if (!this._registeredEvents.has(eventType)) {
            this._registeredEvents.set(eventType, new Set());
        }
        this._registeredEvents.get(eventType).add(callback);
    }
    removeListener(eventType, callback) {
        if (this._registeredEvents.has(eventType)) {
            const registeredEvents = this._registeredEvents.get(eventType);
            registeredEvents.delete(callback);
        }
    }
    addUpdateListener(callback) {
        this._updateCallbacks.add(callback);
    }
    removeUpdateListener(callback) {
        if (this._updateCallbacks.has(callback)) {
            this._updateCallbacks.delete(callback);
        }
    }
    addControllerOnOffListener(callback) {
        this._controllerOnOffCallbacks.add(callback);
    }
    removeControllerOnOffListener(callback) {
        if (this._controllerOnOffCallbacks.has(callback)) {
            this._controllerOnOffCallbacks.delete(callback);
        }
    }
    addControllerStatusChangeListener(callback) {
        this._controllerStatusChangeCallbacks.add(callback);
    }
    removeControllerStatusChangeListener(callback) {
        if (this._controllerStatusChangeCallbacks.has(callback)) {
            this._controllerStatusChangeCallbacks.delete(callback);
        }
    }
    activate(config) {
        this._activated = false;
        if (config != null) {
            this._config = config;
        }
        if (this._config == null) {
            return new Promise((_, reject) => {
                reject(new Error('BluetoothWrapper: missing BluetoothWrapperConfig object'));
            });
        }
        return new Promise((resolve, reject) => {
            const thunderBT = new ThunderBluetooth();
            thunderBT
                .activate()
                .then((result) => {
                this._log('activation successful');
                if (this._bluetoothService != null) {
                    this.deactivate();
                }
                this._bluetoothService = thunderBT;
                this._thunderBluetoothSucceeded = true;
                this._activated = true;
                this._addListeners();
                resolve(result);
            })
                .catch((err) => {
                var _a, _b;
                this._log('activation failed');
                switch ((_a = this._config) === null || _a === void 0 ? void 0 : _a.mockBluetoothType) {
                    case MockBluetoothType.FAKE_CONTROLLERS:
                        this._bluetoothService = new MockBluetoothService();
                        break;
                    case MockBluetoothType.MOCK_SERVER:
                        this._bluetoothService = new MockBluetoothAPI((_b = this._config) === null || _b === void 0 ? void 0 : _b.mockBTServerURL);
                        break;
                    default:
                        this._bluetoothService = thunderBT;
                        this._thunderBluetoothSucceeded = false;
                        reject(err);
                        return;
                }
                this._log('using mock bluetooth');
                this._bluetoothService
                    .activate()
                    .then((result) => {
                    this._activated = true;
                    this._addListeners();
                    reject(err);
                })
                    .catch((mockErr) => {
                    reject(err);
                });
            });
        });
    }
    deactivate() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return;
        }
        this._removeListeners();
        this._bluetoothService.deactivate();
    }
    startContinuousScan(timeout) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        this._continuousScanTimeout = timeout;
        this._continuousScanStarted = true;
        this.addListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._continuallyScan);
        return this.startScan(timeout);
    }
    stopContinuousScan() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        this._continuousScanStarted = false;
        this.removeListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._continuallyScan);
        return this.stopScan();
    }
    startScan(timeout) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this._bluetoothService.startScan(timeout)
                .then((result) => {
                if (result.success) {
                    resolve(result);
                }
                else {
                    reject(new BluetoothError(BluetoothMethods.START_SCAN, result));
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    stopScan() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((resolve, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this._bluetoothService.stopScan()
                .then((result) => {
                if (result.success) {
                    resolve(result);
                }
                else {
                    reject(new BluetoothError(BluetoothMethods.STOP_SCAN, result));
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    async getUpdatedDevices() {
        this._lastQueried = Date.now();
        const values = await Promise.all([
            this.getDiscoveredDevices(),
            this.getPairedDevices(),
            this.getConnectedDevices(),
        ]);
        this._activateUpdateCallbacks(values[0], values[1], values[2]);
    }
    getDiscoveredDevices() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this._bluetoothService.getDiscoveredDevices()
                .then((result) => {
                if (result.success && result.discoveredDevices) {
                    // update cached discovered devices
                    const discoveredDevices = new Map();
                    result.discoveredDevices.forEach((device) => {
                        discoveredDevices.set(device.deviceID, device);
                    });
                    this._discoveredDevicesMap = discoveredDevices;
                    resolve(result.discoveredDevices);
                }
                else {
                    reject(new BluetoothError(BluetoothMethods.GET_DISCOVERED_DEVICES, result));
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    // function that returns the cached discovered devices
    // useful especially for UI rendering so that it doesn't have to wait until RDK returns discovered devices
    // to render something
    getDiscoveredDevicesCached() {
        return Array.from(this._discoveredDevicesMap.values());
    }
    getPairedDevices() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this._bluetoothService.getPairedDevices()
                .then((result) => {
                if (result.success && result.pairedDevices) {
                    // update cached paired devices
                    const pairedDevices = new Map();
                    result.pairedDevices.forEach((device) => {
                        pairedDevices.set(device.deviceID, device);
                    });
                    const notifications = this._compareOldNewPairedDevices(this._pairedDevicesMap, pairedDevices);
                    this._controllerStatusChangeCallbacks.forEach((callback) => {
                        callback(notifications);
                    });
                    this._pairedDevicesMap = pairedDevices;
                    resolve(result.pairedDevices);
                }
                else {
                    reject(new BluetoothError(BluetoothMethods.GET_PAIRED_DEVICES, result));
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    // function that returns the cached paired devices
    // useful especially for UI rendering so that it doesn't have to wait until RDK returns discovered devices
    // to render something
    getPairedDevicesCached() {
        return Array.from(this._pairedDevicesMap.values());
    }
    getConnectedDevices() {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this._bluetoothService.getConnectedDevices()
                .then((result) => {
                if (result.success && result.connectedDevices) {
                    // update cached connected devices
                    const connectedDevices = new Map();
                    result.connectedDevices.forEach((device) => {
                        connectedDevices.set(device.deviceID, device);
                    });
                    const notifications = this._compareOldNewConnectedDevices(this._connectedDevicesMap, connectedDevices);
                    this._controllerStatusChangeCallbacks.forEach((callback) => {
                        callback(notifications);
                    });
                    this._connectedDevicesMap = connectedDevices;
                    resolve(result.connectedDevices);
                }
                else {
                    reject(new BluetoothError(BluetoothMethods.GET_CONNECTED_DEVICES, result));
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    // function that returns the cached connected devices
    // useful especially for UI rendering so that it doesn't have to wait until RDK returns discovered devices
    // to render something
    getConnectedDevicesCached() {
        return Array.from(this._connectedDevicesMap.values());
    }
    pair(deviceID) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            if (this._inProgressDevices.has(deviceID)) {
                reject(new Error(`${deviceID} is already in progress: ${this._inProgressDevices.get(deviceID)}`));
                return;
            }
            this._inProgressDevices.set(deviceID, PairingStatus.PAIRING);
            this.stopScan()
                .then(() => {
                this._bluetoothService.pair(deviceID)
                    .then((pairResult) => {
                    if (pairResult.success) {
                        resolve(pairResult);
                    }
                    else {
                        this._log(`failed: pairing result: ${JSON.stringify(pairResult)}`);
                        this._inProgressDevices.delete(deviceID);
                        this._restartContinuousScan();
                        reject(new BluetoothError(BluetoothMethods.PAIR, pairResult));
                    }
                })
                    .catch((pairErr) => {
                    this._inProgressDevices.delete(deviceID);
                    this._restartContinuousScan();
                    reject(new BluetoothError(BluetoothMethods.PAIR, pairErr));
                });
            })
                .catch((err) => {
                this._log('unable to stop scan successfully');
                this._inProgressDevices.delete(deviceID);
                reject(err);
            });
        });
    }
    unpair(deviceID) {
        if (!this._bluetoothService) {
            console.error('not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            if (this._inProgressDevices.has(deviceID)) {
                reject(new Error(`${deviceID} is already in progress: ${this._inProgressDevices.get(deviceID)}`));
                return;
            }
            this._inProgressDevices.set(deviceID, PairingStatus.UNPAIRING);
            this.stopScan()
                .then(() => {
                this._bluetoothService.unpair(deviceID)
                    .then((result) => {
                    if (result.success) {
                        resolve(result);
                    }
                    else {
                        this._inProgressDevices.delete(deviceID);
                        reject(new BluetoothError(BluetoothMethods.UNPAIR, result));
                    }
                })
                    .catch((err) => {
                    this._inProgressDevices.delete(deviceID);
                    reject(err);
                });
            })
                .catch((err) => {
                this._log('unable to stop scan successfully');
                this._inProgressDevices.delete(deviceID);
                reject(err);
            });
        });
    }
    connect(deviceID, deviceType) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            if (this._inProgressDevices.has(deviceID) &&
                this._inProgressDevices.get(deviceID) !== PairingStatus.PAIRING) {
                reject(new Error(`${deviceID} is already in progress: ${this._inProgressDevices.get(deviceID)}`));
                return;
            }
            this._inProgressDevices.set(deviceID, PairingStatus.CONNECTING);
            this.stopScan()
                .then(() => {
                this._bluetoothService.connect(deviceID, deviceType)
                    .then((result) => {
                    if (result.success) {
                        resolve(result);
                    }
                    else {
                        this._inProgressDevices.delete(deviceID);
                        reject(new BluetoothError(BluetoothMethods.CONNECT, result));
                    }
                })
                    .catch((err) => {
                    this._inProgressDevices.delete(deviceID);
                    reject(err);
                });
            })
                .catch((err) => {
                this._log('unable to stop scan successfully');
                this._inProgressDevices.delete(deviceID);
                reject(err);
            });
        });
    }
    disconnect(deviceID, deviceType) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            if (this._inProgressDevices.has(deviceID)) {
                reject(new Error(`${deviceID} is already in progress: ${this._inProgressDevices.get(deviceID)}`));
                return;
            }
            this._inProgressDevices.set(deviceID, PairingStatus.DISCONNECTING);
            this.stopScan()
                .then(() => {
                this._bluetoothService.disconnect(deviceID, deviceType)
                    .then((result) => {
                    if (result.success) {
                        resolve(result);
                    }
                    else {
                        this._inProgressDevices.delete(deviceID);
                        reject(new BluetoothError(BluetoothMethods.DISCONNECT, result));
                    }
                })
                    .catch((err) => {
                    this._inProgressDevices.delete(deviceID);
                    reject(err);
                });
            })
                .catch((err) => {
                this._log('unable to stop scan successfully');
                this._inProgressDevices.delete(deviceID);
                reject(err);
            });
        });
    }
    respondToEvent(deviceID, eventType, responseValue) {
        if (!this._bluetoothService) {
            console.error('Bluetooth Wrapper: not yet activated');
            return new Promise((_, reject) => {
                reject(new Error(notActivatedErrorMsg));
            });
        }
        return new Promise((resolve, reject) => {
            this.stopScan()
                .then(() => {
                this._bluetoothService.respondToEvent(deviceID, eventType, responseValue)
                    .finally(() => {
                    this._restartContinuousScan();
                })
                    .then((result) => {
                    if (result.success) {
                        resolve(result);
                    }
                    else {
                        reject(new BluetoothError(BluetoothMethods.RESPOND_TO_EVENT, result));
                    }
                })
                    .catch((err) => {
                    reject(err);
                });
            })
                .catch((err) => {
                this._log('unable to stop scan successfully');
                reject(err);
            });
        });
    }
    isInProgress(deviceID) {
        return this._inProgressDevices.has(deviceID);
    }
    isPairing(deviceID) {
        return (this._inProgressDevices.has(deviceID) &&
            this._inProgressDevices.get(deviceID) === PairingStatus.PAIRING);
    }
    isUnpairing(deviceID) {
        return (this._inProgressDevices.has(deviceID) &&
            this._inProgressDevices.get(deviceID) === PairingStatus.UNPAIRING);
    }
    isConnecting(deviceID) {
        return (this._inProgressDevices.has(deviceID) &&
            this._inProgressDevices.get(deviceID) === PairingStatus.CONNECTING);
    }
    isDisconnecting(deviceID) {
        return (this._inProgressDevices.has(deviceID) &&
            this._inProgressDevices.get(deviceID) === PairingStatus.DISCONNECTING);
    }
    isActivated() {
        return this._activated;
    }
    isThunderSuccessful() {
        return this._thunderBluetoothSucceeded;
    }
    _addNotification(notification) {
        this._notificationQueue.push(notification);
        this._processQueue();
    }
    async _processQueue() {
        if (this._queueIsRunning) {
            return;
        }
        this._queueIsRunning = true;
        while (this._notificationQueue.length > 0) {
            const currentNotification = this._notificationQueue.shift();
            if (currentNotification && (currentNotification === null || currentNotification === void 0 ? void 0 : currentNotification.timestamp) < this._lastQueried) {
                continue;
            }
            await this.getUpdatedDevices();
        }
        this._queueIsRunning = false;
    }
    async _onStatusChangedCallback(event) {
        this._log(`onStatusChanged: ${JSON.stringify(event)}`);
        if (event.newStatus === BluetoothStatus.DISCOVERY_COMPLETED) {
            this._isScanning = false;
        }
        else if (event.newStatus === BluetoothStatus.DISCOVERY_STARTED) {
            this._isScanning = true;
        }
        if (event.deviceID == null) {
            this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, event);
            return;
        }
        switch (event.newStatus) {
            case BluetoothStatus.PAIRING_CHANGE:
                if (!event.paired && this.isUnpairing(event.deviceID)) {
                    this._inProgressDevices.delete(event.deviceID);
                    this._bluetoothAnalyticsService.sendControllerStatusChangedEvent({
                        status: ControllerStatus.UNPAIRED,
                        deviceId: event.deviceID,
                        name: event.name,
                    });
                }
                else if (event.paired && this.isPairing(event.deviceID)) {
                    this._bluetoothAnalyticsService.sendControllerStatusChangedEvent({
                        status: ControllerStatus.PAIRED,
                        deviceId: event.deviceID,
                        name: event.name,
                    });
                    this.connect(event.deviceID, event.deviceType);
                }
                break;
            case BluetoothStatus.CONNECTION_CHANGE:
                // ignore connection change event if it is before pairing_change event.
                if (this.isPairing(event.deviceID)) {
                    return;
                }
                if (this.isDisconnecting(event.deviceID) && !event.connected) {
                    this._inProgressDevices.delete(event.deviceID);
                    this._bluetoothAnalyticsService.sendControllerStatusChangedEvent({
                        status: ControllerStatus.DISCONNECTED,
                        deviceId: event.deviceID,
                        name: event.name,
                    });
                }
                else if (this.isConnecting(event.deviceID) && event.connected) {
                    this._inProgressDevices.delete(event.deviceID);
                    this._bluetoothAnalyticsService.sendControllerStatusChangedEvent({
                        status: ControllerStatus.CONNECTED,
                        deviceId: event.deviceID,
                        name: event.name,
                    });
                }
                break;
            default:
                break;
        }
        this._addNotification(new BluetoothNotification(BluetoothEventTypes.ON_STATUS_CHANGED, event, Date.now()));
        this._activateCallbacks(BluetoothEventTypes.ON_STATUS_CHANGED, event);
        if (this._continuousScanStarted) {
            this._restartContinuousScan();
        }
    }
    _compareOldNewPairedDevices(oldDevices, newDevices) {
        const notifications = new Map();
        newDevices.forEach((device, deviceID) => {
            if (!oldDevices.has(deviceID)) {
                notifications.set(deviceID, new PairingChangeNotification(PairingChangeStatus.PAIRED, { ...device }));
            }
        });
        oldDevices.forEach((device, deviceID) => {
            if (!newDevices.has(deviceID)) {
                notifications.set(deviceID, new PairingChangeNotification(PairingChangeStatus.UNPAIRED, { ...device }));
            }
        });
        return notifications;
    }
    _compareOldNewConnectedDevices(oldDevices, newDevices) {
        const notifications = new Map();
        newDevices.forEach((device, deviceID) => {
            if (!oldDevices.has(deviceID)) {
                notifications.set(deviceID, new PairingChangeNotification(PairingChangeStatus.CONNECTED, device));
            }
        });
        oldDevices.forEach((device, deviceID) => {
            if (!newDevices.has(deviceID)) {
                notifications.set(deviceID, new PairingChangeNotification(PairingChangeStatus.DISCONNECTED, device));
            }
        });
        return notifications;
    }
    _onRequestFailedCallback(event) {
        this._log(`onRequestFailed: ${JSON.stringify(event)}`);
        this._inProgressDevices.delete(event.deviceID);
        if (this._continuousScanStarted) {
            this._restartContinuousScan();
        }
        this._activateCallbacks(BluetoothEventTypes.ON_REQUEST_FAILED, event);
    }
    async _onDeviceFoundCallback(event) {
        this._log(`onDeviceFound: ${JSON.stringify(event)}`);
        this._addNotification(new BluetoothNotification(BluetoothEventTypes.ON_DEVICE_FOUND, event, Date.now()));
        this._activateCallbacks(BluetoothEventTypes.ON_DEVICE_FOUND, event);
        if (this._pairedDevicesMap.has(event.deviceID)) {
            this._controllerOnOffCallbacks.forEach((callback) => {
                callback(true, event);
            });
        }
    }
    async _onDeviceLostCallback(event) {
        this._log(`onDeviceLost: ${JSON.stringify(event)}`);
        this._addNotification(new BluetoothNotification(BluetoothEventTypes.ON_DEVICE_LOST, event, Date.now()));
        this._activateCallbacks(BluetoothEventTypes.ON_DEVICE_LOST, event);
        if (this._pairedDevicesMap.has(event.deviceID)) {
            this._controllerOnOffCallbacks.forEach((callback) => {
                callback(false, event);
            });
        }
    }
    async _onDiscoveredDeviceCallback(event) {
        this._log(`onDiscoveredDevice: ${JSON.stringify(event)}`);
        this._addNotification(new BluetoothNotification(BluetoothEventTypes.ON_DISCOVERED_DEVICE, event, Date.now()));
        this._activateCallbacks(BluetoothEventTypes.ON_DISCOVERED_DEVICE, event);
    }
    _onConnectionRequestCallback(event) {
        this._log(`onConnectionRequest: ${JSON.stringify(event)}`);
        this._activateCallbacks(BluetoothEventTypes.ON_CONNECTION_REQUEST, event);
    }
    _isPaired(deviceID) {
        return this.getPairedDevices()
            .then((devices) => {
            const foundDevice = devices.find((device) => device.deviceID === deviceID);
            return foundDevice;
        })
            .catch((err) => {
            return undefined;
        });
    }
    _isConnected(deviceID) {
        return this.getConnectedDevices()
            .then((devices) => {
            const foundDevice = devices.find((device) => device.deviceID === deviceID);
            return foundDevice;
        })
            .catch((err) => {
            return undefined;
        });
    }
    _activateCallbacks(eventType, event) {
        const callbacks = this._registeredEvents.get(eventType);
        if (callbacks) {
            callbacks.forEach((callback) => callback(event));
        }
    }
    _activateUpdateCallbacks(discoveredDevices = Array.from(this._discoveredDevicesMap.values()), pairedDevices = Array.from(this._pairedDevicesMap.values()), connectedDevices = Array.from(this._connectedDevicesMap.values())) {
        this._updateCallbacks.forEach((callback) => {
            callback(discoveredDevices, pairedDevices, connectedDevices);
        });
    }
    _addListeners() {
        if (this._bluetoothService != null) {
            this._bluetoothService.addListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._onStatusChangedCallback);
            this._bluetoothService.addListener(BluetoothEventTypes.ON_DISCOVERED_DEVICE, this._onDiscoveredDeviceCallback);
            this._bluetoothService.addListener(BluetoothEventTypes.ON_CONNECTION_REQUEST, this._onConnectionRequestCallback);
            this._bluetoothService.addListener(BluetoothEventTypes.ON_DEVICE_FOUND, this._onDeviceFoundCallback);
            this._bluetoothService.addListener(BluetoothEventTypes.ON_DEVICE_LOST, this._onDeviceLostCallback);
            this._bluetoothService.addListener(BluetoothEventTypes.ON_REQUEST_FAILED, this._onRequestFailedCallback);
        }
    }
    _removeListeners() {
        if (this._bluetoothService != null) {
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_STATUS_CHANGED, this._onStatusChangedCallback);
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_DISCOVERED_DEVICE, this._onDiscoveredDeviceCallback);
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_CONNECTION_REQUEST, this._onConnectionRequestCallback);
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_DEVICE_FOUND, this._onDeviceFoundCallback);
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_DEVICE_LOST, this._onDeviceLostCallback);
            this._bluetoothService.removeListener(BluetoothEventTypes.ON_REQUEST_FAILED, this._onRequestFailedCallback);
        }
    }
    _restartContinuousScan() {
        if (!this._isScanning && this._inProgressDevices.size === 0) {
            this.startScan(this._continuousScanTimeout);
        }
    }
    _bindFunctions() {
        this.startScan = this.startScan.bind(this);
        this.stopScan = this.stopScan.bind(this);
        this.startContinuousScan = this.startContinuousScan.bind(this);
        this.stopContinuousScan = this.stopContinuousScan.bind(this);
        this.getUpdatedDevices = this.getUpdatedDevices.bind(this);
        this.getDiscoveredDevices = this.getDiscoveredDevices.bind(this);
        this.getPairedDevices = this.getPairedDevices.bind(this);
        this.getConnectedDevices = this.getConnectedDevices.bind(this);
        this.unpair = this.unpair.bind(this);
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.respondToEvent = this.respondToEvent.bind(this);
        this._continuallyScan = this._continuallyScan.bind(this);
        this._log = this._log.bind(this);
        this._onStatusChangedCallback = this._onStatusChangedCallback.bind(this);
        this._onDiscoveredDeviceCallback = this._onDiscoveredDeviceCallback.bind(this);
        this._onRequestFailedCallback = this._onRequestFailedCallback.bind(this);
        this._onDeviceFoundCallback = this._onDeviceFoundCallback.bind(this);
        this._onDeviceLostCallback = this._onDeviceLostCallback.bind(this);
        this._onConnectionRequestCallback = this._onConnectionRequestCallback.bind(this);
        this._activateCallbacks = this._activateCallbacks.bind(this);
        this._activateUpdateCallbacks = this._activateUpdateCallbacks.bind(this);
        this._isConnected = this._isConnected.bind(this);
        this._isPaired = this._isPaired.bind(this);
        this._addListeners = this._addListeners.bind(this);
        this._removeListeners = this._removeListeners.bind(this);
        this.addUpdateListener = this.addUpdateListener.bind(this);
        this.removeUpdateListener = this.removeUpdateListener.bind(this);
        this.addControllerOnOffListener = this.addControllerOnOffListener.bind(this);
        this.removeControllerOnOffListener = this.removeControllerOnOffListener.bind(this);
        this._restartContinuousScan = this._restartContinuousScan.bind(this);
        this.clearTimeouts = this.clearTimeouts.bind(this);
    }
    _continuallyScan(event) {
        if (event.newStatus === BluetoothStatus.DISCOVERY_COMPLETED) {
            if (this._inProgressDevices.size === 0) {
                this.startScan(this._continuousScanTimeout);
            }
        }
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.BLUETOOTH, text, 'BluetoothWrapper: ');
    }
    clearTimeouts() {
        clearTimeout(this._pairingInProgressTimeout);
        clearTimeout(this._unpairingInProgressTimeout);
        clearTimeout(this._connectingInProgressTimeout);
        clearTimeout(this._disconnectingInProgressTimeout);
    }
    // Singleton
    static getInstance() {
        if (!BluetoothWrapper.instance) {
            BluetoothWrapper.instance = new BluetoothWrapper();
        }
        return BluetoothWrapper.instance;
    }
}
