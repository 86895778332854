import { Column } from '@lightning/ui'

export default class NotificationsContainer extends Column {
  static _template() {
    return {
      y: 48,
      w: 560,
      x: 1350,
      defaultX: 1350,
      items: [],
      zIndex: 999,
      // this property is needed or Column prependItems behaves like a Row
      direction: 'column',
      neverScroll: true,
      skipFocus: true,
    }
  }

  // Column's prependItems func calls this func in the parent class (NavigationManager).
  // We need to override locally to prevent console errors due to un-safe references in
  // source code for certain dimensions.
  appendItemsAt(items = [], idx) {
    if (this._lazyItems && idx > this.items.length - 1) {
      const addAtIdx = idx - this.items.length
      this._lazyItems.splice(addAtIdx, 0, ...items)
      return
    }

    const addIndex = Number.isInteger(idx) ? idx : this.Items.children.length
    this.shouldSmooth = false
    this._lastAppendedIdx = addIndex
    this._totalAddedLength = 0

    items.forEach((item, itemIdx) => {
      this.Items.childList.addAt({ ...item, parentFocus: this.hasFocus() }, addIndex + itemIdx)
      const extraItemSpacing = item?.extraItemSpacing || 0
      this._totalAddedLength += this.style.itemSpacing + extraItemSpacing
    })

    if (this.selectedIndex >= this._lastAppendedIdx) {
      this._selectedPastAdded = true
      this._selectedIndex += items.length
    }

    this.requestUpdate()
    this._refocus()
  }

  addAndAnnounceItem(item) {
    this.prependItems([item])
    /// notifications formatter will set the customAnnounce field
    item?.customAnnounce && this.fireAncestors('$announceNotification', item?.customAnnounce)
  }

  getItemIndex(id) {
    return this.items.findIndex((item) => {
      return item.notificationId === id
    })
  }

  $removeNotification(id) {
    const index = this.getItemIndex(id)
    index >= 0 && this.removeItemAt(index)
  }
}
