import { AnalyticsErrorBucketCodes } from './analyticsEnums';
import CoreEventCreator from './coreEventCreator';
import { sendAnalyticsEvent } from './analyticsService';
import { createErrorEvent } from './eventCreators';
export default class PlatformAnalyticsService {
    constructor() {
        this._bindFunctions();
    }
    _bindFunctions() {
        this.sendPlatformError = this.sendPlatformError.bind(this);
    }
    sendPlatformError(code) {
        // statuses returned by platform APIs are type number so convert to string for ErrorDecorator
        // as well as to pass schema validation
        code = code.toString();
        sendAnalyticsEvent(createErrorEvent(CoreEventCreator.getInstance().createEvent(), AnalyticsErrorBucketCodes.PLATFORM, code));
    }
}
