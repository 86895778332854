import PageBase from '../PageBase'
import { imagePath } from '../../utils/imagePath'
import EntityBackground from '../../components/EntityBackground'
import dataLayerAnalytics from '../../lib/analytics/dataLayerAnalyticsService'
import FullScreenScrollingText from '../../components/FullScreenScrollingText'

export const entityDescLabel = 'Overview'

export default class EntityOverview extends PageBase {
  static _template() {
    return {
      hideNav: true,
      Background: {
        type: EntityBackground,
        blur: true,
      },
      Overview: { type: FullScreenScrollingText },
    }
  }

  _onDataProvided() {
    this._background = this.data.assets?.entity && imagePath(this.data, this.data.assets?.entity)
    this._title = this.data.name
    this._description = this.data.description.long

    dataLayerAnalytics.processComplete()
  }

  _active() {
    this._Background.patch({
      imgSrc: this._background,
    })
    this._Overview.patch({
      title: entityDescLabel,
      description: this._description,
    })
    this._Overview._Description.patch({ scrollStep: 200 }) // move 5 lines per scroll
  }

  _getFocused() {
    return this._Overview
  }

  get _Overview() {
    return this.tag('Overview')
  }

  get _Background() {
    return this.tag('Background')
  }
}
