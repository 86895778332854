import { Column, context, TextBox } from '@lightning/ui'
import SurveyButton from '../../components/SurveyButton'
import lng from '@lightningjs/core'
import { SurveyAnalyticsService, SurveyReasons } from '../../lib/analytics/surveyAnalyticsService'
import { StaticBreadcrumb } from '../../components/Breadcrumb'
import XGButton, { corner } from '../../components/XGButton'

export default class BadExperienceReasonsPage extends lng.Component {
  static _template() {
    return {
      Breadcrumb: {
        type: StaticBreadcrumb,
      },
      Content: {
        type: Column,
        y: 172,
        autoResizeWidth: true,
        x: context.theme.layout.screenW / 2,
        mountX: 0.5,
        style: {
          itemSpacing: 12,
        },
        items: [
          {
            type: TextBox,
            centerInParent: true,
            w: 906,
            content: 'What impacted your experience today?',
            style: {
              textStyle: {
                ...context.theme.typography.display2,
                textAlign: 'center',
              },
            },
            skipFocus: true,
          },
          {
            type: TextBox,
            centerInParent: true,
            extraItemSpacing: 36,
            w: 906,
            content: 'Select all that apply',
            style: {
              textStyle: {
                ...context.theme.typography.body1,
                textAlign: 'center',
              },
            },
            skipFocus: true,
          },
          {
            type: Column,
            autoResizeWidth: true,
            centerInParent: true,
            h: 512,
            extraItemSpacing: 56,
            items: [
              {
                type: SurveyButton,
                title: 'Video is glitchy',
                reason: SurveyReasons.VIDEO,
              },
              {
                type: SurveyButton,
                title: 'Controller is delayed',
                reason: SurveyReasons.CONTROL_DELAY,
              },
              {
                type: SurveyButton,
                title: 'Long load times',
                reason: SurveyReasons.LOAD_TIME,
              },
              {
                type: SurveyButton,
                title: 'Audio is not in sync',
                reason: SurveyReasons.AUDIO_DELAY,
              },
              {
                type: SurveyButton,
                title: 'Something else',
                reason: SurveyReasons.OTHER,
              },
            ],
          },
          {
            type: XGButton,
            rounded: corner.bottomLeftAndTopRight,
            title: 'Skip',
            centerInParent: true,
            w: 386,
            onEnter() {
              this.fireAncestors('$onSubmitSurvey')
            },
          },
        ],
      },
    }
  }

  _init() {
    super._init()
    this._selectedOptions = new Set()
    this._surveyAnalyticsService = new SurveyAnalyticsService()
  }

  $selectSurveyOption(isSelected, option) {
    if (isSelected) {
      this._selectedOptions.add(option)
    } else {
      this._selectedOptions.delete(option)
    }

    if (this._selectedOptions.size > 0) {
      this._SubmitButton.patch({ title: 'Submit' })
    } else {
      this._SubmitButton.patch({ title: 'Skip' })
    }
  }

  $onSubmitSurvey() {
    this._surveyAnalyticsService.sendNegativeFeedback(this._selectedOptions)
    this.fireAncestors('$onExitSurvey')
  }

  _getFocused() {
    return this._Content
  }

  get _Content() {
    return this.tag('Content')
  }

  get _ButtonsColumn() {
    return this._Content.items[2]
  }

  get _SubmitButton() {
    return this._Content.items[3]
  }
}
