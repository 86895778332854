import { Column } from '@lightning/ui'
import * as ratingsData from '../../static/rating/data.json'
import ContentControlsListItem from './ContentControlsListItem'
import { RemoteConfig } from '../lib/remoteConfig'
import unlockIcon from '../assets/icons/unlock.png'
import lockIcon from '../assets/icons/lock.png'
import { Language } from '@lightningjs/sdk'
import CodeGenerator from '../lib/codeGenerator'
import UserAccountProvider from '../api/providers/userAccountProvider'

export default class ContentControlsList extends Column {
  static _template() {
    return {
      ...super._template(),
      style: {
        itemSpacing: 8,
        itemTransition: { duration: 0 },
      },
      h: 500,
      neverScroll: true,
      items: [],
    }
  }

  _init() {
    super._init()
    this._ratingsDetails = Object.values(ratingsData).filter((rating) => {
      return rating.inUse
    })
    this.items = this._ratingsDetails.map((item, index) => {
      const locked = UserAccountProvider.getInstance().contentControls[item.rating]

      return {
        type: ContentControlsListItem,
        title: item.title,
        description: item.description,
        prefixLogo: `${RemoteConfig.getInstance().documentsBaseURL}${
          RemoteConfig.getInstance().esrbRatingPath
        }${item.icon}`,
        suffixLogo: locked ? lockIcon : unlockIcon,
        onEnter: () => this._toggleItemLock(),
        announceContext: this._getAnnounceContext(locked, index),
        rating: item.rating,
        locked,
      }
    })
  }

  _active() {
    this.updateRatingsButtonsMode()
  }

  _getAnnounceContext(locked, index) {
    return [
      Language.translate('ANNOUNCE_LENGTH', index + 1, this._ratingsDetails.length),
      locked
        ? Language.translate('PRESS_CENTER_TO_UNLOCK')
        : Language.translate('PRESS_CENTER_TO_LOCK'),
      'PAUSE-2',
    ]
  }

  _toggleItemLock() {
    this.selected.patch({
      suffixLogo: this.selected.locked ? unlockIcon : lockIcon,
    })
    this.selected.announceContext = this._getAnnounceContext(
      !this.selected.locked,
      this.selectedIndex
    )
    this.selected.locked = !this.selected.locked
  }

  updateRatingsButtonsMode() {
    for (let i = 0; i < this.items.length; i++) {
      if (CodeGenerator.getInstance().codeChallengePassed) {
        this.items[i].mode = this.items[i] === this.selected ? 'focused' : 'unfocused'
      } else {
        this.items[i].mode = this.items[i].mode !== 'disabled' && 'disabled'
      }
    }
  }

  get currentContentControls() {
    const contentControls = {}
    for (let i = 0; i < this.items.length; i++) {
      contentControls[this.items[i].rating] = this.items[i].locked
    }
    return contentControls
  }
}
