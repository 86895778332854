import FireboltAuthService from '../../lib/firebolt/fireboltAuthService';
import { Metrics } from '@firebolt-js/sdk';
import { AnalyticsErrorBucketCodes, ErrorDescription, ErrorType } from '../analytics/analyticsEnums';
import LoggingService from '../logging/loggingService';
import { LogLevels } from '../logging/loggingEnums';
export class FireboltMetricsService {
    constructor(bucket, code) {
        this._bucket = bucket;
        this._code = code;
    }
    startContent() {
        if (process.env.PROD && FireboltAuthService.getInstance().isHardware) {
            return Metrics.startContent()
                .then((response) => {
                this._log(`Metrics startContent success: ${JSON.stringify(response)}`);
            })
                .catch((err) => {
                this._log(`Metrics startContent failure: ${JSON.stringify(err)}`);
            });
        }
    }
    stopContent() {
        if (process.env.PROD && FireboltAuthService.getInstance().isHardware) {
            return Metrics.stopContent()
                .then((response) => {
                this._log(`Metrics stopContent success: ${JSON.stringify(response)}`);
            })
                .catch((err) => {
                this._log(`Metrics stopContent failure: ${JSON.stringify(err)}`);
            });
        }
    }
    sendFireboltMetricsError() {
        if (process.env.PROD && FireboltAuthService.getInstance().isHardware && this._code) {
            return Metrics.error(this.errorType, this._code, this.errorDescription, this.errorIsUserFacing)
                .then((response) => {
                this._log(`Metrics error success: ${JSON.stringify(response)}. Type: ${this.errorType}, Code: ${this._code}, Description: ${this.errorDescription}, User facing: ${this.errorIsUserFacing}`);
            })
                .catch((err) => {
                this._log(`Metrics error failure: ${JSON.stringify(err)}`);
            });
        }
    }
    get errorType() {
        if (this._bucket === AnalyticsErrorBucketCodes.BLUETOOTH) {
            return ErrorType.OTHER;
        }
        else if (this._code === '401' || this._code === '403') {
            return ErrorType.ENTITLEMENT;
        }
        else {
            return ErrorType.NETWORK;
        }
    }
    get errorDescription() {
        switch (this._bucket) {
            case AnalyticsErrorBucketCodes.BLUETOOTH:
                return ErrorDescription.BLUETOOTH;
            case AnalyticsErrorBucketCodes.PLATFORM:
                return ErrorDescription.PLATFORM;
            case AnalyticsErrorBucketCodes.GAMEPLAY_SERVICE:
                return ErrorDescription.GAMEPLAY_SERVICE;
            case AnalyticsErrorBucketCodes.GENERAL:
                return ErrorDescription.GENERAL;
            default:
                return 'unknown';
        }
    }
    get errorIsUserFacing() {
        // GENERAL and BLUETOOTH bucket errors are considered non user facing for FB metrics
        return (this._bucket !== AnalyticsErrorBucketCodes.GENERAL &&
            this._bucket !== AnalyticsErrorBucketCodes.BLUETOOTH);
    }
    _log(text) {
        LoggingService.getInstance().logMessage(LogLevels.FIREBOLT, text);
    }
}
