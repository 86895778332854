import lng from '@lightningjs/core'
import { context, utils } from '@lightning/ui'
import fallbackImage from '../../static/images/gameBackgrounds/fallback-entity-background.png'
import { setImage } from '../utils/setImage'

export default class EntityBackground extends lng.Component {
  static _template() {
    return {
      w: context.theme.layout.screenW,
      h: context.theme.layout.screenH,
      Background: {
        Image: {
          w: context.theme.layout.screenW,
          h: context.theme.layout.screenH,
          alpha: 0,
          src: '',
        },
        BlurredImage: {
          w: context.theme.layout.screenW,
          h: context.theme.layout.screenH,
          alpha: 0,
          type: lng.components.FastBlurComponent,
          amount: 10,
          content: {
            w: context.theme.layout.screenW,
            h: context.theme.layout.screenH,
            src: '',
          },
        },
        Overlay: {
          rect: true,
          color: 0x3c141417,
          w: context.theme.layout.screenW,
          h: context.theme.layout.screenH,
          Gradient: {
            rect: true,
            colorTop: utils.getHexColor('#1B032D', 0),
            colorBottom: utils.getHexColor('#141417', 1.0),
            w: context.theme.layout.screenW,
            h: context.theme.layout.screenH,
            zIndex: 1,
          },
          GradientHorz: {
            w: 1280,
            h: context.theme.layout.screenH,
            rect: true,
            colorRight: utils.getHexColor('#6138F5', 0),
            colorLeft: utils.getHexColor('#5A23B9', 0.9),
            zIndex: 1,
          },
        },
      },
    }
  }

  _smoothInBackground() {
    this._Background.setSmooth('alpha', 1, { duration: 0.4 })
  }

  _detectBlurAndSetImage(image) {
    if (this.blur) {
      this._BlurredImage.content.src = image
      this._BlurredImage.alpha = 1
      this._smoothInBackground()
    } else {
      this._Image.src = image
      this._Image.alpha = 1
      this._smoothInBackground()
    }
  }

  _active() {
    if (!this.imgSrc) {
      this._detectBlurAndSetImage(fallbackImage)
      return
    }

    setImage({
      url: this.imgSrc,
      loadCallback: () => {
        this._detectBlurAndSetImage(this.imgSrc)
      },
      errCallback: () => {
        this._detectBlurAndSetImage(fallbackImage)
      },
    })
  }

  get _Image() {
    return this.tag('Image')
  }

  get _BlurredImage() {
    return this.tag('BlurredImage')
  }

  get _Background() {
    return this.tag('Background')
  }
}
