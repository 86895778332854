import { TextBox, context } from '@lightningjs/ui-components'
import { PanelTemplate } from '../PanelTemplate'
import { GameplayEndReason } from '../../../lib/gameplay/gameplayInterface'
import XGButton, { corner } from '../../XGButton'

export class ExitConfirmationPanel extends PanelTemplate {
  static _template() {
    return {
      ...super._template(),
      items: [
        {
          type: TextBox,
          skipFocus: true,
          content: 'Are you sure you want to exit the game?',
          centerInParent: true,
          extraItemSpacing: 32,
          style: {
            textStyle: {
              ...context.theme.typography.headline2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: TextBox,
          skipFocus: true,
          content: 'You may lose unsaved progress.',
          centerInParent: true,
          extraItemSpacing: 50,
          style: {
            textStyle: {
              ...context.theme.typography.body2,
              textAlign: 'center',
              wordWrap: true,
              wordWrapWidth: 426,
            },
          },
        },
        {
          type: XGButton,
          subTheme: 'Sidekick',
          rounded: corner.topRight,
          centerInParent: true,
          title: 'Cancel',
          extraItemSpacing: 16,
          w: 386,
          h: 80,
        },
        {
          type: XGButton,
          subTheme: 'Sidekick',
          rounded: corner.bottomLeft,
          centerInParent: true,
          title: 'Exit',
          w: 386,
          h: 80,
        },
      ],
    }
  }

  _init() {
    super._init()
    this._bindFunctions()
    this._CancelBtn.patch({
      onEnter: () => {
        this.fireAncestors('$closeSidekick')
      },
    })

    this._ExitBtn.patch({ onEnter: this.exitCallback })
  }

  _bindFunctions() {
    this.exitCallback = this.exitCallback.bind(this)
  }

  async exitCallback() {
    this._ExitBtn.patch({
      title: 'Exiting...',
      onEnter: () => {},
      mode: 'disabled',
    })

    if (this.exitOverride) {
      this.exitOverride()
      return
    }

    try {
      // make sure to end the party and kick guests out before exiting the game
      if (this.gamingClient?.isHost && this.gamingClient?.partyIsOngoing) {
        await this.gamingClient.endParty()
        // we need to allow the message to get sent and passed through to the guest, so we set a timeout here
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
    } finally {
      this.fireAncestors('$endGame', GameplayEndReason.USER)
      this.fireAncestors('$closeSidekick')
    }
  }

  get _CancelBtn() {
    return this.items[2]
  }

  get _ExitBtn() {
    return this.items[3]
  }
}
