import { Button, context } from '@lightning/ui'

export const corner = {
  bottomLeft: 'BL',
  topRight: 'TR',
  bottomLeftAndTopRight: 'BLTR',
  none: 'none',
}

export default class XGButton extends Button {
  _active() {
    switch (this.rounded) {
      case corner.bottomLeft:
        this._borderRadius = [0, 0, 0, context.theme.radius.md]
        break
      case corner.topRight:
        this._borderRadius = [0, context.theme.radius.md, 0, 0]
        break
      case corner.bottomLeftAndTopRight:
        this._borderRadius = [0, context.theme.radius.md, 0, context.theme.radius.md]
        break
      case corner.none:
        this._borderRadius = [0, 0, 0, 0]
        break
      default:
        // all Button corners to be rounded by default
        this._borderRadius = undefined
        break
    }
    this._borderRadius && this.patch({ style: { radius: this._borderRadius } })
  }
}
