import PlayProvider from './playProvider';
import dataLayerAnalytics from '../../lib/analytics/dataLayerAnalyticsService';
import { RemoteConfig } from '../../lib/remoteConfig';
import api from './api';
function getEntity(entity, { entityId }) {
    return new Promise((resolve) => {
        var _a;
        if (PlayProvider.getInstance().allGamesMap) {
            entity.data = (_a = PlayProvider.getInstance().allGamesMap) === null || _a === void 0 ? void 0 : _a.get(entityId);
            dataLayerAnalytics.processPending();
            resolve(true);
        }
    });
}
async function getHelpArticles(help) {
    help.data = await api
        .get(RemoteConfig.getInstance().documentsBaseURL +
        RemoteConfig.getInstance().helpAndSupportPath +
        RemoteConfig.getInstance().documentsResourcePath)
        .then((response) => {
        return response.data.topics;
    });
    dataLayerAnalytics.processPending();
}
async function getNotices(notices) {
    notices.data = await api
        .get(RemoteConfig.getInstance().documentsBaseURL +
        RemoteConfig.getInstance().noticesPath +
        RemoteConfig.getInstance().documentsResourcePath)
        .then((response) => {
        return response.data.notices;
    });
    dataLayerAnalytics.processPending();
}
async function getPartySlides(slides) {
    slides.data = await api
        .get(RemoteConfig.getInstance().documentsBaseURL +
        RemoteConfig.getInstance().learnPartyPath +
        RemoteConfig.getInstance().documentsResourcePath)
        .then((response) => {
        return response.data.slides;
    });
    dataLayerAnalytics.processPending();
}
export default {
    getEntity,
    getHelpArticles,
    getNotices,
    getPartySlides,
};
