import PageBase from './PageBase'
import ScrollingRow from '../components/ScrollingRow'
import BluetoothSection from '../components/settings/BluetoothSection'
import FireboltAuthService from '../lib/firebolt/fireboltAuthService'
import { MockBluetoothType, RemoteConfig } from '../lib/remoteConfig'
import { BluetoothWrapper } from '../lib/bluetooth/bluetoothWrapper'

export default class BluetoothDeeplinkPage extends PageBase {
  static _template() {
    return {
      ...super._template(),
      hideNav: !RemoteConfig.getInstance().retroModeFlag, // show in retroMode, hide in default mode
      Content: {
        type: ScrollingRow,
        autoResizeWidth: true,
        itemSpacing: 40,
        alwaysScroll: true,
        x: 562,
        y: 85,
        items: [
          {
            type: BluetoothSection,
          },
        ],
      },
    }
  }

  _enable() {
    if (
      RemoteConfig.getInstance().mockBTType === MockBluetoothType.FAKE_CONTROLLERS ||
      FireboltAuthService.getInstance().isHardware
    ) {
      BluetoothWrapper.getInstance()
        .startContinuousScan(30)
        .catch((err) => {
          console.error(JSON.stringify(err))
        })
    }
  }

  _disable() {
    if (
      RemoteConfig.getInstance().mockBTType === MockBluetoothType.FAKE_CONTROLLERS ||
      FireboltAuthService.getInstance().isHardware
    ) {
      BluetoothWrapper.getInstance()
        .stopContinuousScan()
        .catch((err) => {
          console.error(JSON.stringify(err))
        })
    }
  }

  _inactive() {
    BluetoothWrapper.getInstance().clearTimeouts()
  }

  $sectionUpdated(section, sectionIndex) {
    if (sectionIndex > 0) {
      let prevSection = this._Content.Content[sectionIndex - 1]
      let newTitle = prevSection.header == null ? '' : prevSection.header
      this._Breadcrumb.show(newTitle)
    } else {
      this._Breadcrumb.showBasedOnHistory()
    }
  }

  _expandForNav(smooth = true) {
    if (!this.hideNav) {
      // when nav is shown on this page, move it less on expand to prevent Status widget overlap
      const offsetX = this.PAGE_EXPANDED_X_DELTA - 150
      if (smooth) {
        this.smooth = { x: offsetX }
      } else {
        this.patch({ x: offsetX })
      }
    }
  }

  get _Breadcrumb() {
    return this.widgets.breadcrumb
  }

  get _Content() {
    return this.tag('Content')
  }

  _getFocused() {
    return this._Content
  }
}
