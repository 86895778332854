import { CardAbout, context, TextBox } from '@lightning/ui'
import { RemoteConfig } from '../lib/remoteConfig'
import RatingsProvider from '../api/providers/ratingsProvider'

const defaultIndex = '6'
const defaultRating = 'RP'

export default class RatingCard extends CardAbout {
  static _template() {
    return {
      ...super._template(),
      Icon: {
        src: '',
        w: 80,
        h: 120,
        x: 24,
      },
      RatingDetails: {
        flex: {
          direction: 'column',
          justifyContent: 'center',
        },
        RatingTitle: {
          type: TextBox,
          style: {
            textStyle: {
              ...context.theme.typography.button1,
              wordWrap: true,
              wordWrapWidth: 360,
            },
          },
        },
        RatingDescription: {
          type: TextBox,
          h: 186,
          style: {
            textStyle: {
              ...context.theme.typography.body3,
              lineHeight: 28,
              wordWrap: true,
              wordWrapWidth: 360,
            },
          },
        },
      },
    }
  }

  _init() {
    super._init()
    // TODO: hack-for some reason, the title h is 0 here without us manually setting it so the rating details will overlap on first render
    this._Title.h = this._Title.style.textStyle.fontSize
  }

  _firstActive() {
    this._updateRating(this.rating)
  }

  _updateRating(rating) {
    this.data = RatingsProvider.getInstance().getRating(rating)
    this._ratingIcon = `${RemoteConfig.getInstance().documentsBaseURL}${
      RemoteConfig.getInstance().esrbRatingPath
    }${
      this.data?.icon ??
      RatingsProvider.getInstance().getRating(defaultRating)?.icon ??
      RatingsProvider.getInstance().getRating(defaultIndex)?.icon
    }`
    this._ratingLabel =
      this.data?.title ??
      RatingsProvider.getInstance().getRating(defaultRating)?.title ??
      RatingsProvider.getInstance().getRating(defaultIndex)?.title
    this._ratingMeaning =
      this.data?.description ??
      RatingsProvider.getInstance().getRating(defaultRating)?.description ??
      RatingsProvider.getInstance().getRating(defaultIndex)?.description
    this._update()
  }

  _update() {
    super._update()
    this._updateIcon()
    this._updateRatingDetails()
    this._updateRatingDetailsPosition()
  }

  _updateIcon() {
    this._Icon.patch({
      src: this._ratingIcon,
      y: this._Title.y + this._Title.h + 16,
    })
  }

  // Override this in the parent class since we don't use the Description object
  _updateDescription() {}

  _updateRatingDetails() {
    this._RatingTitle.content = this._ratingLabel
    this._RatingDescription.content = this._ratingMeaning
  }

  _updateRatingDetailsPosition() {
    this._RatingDetails.patch({
      x: this._Icon.w + 44,
      y: this._Icon.y - 6,
      w: 360,
    })
  }

  get _Icon() {
    return this.tag('Icon')
  }

  get _RatingDetails() {
    return this.tag('RatingDetails')
  }

  get _RatingTitle() {
    return this.tag('RatingTitle')
  }

  get _RatingDescription() {
    return this.tag('RatingDescription')
  }

  get announce() {
    return [this._Title.content, this._RatingTitle.content, this._RatingDescription.content]
  }
}
