// prettier-ignore
export function promiseRetryWithTimeout(promise, maxRetries, timeout = 50) {
    let tries = maxRetries;
    const wait = (ms) => new Promise((resolve) => {
        setTimeout(() => resolve(null), ms);
    });
    return new Promise((resolve, reject) => {
        promise
            .then((result) => {
            resolve(result);
        })
            .catch((err) => {
            if (tries > 0) {
                return wait(timeout).then(() => {
                    promiseRetryWithTimeout(promise, --tries, timeout)
                        .then(resolve)
                        .catch(reject);
                });
            }
            return reject(err);
        });
    });
}
