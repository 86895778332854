export default class CouchplayDev {
    constructor() {
        this._couchplayDev = false;
    }
    set couchplayDev(isDev) {
        this._couchplayDev = isDev;
    }
    get couchplayDev() {
        return this._couchplayDev;
    }
    static getInstance() {
        if (!CouchplayDev._instance) {
            CouchplayDev._instance = new CouchplayDev();
        }
        return CouchplayDev._instance;
    }
}
