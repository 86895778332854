export var NotificationType;
(function (NotificationType) {
    NotificationType["PAIR"] = "pair";
    NotificationType["UNPAIR"] = "unpair";
    NotificationType["CONNECT"] = "connect";
    NotificationType["DISCONNECT"] = "disconnect";
    NotificationType["JOIN"] = "join";
    NotificationType["LEAVE"] = "leave";
    NotificationType["REMOVE"] = "remove";
    NotificationType["MUTE"] = "mute";
    NotificationType["UNMUTE"] = "unmute";
    NotificationType["FAILED"] = "failed";
    NotificationType["UNAVAILABLE"] = "unavailable";
    NotificationType["IDLE"] = "idletimeout";
    NotificationType["PARTY_ERROR"] = "party_error";
    NotificationType["BAD_NETWORK"] = "bad_network";
    NotificationType["SURVEY_FEEDBACK"] = "survey_feedback";
    NotificationType["CHANGES_SAVED"] = "changes_saved";
    NotificationType["CHANGES_FAILED"] = "changes_failed";
    NotificationType["SEND_CODE_FAILED"] = "send_code_failed";
    NotificationType["RESEND_CODE_FAILED"] = "resend_code_failed";
    NotificationType["AUDIO"] = "audio";
})(NotificationType || (NotificationType = {}));
