/**
 * The built-in lightning router doesn't usefully broadcast route update events.
 * Individual components can subscribe to a route update event here for route-based updates.
 */
class RouteNotifier {
  constructor() {
    this.activeRoute = ''
    this.listeners = []
    this._activeHash = ''
    this._launchHash = ''
    this._requestedHash = ''
  }
  subcribe(handler) {
    this.listeners.push(handler)
  }
  unsubcribe(handlerToRemove) {
    this.listeners = this.listeners.filter((currListener) => currListener !== handlerToRemove)
  }
  updateRoute(newRoute) {
    this.activeRoute = newRoute
    this.listeners.forEach((listener) => {
      listener(newRoute)
    })
  }
  set activeHash(hash) {
    this._activeHash = hash
  }
  get activeHash() {
    return this._activeHash
  }
  setLaunchHash() {
    this._launchHash = this._activeHash
  }
  get launchHash() {
    return this._launchHash
  }
  clearLaunchHash() {
    this._launchHash = ''
  }
  setRequestedHash() {
    this._requestedHash = this._activeHash
  }
  get requestedHash() {
    return this._requestedHash
  }
  clearRequestedHash() {
    this._requestedHash = ''
  }
}

// Export singleton
const routeNotifier = new RouteNotifier()
export default routeNotifier
